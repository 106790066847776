import { useMemo } from 'react';
import { useGetFieldSource } from 'src/features/fieldBuilder/hooks/useGetFieldSource';
import { BuilderField } from 'src/features/fieldBuilder/models';

export const useSource = (field: BuilderField) => {
  const { source } = useGetFieldSource(field.fieldId, field.source);
  return useMemo<{ sourceIds: string[]; uploadPath: string }>(() => {
    if (!source.length) return { sourceIds: [], uploadPath: '' };

    const sourceIds = source.map((item) => item.itemId);
    const uploadPath = source[0].path;

    return { sourceIds, uploadPath };
  }, [source]);
};
