import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Actor } from 'src/features/common/components/filters/models';
import { MenuWithSearch } from 'src/features/common/components/menuWithSearch/MenuWithSearch';
import { useUserFilter } from 'src/features/search/hooks';

type Props = { actor: Actor };

export const UserFilter: FC<Props> = ({ actor }) => {
  const { users, onFilterChange } = useUserFilter(actor);

  return (
    <MenuWithSearch
      title={actor === 'createdby' ? DICTIONARY.HEADER.CREATED : DICTIONARY.HEADER.UPDATED}
      options={users}
      onChange={onFilterChange}
    />
  );
};
