import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { mdiPencilOutline } from '@mdi/js';
import { T } from '@transifex/react';
import moment from 'moment';
import { memo, useCallback, useReducer, useRef } from 'react';
import { DATETIMEPICKER_FORMAT, DATETIME_ISO_REGEX, DICTIONARY, HIDE_VERSION_ID, VALID_FROM_ID, VALID_TO_ID } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useCurrentItem } from 'src/features/common/hooks/useCurrentItem';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useUpdateItemField } from 'src/features/common/hooks/useUpdateItemField';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { UpdateItemInput } from 'src/features/common/models';
import { DatePicker } from 'src/features/temporary/DatePicker/DatePicker';
import { calcDefaultValues, FullDate, publishRestrictionsReducer, today } from 'src/features/versions/reducers/PublishRestrictionsReducers';
import 'src/features/versions/styles/datepicker.scss';
import { DatePickerTimeInput } from './DatePickerTimeInput';

interface PublishRestrictionsModalProps {
  version: number;
  validFrom: string;
  validTo: string;
  isHidden: string;
}

const convertToIsoFormat = ({ active, date }: FullDate): string =>
  active ? moment(date).toISOString().replace(DATETIME_ISO_REGEX, '') : '';

export const PublishRestrictionsModal = memo(({ version, validFrom, validTo, isHidden }: PublishRestrictionsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);
  const [state, dispatch] = useReducer(publishRestrictionsReducer, calcDefaultValues(validFrom, validTo, isHidden));
  const { currentItem, refetch } = useCurrentItem();
  const { sc_lang } = useUrlStrictContext(UrlParams.sc_lang);
  const { mutateAsync: updateItemField } = useUpdateItemField();
  const toast = useToast();
  const t = useTranslate();

  const handleSave = useCallback(async () => {
    const input: UpdateItemInput = {
      itemId: currentItem?.itemId || '',
      language: sc_lang,
      version: version,
      fields: [
        {
          name: VALID_FROM_ID,
          value: convertToIsoFormat(state.start),
        },
        {
          name: VALID_TO_ID,
          value: convertToIsoFormat(state.end),
        },
        {
          name: HIDE_VERSION_ID,
          value: state.isAvailable ? '' : '1',
        },
      ],
    };
    const data = await updateItemField(input);

    if (data && !data.data.errors) {
      const result = data.data.data.updateItem.item;

      if (result && currentItem && result.itemId === currentItem?.itemId) {
        await refetch();

        toast({ status: 'success', description: t(DICTIONARY.NOTIFICATIONS.PUBLISH_RESTRICTIONS) });
      } else {
        toast({ status: 'error', description: t(DICTIONARY.NOTIFICATIONS.SOMETHING_WENT_WRONG) });
      }
    } else {
      toast({ status: 'error', description: data.data.errors[0].message });
    }

    onClose();
  }, [currentItem, sc_lang, version, state, updateItemField, onClose, toast, t, refetch]);

  const handleAvailableToggle = useCallback((index: number) => {
    dispatch({ type: 'toggleAvailable', value: index === 0 ? true : false });
  }, []);

  return (
    <>
      {currentItem?.access.canWrite && (
        <Tooltip label={t(DICTIONARY.EDIT)}>
          <IconButton
            onClick={onOpen}
            variant="ghost"
            size="xs"
            ml="2"
            aria-label={t(DICTIONARY.EDIT_SCHEDULE)}
            icon={<Icon path={mdiPencilOutline} boxSize="5" />}
          />
        </Tooltip>
      )}
      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef} size="lg">
        <ModalOverlay />
        <ModalContent>
          <form data-testid="schedule-publish" onSubmit={(e) => e.preventDefault()}>
            <ModalHeader pb="0">
              <T _str={DICTIONARY.SCHEDULE_PUBLISH} />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text variant="strong" my="4">
                <T _str={DICTIONARY.TIMEZONE} />
                <Text as="span" fontWeight="normal">
                  {new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4)}
                </Text>
                <Text as="span" variant="subtle" fontStyle="italic" fontWeight="normal" ml="2">
                  <T _str={DICTIONARY.TIMEZONE_IS_ON_SERVER} />
                </Text>
              </Text>
              <Flex>
                <Tabs
                  index={+!state.isAvailable}
                  onChange={handleAvailableToggle}
                  variant="soft-rounded"
                  borderRadius="full"
                  border="1px"
                  borderColor="chakra-border-color"
                  p="1"
                >
                  <TabList>
                    <Tab>
                      <T _str={DICTIONARY.AVAILABLE} />
                    </Tab>
                    <Tab>
                      <T _str={DICTIONARY.NOT_AVAILABLE} />
                    </Tab>
                  </TabList>
                </Tabs>
              </Flex>

              <Box py="3" />

              <FormControl>
                <FormLabel htmlFor="removed">
                  <T _str={DICTIONARY.START_DATE} /> (<T _str={DICTIONARY.OPTIONAL} />)
                </FormLabel>
                <Flex alignItems="center">
                  <Box>
                    <DatePicker
                      onChange={(value: Date) => dispatch({ type: 'changeStartDate', value })}
                      selected={state.start.date}
                      maxDate={state.end.date}
                      minDate={today}
                      dateFormat={DATETIMEPICKER_FORMAT}
                      showTimeInput
                      customTimeInput={<DatePickerTimeInput />}
                    />
                  </Box>
                  <Button
                    variant="ghost"
                    colorScheme="primary"
                    size="sm"
                    ml="2"
                    data-testid="clearStart"
                    onClick={() => dispatch({ type: 'clearStart' })}
                    visibility={state.start.active ? 'visible' : 'hidden'}
                  >
                    <T _str={DICTIONARY.CLEAR} />
                  </Button>
                </Flex>
                <FormHelperText>
                  <T _str={DICTIONARY.START_DATE_INFO} />
                </FormHelperText>

                <Box py="2" />

                <FormLabel htmlFor="removed">
                  <T _str={DICTIONARY.END_DATE} /> (<T _str={DICTIONARY.OPTIONAL} />)
                </FormLabel>
                <Flex alignItems="center">
                  <Box>
                    <DatePicker
                      onChange={(value: Date) => dispatch({ type: 'changeEndDate', value })}
                      selected={state.end.date}
                      minDate={state.start.date || today}
                      dateFormat={DATETIMEPICKER_FORMAT}
                      showTimeInput
                      customTimeInput={<DatePickerTimeInput />}
                    />
                  </Box>
                  <Button
                    variant="ghost"
                    colorScheme="primary"
                    size="sm"
                    ml="2"
                    data-testid="clearEnd"
                    pl="3"
                    onClick={() => dispatch({ type: 'clearEnd' })}
                    visibility={state.end.active ? 'visible' : 'hidden'}
                  >
                    <T _str={DICTIONARY.CLEAR} />
                  </Button>
                </Flex>
                <FormHelperText>
                  <T _str={DICTIONARY.END_DATE_INFO} />
                </FormHelperText>
              </FormControl>

              <Box py="2" />

              <Button variant="ghost" size="xs" colorScheme="danger" onClick={() => dispatch({ type: 'clearAll' })}>
                <T _str={DICTIONARY.CLEAR_SETTINGS} />
              </Button>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button data-testid="cancel-btn" variant="ghost" onClick={onClose}>
                  <T _str={DICTIONARY.CANCEL} />
                </Button>
                <Button data-testid="submit-btn" variant="solid" onClick={handleSave} type="submit">
                  <T _str={DICTIONARY.SAVE} />
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
});

PublishRestrictionsModal.displayName = PublishRestrictionsModal.name;
