import { useCallback } from 'react';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { BuilderField, FormikFieldState } from 'src/features/fieldBuilder/models';

export const useFormikFieldActions = (field: BuilderField) => {
  const { builderField, updateField, resetField } = useFieldActions(field);

  const onSubmit = useCallback(
    (values: FormikFieldState) => {
      updateField(field.fieldId, values[field.name] ?? '');
    },
    [field.name, field.fieldId, updateField],
  );

  const onReset = useCallback(
    async (values: FormikFieldState) => {
      const newValue = await resetField(field.fieldId);

      values[field.name] = newValue?.value;
      return values;
    },
    [field.name, field.fieldId, resetField],
  );

  return {
    builderField,
    onSubmit,
    onReset,
  };
};
