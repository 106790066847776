import { Flex, Image, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY, SC_ICONS_SRC } from 'src/constants';

export const WelcomeInstruction: React.FC = () => (
  <Flex data-testid="welcome-instructions" align="center" boxSize="full" overflowY="auto" direction="column">
    <Image pt="20" width="20" src={SC_ICONS_SRC.ROCKET} />
    <Text py="6" textAlign="center">
      <T _str={DICTIONARY.WELCOME_INSTRUCTIONS_TEXT[0]} /> <br /> <T _str={DICTIONARY.WELCOME_INSTRUCTIONS_TEXT[1]} />
    </Text>
  </Flex>
);
