import { Hide, Th, Tr } from '@chakra-ui/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { TableHeadCell } from 'src/features/shared/treeTable/components/TableHeadCell';
import { useSortingParametersReturnType } from 'src/features/shared/treeTable/hooks/useSortingParameters';

export type TableHeaderProps = {
  showLanguages: boolean;
  showWorkflow: boolean;
} & useSortingParametersReturnType;

export const TableHeader: FC<TableHeaderProps> = (props) => {
  const { showLanguages, showWorkflow } = props;
  return (
    <Tr boxShadow="xs">
      <TableHeadCell text={DICTIONARY.HEADER.NAME} field="name" {...props} />
      <TableHeadCell text={DICTIONARY.HEADER.TYPE} field="type" {...props} />
      <Hide below="md">
        <TableHeadCell text={DICTIONARY.HEADER.CREATED} field="createdBy" {...props} />
        <TableHeadCell text={DICTIONARY.HEADER.UPDATED} field="updatedBy" {...props} />
      </Hide>
      {showLanguages && <TableHeadCell text={DICTIONARY.HEADER.LANGUAGE} field="languages" {...props} />}
      {showWorkflow && <TableHeadCell text={DICTIONARY.HEADER.WORKFLOW} field="workflow" {...props} />}
      <Th />
    </Tr>
  );
};
