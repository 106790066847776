import { Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useMemo } from 'react';
import { DICTIONARY } from 'src/constants';
import { DetailedItem } from 'src/features/common/models';
import { getGreatestVersion } from 'src/features/fieldEditor/components/FieldEditor';
import { WorkflowState } from 'src/features/workflow/components';

interface ItemBadgesProps {
  item: DetailedItem | undefined;
}

export const ItemBadges: FC<ItemBadgesProps> = ({ item }) => {
  const workflowState = useMemo(() => item?.workflow?.workflowState, [item?.workflow?.workflowState]);
  const workflow = useMemo(() => item?.workflow?.workflow, [item?.workflow?.workflow]);
  const version = useMemo(() => getGreatestVersion(item), [item]);

  return (
    <>
      <WorkflowState
        workflowName={workflow?.displayName || ''}
        name={workflowState?.displayName || ''}
        isFinal={workflowState?.final || false}
        maxWidth="lg"
      />
      <Text variant="tiny" fontWeight="semibold" marginLeft={2}>
        {!!version && <T _str={DICTIONARY.VERSION_NUMBER_WITH_DOTS} version={version} />}
      </Text>
    </>
  );
};
