import { SitesOption, SolutionSite } from 'src/features/common/models';

export const filterSites = (sites: SolutionSite[], options: Partial<SitesOption>): SolutionSite[] => {
  return sites
    ?.filter((f) => (options.showTemplateSites ? true : f.isTemplateSite !== 'true'))
    .filter((f) => (!options.sc_type ? true : options.sc_type === 'sxa' ? !!f.siteCollection : !f.siteCollection))
    .filter((f) =>
      !options.sc_collection
        ? true
        : options.sc_collection.split(',').find((collectionName) => f.siteCollection?.displayName === collectionName),
    );
};
