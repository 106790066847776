/* istanbul ignore file */

import { print } from 'graphql';
import { loader } from 'graphql.macro';

export const getItemFieldsQuery = loader('./getItemFields.graphql');
export const getItemFieldsQueryString = print(getItemFieldsQuery);

export const updateItemFieldQuery = loader('./updateItemField.graphql');
export const updateItemFieldQueryString = print(updateItemFieldQuery);

export const useGetFieldSourceQuery = loader('./useGetFieldSource.graphql');
export const useGetFieldSourceQueryString = print(useGetFieldSourceQuery);
