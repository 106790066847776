import { TableCellProps, Text, Th } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

interface SearchThProps extends PropsWithChildren, TableCellProps {}

export const SearchTh: FC<SearchThProps> = ({ children, ...props }) => {
  return (
    <Th {...props} px="4" py="2">
      <Text fontSize="xs" fontWeight="semibold">
        {children}
      </Text>
    </Th>
  );
};
