import { useMemo } from 'react';
import { Actor } from 'src/features/common/components/filters/models';
import { SearchCriteriaInput, SearchCriteriaType, SearchOperator } from 'src/features/search/models';
import { FILTER_FIELDS } from 'src/features/search/searchConstants';

export const useUserFilterCriteria = (params: URLSearchParams, actor: Actor): SearchCriteriaInput[] | undefined => {
  const users = useMemo(() => params.get(actor)?.split(',') || [], [actor, params]);

  return useMemo<SearchCriteriaInput[] | undefined>(() => {
    if (!users.length) return undefined;

    return users.map<SearchCriteriaInput>((user) => ({
      field: actor === 'createdby' ? FILTER_FIELDS.CREATED_BY : FILTER_FIELDS.MODIFIED_BY,
      value: user.replaceAll('\\', '').toLowerCase(),
      criteriaType: SearchCriteriaType.EXACT,
      operator: SearchOperator.SHOULD,
    }));
  }, [actor, users]);
};
