import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

export const useRefetchFieldEditorData = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (params: string[]) => {
      queryClient.fetchQuery(['get-field-editor-item', ...params], { staleTime: 0 });
    },
    [queryClient],
  );
};
