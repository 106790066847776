import { useMemo } from 'react';
import { useGetMediaData } from 'src/features/shared/selectMediaModal/hooks';
import { convertToImageSrc, FILE_TEMPLATES, getMediaItemType, IMAGE_TEMPLATES } from 'src/features/shared/selectMediaModal/mediaConstants';
import { MediaItem, MediaItemData } from 'src/features/shared/selectMediaModal/models';

const getTemplateType = (mediaItem: MediaItem) => {
  const templates = mediaItem?.template?.baseTemplates?.nodes?.map((el) => el.templateId) ?? [];
  return getMediaItemType([mediaItem?.template?.templateId ?? '', ...templates]);
};

export const useGetMediaDataWrapper = (itemId: string, selectType: 'image' | 'file' | 'link') => {
  const { data, isLoading, refetch, url } = useGetMediaData(itemId, selectType === 'image' ? IMAGE_TEMPLATES : FILE_TEMPLATES);

  const mediaDataResult = useMemo<{ mediaDataItems: MediaItemData[]; isWrongSource: boolean }>(() => {
    let mediaDataItems: MediaItemData[] = [];
    const rootItem = data?.pages[0].data.data.item;

    const rootItemType = getTemplateType(rootItem as MediaItem);

    if (rootItemType && rootItem && rootItemType !== 'None' && rootItemType !== 'MediaFolder') {
      if (selectType === 'image' && rootItemType !== 'Image') {
        return { mediaDataItems: [], isWrongSource: true };
      }

      mediaDataItems.push({
        ...rootItem,
        dimensions: rootItem.dimensions?.value ?? '',
        extension: rootItem.extension?.value ?? '',
        size: rootItem.size?.value ?? '',
        src: rootItemType === 'Image' ? convertToImageSrc(url, rootItem.path) : '',
        type: rootItemType,
      });

      return { mediaDataItems, isWrongSource: true };
    }

    if (rootItemType !== 'MediaFolder') {
      return { mediaDataItems: [], isWrongSource: true };
    }

    const mediaItems = data?.pages.flatMap((page) => page?.data?.data?.item?.children?.nodes || []) || [];

    if (!mediaItems.length) return { mediaDataItems: [], isWrongSource: false };

    mediaDataItems = mediaItems.map<MediaItemData>((mediaItem) => {
      const type = getTemplateType(mediaItem);

      return {
        ...mediaItem,
        dimensions: mediaItem.dimensions?.value ?? '',
        extension: mediaItem.extension?.value ?? '',
        size: mediaItem.size?.value ?? '',
        src: type === 'Image' ? convertToImageSrc(url, mediaItem.path) : '',
        type,
      };
    });

    return { mediaDataItems: mediaDataItems ?? [], isWrongSource: false };
  }, [data?.pages, selectType, url]);

  return {
    mediaDataItems: mediaDataResult.mediaDataItems,
    isWrongSource: mediaDataResult.isWrongSource,
    isMediaDataLoading: isLoading,
    refetch,
  };
};
