import { FC } from 'react';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { DetailedItem } from 'src/features/common/models';
import { PublishButton } from 'src/features/publish';
import { WorkflowCommandsContainer } from 'src/features/workflow/components';

interface ActionButtonsProps {
  item: DetailedItem | undefined;
  refetch: () => void;
}

export const ActionButtons: FC<ActionButtonsProps> = ({ item, refetch }) => {
  const { sc_lang } = useUrlStrictContext(UrlParams.sc_lang);

  return (
    <>
      {item && item.workflow?.workflow?.workflowId && (
        <WorkflowCommandsContainer item={item} language={sc_lang} onWorkflowUpdate={refetch} />
      )}
      <PublishButton />
    </>
  );
};
