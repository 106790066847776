import { useMemo } from 'react';
import { CLIENT_LANGUAGE } from 'src/constants';
import { isSameGuid } from 'src/features/common/utils/guidUpdater';
import { useGetAncestors } from 'src/features/shared/selectMediaModal/hooks/useGetAncestors';

export const useGetPreSelectedItemAncestors = (
  itemId: string | undefined,
  sourceIds: string[] | undefined,
): { firstAncestorIds: string[]; isLoading: boolean } => {
  const { data, isLoading } = useGetAncestors(
    { id: itemId || '', lang: CLIENT_LANGUAGE },
    {
      enabled: !!itemId,
    },
  );

  const item = data?.data?.data?.item;

  const firstAncestorIds = useMemo(() => {
    const list = item?.ancestors;
    if (!list || !list.length) {
      return [];
    }
    if (sourceIds && sourceIds.length) {
      const parentById = list.find((anc) => sourceIds.find((sId) => isSameGuid(anc.itemId, sId)));
      if (!parentById) {
        return [];
      }
    }

    return [list[list.length - 1].itemId];
  }, [item?.ancestors, sourceIds]);

  return { firstAncestorIds, isLoading };
};
