import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { viewModes, ViewSwitcherModes } from 'src/features/common/components/actionsBar/models';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';

type UseViewSwitcherModes = () => { viewMode: ViewSwitcherModes; setViewMode: (value: ViewSwitcherModes) => void };

export const useViewSwitcherModes: UseViewSwitcherModes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const viewMode: ViewSwitcherModes = useMemo(() => {
    const viewModeParam = location.pathname.split('/')[1] as ViewSwitcherModes;

    switch (viewModeParam) {
      case viewModes.list:
      case viewModes.columns:
        return viewModeParam;
      default:
        return viewModes.columns;
    }
  }, [location.pathname]);

  const setViewMode = useCallback(
    (value: ViewSwitcherModes) => {
      if (value === viewMode) return;

      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(UrlParams[UrlParams.sc_itemid]);
      searchParams.delete(UrlParams[UrlParams.sc_site]);
      location.search = searchParams.toString();

      navigate({ ...location, pathname: `/${value}` });
    },
    [location, navigate, viewMode],
  );

  return { viewMode, setViewMode };
};
