import { Box } from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { ScrollToTopButton } from 'src/features/common/components/scrollToTopButton/components/ScrollToTopButton';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { SearchTableView } from 'src/features/search/components';
import { TreeTable } from 'src/features/shared/treeTable/components/TreeTable';
import { TreeTableDataProvider } from 'src/features/shared/treeTable/components/TreeTableRefetchContext';
import { useOpenFieldEditor } from 'src/features/shared/treeTable/hooks/useOpenFieldEditor';

export const TreeTableListView: FC = () => {
  const { sc_lang } = useUrlStrictContext(UrlParams.sc_lang);
  const openFieldEditor = useOpenFieldEditor();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <TreeTableDataProvider>
      <Box width="full" height="full" overflow="auto" ref={ref}>
        <SearchTableView boxRef={ref} fallback={<TreeTable openFieldEditor={openFieldEditor} language={sc_lang} />} />
        <ScrollToTopButton boxRef={ref} />
        <Outlet />
      </Box>
    </TreeTableDataProvider>
  );
};
