import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptions } from 'react-query';
import { CLIENT_LANGUAGE } from 'src/constants';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { AncestorsResponseData, ItemWithAncestors } from 'src/features/common/treeComponent/models';
import { getAncestorsQueryString } from '../queries';

type ItemWithAncestorsGraphQLResponse = GraphQLResponse<ItemWithAncestors>;
type ItemWithAncestorsResponse = AxiosResponse<ItemWithAncestorsGraphQLResponse>;

const useGetSelectedItemAncestors = (
  input: { path: string; language: string },
  useQueryOptions?:
    | Omit<UseQueryOptions<ItemWithAncestorsResponse, unknown, ItemWithAncestorsResponse, string[]>, 'queryKey' | 'queryFn'>
    | undefined,
): AncestorsResponseData => {
  const { url } = useXMTenant();
  const toast = useToast();

  const { data, isLoading } = useAuthQuery(
    ['get-selected-item-ancestors', input.path, input.language],
    async (axiosInstance) => {
      return axiosInstance?.post<ItemWithAncestorsGraphQLResponse>(getSitecoreGraphQLEndpoint(url), {
        query: getAncestorsQueryString,
        variables: {
          path: input.path,
          language: input.language,
        },
      });
    },
    { ...useQueryOptions },
    toast,
  );

  const ancestors = useMemo(() => {
    if (isLoading) {
      return;
    }

    return data?.data?.data?.item?.ancestors?.map<string>((anc) => anc.itemId);
  }, [data, isLoading]);

  return { ancestors, isLoading };
};

export const useGetSelectedItemAncestorsWrapper = (path: string, enabled: boolean, language?: string | null): AncestorsResponseData =>
  useGetSelectedItemAncestors({ path, language: language ?? CLIENT_LANGUAGE }, { enabled });
