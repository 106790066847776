import { Box, Center, Image, ImageProps, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { THUMBNAIL } from 'src/constants';
import { useGetLanguageItem } from 'src/features/languageSwitcher/hooks/useGetLanguageItem';
import { useDisplayOptions } from 'src/features/millerColumns/hooks/useDisplayOptions';
import { ContentTemplateData } from 'src/features/shared/millerColumns/models';
import { WorkflowState } from 'src/features/workflow/components';
import { MillerItemTemplateData } from '../models/MillerItemTemplateData';

const thumbnailUrlProps: ImageProps = { borderRadius: 'base', w: `${THUMBNAIL.SIZE.WIDTH}`, h: `${THUMBNAIL.SIZE.HEIGHT}` };

export const MillerColumnItemTemplate = ({ data }: ContentTemplateData<MillerItemTemplateData>) => {
  const { showLanguages, showThumbnail, showWorkflow } = useDisplayOptions();
  const { selectedLanguage } = useGetLanguageItem();

  const shownLanguages = data.languages.length > 7 ? data.languages.slice(0, 6) : data.languages;
  const extraLanguages = data.languages.length > 7 ? data.languages.slice(6) : [];
  const allLanguagesText: string = data.languages.join(', ').toUpperCase();
  const isSelectedInExtraIndex: number = extraLanguages.findIndex((f) => f === selectedLanguage?.iso);

  return (
    <>
      <Box
        display="flex"
        mr="3"
        data-testid="item-thumbnail"
        bgColor="gray.50"
        border="1px solid"
        borderColor="chakra-border-color"
        borderRadius="base"
      >
        {showThumbnail && data.thumbnailUrl ? (
          <Image {...thumbnailUrlProps} src={data.thumbnailUrl} />
        ) : (
          <Center {...thumbnailUrlProps} data-testid="item-icon">
            {data.icon}
          </Center>
        )}
      </Box>
      <Box data-testid="item-settings" flex="1" alignItems="start" lineHeight="150%" isTruncated>
        {data.itemType === 'site' && (
          <Text variant="small" textTransform="capitalize" data-testid="item-type">
            <T _str={data.itemType} />
          </Text>
        )}
        <Text variant="strong" title={data.displayName} isTruncated data-testid="item-name" color="chakra-body-text">
          {data.displayName}
        </Text>
        {showWorkflow && (
          <WorkflowState
            workflowName={data.workflow?.displayName}
            name={data.workflowState?.displayName}
            isFinal={data.workflowState?.final || false}
            maxWidth="full"
            data-testid="item-workflow"
          />
        )}
        {showLanguages && data.languages && (
          <Text textTransform="uppercase" isTruncated color="chakra-subtle-text" data-testid="item-languages">
            {shownLanguages.map((language, index) => (
              <Text as="span" key={language + data.id}>
                <Text
                  as="span"
                  variant="tiny"
                  color={language === selectedLanguage?.iso ? 'success.500' : 'chakra-subtle-text'}
                  data-testid={language === selectedLanguage?.iso ? 'item-active-language' : 'item-language'}
                >
                  {language}
                </Text>
                {shownLanguages.length - 1 !== index && <Text as="span">, </Text>}
              </Text>
            ))}
            {extraLanguages.length > 0 && (
              <Text
                as="u"
                variant="tiny"
                pl="1"
                title={allLanguagesText}
                color={isSelectedInExtraIndex !== -1 ? 'success.500' : 'chakra-subtle-text'}
              >
                +{extraLanguages.length}
              </Text>
            )}
          </Text>
        )}
      </Box>
    </>
  );
};
