import { FC, useCallback, useEffect } from 'react';
import { SECTIONS_ERRORS_STORAGE_KEY } from 'src/constants';
import { useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { fullUrlContext } from 'src/features/common/hooks/useUrlStrictContext';
import { ValidationErrors } from 'src/features/fieldBuilder/models';
import { useEventListener, useSessionStorage } from 'usehooks-ts';

export const AppEvents: FC = () => {
  const [, setErrors] = useSessionStorage<ValidationErrors[]>(SECTIONS_ERRORS_STORAGE_KEY, []);

  const onUnload = useCallback(() => sessionStorage.removeItem(SECTIONS_ERRORS_STORAGE_KEY), []);
  useEventListener('unload', onUnload);

  const { urlContext } = useUrlSharing(fullUrlContext);

  useEffect(() => setErrors([]), [urlContext, setErrors]);

  return <></>;
};
