import { FC, memo } from 'react';
import { useGainsighSetIdentifyFields, useGainsightSetGlobalContext } from 'src/features/common/components/gainsight/hooks';

const GainsightMetricsRegistration: FC = () => {
  useGainsighSetIdentifyFields();
  useGainsightSetGlobalContext();

  return <></>;
};

export default memo(GainsightMetricsRegistration);
