import { useMemo } from 'react';
import { CLIENT_LANGUAGE, isGuid } from 'src/constants';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { ExtendedItemViewModel } from 'src/features/common/treeComponent/models';
import { useGetMediaItem } from 'src/features/shared/selectMediaModal/hooks/useGetMediaItem';

export const useGetMediaItemWrapper = (itemId: string, enabled: boolean) => {
  const inputSource = isGuid(itemId) ? { id: itemId } : { path: itemId };
  const { urlContext } = useUrlSharing(UrlParams.sc_lang);
  const { data, isLoading } = useGetMediaItem(
    {
      id: undefined,
      ...inputSource,
      lang: urlContext.sc_lang || CLIENT_LANGUAGE,
    },
    {
      enabled: enabled,
    },
  );

  const item = useMemo(() => {
    const sitecoreItem = data;
    if (!sitecoreItem) {
      return undefined;
    }

    const children = sitecoreItem?.children.map<ExtendedItemViewModel>((it) => ({ ...it, children: undefined }));
    return { ...sitecoreItem, children } as ExtendedItemViewModel;
  }, [data]);

  return { item, isLoading };
};
