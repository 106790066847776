/* istanbul ignore file */

import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface GenericErrorTemplateProps {
  /** The title of the page */
  title?: ReactNode | string;
  /** Optional subtext of the page */
  subtext?: ReactNode | string;
  /** Main error text */
  text: ReactNode | string;
  /** Links */
  shortcuts?: ReactNode;
  /** Variant types */
  variant: 'error' | 'bad-request' | 'unauthorized' | 'access-denied' | 'not-found' | 'server-error' | 'unavailable';
}

export const variants = {
  error: {
    illustration: {
      colored: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert',
      grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-neutral',
    },
    label: 'Something went wrong',
    error: null,
  },
  'bad-request': {
    illustration: {
      colored: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle',
      grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle-neutral',
    },
    label: 'Bad request',
    error: 'Error 400',
  },
  unauthorized: {
    illustration: {
      colored: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-stop',
      grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-stop-neutral',
    },
    label: 'Unauthorized',
    error: 'Error 401',
  },
  'access-denied': {
    illustration: {
      colored: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-lock',
      grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-lock-neutral',
    },
    label: 'Forbidden',
    error: 'Error 403',
  },
  'not-found': {
    illustration: {
      colored: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-map-search',
      grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-map-search-neutral',
    },
    label: 'Page not found',
    error: 'Error 404',
  },
  'server-error': {
    illustration: {
      colored: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle',
      grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle-neutral',
    },
    label: 'Internal server error',
    error: 'Error 500',
  },
  unavailable: {
    illustration: {
      colored: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-wrench-clock',
      grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-wrench-clock-neutral',
    },
    label: 'Service Unavailable',
    error: 'Error 503',
  },
};

const GenericErrorTemplate: React.FC<GenericErrorTemplateProps> = ({ title, subtext, text, variant, shortcuts }) => {
  const activeVariant = variants[variant];

  return (
    <Container pt={9} py={8} pb={12} height="100%" width="100%">
      <Container maxW="sm" width="sm" pt={24}>
        <Flex justify="center" flexDirection="column" alignItems="center">
          <Box borderRadius="2xl" display="flex" alignItems="center" justifyContent="center">
            <Image width={28} height={28} src={activeVariant.illustration.colored} alt={activeVariant.label} />
          </Box>
          <Heading size="xl" textAlign="center" pt="8">
            {title || activeVariant.label}
          </Heading>
          <Text textAlign="center" variant="subtle" pt="2">
            {subtext || activeVariant.error}
          </Text>
          <Text textAlign="center" py="6" size="md" color="gray.800">
            {text}
          </Text>
          {shortcuts && (
            <Box display="flex" flexDirection="column" alignItems="center">
              {shortcuts}
            </Box>
          )}
        </Flex>
      </Container>
    </Container>
  );
};
export { GenericErrorTemplate };
