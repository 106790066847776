import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { DICTIONARY, MASTER_DB } from 'src/constants';
import { useCreateFullItem } from 'src/features/common/hooks/useCreateFullItem';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { CreateItemInput } from 'src/features/common/models';
import { ItemType } from 'src/features/shared/createItem/models';
import { NestedItems } from 'src/features/shared/treeTable/models';

export const useCreateItemModal = (
  templateId = '',
  parent = '',
  onClose = () => {},
  onCreateSuccess: (item: NestedItems, type: string) => void,
) => {
  const { mutateAsync: createMutation, isLoading } = useCreateFullItem();
  const { sc_lang } = useUrlStrictContext(UrlParams.sc_lang);
  const t = useTranslate();
  const toast = useToast();

  const createItem = useCallback(
    async (name: string, type: ItemType) => {
      const input: CreateItemInput = { language: sc_lang, name, parent, templateId, database: MASTER_DB };
      const result = await createMutation(input);

      if (!result.data.errors) {
        const itemId = result?.data?.data?.createItem?.item?.itemId;

        itemId
          ? onCreateSuccess(result?.data?.data?.createItem?.item, type)
          : toast({ description: t(DICTIONARY.NOTIFICATIONS.SOMETHING_WENT_WRONG), status: 'error' });
      } else {
        toast({ description: t(result.data.errors[0].message), status: 'error' });
      }

      onClose();
    },
    [sc_lang, parent, templateId, createMutation, onClose, toast, t, onCreateSuccess],
  );

  return { createItem, isLoading };
};
