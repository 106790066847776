import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { mdiClose, mdiMagnify } from '@mdi/js';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useMediaItemSearch } from 'src/features/shared/selectMediaModal/hooks/useMediaItemSearch';
import { useSearchInput } from 'src/features/shared/selectMediaModal/hooks/useSearchInput';

export type SearchInputProps = Pick<ReturnType<typeof useMediaItemSearch>, 'searchInput' | 'setSearchInput'> & {
  isDisabled: boolean;
};

export const SearchInput: FC<SearchInputProps> = ({ searchInput, setSearchInput, isDisabled }) => {
  const {
    setClearButtonVisibility,
    clearButtonVisibility,
    setClearButtonVisibilityConditionally,
    setSearchInputAndClearButtonVisibility,
    setSearchInputAndClearButtonVisibilityOnChange,
  } = useSearchInput(searchInput, setSearchInput);

  return (
    <InputGroup onMouseOver={setClearButtonVisibilityConditionally} onMouseOut={() => setClearButtonVisibility('hidden')} maxWidth={80}>
      <InputLeftElement>
        <Icon path={mdiMagnify} boxSize="6" color="neutral" />
      </InputLeftElement>
      <Input
        placeholder={DICTIONARY.SEARCH}
        value={searchInput}
        onChange={setSearchInputAndClearButtonVisibilityOnChange}
        data-testid="filterInput"
        disabled={isDisabled}
      />
      <InputRightElement
        style={{ visibility: clearButtonVisibility }}
        onClick={() => setSearchInputAndClearButtonVisibility('')}
        data-testid="resetSearchButton"
      >
        <Icon path={mdiClose} boxSize="4" color="neutral" />
      </InputRightElement>
    </InputGroup>
  );
};
