import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { CREATE_ITEM_TOAST_DURATION } from 'src/constants';
import { useUpdateSearchTable } from 'src/features/search/hooks/useUpdateSearchTable';
import { CreateItemToastDescription } from 'src/features/shared/createItemToastDescription/CreateItemToastDescription';
import { NestedItems } from 'src/features/shared/treeTable/models';

export const useCreateItemMenuSearchTableWrapper = () => {
  const updateSearchTable = useUpdateSearchTable();
  const toast = useToast();

  return useCallback(
    async (newItem: NestedItems) => {
      updateSearchTable();
      toast({
        description: <CreateItemToastDescription name={newItem.name} itemId={newItem.itemId} />,
        status: 'success',
        isClosable: true,
        duration: CREATE_ITEM_TOAST_DURATION,
      });
    },
    [toast, updateSearchTable],
  );
};
