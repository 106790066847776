import { FC } from 'react';
import { ActionsMenuProps, ExcludedActionCallbacks } from 'src/features/fieldBuilder/models';
import { useActionsMenuTableListWrapper } from '../hooks/useActionsMenuTableListWrapper';
import { ActionsMenu } from './ActionsMenu';

export const ActionsMenuTableListWrapper: FC<Omit<ActionsMenuProps, ExcludedActionCallbacks>> = ({
  hasPresentation,
  canCreate,
  canDelete,
  itemId,
  name,
  hasChildren,
  parentItemId,
  ...props
}) => {
  return (
    <ActionsMenu
      canCreate={canCreate}
      canDelete={canDelete}
      hasChildren={hasChildren}
      hasPresentation={hasPresentation}
      itemId={itemId}
      name={name}
      parentItemId={parentItemId}
      {...props}
      {...useActionsMenuTableListWrapper()}
    />
  );
};
