import { Hide, Tr } from '@chakra-ui/react';
import { FC } from 'react';
import { useGetLanguageItem } from 'src/features/languageSwitcher/hooks/useGetLanguageItem';
import { ActionsCell } from 'src/features/shared/treeTable/components/ActionsCell';
import { ItemTypeCell } from 'src/features/shared/treeTable/components/ItemTypeCell';
import { LanguageCell } from 'src/features/shared/treeTable/components/LanguageCell';
import { NameCell } from 'src/features/shared/treeTable/components/NameCell';
import { TableCell } from 'src/features/shared/treeTable/components/TableCell';
import { RefetchFunction } from 'src/features/shared/treeTable/components/TreeTableRefetchContext';
import { TreeTableRowProps } from 'src/features/shared/treeTable/components/TreeTableRow';
import { UserCell } from 'src/features/shared/treeTable/components/UserCell';
import { WorkflowStateCell } from 'src/features/shared/treeTable/components/WorkFlowStateCell';

export type TreeTrProps = {
  currentLanguage: string;
  nameCellPadding: string;
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  onOpenEditor: () => void;
  refetch: RefetchFunction;
} & TreeTableRowProps;

export const TreeTr: FC<TreeTrProps> = (props) => {
  const {
    nameCellPadding,
    showLanguages,
    showThumbnail,
    showWorkflow,
    refetchParent,
    isExpanded,
    setIsExpanded,
    onOpenEditor,
    refetch,

    itemId,
    displayName,
    hasChildren,
    icon,
    name,
    thumbnailUrl,
    type,
    createdBy,
    updatedBy,
    workflow,
  } = props;

  const { selectedLanguage } = useGetLanguageItem();

  return (
    <Tr height="3.625rem" cursor="pointer" _hover={{ backgroundColor: 'chakra-subtle-bg' }} onClick={onOpenEditor} id={`row-${itemId}`}>
      <TableCell paddingLeft={nameCellPadding}>
        <NameCell
          name={displayName || name}
          canExpand={hasChildren}
          isExpanded={isExpanded}
          toggleExpanded={() => setIsExpanded(!isExpanded)}
          icon={icon}
          thumbnailUrl={thumbnailUrl}
          showThumbnail={showThumbnail}
          noChildrenSpace={hasChildren ? 0 : 10}
        />
      </TableCell>
      <TableCell>
        <ItemTypeCell type={type} />
      </TableCell>
      <Hide below="md">
        <TableCell>
          <UserCell user={createdBy} />
        </TableCell>
        <TableCell>
          <UserCell user={updatedBy} />
        </TableCell>
      </Hide>
      {showLanguages && (
        <TableCell>
          <LanguageCell {...props} selectedLanguage={selectedLanguage} />
        </TableCell>
      )}
      {showWorkflow && (
        <TableCell>
          <WorkflowStateCell
            workflowName={workflow?.workflow?.displayName || ''}
            displayName={workflow?.workflowState?.displayName || ''}
            final={workflow?.workflowState?.final || false}
          />
        </TableCell>
      )}
      <TableCell>
        <ActionsCell {...props} refetchParent={refetchParent} refetchCurrent={refetch} />
      </TableCell>
    </Tr>
  );
};
