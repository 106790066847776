import { useLayoutEffect, useMemo } from 'react';
import { SECTIONS_ERRORS_STORAGE_KEY } from 'src/constants';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { ValidationErrors } from 'src/features/fieldBuilder/models';
import { useSessionStorage } from 'usehooks-ts';
import { BuilderField, GroupSectionSorting } from '../models/fieldBulder.model';
import { useGetItemFields } from './useGetItemFields';

const handleSort = (n1: number, n2: number, s1: string, s2: string) => {
  if (n1 > n2) return 1;
  if (n1 < n2) return -1;

  return s1.localeCompare(s2);
};

export const useFieldBuilderData = () => {
  const { sc_itemid, sc_lang, sc_version } = useUrlStrictContext();
  const [, setErrors] = useSessionStorage<ValidationErrors[]>(SECTIONS_ERRORS_STORAGE_KEY, []);

  const fieldsInput = useMemo(() => ({ id: sc_itemid, lang: sc_lang, ver: sc_version }), [sc_itemid, sc_lang, sc_version]);
  const { itemFields, isLoading } = useGetItemFields(fieldsInput, { enabled: !!fieldsInput.id, cacheTime: 0 });

  const groupedFields = useMemo(() => {
    if (!itemFields.length) return [];

    const fields = itemFields.map<BuilderField>((field) => {
      const error = field?.validation?.[0]?.results?.nodes?.[0]?.message;
      return { ...field, ...field.templateField, canEdit: field.access.canWrite, itemId: sc_itemid, error };
    });

    const groupedFields = fields.reduce<GroupSectionSorting>((r, a) => {
      r[a.section.name] = r[a.section.name] || { sectionName: a.section.name, sortOrder: a.section.sortOrder, fields: [] }; // group fields sections
      r[a.section.name].fields.push(a);

      return r;
    }, Object.create(null));

    const sortedFields = Object.values(groupedFields).sort((a, b) => handleSort(a.sortOrder, b.sortOrder, a.sectionName, b.sectionName));
    sortedFields.forEach((field) => field.fields.sort((a, b) => handleSort(a.sortOrder, b.sortOrder, a.name, b.name)));

    return sortedFields;
  }, [sc_itemid, itemFields]);

  useLayoutEffect(() => {
    if (!isLoading) {
      const groupedSectionErrors: ValidationErrors[] = [];

      groupedFields.forEach((r) => {
        const errorFields = r.fields.filter((f) => !!f.error);

        if (errorFields && errorFields.length) {
          groupedSectionErrors.push({ sectionName: r.sectionName, fields: errorFields.map((e) => e.fieldId) });
        }
      });

      setErrors(groupedSectionErrors);
    }
  }, [groupedFields, isLoading, setErrors]);

  return { groupedFields, isLoading };
};
