import { GUID_REGEX } from 'src/constants';
import { VALIDATIONS } from 'src/validation-constants';
import { object, string } from 'yup';

export const internalLinkValidation = object().shape({
  id: string().required(VALIDATIONS.PATH_VALIDATION_REQUIRED),
  text: string().required(VALIDATIONS.TEXT_VALIDATION_REQUIRED),
});

export const externalLinkValidation = object().shape({
  url: string().required(VALIDATIONS.URL_VALIDATION_REQUIRED),
  text: string().required(VALIDATIONS.TEXT_VALIDATION_REQUIRED),
});

export const isGuid = (string: string) => !!string?.match(GUID_REGEX);
