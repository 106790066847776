import { createFeatureFlags } from '@sitecore/featureflags';

export const { Feature, FlagsProvider, useFeature, useFeatures, withFeature, behindFeature } = createFeatureFlags({
  // Provide name of flags used by the app here, together with their default values
  xm_technicalTemplateSites: false,
  xm_additionalListView: false,
  xm_searchTableView: false,
});

export type FeatureFlags = ReturnType<typeof useFeatures>;
export type FeatureName = keyof FeatureFlags;
