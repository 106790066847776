import * as React from 'react';
import { Box } from '@chakra-ui/react';

import { LayoutAsideProps } from './model';

export const LayoutRight: React.FC<LayoutAsideProps> = ({
  children,
  inset,
  background,
  ...rest
}) => {
  return (
    <Box
      as="aside"
      order={3}
      overflow="auto"
      background={inset ? 'gray.50' : background}
      shadow={inset ? 'inner' : 'stroke'}
      zIndex={2}
      {...rest}
    >
      {children}
    </Box>
  );
};

/**

 * Sitecore UI LayoutRight component default props

 */

LayoutRight.defaultProps = {
  inset: false,
  background: 'white',
  w: 320,
  p: 6,
};

/**

 * Sitecore UI LayoutRight components display names

 */

LayoutRight.displayName = 'LayoutRight';
