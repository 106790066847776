import { Tab, TabList, TabPanel, TabPanelProps, TabPanels, Tabs } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY, FIELD_EDITOR_SELECTED_TAB_KEY } from 'src/constants';
import { useViewSwitcherModes } from 'src/features/common/components/actionsBar/hooks';
import { viewModes } from 'src/features/common/components/actionsBar/models';
import { DetailedItem } from 'src/features/common/models';
import { FieldBuilder } from 'src/features/fieldBuilder/components/FieldBuilder';
import { EditorTabHeader } from 'src/features/fieldEditor/components/EditorTabHeader';
import { ItemDetails } from 'src/features/fieldEditor/components/ItemDetails';
import { VersionsContainer } from 'src/features/versions/components/VersionsContainer';
import { useLocalStorage } from 'usehooks-ts';

const tabPanelProps: TabPanelProps = { boxSize: 'full', px: 8, py: 5, top: 0, overflowY: 'auto', position: 'absolute' };

interface EditorTabsProps {
  item: DetailedItem | undefined;
  onVersionChange: (version: number, tabToSelect: number) => void;
}

export const EditorTabs: FC<EditorTabsProps> = ({ item, onVersionChange }) => {
  const [fieldEditorTabIndex, setFieldEditorTabIndex] = useLocalStorage<number>(FIELD_EDITOR_SELECTED_TAB_KEY, 0);

  const { viewMode } = useViewSwitcherModes();

  if (viewMode === viewModes.list) {
    tabPanelProps.overflowY = undefined;
    tabPanelProps.top = undefined;
    tabPanelProps.position = undefined;
  } else {
    tabPanelProps.overflowY = 'auto';
    tabPanelProps.top = 0;
    tabPanelProps.position = 'absolute';
  }

  return (
    <Tabs w="full" display="flex" flexDirection="column" index={fieldEditorTabIndex} onChange={setFieldEditorTabIndex} isLazy>
      <TabList data-testid="field-editor-tablist" mx="8">
        <Tab data-testid="content-tab">
          <EditorTabHeader name={DICTIONARY.CONTENT} />
        </Tab>
        <Tab>
          <T _str={DICTIONARY.VERSIONS} />
        </Tab>
        <Tab>
          <T _str={DICTIONARY.DETAILS} />
        </Tab>
      </TabList>
      <TabPanels boxSize="full" position="relative">
        <TabPanel
          {...tabPanelProps}
          position={viewMode === viewModes.list ? 'relative' : 'absolute'}
          top="0"
          h="full"
          zIndex="0"
          data-testid="content-tab-body"
        >
          <FieldBuilder />
        </TabPanel>
        <TabPanel data-testid="versions-tab-body" {...tabPanelProps}>
          <VersionsContainer onVersionChange={onVersionChange} />
        </TabPanel>
        <TabPanel data-testid="details-tab-body" {...tabPanelProps}>{item && <ItemDetails item={item} />}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};
