import { Box, Flex } from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { ScrollToTopButton } from 'src/features/common/components/scrollToTopButton/components/ScrollToTopButton';
import { useScroll } from 'src/features/common/hooks/useScroll';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { ItemViewHeader } from 'src/features/fieldEditor/components/ItemViewHeader';
import { WelcomeInstruction } from 'src/features/fieldEditor/components/WelcomeInstruction';

type ItemViewContainerProps = {
  children: JSX.Element;
};

export const ItemViewContainer: FC<ItemViewContainerProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { hasScrolled } = useScroll(ref);
  const { sc_itemid } = useUrlStrictContext();

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      zIndex="modal"
      boxSize="full"
      backgroundColor="white"
      overflow="auto"
      ref={ref}
      css={{ scrollbarGutter: 'stable' }}
    >
      <Flex direction="column">
        <ItemViewHeader hasScrolled={hasScrolled} />
        <Flex justifyContent="center" width="full" pb="10">
          {!sc_itemid ? <WelcomeInstruction /> : <Flex width="7xl">{children}</Flex>}
        </Flex>
        <ScrollToTopButton boxRef={ref} />
      </Flex>
    </Box>
  );
};
