import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import moment from 'moment';
import { memo, MouseEventHandler, useMemo } from 'react';
import { DICTIONARY } from 'src/constants';
import { useCurrentItem } from 'src/features/common/hooks/useCurrentItem';
import { DetailedItem } from 'src/features/common/models';
import { getGreatestVersion } from 'src/features/fieldEditor/components/FieldEditor';
import { ActionsList } from './actions/ActionsList';
import { CreateVersionModal } from './modals/CreateVersionModal';
import { VersionHelpPopover } from './popovers/VersionHelpPopover';
import { VersionCard } from './VersionCard';

interface VersionsContainerProps {
  onVersionChange: (version: number, tabToSelect: number) => void;
}

export const VersionsContainer = memo(({ onVersionChange }: VersionsContainerProps) => {
  const { currentItem } = useCurrentItem();
  const greatestVersion = useMemo(() => getGreatestVersion(currentItem), [currentItem]);
  const autoIncrVersion = useMemo(() => (greatestVersion ? greatestVersion + 1 : 1), [greatestVersion]);
  const publishableVersion = useMemo(
    () =>
      currentItem?.versions
        .filter(
          //filtering available versions: isHidden='' AND in range
          (f) =>
            !((f.workflow && !f.workflow.workflowState?.final) || f.isHidden.value) &&
            (!f.validFrom.value || (!!f.validFrom.value && moment(f.validFrom.value).diff(moment().add(30, 'seconds')) < 0)) &&
            (!f.validTo.value || (!!f.validTo.value && moment(f.validTo.value).diff(moment().subtract(30, 'seconds')) > 0)),
        )
        .reduce((prev, current) => (prev.version > current.version ? prev : current), {} as DetailedItem),
    [currentItem],
  );

  const hasOtherVersions = useMemo(
    () => currentItem?.versions.filter((f) => publishableVersion && f.version !== publishableVersion.version),
    [publishableVersion, currentItem],
  );

  return !currentItem ? (
    <></>
  ) : (
    <>
      <Flex justifyContent="flex-end" alignItems="baseline" pb="6" gap="3">
        <VersionHelpPopover />
        {currentItem.access?.canWrite && (
          <CreateVersionModal
            version={autoIncrVersion}
            initialStateName={currentItem.workflow?.workflow?.initialState?.displayName || ''}
            renderAction={(open: MouseEventHandler<HTMLButtonElement> | undefined) => (
              <Button size="sm" variant="solid" data-testid="new-version" onClick={open}>
                <T _str={DICTIONARY.CREATE_VERSION} />
              </Button>
            )}
          />
        )}
      </Flex>
      <Box data-testid="publishable-version">
        <Heading size="md" pb="1">
          <T _str={DICTIONARY.PUBLISHABLE_VERSION} />
        </Heading>
        <Text variant="subtle" pb="4">
          <T _str={DICTIONARY.PUBLISHABLE_VERSION_INFO} />
        </Text>
        {publishableVersion && !!Object.keys(publishableVersion).length && (
          <VersionCard item={publishableVersion} onVersionChange={onVersionChange}>
            {publishableVersion.access?.canWrite && (
              <ActionsList item={publishableVersion} autoIncrVersion={autoIncrVersion} hasRename hasDuplicate hasDelete />
            )}
          </VersionCard>
        )}
        {publishableVersion && !Object.keys(publishableVersion).length && (
          <Flex border={'1px dashed'} borderColor="chakra-border-color" borderRadius="base" justifyContent="center">
            <Text variant="subtle" p="6">
              <T _str={DICTIONARY.NO_PUBLISHABLE_VERSION} />
            </Text>
          </Flex>
        )}
      </Box>

      {hasOtherVersions && hasOtherVersions.length > 0 && (
        <Box pt="8">
          <Heading size="md" pb="1">
            <T _str={DICTIONARY.OTHER_VERSIONS} />
          </Heading>
          <Text variant="subtle">
            <T _str={DICTIONARY.OTHER_VERSIONS_INFO} />
          </Text>
        </Box>
      )}
      {currentItem?.versions &&
        currentItem.versions
          .sort((ver1: DetailedItem, ver2: DetailedItem) => ver2.version - ver1.version)
          .filter((f) => publishableVersion && f.version !== publishableVersion.version)
          .map((item: DetailedItem) => (
            <Box key={`${item.itemId}-${item.version}`} pt="4" data-testid="version-property">
              <VersionCard item={item} key={`${item.itemId}-${item.version}`} onVersionChange={onVersionChange}>
                {item.access.canWrite && <ActionsList item={item} autoIncrVersion={autoIncrVersion} hasRename hasDuplicate hasDelete />}
              </VersionCard>
            </Box>
          ))}
    </>
  );
});

VersionsContainer.displayName = VersionsContainer.name;
