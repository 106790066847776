import { Flex, Skeleton } from '@chakra-ui/react';
import { FC } from 'react';

export const TreeLoadingSkeleton: FC = () => {
  return (
    <Flex direction="column" data-testid="treeLoadingSkeleton">
      <Skeleton css={{ height: 32, margin: 4 }} />
      <Skeleton css={{ height: 32, margin: 4 }} />
      <Skeleton css={{ height: 32, margin: 4 }} />
    </Flex>
  );
};
