import { FC } from 'react';
import { ImageCard } from 'src/features/media/card/ImageCard';
import { MediaCardProps } from 'src/features/shared/selectMediaModal/models';

export const ImageCardWrapper: FC<MediaCardProps> = ({ item, isSelected, setSelectedId }) => {
  return (
    <ImageCard
      key={item.itemId}
      {...item}
      isSelected={isSelected}
      id={item.itemId}
      onSelect={setSelectedId}
      title={item.name}
      size={Number(item.size)}
    />
  );
};
