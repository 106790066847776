import { AxiosInstance, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseInfiniteQueryOptions } from 'react-query/types/react';
import { useAuthInfiniteQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { ItemFieldsResponse } from 'src/features/fieldBuilder/models';
import { getItemFieldsQueryString } from 'src/features/fieldBuilder/queries';

type ItemGraphQLResponse = GraphQLResponse<ItemFieldsResponse>;
type ItemResponse = AxiosResponse<ItemGraphQLResponse>;

export const useGetItemFields = (
  input: { id: string; lang: string; ver: number | undefined },
  useQueryOptions?: Omit<UseInfiniteQueryOptions<ItemResponse, unknown, ItemResponse>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const { url } = useXMTenant();

  const { data, isLoading, fetchNextPage } = useAuthInfiniteQuery<ItemResponse, unknown, ItemResponse>(
    ['get-item-fields', input.id, input.lang, input.ver?.toString()],
    async (axiosInstance: AxiosInstance, cursor) => {
      return axiosInstance?.post<ItemGraphQLResponse>(getSitecoreGraphQLEndpoint(url), {
        query: getItemFieldsQueryString,
        variables: { ...input, cursor },
      });
    },
    {
      ...useQueryOptions,
      onSuccess: (data) => {
        const lastPage = data.pages[data.pages.length ? data.pages.length - 1 : 0];
        const pI = lastPage.data?.data?.item?.fields?.pageInfo || { endCursor: '', hasNextPage: false };

        if (pI.hasNextPage) {
          fetchNextPage({ pageParam: pI.endCursor });
        }
      },
    },
  );

  const itemFields = useMemo(() => {
    const fields = data?.pages.flatMap((page) => page?.data?.data?.item?.fields?.nodes || []) || [];
    const itemAccessRight = data?.pages[0].data?.data?.item?.access?.canWrite ?? false;
    if (fields && fields.length) {
      fields.forEach((field) => (field.access.canWrite = itemAccessRight && field.access.canWrite));
    }
    return fields;
  }, [data]);

  return { itemFields, isLoading };
};
