import { useCallback } from 'react';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';

export const useNoResultsPage = () => {
  const { setUrlContext } = useUrlSharing(UrlParams.None);

  return useCallback(() => {
    setUrlContext({ sc_itemid: undefined, sc_site: undefined, sc_type: undefined, sc_version: undefined, sc_collection: undefined });
  }, [setUrlContext]);
};
