import { Flex, FormControl, Input } from '@chakra-ui/react';
import { Field } from 'formik';
import { FC } from 'react';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { FieldName } from 'src/features/fieldBuilder/components/FieldName';
import { FieldErrorMessage, ToolTip } from 'src/features/fieldBuilder/components/fields/Common';
import { FormikFieldProps } from 'src/features/fieldBuilder/models';
import { GenericInput } from '../models/fieldBuilderInputs.model';

export const FieldBuilderInput: FC<GenericInput> = ({
  inputName,
  label,
  subtext,
  setFieldValue,
  setFieldTouched,
  submitForm,
  field,
  isRequired,
  updateErrors,
}) => {
  const t = useTranslate();

  return (
    <>
      <FieldName value={t(label)} isRequired={isRequired} />
      <Field name={inputName}>
        {({ field: formikField, form }: FormikFieldProps<string>) => (
          <FormControl isInvalid={!!form.errors[formikField.name] && form.touched[formikField.name]}>
            <Flex direction="column" gap={2}>
              <Flex direction="column" gap={2}>
                <Input
                  {...formikField}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (isRequired && updateErrors && !e.target.value) {
                      updateErrors(!form.errors[formikField.name]);
                    }
                    setFieldValue(formikField.name, e.target.value);
                    setFieldTouched(formikField.name, true, true);
                  }}
                  aria-label={label}
                  onBlur={() => void submitForm()}
                  bgColor="white"
                  isDisabled={!field.canEdit}
                />
                {subtext && <ToolTip text={t(subtext)} />}
              </Flex>
              <FieldErrorMessage message={form.errors[formikField.name]} />
            </Flex>
          </FormControl>
        )}
      </Field>
    </>
  );
};
