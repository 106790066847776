/* istanbul ignore file */

import { useDisclosure } from '@chakra-ui/react';
import { createContext, FC, useContext, useMemo } from 'react';
import { useSelectItemContextProvider } from '../hooks';
import { SelectItemContextData } from '../models';
import { SelectItemModal } from './SelectItemModal';

export const SelectItemContext = createContext<SelectItemContextData>({
  openModal: () => {},
});

export const SelectItemContextProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const disclosure = useDisclosure();

  const {
    selectedIds,
    setSelectedIds,
    selectedSite,
    setSelectedSite,
    selectedLanguage,
    setSelectedLanguage,
    datasource,
    openModal,
    includeFilters,
    texts,
    selectState,
  } = useSelectItemContextProvider(disclosure);

  const providerValue: SelectItemContextData = useMemo(() => ({ openModal }), [openModal]);

  return (
    <>
      <SelectItemContext.Provider value={providerValue}>{children}</SelectItemContext.Provider>
      {selectState && (
        <SelectItemModal
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          selectedSite={selectedSite}
          setSelectedSite={setSelectedSite}
          datasource={datasource}
          texts={texts}
          selectState={selectState}
          includeFilters={includeFilters}
          {...disclosure}
        />
      )}
    </>
  );
};

export const useSelectItemContext = () => {
  return useContext(SelectItemContext);
};
