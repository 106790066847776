import { Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { LanguageItem } from 'src/features/common/models';

export const LanguageCell: FC<{ itemId: string; selectedLanguage: LanguageItem | undefined; languages?: string[] }> = ({
  languages,
  itemId,
  selectedLanguage,
}) => {
  const allLanguagesText: string = useMemo(() => (languages || []).join(', ').toUpperCase(), [languages]);

  return (
    <Text isTruncated minWidth="28" maxWidth="xs" noOfLines={2} whiteSpace="unset" title={allLanguagesText} textTransform="uppercase">
      {languages?.map((language: string, index: number) => (
        <Text as="span" key={language + itemId}>
          <Text
            as="span"
            color={language === selectedLanguage?.iso ? 'success.500' : undefined}
            data-testid={language === selectedLanguage?.iso ? 'activeLanguage' : undefined}
            title={language}
          >
            {language}
          </Text>
          {languages.length - 1 !== index && <Text as="span">, </Text>}
        </Text>
      ))}
    </Text>
  );
};
