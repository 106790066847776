/* istanbul ignore file */

import { useCallback } from 'react';
import { RefetchFunction } from 'src/features/shared/treeTable/components/TreeTableRefetchContext';
import { blinkAddedRow } from 'src/features/shared/treeTable/utils/blink';

export const useRefetchAndBlink = (refetch: RefetchFunction) => {
  return useCallback(
    async (itemId?: string) => {
      if (!refetch) return;

      if (typeof refetch === 'function') {
        await refetch();
      }

      if (typeof refetch === 'object') {
        const promise = refetch as any;
        const result = await promise;
        result.refetch();
      }

      if (itemId) {
        blinkAddedRow(itemId);
      }
    },
    [refetch],
  );
};
