import { useCallback } from 'react';
import { OPEN_IN_PAGES_ROUTE } from 'src/constants';
import { getPagesLink } from 'src/features/common/config/pages-link.config';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { SelectContextItem } from 'src/features/common/models';

export const useOpenInPagesButton = (contextItem: SelectContextItem) => {
  const { urlContext } = useUrlSharing(UrlParams.organization | UrlParams.tenantName);

  return useCallback(async () => {
    window.open(getPagesLink(urlContext.organization || '', urlContext.tenantName || '', OPEN_IN_PAGES_ROUTE, contextItem));
  }, [contextItem, urlContext.organization, urlContext.tenantName]);
};
