/* istanbul ignore file */

import { HIGHLIGHT_TIMEOUT } from 'src/features/shared/treeTable/treeTableConstants';

const waitForRowToAppearElement = (selector: string) => {
  return new Promise<Element>((resolve) => {
    let element = document.querySelector(selector);
    if (element) {
      return resolve(element);
    }

    const observer = new MutationObserver(() => {
      element = document.querySelector(selector);
      if (element) {
        observer.disconnect();
        resolve(element);
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const blinkAddedRow = async (itemId: string) => {
  const row = await waitForRowToAppearElement(`#row-${itemId}`);

  if (!row) {
    return;
  }

  row.classList.add('blink');

  setTimeout(() => {
    row.classList.remove('blink');
  }, HIGHLIGHT_TIMEOUT);
};
