import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { DICTIONARY, LOCK_NONE_SXA_ID } from 'src/constants';
import { MenuWithSearchOption } from 'src/features/common/components/menuWithSearch/models';

import { useGetSitesWrapper } from 'src/features/common/hooks/useGetSitesWrapper';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';

export const useSiteCollections = () => {
  const { collections } = useGetSitesWrapper();
  const { urlContext, setUrlContext } = useUrlSharing(UrlParams.sc_collection | UrlParams.sc_type);

  const siteCollections = useMemo(() => {
    if (urlContext.sc_type === 'nonsxa') {
      return [{ value: LOCK_NONE_SXA_ID, label: DICTIONARY.SHORT_NOT_AVAILABLE, checked: true }];
    }

    if (!collections) {
      return [];
    }

    let collectionsModel = collections.map<MenuWithSearchOption>((collection) => ({
      value: collection.id,
      label: collection.displayName,
    }));
    collectionsModel = _.uniqBy(collectionsModel, (c) => c.label);
    collectionsModel = _.sortBy(collectionsModel, [(c) => c.label]);

    const collectionNames = urlContext.sc_collection?.split(',');
    collectionsModel.forEach((collection) => {
      collection.checked = collectionNames?.find((selectedName) => selectedName === collection.label) !== undefined;
    });

    return collectionsModel;
  }, [collections, urlContext.sc_collection, urlContext.sc_type]);

  const setCollection = useCallback(
    (values: string[] | undefined) => {
      if (!values || !values.length) {
        setUrlContext({ sc_collection: null });
      } else {
        const names = siteCollections
          .filter((collection) => values.find((val) => collection.value === val))
          .map((collection) => collection.label)
          .join(',');
        if (names) {
          setUrlContext({
            sc_collection: names,
            sc_itemid: null,
            sc_site: null,
            sc_version: null,
          });
        }
      }
    },
    [setUrlContext, siteCollections],
  );

  return {
    siteCollections,
    setCollection,
  };
};
