export class LinkValue {
  private _document: Document = new Document();

  constructor(value?: string) {
    this._document = new DOMParser().parseFromString(value || '<link />', 'text/xml');
  }

  private getValue(attributeName: string): string {
    return this._document.firstElementChild?.getAttribute(attributeName) ?? '';
  }

  private setValue(attributeName: string, value: string) {
    if (!this._document.firstElementChild) {
      const linkNode = this._document.createElement('link');
      this._document.appendChild(linkNode);
    }

    this._document.firstElementChild?.setAttribute(attributeName, value);
  }

  get text(): string {
    return this.getValue('text');
  }
  set text(value: string) {
    this.setValue('text', value);
  }

  get title(): string {
    return this.getValue('title');
  }
  set title(value: string) {
    this.setValue('title', value);
  }

  get linktype(): string {
    return this.getValue('linktype');
  }
  set linktype(value: string) {
    this.setValue('linktype', value);
  }

  get url(): string {
    return this.getValue('url');
  }
  set url(value: string) {
    this.setValue('url', value);
  }

  get anchor(): string {
    return this.getValue('anchor');
  }
  set anchor(value: string) {
    this.setValue('anchor', value);
  }

  get target(): string {
    return this.getValue('target');
  }
  set target(value: string) {
    this.setValue('target', value);
  }

  get class(): string {
    return this.getValue('class');
  }
  set class(value: string) {
    this.setValue('class', value);
  }

  get querystring(): string {
    return this.getValue('querystring');
  }
  set querystring(value: string) {
    this.setValue('querystring', value);
  }

  get id(): string {
    return this.getValue('id');
  }
  set id(value: string) {
    this.setValue('id', value);
  }

  public toString: () => string = () => new XMLSerializer().serializeToString(this._document);
}
