/* istanbul ignore file */

import { print } from 'graphql';
import { loader } from 'graphql.macro';

export const addVersionQuery = loader('./addVersion.graphql');
export const addVersionQueryString = print(addVersionQuery);

export const deleteVersionQuery = loader('./deleteVersion.graphql');
export const deleteVersionQueryString = print(deleteVersionQuery);
