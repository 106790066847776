import { useCallback } from 'react';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { normalizeIdWithoutDash } from 'src/features/common/utils/guidUpdater';

export const useGenerateMediaLink = () => {
  const { url } = useXMTenant();

  return useCallback(
    (mediaId: string, addHost: boolean) => {
      const guid = normalizeIdWithoutDash(mediaId);
      const host = addHost ? `${url}/` : '';

      return `${host}-/media/${guid}.ashx`;
    },
    [url],
  );
};
