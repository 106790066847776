import { Flex, Link } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useCallback } from 'react';
import { DICTIONARY, TENANT_NAME_STORAGE_KEY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { setValueToStorages } from 'src/features/common/utils/storages';
import { GenericErrorTemplate } from 'src/features/temporary/GenericErrorTemplate';

export const NoTenantPage: FC = () => {
  const { urlContext, setUrlContext } = useUrlSharing(UrlParams.organization);
  const t = useTranslate();

  const onRelogin = useCallback(() => {
    setValueToStorages(TENANT_NAME_STORAGE_KEY, '');
    setUrlContext(urlContext);
    window.location.reload();
  }, [urlContext, setUrlContext]);

  return (
    <Flex pb="4" overflowY="auto">
      <GenericErrorTemplate
        title={t(DICTIONARY.NOTIFICATIONS.SOMETHING_WENT_WRONG)}
        text={t(DICTIONARY.NOTIFICATIONS.CANNOT_FETCH_TENANT)}
        variant="error"
        shortcuts={
          <>
            <Link pb="4" onClick={() => window.location.reload()}>
              <T _str={DICTIONARY.TRY_AGAIN} />
            </Link>
            <Link pb="4" onClick={onRelogin}>
              <T _str={DICTIONARY.SELECT_ANOTHER_ENV} />
            </Link>
          </>
        }
      />
    </Flex>
  );
};
