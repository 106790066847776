/* istanbul ignore file */

import { IMAGE_TYPES, MEDIA_LIBRARY_PATH } from 'src/constants';
import { useUploadArea } from 'src/features/media/hooks/useUploadArea';
import { UploadFileButtonProps } from 'src/features/shared/selectMediaModal/components/mediaModal';
import { useGetMediaItemWrapper } from 'src/features/shared/selectMediaModal/hooks/useGetMediaItemWrapper';

const dummyFunction = () => {};

export const useUploadFileButton = (props: UploadFileButtonProps) => {
  const { folderItemId, refetchList, type } = props;
  const allowedExtensions = type === 'image' ? IMAGE_TYPES : [];

  const { item } = useGetMediaItemWrapper(folderItemId || '', !!folderItemId);

  const { onMediaSelect } = useUploadArea({
    type,
    mediaId: '',
    allowedExtensions,
    uploadPath: item?.path || MEDIA_LIBRARY_PATH,
    sourceIds: [],
    onUpload: refetchList,
    onSelect: dummyFunction,
  });

  return {
    onMediaSelect,
  };
};
