import { useCallback, useMemo, useState } from 'react';
import { ItemTreeProps } from 'src/features/common/treeComponent/components/ItemTree';
import { ItemViewProps } from 'src/features/common/treeComponent/components/ItemView';
import { isSameGuid } from 'src/features/common/utils/guidUpdater';

export const useItemView = (props: ItemViewProps & ItemTreeProps) => {
  const { item, selectedIDs, setSelectedIds, selectionVariant } = props;
  const [hover, setHover] = useState(false);

  const color = useMemo(() => {
    if (!item) {
      return 'transparent';
    }

    if (selectedIDs.find((id) => isSameGuid(id, item.itemId))) {
      return 'primary.100';
    }

    return hover ? 'blackAlpha.50' : 'transparent';
  }, [hover, item, selectedIDs]);

  const onSelect = useCallback(() => {
    if (!item) {
      return;
    }

    const index = selectedIDs.indexOf(item.itemId);
    if (selectionVariant === 'multiple') {
      if (index >= 0) {
        selectedIDs.splice(index, 1);
        setSelectedIds([...selectedIDs]);
      } else {
        setSelectedIds([...selectedIDs, item.itemId]);
      }
    } else if (index < 0) {
      setSelectedIds([item.itemId]);
    }
  }, [item, selectedIDs, selectionVariant, setSelectedIds]);

  return {
    setHover,
    color,
    onSelect,
  };
};
