import { Box, Button, CircularProgress, Flex, FlexProps, Input, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, LegacyRef, useRef } from 'react';
import { DICTIONARY } from 'src/constants';
import { ReactComponent as UploadIcon } from 'src/features/common/images/upload.svg';
import { useUploadArea } from 'src/features/media/hooks/useUploadArea';
import { UploadAreaProps } from 'src/features/media/models';
import 'src/features/media/styles/uploadArea.scss';

const border = { borderColor: 'chakra-border-color', borderWidth: '1', borderStyle: 'dashed', borderRadius: 'base' } as FlexProps;
const loading = { position: 'absolute', left: '0', top: '0', zIndex: 'dropdown', boxSize: 'full' } as FlexProps;

export const UploadArea: FC<UploadAreaProps> = (props) => {
  const { canEdit, tooltips } = props;
  const { isLoading, dropEvents, onMediaSelect, onMediaModalOpen } = useUploadArea(props);
  const ref = useRef<HTMLInputElement>();

  return (
    <Flex p="3" position="relative" backgroundColor="white">
      <Flex left="0" top="0" position="absolute" boxSize="full" {...(canEdit ? dropEvents : {})} {...border} />

      {isLoading && (
        <Flex data-testid="sc-upload-area-loading" direction="column" justify="center" align="center" gap="3" {...loading}>
          <CircularProgress isIndeterminate size="6" color='primary' trackColor='neutral-color.200'/>
          <Text>
            <T _str={DICTIONARY.UPLOADING} />
          </Text>
        </Flex>
      )}

      <Flex gap="3" visibility={isLoading ? 'hidden' : 'visible'}>
        <Box boxSize="fit-content">
          <UploadIcon style={{ width: 50, height: 50 }} />
        </Box>
        <Flex gap="1" direction="column">
          <Flex gap="1" flexWrap="wrap">
            <Button
              colorScheme="primary"
              variant="link"
              type="button"
              onClick={() => ref.current?.click()}
              isDisabled={!canEdit}
              data-testid="sc-upload-area-upload"
            >
              <T _str={DICTIONARY.UPLOAD} />
            </Button>
            <Text as="span">
              <T _str={DICTIONARY.OR} />
            </Text>
            <Text data-testid="sc-upload-area-drop-message" as="span">
              <T _str={`${tooltips.dropMessage}`} />
            </Text>
            <Text as="span">
              <T _str={DICTIONARY.OR} />
            </Text>
            <Button
              data-testid="sc-upload-area-browse-media-library"
              variant="link"
              colorScheme="primary"
              type="button"
              isDisabled={!canEdit}
              onClick={onMediaModalOpen}
            >
              <T _str={DICTIONARY.BROWSE_MEDIA_LIBRARY} />
            </Button>
          </Flex>
          <Text data-testid="sc-upload-area-supported-types" variant="small">
            <T _str={tooltips.supportTypes} />
          </Text>
          <Text variant="small">
            <T _str={DICTIONARY.MAX_UPLOAD_SIZE_MESSAGE} />
          </Text>
        </Flex>
      </Flex>
      <Input type="file" onChange={onMediaSelect} ref={ref as LegacyRef<HTMLInputElement>} hidden />
    </Flex>
  );
};
