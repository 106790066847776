import { useCallback, useState } from 'react';
import { ItemTreeProps } from 'src/features/common/treeComponent/components/ItemTree';
import { TreeViewProps } from 'src/features/common/treeComponent/components/TreeView';

export const useTreeView = (props: TreeViewProps & ItemTreeProps) => {
  const { itemId, itemViewModel, useGetItem, isOpened, ancestors, stopLoadAncestors, expandOnSelect, setSelectedIds, language } = props;
  const id = itemViewModel?.itemId || itemId;
  const [opened, setOpened] = useState(isOpened || false);
  const { item, isLoading } = useGetItem(id, opened, language);

  const checkAncestor = useCallback(
    (id: string) => {
      if (!ancestors || !ancestors.length) return false;

      return ancestors.includes(id);
    },
    [ancestors],
  );

  const onBranchOpen = useCallback(
    (value: boolean) => {
      setOpened(value);
      if (ancestors?.length) {
        stopLoadAncestors();
      }
    },
    [ancestors?.length, stopLoadAncestors],
  );

  const openBranchOnClick = useCallback(
    (values: string[]) => {
      setSelectedIds(values);

      if (expandOnSelect && itemViewModel?.hasChildren && !opened) {
        setOpened(true);
      }
    },
    [expandOnSelect, itemViewModel?.hasChildren, opened, setSelectedIds],
  );

  return {
    opened,
    item,
    isLoading,
    checkAncestor,
    onBranchOpen,
    openBranchOnClick,
  };
};
