import { Grid } from '@chakra-ui/react';
import { FC } from 'react';
import { isSameGuid } from 'src/features/common/utils/guidUpdater';
import { CardSelector } from 'src/features/shared/selectMediaModal/components/mediaModal/CardSelector';
import { MediaItemData } from 'src/features/shared/selectMediaModal/models';

export type FilesViewProps = {
  selectedMediaId: string | undefined;
  setSelectedMediaId: (itemId: string) => void;
  mediaDataItems: MediaItemData[];
  isLoading: boolean;
};

export const FilesView: FC<FilesViewProps> = ({ mediaDataItems, selectedMediaId, setSelectedMediaId }) => {
  return (
    <Grid p="0.5" marginTop="6" overflow="auto" gap="3" justifyContent="space-around" gridTemplateColumns="repeat(auto-fill, 212px)">
      {mediaDataItems.map((item) => (
        <CardSelector
          key={item.itemId}
          isSelected={isSameGuid(item.itemId, selectedMediaId)}
          setSelectedId={setSelectedMediaId}
          item={item}
        />
      ))}
    </Grid>
  );
};
