import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { MenuWithSearch } from 'src/features/common/components/menuWithSearch/MenuWithSearch';
import { useLanguageFilter } from 'src/features/search/hooks/useLanguageFilter';

export const LanguageFilter: FC = () => {
  const { languages, onFilterChange } = useLanguageFilter();

  return <MenuWithSearch title={DICTIONARY.HEADER.LANGUAGE} options={languages} onChange={onFilterChange} />;
};
