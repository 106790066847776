import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { viewModes } from 'src/features/common/components/actionsBar/models';
import { FieldEditor } from 'src/features/fieldEditor/components/FieldEditor';
import { ItemViewContainer } from 'src/features/fieldEditor/components/ItemViewContainer';
import { ColumnsView } from 'src/features/main/components/ColumnsView';
import { useExplorerNavigation } from 'src/features/main/components/hooks/useExplorerNavigation';
import { TreeTableListView } from 'src/features/main/components/TreeTableListView';

export const ExplorerNavigation: React.FC = () => {
  const location = useLocation();
  const { validatedComponent } = useExplorerNavigation();

  /* We use router outlet to render particular view mode
    Read more here: https://reactrouter.com/en/main/components/outlet
  */
  return (
    <Routes>
      <Route path="*" element={validatedComponent}>
        <Route path={viewModes.columns} element={<ColumnsView />} />
        <Route path={viewModes.list} element={<TreeTableListView />}>
          <Route
            path={ROUTES.EDITOR_VIEW}
            element={
              <ItemViewContainer>
                <FieldEditor />
              </ItemViewContainer>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to={{ ...location, pathname: `/${viewModes.columns}` }} replace />} />
      </Route>
    </Routes>
  );
};
