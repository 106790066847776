import { useMemo } from 'react';
import { MASTER_DB } from 'src/constants';
import { useGetLanguages } from 'src/features/common/hooks/useGetLanguages';
import { useGetSitesWrapper } from 'src/features/common/hooks/useGetSitesWrapper';
import { FilterCollectionItem, SiteFilterCollectionItem, UseSelectItemModalFilters } from '../models';

const emptyCollection: FilterCollectionItem[] = [{ id: '', label: '' }];

const filterBoxStyles = {
  maxWidth: 'calc(50% - 0.625rem)',
  marginRight: 5,
};

export const useSelectItemModalFilters = (): UseSelectItemModalFilters => {
  const { sites } = useGetSitesWrapper();
  const { data: languageData } = useGetLanguages(MASTER_DB);

  const siteCollection = useMemo<SiteFilterCollectionItem[]>(
    () =>
      sites.map((site) => ({
        id: site.displayName,
        label: site.displayName,
        rootItemId: site.rootItem.itemId,
      })) ?? emptyCollection,
    [sites],
  );

  const languageCollection = useMemo<FilterCollectionItem[]>(
    () => languageData?.data?.data?.languages?.nodes.map((lang) => ({ id: lang.name, label: lang.englishName })) ?? emptyCollection,
    [languageData],
  );

  return {
    siteCollection,
    languageCollection,
    filterBoxStyles,
  };
};
