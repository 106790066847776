import { useMemo } from 'react';
import { LOCK_NONE_SXA_ID } from 'src/constants';
import { useSiteCollections } from 'src/features/common/components/filters/hooks';
import { SearchCriteriaInput, SearchCriteriaType, SearchOperator } from 'src/features/search/models';
import { FILTER_FIELDS } from 'src/features/search/searchConstants';
export const useCollectionFilterCriteria = (): SearchCriteriaInput[] | undefined => {
  const { siteCollections } = useSiteCollections();

  return useMemo<SearchCriteriaInput[] | undefined>(() => {
    if (!siteCollections.length) return undefined;

    const allChecked = siteCollections.filter((collection) => collection.checked && collection.value !== LOCK_NONE_SXA_ID);

    if (!allChecked.length) return undefined;

    return allChecked.map<SearchCriteriaInput>((collection) => ({
      field: FILTER_FIELDS.COLLECTION,
      value: collection.value.replaceAll('-', '').toLowerCase(),
      criteriaType: SearchCriteriaType.EXACT,
      operator: SearchOperator.SHOULD,
    }));
  }, [siteCollections]);
};
