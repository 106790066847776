import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config/graphql-config';
import { GraphQLResponse, MetaResponse } from 'src/features/common/models';
import { meta } from 'src/features/common/queries/meta';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export type MetaType = AxiosResponse<GraphQLResponse<MetaResponse>>;

export const useXmCloudVersion = (
  options?: Omit<UseQueryOptions<MetaType, unknown, MetaType, string[]>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const { url } = useXMTenant();
  const toast = useToast();

  const { data, isLoading, error } = useAuthQuery(
    ['get-xm-cloud-version'],
    async (axiosInstance) => axiosInstance?.post<GraphQLResponse<MetaResponse>>(getSitecoreGraphQLEndpoint(url), meta.xm_version),
    { ...options },
    toast,
  );

  return { xMVersion: data?.data.data.meta.xMVersion, isLoading, error: error || data?.data.errors };
};
