import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface SelectedItemFileViewProps {
  name: string;
  size: string;
  onLink: () => void;
}

export const SelectedItemFileView: FC<SelectedItemFileViewProps> = ({ name, size, onLink }) => (
  <Box cursor="pointer" onClick={onLink}>
    <Text as="span" pr="2">
      {name}
    </Text>
    <Text as="span" variant="small">
      ({size})
    </Text>
  </Box>
);
