import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse, ItemRenameResponse, RenameItemInput, UpdateItemInput } from 'src/features/common/models';
import { items } from 'src/features/common/queries/items';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export interface RenameItemInputProps {
  renameInput: RenameItemInput;
  updateInput: UpdateItemInput;
}

export const useRenameItem = () => {
  const { url } = useXMTenant();

  return useAuthMutation(
    (axiosInstance) =>
      ({ renameInput, updateInput }: RenameItemInputProps) =>
        axiosInstance?.post<GraphQLResponse<ItemRenameResponse>>(
          getSitecoreGraphQLEndpoint(url),
          items.rename_item(renameInput, updateInput),
        ),
  );
};
