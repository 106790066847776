import { ENV } from 'src/constants';

export const environmentUpdater = (env: string) => {
  if (env.toLowerCase() === ENV.PRE_PRODUCTION) {
    return 'pre-production';
  }

  if (env.toLowerCase() === ENV.PRODUCTION) {
    return 'production';
  }

  return env;
};
