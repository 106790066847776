import { useCallback } from 'react';
import { viewModes } from 'src/features/common/components/actionsBar/models';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { DetailedItem, NestedItems } from 'src/features/common/models';
import { useSaveNavigate } from 'src/features/common/utils/navigationUtils';
import { ActionsMenuCallbacks } from 'src/features/fieldBuilder/models';
import { useTreeTableRefetchContext } from 'src/features/shared/treeTable/components/TreeTableRefetchContext';

export const useActionsMenuTableListWrapper = (): ActionsMenuCallbacks => {
  const { setUrlContext } = useUrlSharing(UrlParams.None);
  const refreshTable = useTreeTableRefetchContext();
  const goBack = useSaveNavigate();

  const onRenameSuccess = useCallback((item: DetailedItem) => refreshTable(item.itemId), [refreshTable]);

  const onDuplicateItemSuccess = useCallback(
    async (item: NestedItems) => {
      setUrlContext({ sc_itemid: item.itemId });
      refreshTable(item.itemId);
    },
    [refreshTable, setUrlContext],
  );

  const onDeleteItemSuccess = useCallback(() => {
    refreshTable();
    goBack(`/${viewModes.list}`);
  }, [goBack, refreshTable]);

  const onDeleteItemError = useCallback(() => {
    goBack(`/${viewModes.list}`);
  }, [goBack]);

  return { onDuplicateItemSuccess, onDeleteItemSuccess, onDeleteItemError, onRenameSuccess };
};
