import { SingleValue } from 'chakra-react-select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DICTIONARY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { useGetFieldSource } from 'src/features/fieldBuilder/hooks/useGetFieldSource';
import { BuilderField } from 'src/features/fieldBuilder/models';

export type DroplistSelection = { value: string; label: string };

export interface useDroplistProps {
  builderField: BuilderField;
  value: DroplistSelection | null;
  error: string;
  selections: DroplistSelection[];
  onUpdate: (selection: SingleValue<DroplistSelection>) => void;
  onReset: () => Promise<void>;
}

const convert = (name: string): DroplistSelection => ({ label: name, value: name });
const findValue = (array: DroplistSelection[], searchValue: string) => {
  return array.find((r) => r.value.toLowerCase() === searchValue.toLowerCase());
};

export const useDroplist = (field: BuilderField): useDroplistProps => {
  const [value, setValue] = useState<DroplistSelection | null | undefined>(undefined);

  const { builderField, resetField, updateField, updateErrors } = useFieldActions(field);
  const { source, isLoading } = useGetFieldSource(field.fieldId, field.source);
  const t = useTranslate();

  const selections = useMemo<DroplistSelection[]>(
    () =>
      source
        .map<DroplistSelection>((r) => convert(r.name))
        .filter((item, pos, self) => self.findIndex((r) => r.label === item.label) === pos),
    [source],
  );

  const defaultValue = useMemo(
    () => (field.value ? findValue(selections, field.value) ?? convert(field.value) : null),
    [selections, field.value],
  );

  const verifiedValue = useMemo(() => (value === undefined ? defaultValue : value), [value, defaultValue]);

  const error = useMemo(() => {
    if (isLoading) {
      return '';
    } else if (!field.source) {
      return t(DICTIONARY.FIELD_DATASOURCE_EMPTY);
    } else if (verifiedValue && !findValue(selections, verifiedValue.value)) {
      return t(DICTIONARY.FIELD_CONTAINS_VALUE_NOT_IN_SELECTIONS);
    }

    return '';
  }, [field.source, isLoading, verifiedValue, selections, t]);

  useEffect(() => updateErrors(!!error || !!builderField.error), [error, builderField.error, updateErrors]);

  const onReset = useCallback(async () => {
    const fieldUpdated = await resetField(field.fieldId);

    if (fieldUpdated && fieldUpdated.value) {
      setValue(findValue(selections, fieldUpdated.value) ?? convert(fieldUpdated.value));
    } else if (fieldUpdated && !fieldUpdated.value) {
      setValue(null);
    }
  }, [field.fieldId, selections, resetField, setValue]);

  const onUpdate = useCallback(
    (selection: SingleValue<DroplistSelection>) => {
      if (selection) {
        updateField(field.fieldId, selection.value);
        setValue(selection);
      }
    },
    [field.fieldId, updateField, setValue],
  );

  return { value: verifiedValue, builderField, error, selections, onReset, onUpdate };
};
