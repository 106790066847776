import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { EmptyContentPage } from 'src/features/fieldBuilder/components/EmptyContentPage';
import { SelectItemContextProvider } from 'src/features/shared/selectItemModal/components/SelectItemContext';
import { SelectMediaContextProvider } from 'src/features/shared/selectMediaModal/components/SelectMediaContext';
import { useFieldBuilderData } from '../hooks/useFieldBuilderData';
import { FieldRenderer } from './FieldRenderer';
import { Section } from './Section';
import { SectionGroup } from './SectionGroup';

export const FieldBuilder: FC = () => {
  const { groupedFields, isLoading } = useFieldBuilderData();

  if (!groupedFields.length && !isLoading) {
    return <EmptyContentPage />;
  }

  return (
    <SelectMediaContextProvider>
      <SelectItemContextProvider>
        <SectionGroup>
          {groupedFields.map((section, sectionIndex) => (
            <Section section={section} index={sectionIndex} key={section.sectionName}>
              {section.fields.map((field, fieldIndex) => (
                <Box key={`${field.itemId}-${field.fieldId}`} mt={fieldIndex ? '6' : '0'}>
                  <FieldRenderer field={field} />
                </Box>
              ))}
            </Section>
          ))}
        </SectionGroup>
      </SelectItemContextProvider>
    </SelectMediaContextProvider>
  );
};
