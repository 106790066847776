import { useToast } from '@chakra-ui/react';
import { memo, useEffect } from 'react';
import { CLIENT_LANGUAGE, DICTIONARY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { LanguageItem } from 'src/features/common/models/index';
import { useLanguageSwitcher } from 'src/features/languageSwitcher/hooks/useLanguageSwitcher';
import { LanguageSwitcher } from 'src/features/shared/languageSwitcher/LanguageSwitcher';

export const LanguageSwitcherContainer = memo(() => {
  const { languages, languageRefetchHandler, languageHandler, defaultLanguage } = useLanguageSwitcher();
  const { sc_lang } = useUrlStrictContext(UrlParams.sc_lang);
  const toast = useToast();
  const t = useTranslate();

  useEffect(() => {
    if (!languages.length) return;

    const language: LanguageItem = languages.find(({ name }) => name === sc_lang) || defaultLanguage;
    !language && toast({ status: 'error', description: t(DICTIONARY.NOTIFICATIONS.URL_LANGUAGE_ERROR) });

    languageHandler(language || defaultLanguage, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, sc_lang, languageHandler, toast, t]);

  return (
    <LanguageSwitcher
      languages={languages}
      defaultLanguage={CLIENT_LANGUAGE}
      selectedLanguage={sc_lang}
      onLanguageSelection={languageHandler}
      onMenuOpen={languageRefetchHandler}
      menuButtonConfig={{ variant: 'ghost', size: 'sm' }}
    />
  );
});

LanguageSwitcherContainer.displayName = LanguageSwitcherContainer.name;
