import { FC, memo } from 'react';
import { FileCardWrapper } from 'src/features/shared/selectMediaModal/components/mediaModal/FileCardWrapper';
import { ImageCardWrapper } from 'src/features/shared/selectMediaModal/components/mediaModal/ImageCardWrapper';
import { MediaCardProps } from 'src/features/shared/selectMediaModal/models';

const CardSelectorBasic: FC<MediaCardProps> = (props) => {
  return props.item.type === 'Image' ? <ImageCardWrapper {...props} /> : <FileCardWrapper {...props} />;
};

export const CardSelector = memo(CardSelectorBasic, (prev, next) => prev.isSelected === next.isSelected);
