import { FC } from 'react';
import { ReactComponent as AudioIcon } from 'src/features/common/images/audio.svg';
import { ReactComponent as DocumentIcon } from 'src/features/common/images/document.svg';
import { ReactComponent as FileIcon } from 'src/features/common/images/file.svg';
import { ReactComponent as VideoIcon } from 'src/features/common/images/video.svg';
import { Card, CardFileInfo, CardIcon, CardInfoPanel, CardMediaDimensions } from 'src/features/media/card/Card';
import { FileCardProps } from 'src/features/media/card/model/card.model';

const iconSize = { height: '5.5rem', width: '5.5rem' };

export const FileCard: FC<FileCardProps> = ({ id, isSelected, title, extension, size, type, onSelect }) => (
  <Card id={id} isSelected={isSelected} onSelect={onSelect}>
    {type === 'Audio' && <CardIcon icon={<AudioIcon style={iconSize} />} />}
    {type === 'Document' && <CardIcon icon={<DocumentIcon style={iconSize} />} />}
    {type === 'File' && <CardIcon icon={<FileIcon style={iconSize} />} />}
    {type === 'Video' && <CardIcon icon={<VideoIcon style={iconSize} />} />}
    <CardInfoPanel title={title}>
      <CardFileInfo extension={extension} size={size} />
      <CardMediaDimensions dimensions={''} />
    </CardInfoPanel>
  </Card>
);
