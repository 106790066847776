import { Checkbox, Flex, FormControl } from '@chakra-ui/react';
import { Field } from 'formik';
import { FC, useCallback } from 'react';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { FieldErrorMessage } from 'src/features/fieldBuilder/components/fields/Common';
import { FormikFieldProps } from 'src/features/fieldBuilder/models';
import { GenericInput } from '../models/fieldBuilderInputs.model';

export const FieldBuilderCheckbox: FC<GenericInput> = ({ inputName, label, setFieldValue, setFieldTouched, submitForm, field }) => {
  const t = useTranslate();

  const normalizeTarget = useCallback((value: boolean) => {
    return value ? '_blank' : '';
  }, []);

  return (
    <Field name={inputName}>
      {({ field: formikField, form }: FormikFieldProps<string>) => (
        <FormControl isInvalid={!!form.errors[formikField.name] && form.touched[formikField.name]}>
          <Flex direction="column" gap={2}>
            <Checkbox
              isChecked={!!formikField.value}
              {...formikField}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(formikField.name, normalizeTarget(e.target.checked));
                setFieldTouched(formikField.name, true, false);
              }}
              onBlur={() => void submitForm()}
              isDisabled={!field.canEdit}
            >
              {t(label)}
            </Checkbox>
            <FieldErrorMessage message={form.errors[formikField.name]} />
          </Flex>
        </FormControl>
      )}
    </Field>
  );
};
