import moment from 'moment';

type Action =
  | { type: 'changeStartDate'; value: Date }
  | { type: 'changeEndDate'; value: Date }
  | { type: 'clearStart' }
  | { type: 'clearEnd' }
  | { type: 'toggleAvailable'; value: boolean }
  | { type: 'clearAll' };

export type FullDate = {
  date: Date | null;
  active: boolean;
};

type State = {
  start: FullDate;
  end: FullDate;
  isAvailable: boolean;
};

export const today = new Date();

const addMinutes = (date: Date, minutes: number): Date => new Date(date.getTime() + minutes * 60000);

export const defaultPublishRestrictionsStateValues: State = {
  start: { date: null, active: false },
  end: { date: null, active: false },
  isAvailable: true,
};

export const calcDefaultValues = (validFrom: string, validTo: string, isHidden: string) => {
  const defaults: State = JSON.parse(JSON.stringify(defaultPublishRestrictionsStateValues));
  if (validFrom) {
    defaults.start.date = new Date(moment(validFrom).toDate());
    defaults.start.active = true;
  }
  if (validTo) {
    defaults.end.date = new Date(moment(validTo).toDate());
    defaults.end.active = true;
  }
  if (isHidden) defaults.isAvailable = false;
  return defaults;
};

export const publishRestrictionsReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'changeStartDate':
      if (state.start.date === null && state.end.date !== null && state.end.date <= action.value)
        return { ...state, start: { ...state.start, date: addMinutes(state.end.date, -1), active: true } };
      if (state.start.date === null || state.end.date === null || action.value < state.end.date)
        return { ...state, start: { ...state.start, date: action.value, active: true } };
      return state;
    case 'changeEndDate':
      if (state.start.date !== null && state.end.date === null && state.start.date >= action.value)
        return { ...state, end: { ...state.end, date: addMinutes(state.start.date, 1), active: true } };
      if (state.start.date === null || state.end.date === null || state.start.date < action.value)
        return { ...state, end: { ...state.end, date: action.value, active: true } };
      return state;
    case 'clearStart':
      return { ...state, start: { date: null, active: false } };
    case 'clearEnd':
      return { ...state, end: { date: null, active: false } };
    case 'toggleAvailable':
      return { ...state, isAvailable: action.value };
    case 'clearAll':
      return defaultPublishRestrictionsStateValues;
    default:
      return state;
  }
};
