import { TableCellProps, Td } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

interface SearchTdProps extends PropsWithChildren, TableCellProps {}

export const SearchTd: FC<SearchTdProps> = ({ children, ...props }) => {
  return (
    <Td {...props} px="4" py="2">
      {children}
    </Td>
  );
};
