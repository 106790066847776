import { Switch } from '@chakra-ui/react';
import { FC, useCallback, useState } from 'react';
import { GenericFieldView } from 'src/features/fieldBuilder/components/fields/GenericFieldView';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { GenericField } from 'src/features/fieldBuilder/models';

export const Checkbox: FC<GenericField> = ({ field }) => {
  const [value, setValue] = useState(!!field.value);
  const { builderField, updateField, resetField } = useFieldActions(field);

  const onReset = useCallback(async () => {
    const fieldUpdated = await resetField(field.fieldId);
    fieldUpdated && setValue(!!fieldUpdated?.value);
  }, [field.fieldId, resetField, setValue]);

  const onUpdate = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      updateField(field.fieldId, target.checked ? target.value : '');
      setValue(target.checked);
    },
    [field.fieldId, updateField],
  );

  return (
    <GenericFieldView field={builderField} onReset={onReset}>
      <Switch
        data-testid="ef-checkbox"
        key={field.fieldId}
        size="md"
        value="1"
        isChecked={value}
        isDisabled={!field.canEdit}
        onChange={onUpdate}
      />
    </GenericFieldView>
  );
};
