import { useCallback, useMemo, useState } from 'react';
import { SelectItemFilterProps } from '../models';

export const useSelectItemFilter = ({
  collection,
  defaultFilter,
  onChange,
}: Pick<SelectItemFilterProps, 'collection' | 'defaultFilter' | 'onChange'>) => {
  const [filter, setFilter] = useState(defaultFilter);

  const onFilterChange = useCallback(
    (filter: string) => {
      onChange(filter);
      setFilter(filter);
    },
    [onChange],
  );
  const selectedFilter = useMemo(() => collection.find((el) => el.id === filter), [collection, filter]);

  return {
    onFilterChange,
    selectedFilter,
  };
};
