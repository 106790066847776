import { Accordion } from '@chakra-ui/react';
import { FC } from 'react';

export const SectionGroup: FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Accordion allowMultiple data-testid="accordion" defaultIndex={[0]}>
      {children}
    </Accordion>
  );
};
