import { FC } from 'react';
import { ItemTree, ItemTreeProps } from 'src/features/common/treeComponent/components/ItemTree';
import { TreeViewProps } from 'src/features/common/treeComponent/components/TreeView';
import { LeftSidePanel } from 'src/features/shared/selectMediaModal/components/mediaModal/LeftSidePanel';
import { DataSourcesList } from 'src/features/shared/selectMediaModal/models';

export type ItemTreeWrapperProps = {
  isFirsAncestorLoading: boolean;
  isSourceLoading: boolean;
  isWrongSource: boolean;
} & ItemTreeProps &
  Omit<TreeViewProps, 'stopLoadAncestors' | 'itemViewModel' | 'ancestors' | 'isOpened'> &
  DataSourcesList;

export const ItemTreeWrapper: FC<ItemTreeWrapperProps> = (props) => {
  const { isFirsAncestorLoading, isWrongSource, isSourceLoading, sourceIds } = props;
  if (isFirsAncestorLoading || (isWrongSource && !isSourceLoading)) {
    return <LeftSidePanel></LeftSidePanel>;
  }

  if (sourceIds.length === 0) {
    return (
      <LeftSidePanel>
        <ItemTree {...props} />
      </LeftSidePanel>
    );
  }

  return (
    <LeftSidePanel>
      {sourceIds.map((sourceId) => (
        <ItemTree key={sourceId} {...props} itemId={sourceId} isMultiple />
      ))}
    </LeftSidePanel>
  );
};
