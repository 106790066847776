import { AxiosInstance, AxiosResponse } from 'axios';
import { UseInfiniteQueryOptions } from 'react-query';
import { THUMBNAIL } from 'src/constants';
import { useAuthInfiniteQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { SearchQueryInput, SearchResultResponse } from 'src/features/search/models';
import { getSearchDataQueryString } from 'src/features/search/queries';
import { SEARCH_DEFAULT_PAGE } from 'src/features/search/searchConstants';

type ItemGraphQLResponse = GraphQLResponse<SearchResultResponse>;
export type SearchResponse = AxiosResponse<ItemGraphQLResponse>;

export const useGetSearchData = (
  query: SearchQueryInput,
  keys: string[],
  useQueryOptions?: Omit<UseInfiniteQueryOptions<SearchResponse, unknown, SearchResponse>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const { url } = useXMTenant();

  return useAuthInfiniteQuery<SearchResponse, unknown, SearchResponse>(
    ['get-search-data', ...keys],
    async (axiosInstance: AxiosInstance, page) => {
      return axiosInstance?.post<ItemGraphQLResponse>(getSitecoreGraphQLEndpoint(url), {
        query: getSearchDataQueryString,
        variables: { query: { ...query, paging: page ?? SEARCH_DEFAULT_PAGE }, width: THUMBNAIL.URL.WIDTH, height: THUMBNAIL.URL.HEIGHT },
      });
    },
    { ...useQueryOptions },
  );
};
