import { Heading, Spacer } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { useViewSwitcherModes } from 'src/features/common/components/actionsBar/hooks';
import { viewModes } from 'src/features/common/components/actionsBar/models';
import { useGetSitesWrapper } from 'src/features/common/hooks/useGetSitesWrapper';
import { DetailedItem, SolutionSite } from 'src/features/common/models';
import { ActionsMenuTableListWrapper } from 'src/features/fieldEditor/components/ActionsMenuTableListWrapper';
import { RenameItemButtonColumnView, RenameItemButtonItemView } from '../modals';
import { ActionsMenuFieldEditorWrapper } from './ActionsMenuFieldEditorWrapper';

interface HeaderProps {
  item: DetailedItem | undefined;
}

export const Header: FC<HeaderProps> = ({ item }) => {
  const { sites } = useGetSitesWrapper();
  const { viewMode } = useViewSwitcherModes();

  const isSite = useMemo(() => sites.find((site: SolutionSite) => site.rootItem.itemId === item?.itemId), [item?.itemId, sites]);

  const isRenameVisible = useMemo(
    () => (item?.access?.canRename || item?.displayNameField?.access?.canWrite) && !isSite,
    [item?.access?.canRename, item?.displayNameField?.access?.canWrite, isSite],
  );

  return (
    <>
      <Heading size="lg" isTruncated data-testid="item-name">
        {item?.displayName}
      </Heading>
      {isRenameVisible && (viewMode === viewModes.columns ? <RenameItemButtonColumnView /> : <RenameItemButtonItemView />)}
      <Spacer />
      {!isSite &&
        (item?.access?.canCreate || item?.access?.canDelete || item?.hasPresentation) &&
        (viewMode === viewModes.columns ? (
          <ActionsMenuFieldEditorWrapper
            variant="outline"
            canCreate={item.access.canCreate}
            canDelete={item.access.canDelete}
            hasChildren={item.hasChildren}
            hasPresentation={item.hasPresentation}
            itemId={item.itemId}
            name={item.name}
            parentItemId={item.parent.itemId}
            version={item.version}
          />
        ) : (
          <ActionsMenuTableListWrapper
            variant="outline"
            canCreate={item.access.canCreate}
            canDelete={item.access.canDelete}
            hasChildren={item.hasChildren}
            hasPresentation={item.hasPresentation}
            itemId={item.itemId}
            name={item.name}
            parentItemId={item.parent.itemId}
            version={item.version}
          />
        ))}
    </>
  );
};
