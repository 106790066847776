import { mdiChevronDown } from '@mdi/js';
import * as React from 'react';
/**
 * Chakra UI components
 */

import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Button, ButtonProps, IconButton, Image, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Icon } from 'src/features/common/components/icon/Icon';
import { NavigationAction, NavigationHorizontalProps, NavigationItem, NavigationVerticalProps } from './model';

/**
 * determineNavItemTargetId
 * Apply data-targetid to each navigation menu option,
 * with the format of "${name}_{index}" or "nav-menu-btn_${index}"
 * @param navItem function | object
 * @param index number
 * @returns "${name}_{index}" or "nav-menu-btn_${index}"
 */
const determineNavItemTargetId = (navItem: NavigationItem, index: number) => {
  if (typeof navItem === 'function' || typeof navItem.label !== 'string') {
    return `nav-menu-btn_${index}`;
  }

  return `${navItem.label.replace(/\s/g, '').toLocaleLowerCase()}_${index}`;
};

const OverflowMenu: React.FC<any> = ({
  visibilityMap,
  navItems,
}: {
  visibilityMap: {
    [key: string]: boolean;
  };
  navItems: NavigationItem[];
}) => {
  const allOptionsHidden = React.useMemo(() => {
    return Object.values(visibilityMap).every((v) => v === false);
  }, [visibilityMap]);

  return (
    <Box>
      <Menu placement="bottom-end" closeOnBlur id="nav-menu-more">
        <MenuButton
          as={Button}
          size="sm"
          variant="navHorizontal"
          rightIcon={<Icon layerStyle="menuButtonIcon" path={mdiChevronDown} />}
          data-testid="nav-btn-menu-more"
        >
          {allOptionsHidden ? 'Menu' : 'More'}
        </MenuButton>
        <MenuList>
          {navItems.map((item: any, i: number) => {
            if (!visibilityMap[determineNavItemTargetId(item, i)]) {
              if (typeof item === 'function') {
                return item(i, {
                  style: {
                    display: 'block',
                  },
                  buttonStyle: {
                    width: '100%',
                    fontWeight: 400,
                    color: 'neutral.800',
                    textAlign: 'left',
                    padding: '0.4rem 0.8rem',
                  },
                });
              }

              return (
                <MenuItem
                  key={i}
                  onClick={item.onClick}
                  color={item.active ? 'primary.500' : undefined}
                  fontWeight={item.active ? 600 : undefined}
                  _hover={{
                    bgColor: 'neutral.100',
                  }}
                >
                  {item.label}
                </MenuItem>
              );
            }
            return null;
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};

const IntersectionOberverWrapper: React.FC<any> = ({ children, navItems }: { children: any; navItems: NavigationItem[] }) => {
  const containerRef = React.useRef<any>(null);
  const previousItems = React.useRef<any>(navItems);

  const [visibilityMap, setVisibilityMap] = React.useState<{
    [key: string]: boolean;
  }>({});

  React.useEffect(() => {
    if (!containerRef.current) return;

    const navMenuBtnsObserver = new IntersectionObserver(handleMenuOptionsIntersection, {
      root: containerRef.current,
      threshold: 1,
      // 'more' menu option is around 82px
      // we take into acount its space to the intersection
      rootMargin: '0px -85px 0px 0px',
    });

    // We are adding observers to child elements of the container div
    // with ref as navRef. Notice that we are adding observers
    // only if we have the data attribute targetid on the child element
    Array.from(containerRef.current.children).forEach((item: any) => {
      if (item.dataset.targetid) {
        navMenuBtnsObserver.observe(item);
      }
    });

    if (previousItems.current !== navItems) {
      previousItems.current = navItems;
      // reset visibility map in case of new items
      setVisibilityMap({});
    }

    // clean up on unmount
    return () => {
      navMenuBtnsObserver.disconnect();
    };
  }, [navItems]);

  /**
   * handleMenuOptionsIntersection
   * The function to execute when a navigation menu option is intersected.
   * We update the visibility map by whether the option
   * is partially hidden or fully visible
   * @param entries
   */
  const handleMenuOptionsIntersection = (entries: { [key: string]: any }) => {
    const updatedEntries: { [key: string]: boolean } = {};

    entries.forEach((entry: any) => {
      const targetid = entry.target.dataset.targetid;

      // Check if element is visibile within container
      if (entry.isIntersecting) {
        updatedEntries[targetid] = true;
      } else {
        updatedEntries[targetid] = false;
      }
    });

    // Overwrite previous state values with current state
    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries,
    }));
  };

  const showOverflowMenu = React.useMemo(() => {
    return !Object.values(visibilityMap).every((v) => v === true);
  }, [visibilityMap]);

  return (
    <React.Fragment>
      <Box
        ref={containerRef}
        sx={{
          '.visible': {
            order: 0,
            opacity: 1,
            visibility: 'visible',
          },
          '.inVisible': {
            visibility: 'hidden',
            order: 100,
            opacity: 0,
            mx: '0.5rem',
            pointerEvents: 'none',
          },
        }}
        display="flex"
        height="100%"
        alignItems="center"
        overflow="hidden"
        p={1}
        boxSizing="content-box"
        flexBasis="auto"
        flexGrow={1}
        flexShrink={1}
        id="navigation-horizontal__menu"
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            className: visibilityMap[child.props['data-targetid'] as string] ? 'visible' : 'inVisible',
            'data-testid': `nav-item-btn_${index}`,
          });
        })}

        {showOverflowMenu && <OverflowMenu visibilityMap={visibilityMap} navItems={navItems} />}
      </Box>
    </React.Fragment>
  );
};

/**
 * Sitecore UI Navigation components
 */
export const NavigationHorizontal: React.FC<NavigationHorizontalProps> = (props) => {
  const { user } = useAuth0();

  return (
    <Box
      h="100%"
      w="100%"
      alignItems="center"
      display="flex"
      data-testid="navigationHorizontal"
      id="navigation-horizontal"
      {...props.boxProps}
    >
      {props.icon}
      {/* @ts-ignore */}
      {props.image && <Image ml="10px" alt="Logo" mr={15} {...props.image} />}
      <IntersectionOberverWrapper navItems={props.items}>
        {renderItems(props.items, {
          style: { height: '100%', marginRight: '0.5rem', flexShrink: 0 },
        })}
      </IntersectionOberverWrapper>
      <Box ml="auto" display="flex" alignItems="center" justifyContent="flex-end">
        {props.actions && renderActions(props.actions)}
        {props.menu && (
          <Menu data-testid="navigationHorizontal_accountMenu">
            <MenuButton data-testid="navigationHorizontal_accountMenuButton">
              <Avatar size="sm" name={props.menu.name} src={user?.picture} />
            </MenuButton>
            <MenuList>
              {props.menu.items.map((item, i) => {
                return (
                  <MenuItem onClick={item.onClick} key={i}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        )}
      </Box>
    </Box>
  );
};

export const NavigationVertical: React.FC<NavigationVerticalProps> = (props) => {
  return (
    <Box display="flex" flexDirection="column" data-testid="navigationVertical" id="navigation-vertical" {...props.boxProps}>
      {renderItems(props.items, { style: { marginBottom: '0.5rem' } })}
    </Box>
  );
};

/**
 * Sitecore UI Navigation component default props
 */
NavigationHorizontal.defaultProps = {};
NavigationVertical.defaultProps = {};

/**
 * Sitecore UI Navigation components display names
 */
NavigationHorizontal.displayName = 'NavigationHorizontal';
NavigationVertical.displayName = 'NavigationVertical';

const renderItems = (items: NavigationItem[], props?: ButtonProps) => {
  return items.map((item, i) => {
    if (typeof item === 'function') {
      return item(i, props);
    }

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      props?.onClick && props.onClick(e);
      item.onClick && item.onClick();
    };
    return (
      <Button
        data-targetid={determineNavItemTargetId(item, i)}
        key={i}
        // colorScheme={item.active ? 'primary' : 'gray'}
        // bgColor={item.active ? 'primary.100' : ''}
        variant={item.active ? 'navHorizontalActive' : 'navHorizontal'}
        // color={item.active ? 'primary.600' : 'inherit'}
        justifyContent="flex-start"
        {...props}
        onClick={onClick}
        px={2}
      >
        {item.icon && (
          <span
            style={{
              fontSize: '20px',
              display: 'inline-flex',
              lineHeight: '20px',
              height: '20px',
            }}
          >
            {item.icon}
          </span>
        )}
        {item.label}
      </Button>
    );
  });
};

const renderActions = (items: NavigationAction[]) => {
  return items.map((item, i) => {
    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      item.onClick && item.onClick();
    };

    return (
      <IconButton
        key={i}
        variant="ghost"
        size="sm"
        aria-label={item.ariaLabel || ''}
        onClick={onClick}
        icon={item.icon}
        style={{
          backgroundColor: item.active ? 'primary.50' : undefined,
          cursor: item.active ? undefined : 'pointer',
        }}
        title={item.ariaLabel || ''}
        mr="4"
        data-behavior-analytics-id={item['data-behavior-analytics-id']}
      />
    );
  });
};
