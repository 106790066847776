import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QUERIES_NAMES } from 'src/features/search/searchConstants';

export const useResetSearch = () => {
  const [, setSearchParams] = useSearchParams();

  const searchQueries = useMemo(() => [QUERIES_NAMES.SEARCH, QUERIES_NAMES.SEARCH_IN], []);

  const filterQueries = useMemo(() => Object.values(QUERIES_NAMES).filter((val) => searchQueries.indexOf(val) === -1), [searchQueries]);

  const resetAll = useCallback(() => {
    setSearchParams((params) => {
      [...searchQueries, ...filterQueries].forEach((query) => params.delete(query));

      return params;
    });
  }, [filterQueries, searchQueries, setSearchParams]);

  return { resetAll };
};
