import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { FieldEditor } from 'src/features/fieldEditor/components/FieldEditor';
import { WelcomeInstruction } from 'src/features/fieldEditor/components/WelcomeInstruction';
import { MillerColumnsContainer } from 'src/features/millerColumns/components/MillerColumnsContainer';

export const ColumnsView: FC = () => {
  const { sc_itemid, sc_site } = useUrlStrictContext();

  return (
    <Flex flexGrow="1" height="0">
      <Flex>
        <MillerColumnsContainer />
      </Flex>
      <Flex flex="10" overflow="hidden" borderLeft="1px" borderColor="chakra-border-color">
        {!sc_itemid || !sc_site ? <WelcomeInstruction /> : <FieldEditor />}
      </Flex>
    </Flex>
  );
};
