const element = document.getElementById('site-manager-auth-settings');
const settings = element ? JSON.parse(element.textContent || '') : null;

const domain = settings?.auth0?.domain;
const clientId = settings?.auth0?.clientId;
const audience = settings?.auth0?.audience;
const systemId = settings?.xmCloud?.systemId;

export const getAuthSettings = () => {
  return { domain, clientId, audience, systemId };
};
