import { Flex, Text, Th } from '@chakra-ui/react';
import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import { T } from '@transifex/react';
import { FC, useCallback, useMemo } from 'react';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useSortingParametersReturnType } from 'src/features/shared/treeTable/hooks/useSortingParameters';
import { TreeListItem } from 'src/features/shared/treeTable/models';
import { rowProps } from '../styles/tableListProps';

type TableHeaderProps = { text: string; field: keyof TreeListItem } & useSortingParametersReturnType;

export const TableHeadCell: FC<TableHeaderProps> = ({ text, field, setOrderField, setSortingOrder, orderField, sortingOrder }) => {
  const icon = useMemo(() => {
    if (orderField !== field) return undefined;

    if (sortingOrder === 'asc') return mdiArrowUp;
    if (sortingOrder === 'desc') return mdiArrowDown;
  }, [field, orderField, sortingOrder]);

  const onClick = useCallback(() => {
    if (orderField !== field) {
      setOrderField(field);
      setSortingOrder('asc');
    } else {
      if (sortingOrder === undefined) setSortingOrder('asc');
      if (sortingOrder === 'asc') setSortingOrder('desc');
      if (sortingOrder === 'desc') setSortingOrder(undefined);
    }
  }, [field, orderField, setOrderField, setSortingOrder, sortingOrder]);

  return (
    <Th {...rowProps}>
      <Flex gap="2" alignItems="flex-end" onClick={onClick} cursor="pointer">
        <Text fontSize="xs" fontWeight="semibold">
          <T _str={text} />
        </Text>
        <Icon path={icon} boxSize="3" />
      </Flex>
    </Th>
  );
};
