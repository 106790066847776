import { AxiosResponse } from 'axios';
import { memo, useCallback, useMemo } from 'react';
import {
  WorkflowCommand as Command,
  DetailedItem,
  ExecuteWorkflowCommandInput,
  GraphQLResponse,
  WorkflowResponse,
} from 'src/features/common/models';
import { useGetWorkflowCommands } from 'src/features/workflow/hooks/useGetWorkflowCommands';
import { useUpdateItemWorkflow } from 'src/features/workflow/hooks/useUpdateItemWorkflow';
import { WorkflowCommand } from './WorkflowCommand';

export interface WorkflowCommandsProps {
  item: DetailedItem;
  language: string;
  onWorkflowUpdate: () => void;
}

const formatCommands = (commands: AxiosResponse<GraphQLResponse<WorkflowResponse>>): Command[] => {
  return commands?.data.data.workflow.commands.nodes.filter((f) => !f.displayName.startsWith('__'));
};

export const WorkflowCommandsContainer = memo(({ item, language, onWorkflowUpdate }: WorkflowCommandsProps) => {
  const { mutate: updateItemWorkflow } = useUpdateItemWorkflow();
  const { data: commands, isSuccess: workflowIsSuccess } = useGetWorkflowCommands(
    item.itemId,
    language,
    item.workflow?.workflow?.workflowId || '',
    item.workflow?.workflowState?.stateId || '',
    {
      enabled: !!item,
    },
  );
  const itemCommands: Command[] | undefined = useMemo(() => commands && formatCommands(commands), [commands]);

  const onCommandClick = useCallback(
    (commandId: string, comments: string) => {
      const input = { commandId, comments, item: { itemId: item.itemId, language, version: item.version } } as ExecuteWorkflowCommandInput;
      updateItemWorkflow(input, { onSuccess: onWorkflowUpdate });
    },
    [item, language, onWorkflowUpdate, updateItemWorkflow],
  );

  return (
    <>
      {workflowIsSuccess &&
        itemCommands?.map((command) => <WorkflowCommand key={command.commandId} command={command} onCommandClick={onCommandClick} />)}
    </>
  );
});

WorkflowCommandsContainer.displayName = WorkflowCommandsContainer.name;
