import { Button, Input } from '@chakra-ui/react';
import { mdiTrayArrowUp } from '@mdi/js';
import { T } from '@transifex/react';
import { FC, LegacyRef, useRef } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useUploadFileButton } from 'src/features/shared/selectMediaModal/hooks/useUploadFileButton';

export type UploadFileButtonProps = {
  folderItemId: string | undefined;
  type: 'image' | 'file';
  refetchList: () => void;
  isDisabled: boolean;
};

export const UploadFileButton: FC<UploadFileButtonProps> = (props) => {
  const { onMediaSelect } = useUploadFileButton(props);
  const ref = useRef<HTMLInputElement>();

  return (
    <>
      <Button
        aria-label={DICTIONARY.REFRESH}
        gap="2"
        variant="outline"
        onClick={() => ref.current?.click()}
        isDisabled={props.isDisabled}
        data-testid="sc-upload-media-modal-upload"
      >
        <Icon path={mdiTrayArrowUp} fontSize="lg" />
        <T _str={DICTIONARY.UPLOAD} />
      </Button>
      <Input type="file" onChange={onMediaSelect} ref={ref as LegacyRef<HTMLInputElement>} hidden />
    </>
  );
};
