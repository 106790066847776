import { Text, Tooltip } from '@chakra-ui/react';
import { FC } from 'react';

export type SelectedItemViewType = {
  text: string;
};

export const SelectedItemView: FC<SelectedItemViewType> = ({ text }) => {
  return (
    <Tooltip label={text}>
      <Text as="span" isTruncated>
        {text}
      </Text>
    </Tooltip>
  );
};
