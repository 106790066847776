import { Flex } from '@chakra-ui/react';
import { mdiArrowExpand } from '@mdi/js';
import { FC, useState } from 'react';
import { Icon } from 'src/features/common/components/icon/Icon';
import { MediaLightboxProps } from 'src/features/media/models';
import 'src/features/media/styles/lightbox.scss';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/plugins/captions.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css'; //import once

export const MediaLightbox: FC<MediaLightboxProps> = ({ children, imageUrl, title, alt, overlayStyles }) => {
  const [hover, setHover] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);
  const slides = [{ src: imageUrl, alt, title }];

  return (
    <Flex position="relative">
      {children}
      <Flex
        justify="center"
        alignItems="center"
        {...overlayStyles}
        backgroundColor={hover ? 'blackAlpha.300' : ''}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          setHover(false);
          setOpenLightbox(!openLightbox);
        }}
        data-testid="image-overlay-area"
      >
        {hover && <Icon path={mdiArrowExpand} boxSize="1.75rem" p="1" backgroundColor="gray" color="white" />}
      </Flex>
      {openLightbox && (
        <Lightbox
          carousel={{ finite: slides.length <= 1 }}
          render={{
            buttonPrev: slides.length <= 1 ? () => null : undefined,
            buttonNext: slides.length <= 1 ? () => null : undefined,
          }}
          open={openLightbox}
          close={() => setOpenLightbox(false)}
          slides={slides}
          styles={{ root: { '--yarl__color_backdrop': 'var(--colors-blackAlpha-600)' } }}
          plugins={[Captions, Zoom]}
          zoom={{ scrollToZoom: true, maxZoomPixelRatio: 10 }}
        />
      )}
    </Flex>
  );
};
