import { Box, Button, Flex, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import { useMemo } from 'react';
import { MENU_HEIGHT } from 'src/constants';
import { useActionsBarContext } from 'src/features/common/components/actionsBar/ActionsBarContext';
import { Icon } from 'src/features/common/components/icon/Icon';
import { LanguageItem } from 'src/features/common/models';

export interface MenuButtonProps {
  menuButtonAs?: any;
  rightIcon?: JSX.Element;
  variant?: string;
  size?: string;
  borderRadius?: string;
}

interface LanguageSwitcherProps {
  languages: LanguageItem[];
  selectedLanguage: string;
  defaultLanguage?: string;
  onLanguageSelection: (language: LanguageItem) => void;
  onMenuOpen: any;
  menuButtonConfig?: MenuButtonProps;
}

const defaultMenuButtonConfig = {
  as: Button,
  rightIcon: <Icon layerStyle="menuButtonIcon" path={mdiChevronDown} />,
  variant: 'outline',
};

export const LanguageSwitcher = ({
  languages,
  onLanguageSelection,
  onMenuOpen,
  menuButtonConfig,
  selectedLanguage,
}: LanguageSwitcherProps) => {
  menuButtonConfig = { ...defaultMenuButtonConfig, ...menuButtonConfig };

  const activeLanguage = useMemo(() => languages.find(({ name }) => name === selectedLanguage), [selectedLanguage, languages]);

  const { languageSwitcherRef } = useActionsBarContext();

  return (
    <Box>
      <Menu onOpen={onMenuOpen} variant="filter" closeOnSelect={false}>
        <MenuButton data-testid="languageOptions" {...menuButtonConfig}>
          <Flex direction="row" minWidth="fit-content">
            {activeLanguage?.englishName ?? languages[0]?.englishName}
          </Flex>
        </MenuButton>
        <MenuList zIndex="dropdown" maxHeight={MENU_HEIGHT} overflowY="auto" ref={languageSwitcherRef}>
          <MenuOptionGroup type="radio" defaultValue={selectedLanguage}>
            {languages.map((lang, index) => (
              <MenuItemOption key={index} value={lang.name} onClick={() => onLanguageSelection(lang)} data-testid={lang.englishName}>
                {lang.englishName}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
