import { useCallback } from 'react';
import { FILTERS_BAR_VISIBLE_STORAGE_KEY } from 'src/constants';
import { useLocalStorage } from 'usehooks-ts';

export type ToggleButtonProps = { isFilterVisible: boolean; toggleFilters: () => void };

export type UseShowFiltersMode = () => ToggleButtonProps;

export const useShowFiltersMode: UseShowFiltersMode = () => {
  const [isFilterVisible, setIsFilterVisible] = useLocalStorage<boolean>(FILTERS_BAR_VISIBLE_STORAGE_KEY, true);

  const toggleFilters = useCallback(() => setIsFilterVisible(!isFilterVisible), [isFilterVisible, setIsFilterVisible]);

  return { isFilterVisible, toggleFilters };
};
