import { Box, Button, Menu, MenuButton, MenuList, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useMenuWithRadio } from 'src/features/common/components/menuWithRadio/hooks/useMenuWithRadio';
import { MenuWithRadioProps } from 'src/features/common/components/menuWithRadio/models';

export const MenuWithRadio: FC<MenuWithRadioProps> = ({ title, options, onChange }) => {
  const { icon, onCloseClick, onRadioChange, checkedIndex } = useMenuWithRadio(options, onChange);

  const isCheckedItem = checkedIndex >= 0;

  return (
    <Menu closeOnSelect={false} isLazy lazyBehavior="unmount">
      <MenuButton
        as={Button}
        rightIcon={<Icon path={icon} layerStyle="menuButtonIcon" onClick={onCloseClick} />}
        data-testid="menu-button-filter"
      >
        <Text as="span">
          <T _str={title} />
          {isCheckedItem ? ':' : ''}
        </Text>
        {isCheckedItem && (
          <Text as="span" fontWeight="normal" marginLeft="1">
            {options[checkedIndex].label}
          </Text>
        )}
      </MenuButton>
      <MenuList data-testid="menu-with-search-dropdown">
        <Box maxHeight="xs" overflowY="auto" position="relative">
          <RadioGroup onChange={onRadioChange} value={isCheckedItem ? options[checkedIndex].value : ''}>
            <Stack direction="column" gap="0">
              {options.map((option, index) => (
                <Box key={option.value} title={option.title || undefined}>
                  <Radio data-testid={option.value} value={option.value} marginX="4" marginY="2" isDisabled={option.isDisabled}>
                    {option.label}
                  </Radio>
                </Box>
              ))}
            </Stack>
          </RadioGroup>
        </Box>
      </MenuList>
    </Menu>
  );
};
