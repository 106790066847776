import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { MenuWithSearch } from 'src/features/common/components/menuWithSearch/MenuWithSearch';
import { useSiteFilter } from 'src/features/search/hooks';

export const SiteFilter: FC = () => {
  const { sites, onFilterChange } = useSiteFilter();

  return <MenuWithSearch title={DICTIONARY.HEADER.SITE} options={sites || []} onChange={onFilterChange} />;
};
