import { Alert, AlertDescription, AlertIcon, Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, MouseEventHandler } from 'react';
import { DICTIONARY } from 'src/constants';
import { DetailedItem } from 'src/features/common/models';
import { useGetLanguageItem } from 'src/features/languageSwitcher/hooks/useGetLanguageItem';
import { CreateVersionModal } from '../modals/CreateVersionModal';

export const MissingVersionAlert: FC<{ currentItem: DetailedItem; hidden?: boolean }> = ({ currentItem, hidden }) => {
  const { selectedLanguage } = useGetLanguageItem();

  return (
    <Box width="full" paddingX="6" paddingBottom="2" hidden={hidden}>
      <Alert colorScheme="purple" width="full" orientation="horizontal" status="info" alignItems="center">
        <AlertIcon />
        <AlertDescription>
          <T _str={DICTIONARY.MISSING_VERSION_MESSAGE} />
          <Text as="span" fontWeight="semibold" ml="1">
            &quot;{selectedLanguage && selectedLanguage.displayName}&quot;
          </Text>
        </AlertDescription>
        <Spacer />
        <Flex>
          <CreateVersionModal
            version={1}
            initialStateName={currentItem?.workflow?.workflow?.initialState?.displayName || ''}
            renderAction={(open: MouseEventHandler<HTMLButtonElement> | undefined) => (
              <Button size="sm" variant="solid" data-testid="new-version" onClick={open}>
                <T _str={DICTIONARY.ADD_VERSION} />
              </Button>
            )}
          />
        </Flex>
      </Alert>
    </Box>
  );
};
