import { AxiosResponse } from 'axios';
import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse, UpdateItemInput } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { updateItemFieldQueryString } from 'src/features/fieldBuilder/queries';
import { FieldResetResponse } from '../models/itemFields.model';

export const useUpdateItemField = () => {
  const { url } = useXMTenant();

  return useAuthMutation((axiosInstance) => (item: UpdateItemInput) => {
    const variables = { item, fieldId: item.fields[0].name, lang: item.language };

    return axiosInstance?.post<any, AxiosResponse<GraphQLResponse<FieldResetResponse>>>(getSitecoreGraphQLEndpoint(url), {
      query: updateItemFieldQueryString,
      variables,
    });
  });
};
