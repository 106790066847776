/* istanbul ignore file */

import { SELECTED_SHOW_OPTIONS_DEFAULT_VALUES, SELECTED_SHOW_OPTIONS_KEYS } from 'src/constants';
import { useLocalStorage } from 'usehooks-ts';

export const useDisplayOptions = () => {
  const [showLanguages] = useLocalStorage(
    SELECTED_SHOW_OPTIONS_KEYS.LANGUAGES,
    SELECTED_SHOW_OPTIONS_DEFAULT_VALUES[SELECTED_SHOW_OPTIONS_KEYS.LANGUAGES],
  );
  const [showWorkflow] = useLocalStorage(
    SELECTED_SHOW_OPTIONS_KEYS.WORKFLOW_STATE,
    SELECTED_SHOW_OPTIONS_DEFAULT_VALUES[SELECTED_SHOW_OPTIONS_KEYS.WORKFLOW_STATE],
  );
  const [showThumbnail] = useLocalStorage(
    SELECTED_SHOW_OPTIONS_KEYS.THUMBNAILS,
    SELECTED_SHOW_OPTIONS_DEFAULT_VALUES[SELECTED_SHOW_OPTIONS_KEYS.THUMBNAILS],
  );

  return {
    showLanguages,
    showWorkflow,
    showThumbnail,
  };
};
