import { MASTER_DB, MEDIA_LIBRARY_PATH } from 'src/constants';
import { isSameGuid } from 'src/features/common/utils/guidUpdater';
import { MediaFileType } from 'src/features/shared/selectMediaModal/models';

export const IMAGE_TEMPLATES = ['{F1828A2C-7E5D-4BBD-98CA-320474871548}', '{C97BA923-8009-4858-BDD5-D8BE5FCCECF7}'];

export const FILE_TEMPLATES = ['{962B53C4-F93B-4DF9-9821-415C867B8903}', '{611933AC-CE0C-4DDC-9683-F830232DB150}'];

export const AUDIO_TEMPLATES = ['{D56DB3AA-7373-4651-837E-8D3977A0B544}', '{E19A2758-F802-4FDC-B497-5FF7B3BAC54B}'];

export const VIDEO_TEMPLATES = ['{374D3A99-C098-4CD2-8FBC-DC2D1CA1C904}', '{E76ADBDF-87D1-4FCB-BA71-274F7DBF5670}'];

export const DOCUMENT_TEMPLATES = ['{777F0C76-D712-46EA-9F40-371ACDA18A1C}', '{2A130D0C-A2A9-4443-B418-917F857BF6C9}'];

export const MEDIA_LIBRARY_FOLDERS = ['{FE5DD826-48C6-436D-B87A-7C4210C7413B}', '{E3E2D58C-DF95-4230-ADC9-279924CECE84}'];

export const convertToImageSrc = (host: string, path: string) =>
  `${host}/-/media/${path.substring(MEDIA_LIBRARY_PATH.length + 1)}?db=${MASTER_DB}&thn=1`;

export const getMediaItemType = (templateIds: string[]): MediaFileType => {
  switch (true) {
    case isImage(templateIds):
      return 'Image';
    case isAudio(templateIds):
      return 'Audio';
    case isVideo(templateIds):
      return 'Video';
    case isDocument(templateIds):
      return 'Document';
    case isFile(templateIds):
      return 'File';
    case isMediaFolder(templateIds):
      return 'MediaFolder';
    default:
      return 'None';
  }
};

const isImage = (templateIds: string[]): boolean => isIdIncluded(IMAGE_TEMPLATES, templateIds);

const isAudio = (templateIds: string[]): boolean => isIdIncluded(AUDIO_TEMPLATES, templateIds);

const isVideo = (templateIds: string[]): boolean => isIdIncluded(VIDEO_TEMPLATES, templateIds);

const isDocument = (templateIds: string[]): boolean => isIdIncluded(DOCUMENT_TEMPLATES, templateIds);

const isFile = (templateIds: string[]): boolean => isIdIncluded(FILE_TEMPLATES, templateIds);

const isMediaFolder = (templateIds: string[]): boolean => isIdIncluded(MEDIA_LIBRARY_FOLDERS, templateIds);

const isIdIncluded = (firstList: string[], secondList: string[]) => firstList.some((r1) => secondList.some((r2) => isSameGuid(r1, r2)));
