import { Table, Tbody, Thead } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { TableHeader } from 'src/features/shared/treeTable/components/TableHeader';
import { TreeTableLoadingRow } from 'src/features/shared/treeTable/components/TreeTableLoadingRow';
import { TreeTableRow } from 'src/features/shared/treeTable/components/TreeTableRow';
import { useGetSitesViewModel } from 'src/features/shared/treeTable/hooks/useGetSitesViewModel';
import { useOpenEditorAndSetContext } from 'src/features/shared/treeTable/hooks/useOpenEditorAndSetContext';
import { useOptions } from 'src/features/shared/treeTable/hooks/useOptions';
import { useSortingParameters } from 'src/features/shared/treeTable/hooks/useSortingParameters';
import '../styles/styles.scss';

export type TreeTableProps = {
  openFieldEditor: (itemId: string) => void;
  language: string;
};

const TreeTableComponent: FC<TreeTableProps> = ({ openFieldEditor, language }) => {
  const options = useOptions();
  const openFieldEditorAndSetContext = useOpenEditorAndSetContext(openFieldEditor);

  const sortingParams = useSortingParameters();
  const { sites, isLoading } = useGetSitesViewModel(options.showTemplateSites, sortingParams.orderField, sortingParams.sortingOrder);

  return (
    <Table marginBottom="10">
      <Thead position="sticky" zIndex="1" top="0" backgroundColor="white">
        <TableHeader {...sortingParams} {...options} />
      </Thead>
      <Tbody>
        {sites.map((site) => (
          <TreeTableRow
            key={site.itemId}
            {...site}
            {...options}
            currentLanguage={language}
            subRowLvl={0}
            openFieldEditor={openFieldEditorAndSetContext}
            {...sortingParams}
          />
        ))}
        {isLoading && <TreeTableLoadingRow subRowLvl={0} itemsLength={0} {...options} />}
      </Tbody>
    </Table>
  );
};

export const TreeTable = memo(TreeTableComponent, (prevProps, nextProps) => prevProps.language === nextProps.language);
