/* istanbul ignore file */
//* This is a temporary file and this logic should be in the Design System library for uniformity.
import { Input } from '@chakra-ui/react';
import { FC } from 'react';

interface DatePickerTimeInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const DatePickerTimeInput: FC<DatePickerTimeInputProps> = ({ value, onChange }) => (
  <Input
    value={value}
    className="react-datepicker-time__input"
    type="time"
    required
    name="time-input"
    onChange={(e: { target: { value: any } }) => e.target.value && onChange?.(e.target.value)}
  />
);

DatePickerTimeInput.displayName = DatePickerTimeInput.name;
