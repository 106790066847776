import {
  Box,
  Button,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { memo } from 'react';
import { DICTIONARY } from 'src/constants';

export const VersionHelpPopover = memo(() => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="link" size="sm">
            <T _str={DICTIONARY.LEARN_MORE} />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Heading size="sm" px="1">
            <T _str={DICTIONARY.ABOUT_VERSIONING} />
          </Heading>
        </PopoverHeader>
        <PopoverBody css={{ height: '200px', overflow: 'auto' }}>
          <Box p="1" textAlign="justify">
            <Text mb="2">
              <T _str={DICTIONARY.VERSION_HELP[0]} />
            </Text>
            <Text mb="2">
              <T _str={DICTIONARY.VERSION_HELP[1]} />
            </Text>
            <Text mb="2">
              <T _str={DICTIONARY.VERSION_HELP[2]} />
            </Text>
            <Text>
              <T _str={DICTIONARY.VERSION_HELP[3]} />
            </Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});

VersionHelpPopover.displayName = VersionHelpPopover.name;
