import { useMemo } from 'react';
import { CACHE_LIFETIME } from 'src/constants';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import {
  StateItem,
  WorkflowListGraphQLResponse,
  WorkflowListQueryOptions,
  WorkflowListResponseData,
} from 'src/features/common/components/filters/models';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { getWorkflowListQueryString as query } from '../queries';

const useWorkflowList = (useQueryOptions?: WorkflowListQueryOptions): WorkflowListResponseData => {
  const { url } = useXMTenant();

  const transformId = (id: string) => id.replace(/[{}-]/g, '').toLowerCase();

  const { data, isLoading } = useAuthQuery(
    ['get-workflow-list'],
    async (axiosInstance) => axiosInstance?.post<WorkflowListGraphQLResponse>(getSitecoreGraphQLEndpoint(url), { query }),
    { ...useQueryOptions, staleTime: CACHE_LIFETIME },
  );

  const workflowList = useMemo(
    () =>
      !isLoading
        ? data?.data?.data?.workflows.nodes.map((item) => ({
            ...item,
            workflowId: transformId(item.workflowId),
            states: { nodes: item.states?.nodes?.map((state: StateItem) => ({ ...state, stateId: transformId(state.stateId) })) },
          }))
        : undefined,
    [data, isLoading],
  );

  return { workflowList, isLoading };
};

export const useWorkflowListWrapper = (useQueryOptions?: WorkflowListQueryOptions): WorkflowListResponseData =>
  useWorkflowList(useQueryOptions);
