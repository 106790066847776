import { useCallback, useState } from 'react';

export const useSearchInput = (searchInput: string, setSearchInput: (value: string) => void) => {
  const [clearButtonVisibility, setClearButtonVisibility] = useState<'visible' | 'hidden'>('hidden');
  const setClearButtonVisibilityConditionally = useCallback(() => {
    if (searchInput) {
      setClearButtonVisibility('visible');
    }
  }, [searchInput]);

  const setSearchInputAndClearButtonVisibility = useCallback(
    (value: string) => {
      setSearchInput(value);
      if (value) {
        setClearButtonVisibility('visible');
      } else {
        setClearButtonVisibility('hidden');
      }
    },
    [setSearchInput],
  );

  const setSearchInputAndClearButtonVisibilityOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchInputAndClearButtonVisibility(value);
    },
    [setSearchInputAndClearButtonVisibility],
  );

  return {
    clearButtonVisibility,
    setClearButtonVisibility,
    setClearButtonVisibilityConditionally,
    setSearchInputAndClearButtonVisibility,
    setSearchInputAndClearButtonVisibilityOnChange,
  };
};
