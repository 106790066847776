import { mdiChevronDown, mdiClose } from '@mdi/js';
import { useCallback, useMemo, useState } from 'react';
import { MenuWithSearchCheckedItem, MenuWithSearchOption, UseMenuWithSearch } from 'src/features/common/components/menuWithSearch/models';

export const useMenuWithSearch: UseMenuWithSearch = (options, visibleSelectedItemsCount, onChange, searchShowCount) => {
  const [search, setSearch] = useState('');

  const isSearchVisible = useMemo(
    () => searchShowCount < (options.reduce((acc, obj) => acc + (obj.children?.length || 0), 0) || options.length),
    [options, searchShowCount],
  );

  const getCheckedItems = useCallback((options: MenuWithSearchOption[]): MenuWithSearchCheckedItem[] => {
    const checkedValues: MenuWithSearchCheckedItem[] = [];

    options.forEach((option) => {
      if (option.checked) {
        checkedValues.push(option);
      }

      option.children?.forEach((child) => {
        if (child.checked) {
          checkedValues.push({ ...child, parentLabel: option.label });
        }
      });
    });

    return checkedValues;
  }, []);

  const isIndeterminate = (option: MenuWithSearchOption) => {
    if (!option.children) {
      return false;
    }

    const checked = option.children.filter((child) => child.checked);
    return checked.length > 0 && checked.length < option.children.length;
  };

  const filteredOptions: MenuWithSearchOption[] = useMemo(() => {
    return options.filter((option) => {
      const searchValue = search.toLowerCase();
      const isParentIncluded = option.label.toLowerCase().includes(searchValue);
      let isOptionVisible = false;

      if (option.children) {
        option.children?.forEach((child) => {
          const isIncluded = child.label.toLowerCase().includes(searchValue);
          child.isHidden = !isIncluded;

          if (isIncluded) {
            isOptionVisible = true;
          }
        });
        option.isHidden = isOptionVisible ? false : !isParentIncluded;
      } else {
        option.isHidden = !isParentIncluded;
      }

      return true;
    });
  }, [options, search]);

  const getSelectedSubItems = useCallback(() => {
    const selected: string[] = [];

    options.forEach((optionChild) => optionChild.children?.forEach((child) => (child.checked ? selected.push(child.value) : null)));

    return selected;
  }, [options]);

  const selectedString = useMemo(() => {
    const selected = getCheckedItems(options).map((item) => ({ label: item.label, parentLabel: item.parentLabel }));

    if (selected.length === 0) {
      return '';
    }

    const selectedVisible = selected.slice(0, visibleSelectedItemsCount);
    let result = selected[0].parentLabel
      ? selectedVisible.map((item) => `${item.parentLabel} - ${item.label}`).join(', ')
      : selectedVisible.map((item) => item.label).join(', ');

    if (selected.length > visibleSelectedItemsCount) {
      result += ` +${selected.length - visibleSelectedItemsCount}`;
    }

    return `${result}`;
  }, [getCheckedItems, options, visibleSelectedItemsCount]);

  const onCloseClick = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      if (selectedString.length) {
        event.stopPropagation();
        setSearch('');
        onChange([]);
      }
    },
    [onChange, selectedString.length],
  );

  const handleOnChangeParent = useCallback(
    (item: MenuWithSearchOption, index: number) => {
      item.children?.forEach((child: MenuWithSearchOption) => (child.checked = item.checked));
      options[index] = item;

      const selected: string[] = [];

      item.children
        ? options.forEach((child) => child.children?.forEach((child) => (child.checked ? selected.push(child.value) : null)))
        : options.forEach((child) => (child.checked ? selected.push(child.value) : null));

      onChange(selected);
    },
    [onChange, options],
  );

  const handleOnChangeChild = useCallback(
    (item: MenuWithSearchOption, index: number, parentIndex: number) => {
      const children = options[parentIndex].children;

      if (children) {
        children[index] = item;
      }

      const selected = getSelectedSubItems();

      onChange(selected);
    },
    [getSelectedSubItems, onChange, options],
  );

  const isEachChildChecked = useCallback((options: MenuWithSearchOption[]) => options.every((child) => child.checked), []);

  const isEachChildHidden = useCallback((options: MenuWithSearchOption[]) => options.every((child) => child.isHidden), []);

  const icon = selectedString.length ? mdiClose : mdiChevronDown;

  return {
    isIndeterminate,
    filteredOptions,
    selectedString,
    icon,
    search,
    setSearch,
    onCloseClick,
    handleOnChangeParent,
    handleOnChangeChild,
    isSearchVisible,
    isEachChildChecked,
    isEachChildHidden,
  };
};
