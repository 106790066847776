import { Flex, IconButton, Text } from '@chakra-ui/react';
import { mdiChevronDown, mdiChevronRight, mdiFileOutline } from '@mdi/js';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { ItemTreeProps } from 'src/features/common/treeComponent/components/ItemTree';
import { useItemView } from 'src/features/common/treeComponent/hooks/useItemView';
import { ExtendedItemViewModel, ItemViewModel } from 'src/features/common/treeComponent/models';

export type ItemViewProps = {
  item: ItemViewModel | undefined;
  opened: boolean;
  setOpened: (value: boolean) => void;
  level?: number;
};

export const ItemView: FC<ItemViewProps & ItemTreeProps> = (props) => {
  const { item, opened, setOpened, level } = props;
  const { setHover, color, onSelect } = useItemView(props);
  const extendedItem = props.item as ExtendedItemViewModel;

  if (!item) {
    return <></>;
  }

  return (
    <Flex
      direction="row"
      padding={1}
      gap={2}
      alignItems="center"
      height={10}
      backgroundColor={color}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onSelect}
      cursor="pointer"
      paddingLeft={`${(level ? level * 3 : 1) / 4}rem`}
    >
      <IconButton
        onClick={(e) => {
          setOpened(!opened);
          e.stopPropagation();
        }}
        variant="ghost"
        size="sm"
        aria-label={opened ? DICTIONARY.COLLAPSE : DICTIONARY.EXPAND}
        icon={<Icon path={opened ? mdiChevronDown : mdiChevronRight} boxSize={6} />}
        visibility={item.hasChildren ? 'visible' : 'hidden'}
      />
      <Icon path={mdiFileOutline} color="neutral.800" boxSize={6} />
      <Text maxWidth="72" noOfLines={1} title={item.name}>
        {extendedItem.displayName || extendedItem.name}
      </Text>
    </Flex>
  );
};
