import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { stopPropagation } from 'src/features/common/utils/stopPropagation';
import { ActionsMenuTreeTableWrapper } from 'src/features/fieldEditor/components/ActionsMenuTreeTableWrapper';
import { TreeTableCreateItemMenuWrapper } from 'src/features/shared/createItem/components';
import { RefetchFunction } from 'src/features/shared/treeTable/components/TreeTableRefetchContext';
import { ParentData, TreeListItem } from 'src/features/shared/treeTable/models';

export type ActionsCellProps = {
  refetchParent?: RefetchFunction;
  refetchCurrent: RefetchFunction;
} & TreeListItem &
  ParentData;

export const ActionsCell: FC<ActionsCellProps> = (props) => {
  const { refetchParent, access, parentAccess, insertOptions, parentInsertOptions, hasPresentation } = props;
  const isCreateEnabled = access?.canCreate || parentAccess?.canCreate || !!insertOptions?.length || !!parentInsertOptions?.length;

  const canShowActionsMenu = access?.canCreate || access?.canDelete || access?.canRename || hasPresentation;

  if (!refetchParent) return <></>;

  return (
    <Flex direction="row" gap="1" onClick={stopPropagation}>
      {isCreateEnabled && <TreeTableCreateItemMenuWrapper {...props} />}
      {canShowActionsMenu && <ActionsMenuTreeTableWrapper {...props} />}
    </Flex>
  );
};
