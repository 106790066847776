import { useMemo } from 'react';
import { SearchCriteriaInput, SearchCriteriaType, SearchOperator } from 'src/features/search/models';
import { FILTER_FIELDS, QUERIES_NAMES, SITE_TYPE_VALUE } from 'src/features/search/searchConstants';

export const useSiteTypeFilterCriteria = (params: URLSearchParams): SearchCriteriaInput[] | undefined => {
  const siteType = useMemo(() => params.get(QUERIES_NAMES.SITE_TYPE) || '', [params]);

  const siteTypeValue = useMemo(() => {
    switch (siteType) {
      case 'sxa':
        return SITE_TYPE_VALUE.HEADLESS;
      case 'nonsxa':
        return SITE_TYPE_VALUE.NON_SXA;
      default:
        return undefined;
    }
  }, [siteType]);

  return useMemo<SearchCriteriaInput[] | undefined>(() => {
    if (!siteType.length || !siteTypeValue) {
      return undefined;
    }

    const baseSearchCriteria: Pick<SearchCriteriaInput, 'field' | 'value' | 'criteriaType'> = {
      field: FILTER_FIELDS.SITE_TYPE,
      value: SITE_TYPE_VALUE.HEADLESS,
      criteriaType: SearchCriteriaType.EXACT,
    };

    return siteTypeValue === SITE_TYPE_VALUE.NON_SXA
      ? [
          { ...baseSearchCriteria, operator: SearchOperator.NOT },
          { ...baseSearchCriteria, value: SITE_TYPE_VALUE.SXA, operator: SearchOperator.NOT },
        ]
      : [{ ...baseSearchCriteria, operator: SearchOperator.SHOULD }];
  }, [siteType.length, siteTypeValue]);
};
