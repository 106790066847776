import { useToast } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { DICTIONARY, MASTER_DB } from 'src/constants';
import { useGetLanguages } from 'src/features/common/hooks/useGetLanguages';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { PublishItemInput } from 'src/features/common/models';
import { Disclosure } from 'src/features/common/models/disclosure.model';
import { usePublishItem } from 'src/features/publish/hooks/usePublishItem';

export const usePublishModal = ({ onClose }: Disclosure) => {
  const { sc_itemid } = useUrlStrictContext(UrlParams.sc_itemid | UrlParams.sc_site);
  const { data: langsData } = useGetLanguages(MASTER_DB);
  const { mutate: publishItem } = usePublishItem();
  const toast = useToast();
  const t = useTranslate();

  const [input, setInput] = useState(new PublishItemInput());

  const languages = useMemo(() => langsData?.data?.data?.languages?.nodes || [], [langsData]);
  const allLanguages = useMemo(() => languages.map(({ name }) => name) || [], [languages]);
  const isAllLangs = useMemo(() => input.languages.length === allLanguages.length, [input.languages, allLanguages]);

  const setInputProps = useCallback((options: Record<string, any>) => setInput((state) => ({ ...state, ...options })), [setInput]);

  const setAllOrZeroLangs = useCallback(
    (isAllLangs: boolean) => setInput((state) => ({ ...state, languages: isAllLangs ? allLanguages : [] })),
    [allLanguages, setInput],
  );

  const onClick = useCallback(() => {
    const publishInput = { ...input, rootItemId: sc_itemid } as PublishItemInput;

    publishItem(publishInput, {
      onSuccess: (data) => {
        if (data && !data.data.errors) {
          toast({ status: 'success', description: t(DICTIONARY.NOTIFICATIONS.PUBLISH) });
        } else if (data.data.errors) {
          toast({ status: 'error', description: data.data.errors[0].message });
        } else {
          toast({ status: 'error', description: t(DICTIONARY.NOTIFICATIONS.SOMETHING_WENT_WRONG) });
        }

        onClose();
      },
    });
  }, [sc_itemid, input, publishItem, onClose, toast, t]);

  return { input, languages, isAllLangs, onClick, setInput, setInputProps, setAllOrZeroLangs };
};
