import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Hide } from '@chakra-ui/react';
import { DICTIONARY } from 'src/constants';
import { SitecoreUser } from 'src/features/common/auth/SitecoreUser';
import { AnnotationDetails } from 'src/features/common/components/headers/AnnotationDetails';
import { ApplicationLinks } from 'src/features/common/components/links/ApplicationLinks';
import { getAppLink } from 'src/features/common/config';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { LanguageSwitcherContainer } from 'src/features/languageSwitcher/components/LanguageSwitcherContainer';
import { CreateSupportCase } from 'src/features/main/components/CreateSupportCase';
import { NavigationHorizontal } from 'src/features/temporary/Navigation/Navigation';

export const Header: React.FC = () => {
  const t = useTranslate();
  const { logout, user } = useAuth0<SitecoreUser>();
  const { urlContext } = useUrlSharing(UrlParams.tenantName | UrlParams.organization);
  const organization = urlContext.organization || '';
  const tenantName = urlContext.tenantName || '';

  const menu = {
    name: `${user?.given_name} ${user?.family_name}`,
    items: [
      {
        label: t(DICTIONARY.MANAGE_ACCOUNT),
        onClick: () =>
          window.open(encodeURI(getAppLink({ app: 'portal', path: '/profile', params: { organization, tenantName }, isNew: true }))),
      },
      { label: t(DICTIONARY.LOGOUT), onClick: () => logout({ logoutParams: { returnTo: window.location.origin } }) },
    ],
  };

  return (
    <Flex justifyContent="space-between">
      <Flex>
        <ApplicationLinks />
      </Flex>
      <Flex alignItems="center">
        <Hide below="md">
          <AnnotationDetails marginRight="5" />
        </Hide>
        <LanguageSwitcherContainer />
        <CreateSupportCase marginLeft="2" />
        <NavigationHorizontal boxProps={{ w: 'fit-content' }} items={[]} menu={menu} data-testid="nav-bar"></NavigationHorizontal>
      </Flex>
    </Flex>
  );
};
