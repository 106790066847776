export type UrlContextType = string | null | undefined;
export type UrlSiteType = 'sxa' | 'nonsxa' | undefined;

export interface Context<T, V> {
  sc_site: T;
  sc_itemid: T;
  sc_lang: T;
  sc_version: V;
}

export interface UrlSharingBaseActions<T> {
  setUrlContext: (context: Partial<T>, shouldOverride?: boolean) => void;
}

export interface UrlSharingBase<T> extends UrlSharingBaseActions<T> {
  urlContext: T;
}

export class UrlContext implements Context<UrlContextType, UrlContextType> {
  organization: UrlContextType;
  tenantName: UrlContextType;
  sc_site: UrlContextType;
  sc_itemid: UrlContextType;
  sc_version: UrlContextType;
  sc_lang: UrlContextType;
  sc_collection: UrlContextType;
  sc_type: UrlSiteType;
}

export interface UrlSharing extends UrlSharingBase<UrlContext> {}

export interface UrlStrictContext extends Context<string, number | undefined> {
  sc_collection: string;
  sc_type: UrlSiteType;
}

export interface UrlStrictSiteContext extends UrlStrictContext {
  sc_isSite: boolean;
}

export interface UseUrlStrictContext extends UrlStrictSiteContext, UrlSharingBaseActions<UrlStrictContext> {}
