import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { GenericView } from 'src/features/fieldBuilder/models';
import { AccessRestrictedWrapper, FieldError, FieldTopping, ResetButton, ToolTip } from './Common';

export const GenericFieldView: FC<React.PropsWithChildren<GenericView>> = ({ field, errorMsg, children, onReset }) => {
  return (
    <Flex gap="3" direction="column">
      <FieldTopping field={field} />
      <Flex gap="2" direction="column">
        <AccessRestrictedWrapper canEdit={field.canEdit}>{children}</AccessRestrictedWrapper>
        {field.description && <ToolTip text={field.description} />}
      </Flex>
      {(field.error || errorMsg) && <FieldError message={errorMsg || field.error} />}
      <ResetButton onClick={onReset} isDisabled={!field.canEdit || field.containsStandardValue || field.containsInheritedValue} />
    </Flex>
  );
};
