import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptions } from 'react-query';
import { CLIENT_LANGUAGE } from 'src/constants';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { ExtendedItemViewModel, GetTreeItemData, TreeItemResponse } from 'src/features/common/treeComponent/models';
import { getItemQueryString } from '../queries';

type TreeItemResponseGraphQLResponse = GraphQLResponse<TreeItemResponse>;
type TreeItemResponseResponse = AxiosResponse<TreeItemResponseGraphQLResponse>;

const useGetSelectedTreeItem = (
  input: { path: string; language: string },
  useQueryOptions?:
    | Omit<UseQueryOptions<TreeItemResponseResponse, unknown, TreeItemResponseResponse, string[]>, 'queryKey' | 'queryFn'>
    | undefined,
): GetTreeItemData => {
  const { url } = useXMTenant();
  const toast = useToast();

  const { data, isLoading } = useAuthQuery(
    ['get-selected-tree-item', input.path, input.language],
    async (axiosInstance) => {
      return axiosInstance?.post<TreeItemResponseGraphQLResponse>(getSitecoreGraphQLEndpoint(url), {
        query: getItemQueryString,
        variables: {
          path: input.path,
          language: input.language,
        },
      });
    },
    { ...useQueryOptions },
    toast,
  );

  const item = useMemo(() => {
    const sitecoreItem = data?.data?.data?.item;

    if (!sitecoreItem) {
      return undefined;
    }

    const children = sitecoreItem?.children?.nodes.map<ExtendedItemViewModel>((it) => ({ ...it, children: undefined }));
    return { ...sitecoreItem, children };
  }, [data?.data.data.item]);

  return { item, isLoading };
};

export const useGetSelectedTreeItemWrapper = (path: string, enabled: boolean, language?: string | null) =>
  useGetSelectedTreeItem({ path, language: language ?? CLIENT_LANGUAGE }, { enabled });
