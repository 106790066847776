import { useCallback, useEffect, useMemo, useState } from 'react';
import { DICTIONARY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { useGetFieldSource } from 'src/features/fieldBuilder/hooks/useGetFieldSource';
import { BuilderField } from 'src/features/fieldBuilder/models';
import { useSelectItemContext } from 'src/features/shared/selectItemModal/components/SelectItemContext';
import { SelectItemContextType } from 'src/features/shared/selectItemModal/models';
import { UseDroptreeProps } from '../models';
import { useGetLinkedItemWrapper } from './';

const selectItemDialogTexts = {
  header: DICTIONARY.SELECT_ITEM,
  actionButton: DICTIONARY.ADD_ITEM,
};

export const useDroptree = (field: BuilderField): UseDroptreeProps => {
  const t = useTranslate();
  const { builderField, resetField, updateField, updateErrors } = useFieldActions(field);
  const { sc_lang } = useUrlStrictContext(UrlParams.sc_lang);
  const { openModal } = useSelectItemContext();
  const { source } = useGetFieldSource(field.fieldId, field.source);
  const { item: linkedItem } = useGetLinkedItemWrapper(builderField.value, true, sc_lang);

  const error = useMemo(() => (field.source && !source.length ? t(DICTIONARY.INVALID_DATASOURCE) : ''), [field.source, source.length, t]);

  useEffect(() => updateErrors(!!error || !!builderField.error), [error, builderField.error, updateErrors]);

  const onSelectChange = useCallback(
    async (data: SelectItemContextType) => {
      const result = await updateField(builderField.fieldId, data.id);

      if (result) {
        setCurrentContext({ ...data, onSelectChange });
      }
    },
    [builderField, updateField],
  );

  const [currentContext, setCurrentContext] = useState<SelectItemContextType>({ id: builderField.value, onSelectChange });

  const onSelectItemButtonClick = useCallback(() => {
    openModal({
      datasource: builderField.source,
      texts: selectItemDialogTexts,
      includeFilters: false,
      context: currentContext,
    });
  }, [builderField.source, currentContext, openModal]);

  const onReset = useCallback(async () => {
    const fieldUpdated = await resetField(field.fieldId);

    if (fieldUpdated) {
      setCurrentContext({ id: fieldUpdated.value, onSelectChange });
    }
  }, [resetField, field.fieldId, onSelectChange]);

  const onClean = useCallback(async () => {
    const result = await updateField(builderField.fieldId, '');

    if (result) {
      setCurrentContext({ id: '', onSelectChange });
    }
  }, [builderField, onSelectChange, setCurrentContext, updateField]);

  const selectedItemViewText = useMemo(() => linkedItem?.displayName || t(DICTIONARY.NONE), [linkedItem, t]);

  return {
    error,
    onReset,
    onSelectItemButtonClick,
    onClean,
    selectedItemViewText,
    builderField,
  };
};
