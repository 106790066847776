import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';

export const useSaveNavigate = (): ((pathname: string) => void) => {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (pathname: string) => {
      if (location.state && location.state.language) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(UrlParams[UrlParams.sc_lang], location.state.language);
        location.search = searchParams.toString();
      }

      navigate({ ...location, pathname });
    },
    [location, navigate],
  );
};
