import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { DeleteItemVersionInput, DeleteItemVersionResponse, GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { deleteVersionQueryString } from 'src/features/versions/graphql';

export const useDeleteItemVersion = () => {
  const { url } = useXMTenant();

  return useAuthMutation(
    (axiosInstance) => (input: DeleteItemVersionInput) =>
      axiosInstance?.post<GraphQLResponse<DeleteItemVersionResponse>>(getSitecoreGraphQLEndpoint(url), {
        query: deleteVersionQueryString,
        variables: { input },
      }),
  );
};
