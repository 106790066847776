import { AxiosInstance, AxiosResponse } from 'axios';
import { THUMBNAIL } from 'src/constants';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { ListItemsResponse } from 'src/features/shared/treeTable/models';
import { navigationQueryString } from 'src/features/shared/treeTable/queries';

type ListItemGraphQLResponse = GraphQLResponse<ListItemsResponse>;

export type GetListItemsVariables = {
  id: string;
  language: string;
  includeHiddenItems: boolean;
  excludeTemplateIDs: string[];
};

export type OnErrorCallback = (errors: any[] | undefined) => void;

export const useGetTreeListItems = (variables: GetListItemsVariables, expanded: boolean, onError?: OnErrorCallback) => {
  const { url } = useXMTenant();
  const { id, language, includeHiddenItems, excludeTemplateIDs } = variables;

  const { data, isLoading, refetch } = useAuthQuery(
    ['get-tree-list-items', id, language, includeHiddenItems?.toString(), excludeTemplateIDs.join(',')],
    async (axiosInstance) => {
      return fetchAllChildrenPages(axiosInstance, url, variables, makeGetItemsRequest, onError);
    },
    {
      enabled: expanded,
      cacheTime: 0,
    },
  );

  const item = data?.data?.data?.item;

  return { item, isLoading, refetch };
};

export const fetchAllChildrenPages = async (
  axiosInstance: AxiosInstance,
  url: string,
  variables: GetListItemsVariables,
  makeRequest: (
    axiosInstance: AxiosInstance,
    url: string,
    variables: GetListItemsVariables,
    cursor: string | undefined,
  ) => Promise<AxiosResponse<GraphQLResponse<ListItemsResponse>, any>>,
  onError?: OnErrorCallback,
) => {
  let response: AxiosResponse<GraphQLResponse<ListItemsResponse>, any>;
  let cursor: string | undefined;
  const children = [];

  do {
    response = await makeRequest(axiosInstance, url, variables, cursor);

    if (response.data.errors?.length && onError) {
      onError(response.data.errors);
    }

    cursor = response?.data?.data?.item?.children?.pageInfo?.endCursor;

    const childrenNodes = response?.data?.data?.item?.children?.nodes || [];
    children.push(...childrenNodes);
  } while (response?.data?.data?.item?.children?.pageInfo?.hasNextPage);

  if (!response.data.errors?.length) {
    response.data.data.item.children.nodes = children;
  }

  return response;
};

const makeGetItemsRequest = async (
  axiosInstance: AxiosInstance,
  url: string,
  variables: GetListItemsVariables,
  cursor: string | undefined,
) => {
  const { id, language, includeHiddenItems, excludeTemplateIDs } = variables;
  return axiosInstance?.post<ListItemGraphQLResponse>(getSitecoreGraphQLEndpoint(url), {
    query: navigationQueryString,
    variables: {
      item: { itemId: id, language },
      includeHiddenItems,
      excludeTemplateIDs,
      thumbnailHeight: THUMBNAIL.URL.HEIGHT,
      thumbnailWidth: THUMBNAIL.URL.WIDTH,
      cursor,
    },
  });
};
