import { Select } from 'chakra-react-select';
import { FC } from 'react';
import { MENU_HEIGHT } from 'src/constants';
import { GenericFieldView } from 'src/features/fieldBuilder/components/fields/GenericFieldView';
import { useDroplist } from 'src/features/fieldBuilder/components/fields/hooks';
import { GenericField } from 'src/features/fieldBuilder/models';

export const Droplist: FC<GenericField> = ({ field }) => {
  const { builderField, value, selections, error, onReset, onUpdate } = useDroplist(field);

  return (
    <GenericFieldView field={builderField} errorMsg={error} onReset={onReset}>
      <Select
        value={value}
        options={selections}
        isDisabled={!field.canEdit || !builderField.source}
        onChange={onUpdate}
        maxMenuHeight={MENU_HEIGHT}
        menuPosition="fixed"
        isInvalid={!!error || !!builderField.error}
        className="sc-droplist"
        inputId="ef-droplist"
      />
    </GenericFieldView>
  );
};
