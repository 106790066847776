/* istanbul ignore file */

import { print } from 'graphql';
import { loader } from 'graphql.macro';

export const getWorkflowListQuery = loader('./getWorkflowListQuery.graphql');
export const getWorkflowListQueryString = print(getWorkflowListQuery);

export const getUsers = loader('./getUsers.graphql');
export const getUsersString = print(getUsers);
