export const languages = {
  get_languages: (databaseName: string) => {
    return {
      query: `
      query GetLanguages($databaseName: String!){
        languages(databaseName: $databaseName) {
          nodes {
            name
            englishName
            displayName
            iso
          }
        }
      }
      `,
      variables: {
        databaseName,
      },
    };
  },
};
