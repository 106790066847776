import { ChakraProvider, UseToastOptions } from '@chakra-ui/react';
import sitecoreTheme from '@sitecore/blok-theme';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { TOAST_DEFAULTS } from 'src/constants';
import { AuthenticatedAxiosProvider } from 'src/features/common/apiUtils/AxiosProvider';
import { Auth0ProviderWithHistory } from 'src/features/common/auth/Auth0ProviderWithHistory';
import { AppEvents } from 'src/features/common/components/AppEvents';
import GainsightMetricsRegistration from 'src/features/common/components/gainsight/GainsightMetricsRegistration';
import GainsightScriptInjector from 'src/features/common/components/gainsight/GainsightScriptInjector';
import { queryClient } from 'src/features/common/config';
import { AzureFeaturesProvider } from 'src/features/common/flags/AzureFeaturesProvider';
import { XMTenantProvider } from 'src/features/common/tenants/xmTenantProvider';
import { AuthenticatedExplorerLayout } from 'src/features/main/components/AuthenticatedExplorerLayout';
import { ExplorerNavigation } from 'src/features/main/components/ExplorerNavigation';
import './styles/globals.scss';

export const App = () => {
  return (
    <Router>
      <ChakraProvider theme={sitecoreTheme} toastOptions={{ defaultOptions: TOAST_DEFAULTS as UseToastOptions }}>
        <Auth0ProviderWithHistory>
          <GainsightScriptInjector />
          <QueryClientProvider client={queryClient}>
            <AuthenticatedAxiosProvider>
              <XMTenantProvider>
                <GainsightMetricsRegistration />
                <AuthenticatedExplorerLayout>
                  <AzureFeaturesProvider>
                    <ExplorerNavigation />
                  </AzureFeaturesProvider>
                </AuthenticatedExplorerLayout>
              </XMTenantProvider>
            </AuthenticatedAxiosProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Auth0ProviderWithHistory>
      </ChakraProvider>
      <AppEvents />
    </Router>
  );
};
