import { Box, Heading, Tooltip } from '@chakra-ui/react';
import { mdiAlertCircle } from '@mdi/js';
import { FC, useMemo } from 'react';
import { DICTIONARY, SECTIONS_ERRORS_STORAGE_KEY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { ValidationErrors } from 'src/features/fieldBuilder/models';
import { useSessionStorage } from 'usehooks-ts';

interface SectionHeaderProps {
  name: string;
}

export const SectionHeader: FC<SectionHeaderProps> = ({ name }) => {
  const [errors] = useSessionStorage<ValidationErrors[]>(SECTIONS_ERRORS_STORAGE_KEY, []);
  const t = useTranslate();
  const hasError = useMemo(() => errors.find((r) => r.sectionName === name), [name, errors]);

  return (
    <Heading size="md" fontWeight="semibold" isTruncated>
      {hasError && (
        <Tooltip label={t(DICTIONARY.FIELDS_IN_SECTION_CONTAIN_ERRORS)}>
          <Box as="span">
            <Icon data-testid="sc-errorIcon" path={mdiAlertCircle} boxSize="5" color="danger" mr="1" verticalAlign="middle" />
          </Box>
        </Tooltip>
      )}
      <Box as="span" verticalAlign="middle">
        {name}
      </Box>
    </Heading>
  );
};
