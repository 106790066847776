import { Alert, AlertDescription, Flex } from '@chakra-ui/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { FC, Fragment } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';

export const WrongDataSourceError: FC = () => {
  return (
    <Flex paddingX={6} paddingY={3}>
      <Alert status="error">
        <Fragment key=".0">
          <Icon path={mdiAlertCircleOutline} boxSize={6} />
          <AlertDescription>{DICTIONARY.DATA_SOURCE_ERROR_MESSAGE}</AlertDescription>
        </Fragment>
      </Alert>
    </Flex>
  );
};
