import { Box, Flex, FlexProps, Heading, Image, Link, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY, SC_ICONS_SRC } from 'src/constants';
import { useNoResultsPage } from 'src/features/common/components/customErrorPages/hooks/useNoResultsPage';

export const NoResultsPage: React.FC<FlexProps> = (props) => {
  const onReset = useNoResultsPage();

  return (
    <Flex data-testid="no-results" align="center" boxSize="full" overflowY="auto" direction="column" {...props}>
      <Box>
        <Image width="20" height="20" src={SC_ICONS_SRC.NO_RESULTS} alt={DICTIONARY.NO_RESULTS_IMAGE_ALT_TEXT} />
      </Box>
      <Text mb="4" mt="6" px="2" textAlign="center" w="80">
        <T _str={DICTIONARY.NO_RESULTS.MESSAGE} />
      </Text>
      <Link onClick={onReset}>
        <Heading size="sm">
          <T _str={DICTIONARY.NO_RESULTS.BUTTON_TEXT} />
        </Heading>
      </Link>
    </Flex>
  );
};
