import { Flex, Link } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DICTIONARY, ORG_ID_STORAGE_KEY, TENANT_NAME_STORAGE_KEY } from 'src/constants';
import { setValueToStorages } from 'src/features/common/utils/storages';
import { GenericErrorTemplate } from 'src/features/temporary/GenericErrorTemplate';

interface AuthorizationFailedPageProps {
  title: string;
  message: string;
}

export const AuthorizationFailedPage: FC<AuthorizationFailedPageProps> = ({ title, message }) => {
  title = `${title.charAt(0).toUpperCase()}${title.slice(1)}`;

  const [, setSearchParams] = useSearchParams();
  const onLogin = useCallback(() => {
    setValueToStorages(TENANT_NAME_STORAGE_KEY, '');
    setValueToStorages(ORG_ID_STORAGE_KEY, '');
    setSearchParams({});
  }, [setSearchParams]);

  return (
    <Flex pb="4" overflowY="auto">
      <GenericErrorTemplate
        title={title}
        text={message}
        variant="unauthorized"
        shortcuts={
          <Link data-testid="sc-login-button" pb={4} onClick={onLogin}>
            <T _str={DICTIONARY.LOG_IN_AGAIN} />
          </Link>
        }
      />
    </Flex>
  );
};
