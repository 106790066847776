import { useCallback } from 'react';
import { RefetchFunction } from 'src/features/shared/treeTable/components/TreeTableRefetchContext';
import { TreeListItem } from 'src/features/shared/treeTable/models';

export const useRefetchCurrentRow = (
  item: TreeListItem | undefined,
  isExpanded: boolean,
  setIsExpanded: (value: boolean) => void,
  refetch: RefetchFunction,
) => {
  return useCallback(() => {
    if (item && isExpanded && refetch) {
      return refetch();
    }

    setIsExpanded(true);
    return Promise.resolve({});
  }, [isExpanded, item, refetch, setIsExpanded]);
};
