import { FC } from 'react';
import { TreeLoadingSkeleton } from 'src/features/common/treeComponent/components/TreeLoadingSkeleton';
import { TreeView, TreeViewProps } from 'src/features/common/treeComponent/components/TreeView';
import { useItemTree } from 'src/features/common/treeComponent/hooks/useItemTree';
import { AncestorsResponseData } from 'src/features/common/treeComponent/models';

export type ItemTreeProps = {
  selectedIDs: string[];
  setSelectedIds: (values: string[]) => void;
  selectionVariant: 'single' | 'multiple';
  expandOnSelect?: boolean;
  initialId?: string;
  language?: string | null;
  useGetInitialAncestors?: (itemId: string, enabled: boolean, language?: string | null) => AncestorsResponseData;
};

export const ItemTree: FC<ItemTreeProps & Omit<TreeViewProps, 'stopLoadAncestors' | 'itemViewModel' | 'ancestors' | 'isOpened'>> = (
  props,
) => {
  const { ancestors, isLoading, stopLoadAncestors } = useItemTree(props);

  if (isLoading) {
    return <TreeLoadingSkeleton />;
  }

  return <TreeView {...props} ancestors={ancestors} stopLoadAncestors={stopLoadAncestors} isOpened />;
};
