export interface SearchQueryInput {
  filterStatement?: SearchStatementInput;
  index?: string;
  language?: string;
  latestVersionOnly?: boolean;
  searchStatement?: SearchStatementInput;
  paging?: SearchPagingInput;
}

export interface SearchStatementInput {
  criteria: SearchCriteriaInput[];
  operator?: SearchOperator;
  subStatements?: SearchStatementInput[];
}

export interface SearchCriteriaInput {
  criteriaType?: SearchCriteriaType;
  field: string;
  operator?: SearchOperator;
  value: string;
}

export interface SearchPagingInput {
  pageIndex?: number;
  pageSize?: number;
  skip?: number;
}

export enum SearchOperator {
  /** Logical OR */
  SHOULD = 'SHOULD',
  /** Logical AND */
  MUST = 'MUST',
  NOT = 'NOT',
}

export enum SearchCriteriaType {
  EXACT = 'EXACT',
  STARTSWITH = 'STARTSWITH',
  CONTAINS = 'CONTAINS',
  ENDSWITH = 'ENDSWITH',
  WILDCARD = 'WILDCARD',
  SEARCH = 'SEARCH',
  RANGE = 'RANGE',
  FUZZY = 'FUZZY',
  PROXIMITY = 'PROXIMITY',
  REGEX = 'REGEX',
}
