/* istanbul ignore file */

import { useToast } from '@chakra-ui/react';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getAppLink } from 'src/features/common/config';
import { environment } from 'src/features/common/config/tenant-config';
import { environmentUpdater } from 'src/features/common/utils/environmentUpdater';
import { AzureFeatureFlagValue } from '../AzureFeaturesProvider';

export function useAzureFeatureFlags(options?: Partial<{ enabled: boolean }>) {
  const toast = useToast();

  return useAuthQuery(
    'featureflags',
    (axiosInstance) =>
      axiosInstance.get<AzureFeatureFlagValue[]>(
        getAppLink({
          app: 'featureflags',
          path: '/api/featureflags/v1/flags',
          params: { key: 'explorer', labelfilter: environmentUpdater(environment) },
        }),
      ),
    options,
    toast,
  );
}
