import { ButtonGroup, Wrap } from '@chakra-ui/react';
import { FC } from 'react';
import { useShowFiltersMode } from 'src/features/common/components/actionsBar/hooks';
import { SiteCollectionsFilter } from 'src/features/common/components/filters/SiteCollectionsFilter';
import { SiteTypesFilter } from 'src/features/common/components/filters/SiteTypesFilter';
import { ClearAll, LanguageFilter, SiteFilter, UserFilter, WorkflowFilter } from 'src/features/search/components/filters';
import { useFilterCriteria } from 'src/features/search/hooks';

export const FiltersBar: FC = () => {
  const { isFilterVisible } = useShowFiltersMode();
  const filterCriteria = useFilterCriteria();

  return isFilterVisible ? (
    <Wrap data-testid="filters-bar" align="center" zIndex="2">
      <ButtonGroup as={Wrap} variant="filter">
        <SiteCollectionsFilter />
        <SiteTypesFilter />
        <UserFilter actor="createdby" />
        <UserFilter actor="modifiedby" />
        <LanguageFilter />
        <SiteFilter />
        <WorkflowFilter />
        {filterCriteria.length > 0 && <ClearAll />}
      </ButtonGroup>
    </Wrap>
  ) : null;
};
