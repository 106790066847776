import { useEffect } from 'react';
import { ORG_ID_STORAGE_KEY, TENANT_NAME_STORAGE_KEY } from 'src/constants';
import { TokenCustomClaimKeysEnum } from 'src/features/common/auth/SitecoreUser';
import { useAuthWithClaims } from 'src/features/common/auth/useAuthWithClaims';
import { useGetTenant } from 'src/features/common/tenants/useGetTenant';
import { setValueToStorages } from 'src/features/common/utils/storages';
import { UrlParams, useUrlSharing } from '../hooks/useUrlSharing';

export const useResolveTenant = () => {
  const { user } = useAuthWithClaims();
  const { setUrlContext } = useUrlSharing(UrlParams.None);
  const tenantName = user?.[TokenCustomClaimKeysEnum.TENANT_NAME];
  const orgId = user?.[TokenCustomClaimKeysEnum.ORG_ID];

  const organizationId = orgId || '';
  const enabled = !!organizationId && !!tenantName;

  const { data: tenant, isLoading, isFailed } = useGetTenant({ organizationId, tenantName }, { enabled });

  useEffect(() => {
    if (tenant) {
      setValueToStorages(TENANT_NAME_STORAGE_KEY, tenant.name);
      setValueToStorages(ORG_ID_STORAGE_KEY, organizationId);
      setUrlContext({ organization: organizationId, tenantName: tenant.name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  return { tenant, loading: isLoading, isFailed };
};
