import { Outlet } from 'react-router-dom';
import { ActionsBar } from 'src/features/common/components/actionsBar/ActionsBar';
import { ActionsBarProvider } from 'src/features/common/components/actionsBar/ActionsBarContext';
import { NoResultsPage } from 'src/features/common/components/customErrorPages/NoResultsPage';
import { NoWebsitePage } from 'src/features/common/components/customErrorPages/NoWebsitePage';
import { useExplorerContainer } from 'src/features/main/components/hooks/useExplorerContainer';

export const ExplorerContainer: React.FC = () => {
  const { hasSites, hasFilteredSites, isLoading } = useExplorerContainer();
  const hasData = hasSites && hasFilteredSites && !isLoading;

  return (
    <ActionsBarProvider>
      {hasSites && !isLoading && <ActionsBar />}
      {hasData && <Outlet />}
      {!hasFilteredSites && hasSites && <NoResultsPage pt="28" pb="4" />}
      {!hasSites && !isLoading && <NoWebsitePage pt="28" pb="4" />}
    </ActionsBarProvider>
  );
};
