import { print } from 'graphql';
import { loader } from 'graphql.macro';
import { DISPLAY_NAME_ID, FOLDER_TEMPLATE_ID, SITE_TEMPLATE_ID } from 'src/constants';
import { CreateItemInput, DeleteItemInput, RenameItemInput, UpdateItemInput } from '../models';
import { PUBLISH_RESTRICTIONS_DETAILS } from './fragments';

export const duplicateItemQuery = loader('./duplicateItem.graphql');
export const duplicateItemQueryString = print(duplicateItemQuery);

export const items = {
  get_item: (itemId: string, language: string, version: number) => {
    const dynamicInput = version && version > 0 ? { itemId, language, version } : { itemId, language };

    return {
      query: `query GetItem($item: ItemQueryInput) {
        item(where: $item) {
          ${itemQuery}
        }
      }
      ${PUBLISH_RESTRICTIONS_DETAILS}
      `,
      variables: {
        item: dynamicInput,
      },
    };
  },

  get_item_by_path: (path: string, language: string) => {
    return {
      query: `query GetItem($item: ItemQueryInput) {
        item(where: $item) {
          itemId(format: D)
          path
        }
      }
      `,
      variables: {
        item: { path, language },
      },
    };
  },

  create_item: (item: CreateItemInput) => {
    return {
      query: `
        mutation CreateItem($item: CreateItemInput!) {
          createItem(input: $item) {
            item {
              itemId(format: D)
              version
            }
          }
        }
      `,
      variables: { item },
    };
  },

  create_full_item: (item: CreateItemInput) => {
    return {
      query: `
        mutation CreateItem($item: CreateItemInput!) {
          createItem(input: $item) {
            item {
              ${itemQuery}
            }
          }
        }
        ${PUBLISH_RESTRICTIONS_DETAILS}
      `,
      variables: { item },
    };
  },

  rename_item: (renameItem: RenameItemInput | null, updateItem: UpdateItemInput | null) => {
    return {
      query: `mutation UpdateItem($renameItem: RenameItemInput!, $updateItem: UpdateItemInput!) {
          renameItem(input: $renameItem) @include(if: ${!!renameItem?.newName}) {
            item {
              name
              path
            }
          }
          updateItem(input: $updateItem) @include(if: ${!!updateItem?.fields.length}) {
            item {
              displayName
            }
          }
        }
      `,
      variables: { renameItem, updateItem },
    };
  },

  update_item: (item: UpdateItemInput) => {
    return {
      query: `mutation UpdateItem($item: UpdateItemInput!) {
          updateItem(input: $item) {
            item {
              itemId(format: D)
              version
              versionName
            }
          }
        }
      `,
      variables: { item },
    };
  },

  delete_item: (item: DeleteItemInput) => {
    return {
      query: `mutation DeleteItem($item: DeleteItemInput!) {
          deleteItem(input: $item) {
            successful
          }
        }
      `,
      variables: { item },
    };
  },
};

const itemQuery = `itemId(format: D)
name
displayName
path
hasPresentation
hasChildren
version
parent {
  itemId(format: D)
}
template {
  fullName
  name
  templateId(format: D)
  isSite: hasAnyOfBaseTemplates(templateIds: ["${SITE_TEMPLATE_ID}"])
  isFolder: hasAnyOfBaseTemplates(templateIds: ["${FOLDER_TEMPLATE_ID}"])
}
languages: versions(allLanguages: true) {
  language {
    name
    iso
  }
}
versions(allLanguages: false) {
  version
  versionName
  access {
    canWrite
  }
  workflow {
    workflow {
      workflowId
      displayName
      initialState {
        displayName
      }
    }
    workflowState {
      stateId
      displayName
      final
    }
  }
  updatedBy: field(name: "__Updated by") {
    value
  }
  updatedAt: field(name: "__Updated") {
    value
  }
  ...PublishRestrictionsDetails
}
versionName
createdBy: field(name: "__Created by") {
  value
}
createdAt: field(name: "__Created") {
  value
}
updatedBy: field(name: "__Updated by") {
  value
}
updatedAt: field(name: "__Updated") {
  value
}
...PublishRestrictionsDetails
workflow {
  workflow {
    workflowId
    displayName
    initialState {
      displayName
    }
  }
  workflowState {
    stateId
    displayName
    final
  }
}
insertOptions {
  name
  templateId
}
access {
  canCreate
  canRename
  canWrite
  canDelete
}
displayNameField: field(name: "${DISPLAY_NAME_ID}") {
  access {
    canRead
    canWrite
  }
}`;
