import { Box, Flex } from '@chakra-ui/react';
import { mdiFileTree } from '@mdi/js';
import { FC, ReactNode } from 'react';
import { Icon } from 'src/features/common/components/icon/Icon';

export type SelectedItemCardType = {
  onRemoveClick?: () => void;
  isDisabled?: boolean;
  icon?: JSX.Element;
  actions?: ReactNode;
};

export const SelectedItemCard: FC<React.PropsWithChildren<SelectedItemCardType>> = ({
  isDisabled,
  children,
  actions,
  icon = <Icon color="neutral-fg" boxSize="6" path={mdiFileTree} />,
}) => {
  return (
    <Flex
      height="12"
      background="neutral.50"
      paddingLeft="3"
      paddingRight="3"
      alignItems="center"
      justifyContent="space-between"
      opacity={isDisabled ? '.4' : '1'}
      borderRadius="base"
    >
      <Flex alignItems="center" gap="2" flex="1" overflow="hidden">
        <Box flex="0 0 auto">{icon}</Box>
        {children}
      </Flex>
      <Flex gap="2">{actions}</Flex>
    </Flex>
  );
};
