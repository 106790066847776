export const meta = {
  xm_version: {
    query: `
    query GetXmCloudMeta {
      meta {
        xMVersion
      }
    }
    `,
  },
};
