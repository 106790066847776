import { Flex } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FC } from 'react';
import { DICTIONARY, LINK_TYPES } from 'src/constants';
import { AccessRestrictedWrapper, FieldError, ResetButton } from 'src/features/fieldBuilder/components/fields/Common';
import { FormikFieldState } from 'src/features/fieldBuilder/models';
import { GENERAL_LINK, GenericLink } from '../models';
import { externalLinkValidation } from '../utils/validationSchemas';
import { FieldBuilderCheckbox, FieldBuilderInput } from './';

export const ExternalLink: FC<GenericLink> = ({ field, value, onSubmit, onReset, updateErrors }) => {
  return (
    <Formik<FormikFieldState>
      initialValues={{
        url: value.url,
        text: value.text,
        title: value.title,
        target: value.target,
        linktype: LINK_TYPES.EXTERNAL,
      }}
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={externalLinkValidation}
      validateOnChange={true}
    >
      {({ submitForm, resetForm, setFieldValue, setFieldTouched }) => (
        <Form>
          <AccessRestrictedWrapper canEdit={field.canEdit}>
            <Flex direction="column" gap={3}>
              <FieldBuilderInput
                inputName={GENERAL_LINK.URL}
                label={DICTIONARY.URL}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
                isRequired={true}
                updateErrors={updateErrors}
              />
              <FieldBuilderInput
                inputName={GENERAL_LINK.TEXT}
                label={DICTIONARY.LINK_TEXT}
                subtext={DICTIONARY.LINK_TEXT_TOOLTIP}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
                isRequired={true}
                updateErrors={updateErrors}
              />
              <FieldBuilderInput
                inputName={GENERAL_LINK.TITLE}
                label={DICTIONARY.LINK_TITLE}
                subtext={DICTIONARY.LINK_TITLE_TOOLTIP}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
              />
              <FieldBuilderCheckbox
                inputName={GENERAL_LINK.TARGET}
                label={DICTIONARY.LINK_TARGET}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
              />
              <ResetButton onClick={resetForm} isDisabled={!field.canEdit || field.containsStandardValue || field.containsInheritedValue} />
              {field.error && <FieldError message={field.error} />}
            </Flex>
          </AccessRestrictedWrapper>
        </Form>
      )}
    </Formik>
  );
};
