import { useCallback, useMemo } from 'react';
import { CLIENT_LANGUAGE } from 'src/constants';
import { useGetSitesWrapper } from 'src/features/common/hooks/useGetSitesWrapper';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { Context, SolutionSite, UrlContextType, UrlStrictContext, UseUrlStrictContext } from 'src/features/common/models';

const handleSiteAsItemId = (id: UrlContextType, siteName: string, sites: SolutionSite[]): string => {
  if (id) {
    return id;
  }

  const site = sites.find((s) => s.name.toLowerCase() === siteName.toLowerCase());

  return site?.rootItem?.itemId || '';
};

export const fullUrlContext = UrlParams.sc_site | UrlParams.sc_itemid | UrlParams.sc_lang | UrlParams.sc_version;

export const useUrlStrictContext = (urlParams = fullUrlContext): UseUrlStrictContext => {
  const { urlContext, setUrlContext } = useUrlSharing(urlParams);
  const { sites } = useGetSitesWrapper();

  const sc_site = useMemo(() => urlContext.sc_site ?? '', [urlContext.sc_site]);
  const sc_lang = useMemo(() => urlContext.sc_lang ?? CLIENT_LANGUAGE, [urlContext.sc_lang]);
  const sc_version = useMemo(() => parseInt(urlContext.sc_version || '') || undefined, [urlContext.sc_version]);
  const sc_collection = useMemo(() => urlContext.sc_collection || '', [urlContext.sc_collection]);
  const sc_type = useMemo(() => urlContext.sc_type, [urlContext.sc_type]);
  const sc_isSite = useMemo(() => !!urlContext.sc_site && !urlContext.sc_itemid, [urlContext.sc_site, urlContext.sc_itemid]);
  const sc_itemid = useMemo(
    () => handleSiteAsItemId(urlContext.sc_itemid, urlContext.sc_site ?? '', sites),
    [urlContext.sc_itemid, urlContext.sc_site, sites],
  );

  const setContext = useCallback(
    (context: Partial<UrlStrictContext>) => setUrlContext(context as Context<UrlContextType, UrlContextType>),
    [setUrlContext],
  );

  return { sc_isSite, sc_itemid, sc_lang, sc_site, sc_version, sc_collection, sc_type, setUrlContext: setContext };
};
