import { useMemo } from 'react';
import { useGetSitesWrapper } from 'src/features/common/hooks/useGetSitesWrapper';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { filterSites } from 'src/features/common/utils/filterSites';
import { useShowOptions } from 'src/features/millerColumns/hooks/useShowOptions';

export const useExplorerContainer = () => {
  const { urlContext } = useUrlSharing(UrlParams.sc_collection | UrlParams.sc_type);
  const { sites, isLoading } = useGetSitesWrapper();
  const { showTemplateSites } = useShowOptions();

  const hasSites = useMemo(() => (!isLoading ? !!sites.length : false), [sites, isLoading]);

  const hasFilteredSites = useMemo(() => {
    if (isLoading) {
      return true;
    }

    const filteredSites = filterSites(sites, {
      showTemplateSites,
      sc_collection: urlContext.sc_collection || undefined,
      sc_type: urlContext.sc_type,
    });

    return !!filteredSites.length;
  }, [sites, isLoading, urlContext.sc_collection, urlContext.sc_type, showTemplateSites]);

  return { hasSites, hasFilteredSites, isLoading };
};
