import { FormikFieldState, GenericField } from 'src/features/fieldBuilder/models';
import { LinkValue } from './linkValue.model';

export interface GenericLink extends GenericField {
  value: LinkValue;
  onSubmit: (values: FormikFieldState) => void;
  onReset: (values: FormikFieldState) => Promise<FormikFieldState>;
  updateErrors: (shouldAddError: boolean) => void;
}

export const GENERAL_LINK = {
  ID: 'id',
  TEXT: 'text',
  URL: 'url',
  TITLE: 'title',
  TARGET: 'target',
  ANCHOR: 'anchor',
  QUERY: 'querystring',
};
