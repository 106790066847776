import { User } from '@auth0/auth0-react';

export enum TokenCustomClaimKeysEnum {
  ORG_DISPLAY_NAME = 'https://auth.sitecorecloud.io/claims/org_display_name',
  ORG_TYPE = 'https://auth.sitecorecloud.io/claims/org_type',
  ORG_ID = 'https://auth.sitecorecloud.io/claims/org_id',
  ORG_ACC_ID = 'https://auth.sitecorecloud.io/claims/org_account_id',
  TENANT_NAME = 'https://auth.sitecorecloud.io/claims/tenant_name',
  TENANT_ID = 'https://auth.sitecorecloud.io/claims/tenant_id',
  ROLES = 'https://auth.sitecorecloud.io/claims/roles',
}

export interface SitecoreUser extends User {
  [TokenCustomClaimKeysEnum.ORG_DISPLAY_NAME]: string;
  [TokenCustomClaimKeysEnum.ORG_TYPE]: string;
  [TokenCustomClaimKeysEnum.ORG_ACC_ID]: string;
  [TokenCustomClaimKeysEnum.ORG_ID]: string;
  [TokenCustomClaimKeysEnum.TENANT_NAME]: string;
  [TokenCustomClaimKeysEnum.TENANT_ID]: string;
  [TokenCustomClaimKeysEnum.ROLES]: string[];
}
