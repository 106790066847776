import { AxiosInstance, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptions } from 'react-query';
import { THUMBNAIL } from 'src/constants';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import {
  GetItemQueryVariables,
  GraphQLResponse,
  Item,
  ItemAncestorsResponse,
  NestedItems,
  NestedItemsWithAncestors,
} from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { navigationQueryString } from 'src/features/millerColumns/queries';

type ItemAncestorsGraphQLResponse = GraphQLResponse<ItemAncestorsResponse>;
type ItemResponse = AxiosResponse<ItemAncestorsGraphQLResponse>;

export const useGetItemAncestors = (
  id: string,
  language: string,
  includeHiddenItems: boolean,
  excludeTemplateIDs: string[],
  useQueryOptions?: Omit<UseQueryOptions<ItemResponse, unknown, ItemResponse, string[]>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const { url } = useXMTenant();

  const { data } = useAuthQuery(
    ['get-item-ancestors', id, language, includeHiddenItems?.toString(), excludeTemplateIDs.join(',')],
    async (axiosInstance) => {
      let response: ItemResponse;
      let cursor: string | undefined = undefined;
      let ancestors: NestedItems[] = [];
      let item: NestedItemsWithAncestors;
      const children: Item[] = [];

      do {
        response = await makeGetItemsRequest(axiosInstance, url, cursor, { id, language, includeHiddenItems, excludeTemplateIDs });
        item = response?.data?.data.item;
        ancestors = item?.ancestors ?? ancestors;
        cursor = item?.children?.pageInfo?.endCursor;
        children.push(...(item?.children?.nodes || []));
      } while (item?.children?.pageInfo?.hasNextPage);

      if (item) {
        item.children.nodes = children;
        item.ancestors = ancestors;
      }
      return response;
    },
    { ...useQueryOptions },
  );

  return useMemo(() => data?.data?.data?.item, [data]);
};

const makeGetItemsRequest = (axiosInstance: AxiosInstance, url: string, cursor: string | undefined, variables: GetItemQueryVariables) => {
  const { id, language, includeHiddenItems, excludeTemplateIDs } = variables;

  return axiosInstance?.post<ItemAncestorsGraphQLResponse>(getSitecoreGraphQLEndpoint(url), {
    query: navigationQueryString,
    variables: {
      item: { itemId: id, language },
      includeHiddenItems,
      excludeTemplateIDs,
      includeAncestors: !!cursor,
      thumbnailHeight: THUMBNAIL.URL.HEIGHT,
      thumbnailWidth: THUMBNAIL.URL.WIDTH,
      cursor,
    },
  });
};
