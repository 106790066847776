import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MenuWithSearchOption } from 'src/features/common/components/menuWithSearch/models';
import { useGetSitesWrapper } from 'src/features/common/hooks/useGetSitesWrapper';
import { SolutionSite } from 'src/features/common/models';
import { useCommonFilter } from 'src/features/search/hooks/useCommonFilter';
import { QUERIES_NAMES } from 'src/features/search/searchConstants';

export const useSiteFilter = () => {
  const [searchParams] = useSearchParams();
  const { sites: solutionSites } = useGetSitesWrapper();
  const { onFilterChange } = useCommonFilter(QUERIES_NAMES.SITES);

  const sites = useMemo(() => {
    if (!solutionSites.length) return [];

    const queryParams = searchParams.get(QUERIES_NAMES.SITES);
    const filterSites = queryParams?.split(',') ?? [];

    return solutionSites.map<MenuWithSearchOption>((site: SolutionSite) => ({
      checked: filterSites.includes(site.name),
      label: site.displayName,
      value: site.name,
    }));
  }, [searchParams, solutionSites]);

  return { sites, onFilterChange };
};
