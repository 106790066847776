import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { ExecuteWorkflowCommandInput } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { workflow } from 'src/features/workflow/graphql/workflow';

export const useUpdateItemWorkflow = () => {
  const { url } = useXMTenant();

  return useAuthMutation(
    (axiosInstance) => (input: ExecuteWorkflowCommandInput) =>
      axiosInstance?.post(getSitecoreGraphQLEndpoint(url), workflow.execute_workflow_command(input)),
  );
};
