/* istanbul ignore file */

import { Box, CircularProgress, Text } from '@chakra-ui/react';
import React from 'react';

export interface LoadingScreenProps {
  /** The message that appears below the loading icon */
  message?: JSX.Element | string;
  /** Enables overlay */
  withOverlay?: boolean;
}

export const LoadingScreen: React.FC<LoadingScreenProps> = ({ message, withOverlay = false }) => {
  return (
    <Box
      height="full"
      width="full"
      alignItems="center"
      bgColor={withOverlay ? 'whiteAlpha.900' : 'white'}
      display="flex"
      justifyContent="center"
    >
      <Box textAlign="center">
        <CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" />
        {message && <Text mt={6}>{message}</Text>}
      </Box>
    </Box>
  );
};
