import { EDGE_DB, MASTER_DB } from 'src/constants';

export class PublishItemInput {
  constructor(
    public languages: string[] = [],
    public publishItemMode: 'SMART' | 'FULL' = 'SMART',
    public publishSubItems = true,
    public sourceDatabase = MASTER_DB,
    public targetDatabases = [EDGE_DB],
    public rootItemId = '',
  ) {}
}
