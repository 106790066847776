import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';

export const useOpenFieldEditor = () => {
  const location = useLocation();
  const navigateTo = useNavigate();

  return useCallback(
    (itemId: string, version?: number, language?: string) => {
      const searchParams = new URLSearchParams(location.search);
      const sc_lang = searchParams.get(UrlParams[UrlParams.sc_lang]);

      searchParams.set(UrlParams[UrlParams.sc_itemid], itemId);

      if (version) {
        searchParams.set(UrlParams[UrlParams.sc_version], version.toString());
      }

      if (language) {
        searchParams.set(UrlParams[UrlParams.sc_lang], language);
      }

      location.search = searchParams.toString();

      navigateTo({ ...location, pathname: ROUTES.EDITOR_VIEW || '/' }, { state: { language: sc_lang } });
    },
    [location, navigateTo],
  );
};
