import { useCallback, useEffect, useMemo, useState } from 'react';
import { DICTIONARY } from 'src/constants';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { useSource } from 'src/features/fieldBuilder/hooks/useSource';
import { BuilderField as field, MediaSelection, UseImageProps } from 'src/features/fieldBuilder/models';
import { useGetMediaData } from 'src/features/media/hooks';
import { ImageValue } from 'src/features/media/models';

export const useImage = ({ field }: UseImageProps) => {
  const [value, setValue] = useState(new ImageValue(field.value));

  const { mediaItem } = useGetMediaData(value.mediaid, { enabled: !!value.mediaid || !!field.source });
  const { builderField, updateField, resetField, updateErrors } = useFieldActions(field);
  const { sourceIds, uploadPath } = useSource(field);

  const onReset = useCallback(async () => {
    const fieldUpdated = await resetField(field.fieldId);
    fieldUpdated && setValue(new ImageValue(fieldUpdated.value));
  }, [field.fieldId, resetField, setValue]);

  const onChange = useCallback(
    (selection: Partial<MediaSelection>) => {
      if (selection.id) value.mediaid = selection.id;
      if (selection.alt) value.alt = selection.alt;

      setValue(value);
      updateField(field.fieldId, value.toString());
    },
    [field.fieldId, value, setValue, updateField],
  );

  const onRemove = useCallback(() => {
    setValue(new ImageValue());
    updateField(field.fieldId, '');
  }, [field.fieldId, updateField]);

  const verifiedFieldSource: { builderField: field; errorMsg?: string } = useMemo(() => {
    if (field.source && !uploadPath) {
      return {
        builderField: { ...builderField, canEdit: false },
        errorMsg: DICTIONARY.DATA_SOURCE_ERROR_MESSAGE,
      };
    }

    if (value.mediaid && mediaItem === null) {
      return {
        builderField,
        errorMsg: DICTIONARY.SELECT_LINK_OR_REBASE_LINKS_DB,
      };
    }

    return {
      builderField,
      errorMsg: undefined,
    };
  }, [builderField, field.source, mediaItem, uploadPath, value.mediaid]);

  useEffect(
    () => updateErrors(!!verifiedFieldSource.errorMsg || !!builderField.error),
    [verifiedFieldSource.errorMsg, builderField.error, updateErrors],
  );

  return {
    builderField: verifiedFieldSource.builderField,
    errorMsg: verifiedFieldSource.errorMsg,
    mediaItem,
    uploadPath,
    sourceIds,
    value,
    onRemove,
    onChange,
    onReset,
  };
};
