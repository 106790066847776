import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config/graphql-config';
import { GraphQLResponse, LanguageResponse } from 'src/features/common/models';
import { languages } from 'src/features/common/queries/languages';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export const useGetLanguages = (
  databaseName: string,
  useQueryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<GraphQLResponse<LanguageResponse>>,
          unknown,
          AxiosResponse<GraphQLResponse<LanguageResponse>>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { url } = useXMTenant();
  const toast = useToast();

  return useAuthQuery(
    ['get-languages', databaseName],
    async (axiosInstance) => {
      return axiosInstance?.post<GraphQLResponse<LanguageResponse>>(getSitecoreGraphQLEndpoint(url), languages.get_languages(databaseName));
    },
    { ...useQueryOptions },
    toast,
  );
};
