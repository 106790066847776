import { useMemo } from 'react';
import { SearchCriteriaInput, SearchCriteriaType, SearchOperator } from 'src/features/search/models';
import { FILTER_FIELDS, QUERIES_NAMES } from 'src/features/search/searchConstants';

export const useLanguageFilterCriteria = (params: URLSearchParams): SearchCriteriaInput[] | undefined => {
  const languages = useMemo(() => params.get(QUERIES_NAMES.LANGUAGES)?.split(',') || [], [params]);

  return useMemo<SearchCriteriaInput[] | undefined>(() => {
    if (!languages.length) return undefined;

    return languages.map<SearchCriteriaInput>((language) => ({
      field: FILTER_FIELDS.LANGUAGE,
      value: language,
      criteriaType: SearchCriteriaType.EXACT,
      operator: SearchOperator.SHOULD,
    }));
  }, [languages]);
};
