import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'src/constants';

export const EmptyContentPage: FC = () => (
  <Flex data-testid="sc-empty-content" align="center" boxSize="full" direction="column" pt="20" pb="5">
    <Box boxSize="20">
      <Image src={SC_ICONS_SRC.CACTUS_GRAY} />
    </Box>
    <Text mt="6" pb="3" align="center">
      <T _str={DICTIONARY.NO_CONTENT_AVAILABLE_FOR_THIS_ITEM} />
    </Text>
  </Flex>
);
