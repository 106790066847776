import { FC, useMemo } from 'react';
import { GenericErrorPage } from 'src/features/common/components/customErrorPages/GenericErrorPage';
import { useXmCloudVersion } from 'src/features/common/hooks/useXmCloudVersion';
import { FlagsProvider } from './features';
import { useAzureFeatureFlags } from './hooks/useAzureFeatureFlags';

export const FeatureVersions = {
  xm_technicalTemplateSites: '1.2.420',
} as Record<string, string>;

export interface AzureFeatureFlagValue {
  id: string;
  enabled: boolean;
  conditions: {
    clientFilters: { name: string; parameters?: Record<string, unknown> }[];
  };
  description?: string;
  displayName?: string;
}

export function transformFlags(flags: AzureFeatureFlagValue[], xmVersion: string) {
  const isSupported = (xmVersion: string, featureVersion: string) =>
    xmVersion.localeCompare(featureVersion ?? '', undefined, { numeric: true, sensitivity: 'base' }) >= 0;

  const result: Record<string, boolean> = {};

  flags.forEach(({ id, enabled }) => {
    const isFeatureSupported = enabled && isSupported(xmVersion, FeatureVersions[id]);
    result[id] = enabled && isFeatureSupported;
  });

  return result;
}

export const AzureFeaturesProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const { xMVersion, isLoading: xmCloudVersionLoading, error } = useXmCloudVersion();
  const { data: flagData, isLoading: azureFeatureFlagLoading } = useAzureFeatureFlags();

  const togglePoints = useMemo(() => transformFlags(flagData?.data ?? [], xMVersion || ''), [flagData?.data, xMVersion]);

  if (xmCloudVersionLoading || azureFeatureFlagLoading) {
    return null;
  } else if (error) {
    return <GenericErrorPage />;
  }

  return <FlagsProvider features={togglePoints}>{children}</FlagsProvider>;
};
