import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { DICTIONARY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { DeleteItemInput } from 'src/features/common/models';
import { useDeleteItem } from './useDeleteItem';

export const useDeleteItemModal = (
  itemId: string,
  itemName: string,
  parentId: string,
  onClose: () => void,
  onDeleteItemSuccess: (parentItemId: string, itemId: string) => void,
  onDeleteItemError: (parentItemId: string, itemId: string) => void,
) => {
  const toast = useToast();
  const t = useTranslate();
  const { mutate: deleteItem } = useDeleteItem();

  return useCallback(() => {
    const input: DeleteItemInput = {
      itemId,
      database: 'master',
    };
    deleteItem(input, {
      onSuccess: (data) => {
        const result = data?.data?.data;
        if (result.deleteItem.successful) {
          toast({
            description: t(DICTIONARY.DELETE_SUCCESSFUL, { itemName }),
            status: 'success',
          });
        } else {
          toast({
            description: t(DICTIONARY.DELETE_UNSUCCESSFUL, { itemName }),
            status: 'error',
          });
        }
        onClose();
        onDeleteItemSuccess(parentId, itemId);
      },
      onError: () => {
        onClose();
        onDeleteItemError(parentId, itemId);
        toast({ description: t(DICTIONARY.DELETE_UNSUCCESSFUL, { itemName }), status: 'error' });
      },
    });
  }, [itemId, deleteItem, onClose, onDeleteItemSuccess, parentId, toast, t, itemName, onDeleteItemError]);
};
