import { detectDeviceType } from '@chakra-ui/utils';
import { useContext, useMemo } from 'react';
import { ROUTES } from 'src/constants';
import { useUserInOrganization } from 'src/features/common/auth/useUserInOrganization';
import { viewModes } from 'src/features/common/components/actionsBar/models';
import { NoAccessPage } from 'src/features/common/components/customErrorPages/NoAccessPage';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { XMTenantContext } from 'src/features/common/tenants/xmTenantContext';
import { useSaveNavigate } from 'src/features/common/utils/navigationUtils';
import { ExplorerContainer } from 'src/features/main/components/ExplorerContainer';
import { useEffectOnce } from 'usehooks-ts';

export const useExplorerNavigation = () => {
  const userInOrganization = useUserInOrganization();
  const xmTenantContext = useContext(XMTenantContext);
  const deviceType = detectDeviceType(navigator);
  const navigate = useSaveNavigate();
  const { sc_site } = useUrlStrictContext(UrlParams.sc_site);

  const validatedComponent = useMemo(
    () => (userInOrganization && xmTenantContext?.resolved ? <ExplorerContainer /> : <NoAccessPage />),
    [userInOrganization, xmTenantContext],
  );

  useEffectOnce(() => {
    if (deviceType !== 'desktop') {
      const pathname = sc_site ? `/${viewModes.list}/${ROUTES.EDITOR_VIEW}` : `/${viewModes.list}`;

      navigate(pathname);
    }
  });

  return { validatedComponent };
};
