import { AxiosResponse } from 'axios';
import { throttle } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { CLIENT_LANGUAGE, DISPLAY_LANGUAGE, LANGUAGE_STORAGE_KEY, MASTER_DB, THROTTLE_LANGUAGE_DELAY } from 'src/constants';
import { useGetLanguages } from 'src/features/common/hooks/useGetLanguages';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { GraphQLResponse, LanguageItem, LanguageResponse } from 'src/features/common/models';

interface LanguageProps {
  languages: LanguageItem[];
  languageRefetchHandler: any;
  languageHandler: (language: LanguageItem, shouldResetVersion?: any) => void;
  defaultLanguage: LanguageItem;
}

const defaultLanguage = new LanguageItem(CLIENT_LANGUAGE, '', DISPLAY_LANGUAGE, CLIENT_LANGUAGE);

const formatLanguages = (languages: AxiosResponse<GraphQLResponse<LanguageResponse>>): LanguageItem[] =>
  languages.data.data.languages.nodes;

export const useLanguageSwitcher = (): LanguageProps => {
  const [languages, setLanguages] = useState<LanguageItem[]>([]);
  const { setUrlContext } = useUrlStrictContext(UrlParams.None);

  const onLanguagesLoad = useCallback(
    (data: AxiosResponse<GraphQLResponse<LanguageResponse>>) => setLanguages(data && !data.data.errors ? formatLanguages(data) : []),
    [],
  );

  const { refetch } = useGetLanguages(MASTER_DB, { onSuccess: onLanguagesLoad });

  const languageRefetchHandler = useMemo(() => throttle(refetch, THROTTLE_LANGUAGE_DELAY, { trailing: false }), [refetch]);

  const languageHandler = useCallback(
    (language: LanguageItem, shouldResetVersion = true) => {
      setUrlContext({ sc_lang: language.name });
      shouldResetVersion && setUrlContext({ sc_version: undefined });
      localStorage.setItem(LANGUAGE_STORAGE_KEY, language.name);
    },
    [setUrlContext],
  );

  return { languages, languageRefetchHandler, languageHandler, defaultLanguage };
};
