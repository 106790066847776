import { AppState, AuthorizationParams, GetTokenSilentlyOptions, RedirectLoginOptions } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { APP_NAME } from 'src/constants';
import { useAuthWithClaims } from 'src/features/common/auth/useAuthWithClaims';
import { useUserInOrganization } from 'src/features/common/auth/useUserInOrganization';
import { environment } from 'src/features/common/config';
import { environmentUpdater } from 'src/features/common/utils/environmentUpdater';
import { AppSwitcherInterface } from '../models';

interface CompatibleRedirectLoginOptions extends Omit<RedirectLoginOptions<AppState>, 'authorizationParams'>, AuthorizationParams {
  redirectUri?: string;
}

export const compatibleLoginWithRedirect = (loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void>) => {
  return (oldOptions?: CompatibleRedirectLoginOptions) => {
    if (oldOptions && 'authorizationParams' in oldOptions) {
      return loginWithRedirect(oldOptions);
    }

    const { appState, fragment, ...authorizationParams } = oldOptions ?? {};
    // rename redirectUri property to redirect_uri
    authorizationParams.redirect_uri = authorizationParams.redirectUri;
    authorizationParams.redirectUri = undefined;
    // ensure tenant_name is not provided when organization switches
    authorizationParams.tenant_name = undefined;

    const options: RedirectLoginOptions<AppState> = { appState, fragment, authorizationParams };

    return loginWithRedirect(options);
  };
};

export type GetTokenSilentlyAuthorizationParams = NonNullable<GetTokenSilentlyOptions['authorizationParams']>;
export interface OldGetTokenSilentlyOptions
  extends Omit<GetTokenSilentlyOptions, 'authorizationParams' | 'cacheMode'>,
    GetTokenSilentlyAuthorizationParams {
  ignoreCache?: boolean;
}

export const compatibleGetAccessTokenSilently = (getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>) => {
  return (oldOptions?: OldGetTokenSilentlyOptions) => {
    const { timeoutInSeconds, detailedResponse, ignoreCache, ...authorizationParams } = oldOptions ?? {};
    if (oldOptions && 'authorizationParams' in oldOptions) {
      return getTokenSilently(oldOptions);
    }

    const options: GetTokenSilentlyOptions = {
      timeoutInSeconds,
      detailedResponse,
      cacheMode: ignoreCache ? 'off' : ignoreCache === false ? 'on' : undefined,
      authorizationParams,
    };

    return getTokenSilently(options);
  };
};

export const AppSwitcherWrapper = () => {
  const { loginWithRedirect, getAccessTokenSilently, user } = useAuthWithClaims();
  const userInOrganization = useUserInOrganization();

  useEffect(() => {
    const initialize = async () => {
      const appSwitcher = document.querySelector('app-switcher') as any as AppSwitcherInterface;
      if (!appSwitcher || !appSwitcher?.componentOnReady || !userInOrganization) {
        return;
      }

      await appSwitcher.componentOnReady();
      appSwitcher.init({
        getAccessToken: compatibleGetAccessTokenSilently(getAccessTokenSilently),
        loginWithRedirect: compatibleLoginWithRedirect(loginWithRedirect),
        organizationId: user?.['https://auth.sitecorecloud.io/claims/org_id'] ?? '',
        tenantId: user?.['https://auth.sitecorecloud.io/claims/tenant_id'],
        applicationName: APP_NAME,
        isDevMode: false,
        environment: environmentUpdater(environment),
      });
    };

    initialize();
  }, [getAccessTokenSilently, loginWithRedirect, userInOrganization, user]);

  return <app-switcher data-testid="app-switcher" />;
};
