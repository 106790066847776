/* istanbul ignore file */

import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseInfiniteQueryOptions } from 'react-query';
import { useAuthInfiniteQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { TreeItemResponse } from 'src/features/common/treeComponent/models';
import { getItemQueryString } from 'src/features/shared/selectMediaModal/queries';

type TreeResponseType = GraphQLResponse<TreeItemResponse>;

type TreeItemGraphQLResponse = GraphQLResponse<TreeItemResponse>;
type AxiosTreeResponseType = AxiosResponse<TreeItemGraphQLResponse>;

export const useGetMediaItem = (
  input: { id?: string; path?: string; lang: string },
  useQueryOptions?:
    | Omit<UseInfiniteQueryOptions<AxiosTreeResponseType, unknown, AxiosTreeResponseType>, 'queryKey' | 'queryFn'>
    | undefined,
) => {
  const { url } = useXMTenant();
  const cacheKey = input.id || input.path || 'no key';

  const { data, fetchNextPage, isLoading } = useAuthInfiniteQuery<AxiosTreeResponseType, unknown, AxiosTreeResponseType>(
    ['get-media-library-item', cacheKey, input.lang],
    async (axiosInstance, cursor) => {
      return axiosInstance?.post<TreeResponseType>(getSitecoreGraphQLEndpoint(url), {
        query: getItemQueryString,
        variables: {
          itemId: input.id,
          path: input.path,
          language: input.lang,
          cursor,
        },
      });
    },
    {
      ...useQueryOptions,
      onSuccess: (data) => {
        const lastPage = data.pages[data.pages.length ? data.pages.length - 1 : 0];
        const pI = lastPage.data?.data?.item?.children?.pageInfo || { endCursor: '', hasNextPage: false };

        if (pI.hasNextPage) {
          fetchNextPage({ pageParam: pI.endCursor });
        }
      },
    },
  );

  const item = useMemo(() => {
    if (!data?.pages.length) {
      return undefined;
    }

    const children = data?.pages.flatMap((page) => page?.data?.data?.item?.children?.nodes || []);
    return { ...data.pages[0].data.data.item, children: children };
  }, [data]);

  return {
    data: item,
    isLoading,
  };
};
