import { useCallback, useMemo, useState } from 'react';
import { DEFAULT_MEDIA_LIBRARY_ID } from 'src/constants';
import { SelectMediaCallbackType } from 'src/features/shared/selectMediaModal/components/SelectMediaContext';

export const useSelectMediaContextProvider = (disclosure: { onOpen: () => void; onClose: () => void }) => {
  const { onClose, onOpen } = disclosure;
  const [selectedFolderIds, setSelectedFolderIds] = useState<string[]>([]);
  const [selectState, setSelectState] = useState<SelectMediaCallbackType | undefined>(undefined);
  const [selectedMediaId, setSelectedMediaId] = useState<string>();

  const onSelectButtonClick = useCallback(() => {
    if (selectState && selectedMediaId) {
      selectState?.onSelected(selectedMediaId);
    }

    onClose();
  }, [onClose, selectState, selectedMediaId]);

  const rootIdOrPath = useMemo(() => selectState?.rootItemIdOrPath || DEFAULT_MEDIA_LIBRARY_ID, [selectState]);

  const openModal = useCallback(
    (options: SelectMediaCallbackType) => {
      onOpen();
      setSelectState(options);
      setSelectedMediaId(options.preSelectedMediaId);
      setSelectedFolderIds([]);
    },
    [onOpen],
  );

  return {
    selectedMediaId,
    setSelectedMediaId,
    selectedFolderIds,
    setSelectedFolderIds,
    rootIdOrPath,
    openModal,
    selectState,
    onSelectButtonClick,
  };
};
