import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useCollectionFilterCriteria,
  useLanguageFilterCriteria,
  useSiteFilterCriteria,
  useSiteTypeFilterCriteria,
  useUserFilterCriteria,
  useWorkflowFilterCriteria,
} from 'src/features/search/hooks/filters';
import { SearchCriteriaInput } from 'src/features/search/models';

export type UseFiltersCriteria = () => SearchCriteriaInput[][];

export const useFilterCriteria: UseFiltersCriteria = () => {
  const [searchParams] = useSearchParams();

  const languageCriteria = useLanguageFilterCriteria(searchParams);
  const createdByCriteria = useUserFilterCriteria(searchParams, 'createdby');
  const modifiedByCriteria = useUserFilterCriteria(searchParams, 'modifiedby');
  const workflowCriteria = useWorkflowFilterCriteria(searchParams);
  const siteCriteria = useSiteFilterCriteria(searchParams);
  const collectionCriteria = useCollectionFilterCriteria();
  const siteTypeCriteria = useSiteTypeFilterCriteria(searchParams);

  return useMemo<SearchCriteriaInput[][]>(() => {
    const criteria = [];

    if (languageCriteria) {
      criteria.push(languageCriteria);
    }

    if (createdByCriteria) {
      criteria.push(createdByCriteria);
    }

    if (modifiedByCriteria) {
      criteria.push(modifiedByCriteria);
    }

    if (workflowCriteria) {
      criteria.push(workflowCriteria);
    }

    if (siteCriteria) {
      criteria.push(siteCriteria);
    }

    if (collectionCriteria) {
      criteria.push(collectionCriteria);
    }

    if (siteTypeCriteria) {
      criteria.push(siteTypeCriteria);
    }

    return criteria;
  }, [languageCriteria, createdByCriteria, modifiedByCriteria, workflowCriteria, siteCriteria, collectionCriteria, siteTypeCriteria]);
};
