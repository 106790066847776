import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Textarea,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { memo, useCallback, useState } from 'react';
import { DICTIONARY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { WorkflowCommand as Command } from 'src/features/common/models';

export interface WorkflowCommandProps {
  command: Command;
  onCommandClick: (commandId: string, comments: string) => void;
}

export const WorkflowCommand = memo(({ command, onCommandClick }: WorkflowCommandProps) => {
  const t = useTranslate();
  const [comments, setComments] = useState('');

  const handleConfirm = useCallback((commandId: string) => onCommandClick(commandId, comments), [comments, onCommandClick]);

  return command.suppressComments ? (
    <Button data-testid="trigger-button" onClick={() => handleConfirm(command.commandId)} variant="outline" size="sm" mr="2">
      {command.displayName}
    </Button>
  ) : (
    <Popover onClose={() => setComments('')} placement="bottom" gutter={16}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button data-testid="trigger-button" variant="outline" size="sm" mr="2">
              {command.displayName}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <Box fontWeight={600}>
              <PopoverHeader>
                <Box paddingX="2">
                  <T _str={DICTIONARY.ARE_YOU_SURE} />
                </Box>
              </PopoverHeader>
            </Box>
            <PopoverBody>
              <Box>
                <Textarea
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  placeholder={t(DICTIONARY.WORKFLOW_POPOVER_TEXTAREA)}
                ></Textarea>
                <ButtonGroup mt="3">
                  <Button
                    onClick={() => {
                      handleConfirm(command.commandId);
                      return onClose();
                    }}
                    variant="solid"
                    size="sm"
                    mr="1"
                  >
                    <T _str={DICTIONARY.CONFIRM} />
                  </Button>
                  <Button
                    data-testid="cancel-button"
                    onClick={() => {
                      setComments('');
                      return onClose();
                    }}
                    variant="outline"
                    size="sm"
                  >
                    <T _str={DICTIONARY.CANCEL} />
                  </Button>
                </ButtonGroup>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
});

WorkflowCommand.displayName = WorkflowCommand.name;
