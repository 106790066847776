/* istanbul ignore file */

import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { ItemWithAncestors } from 'src/features/common/treeComponent/models';
import { getAncestorsQueryString } from 'src/features/shared/selectMediaModal/queries';

export const useGetAncestors = (
  input: { id: string; lang: string },
  useQueryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<GraphQLResponse<ItemWithAncestors>>,
          unknown,
          AxiosResponse<GraphQLResponse<ItemWithAncestors>>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { url } = useXMTenant();
  const toast = useToast();

  return useAuthQuery(
    ['get-item-with-ancestors', input.id, input.lang],
    async (axiosInstance) => {
      return axiosInstance?.post<GraphQLResponse<ItemWithAncestors>>(getSitecoreGraphQLEndpoint(url), {
        query: getAncestorsQueryString,
        variables: {
          itemId: input.id,
          language: input.lang,
        },
      });
    },
    { ...useQueryOptions },
    toast,
  );
};
