import { withAuthenticationRequired } from '@auth0/auth0-react';
import { T } from '@transifex/react';
import React, { useMemo } from 'react';
import { DICTIONARY } from 'src/constants';
import { NoTenantPage } from 'src/features/common/components/customErrorPages/NoTenantPage';
import { useResolveTenant } from 'src/features/common/tenants/useResolveTenant';
import { LoadingScreen } from 'src/features/temporary/LoadingScreen';
import { XMTenantContext, XMTenantModelWrapper } from './xmTenantContext';

export const XMTenantProvider: React.FC<React.PropsWithChildren> = withAuthenticationRequired(
  ({ children }) => {
    const { tenant, loading, isFailed } = useResolveTenant();

    const xmTenant = useMemo<XMTenantModelWrapper>(() => {
      return tenant
        ? {
            resolved: true,
            model: {
              id: tenant.id,
              name: tenant.name,
              url: tenant.annotations['URL'] && new URL(tenant.annotations['URL']).origin,
              projectName: tenant.annotations['XMCloud.ProjectName'] || '',
              environmentName: tenant.annotations['XMCloud.EnvironmentName'] || '',
            },
          }
        : { resolved: false };
    }, [tenant]);

    if (isFailed) {
      return <NoTenantPage />;
    }

    if (!xmTenant.resolved || loading) {
      return <LoadingScreen message={<T _str={DICTIONARY.LOADING} />} />;
    }

    return <XMTenantContext.Provider value={xmTenant}>{children}</XMTenantContext.Provider>;
  },
  {
    onRedirecting: () => <LoadingScreen message={<T _str={DICTIONARY.LOADING} />} />,
  },
);
