import { Box, Menu, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { SearchTypeFilterButton } from 'src/features/search/components';
import { useSearchTypeFilter } from 'src/features/search/hooks';
import { SEARCH_IN_PARAM_VALUE } from 'src/features/search/searchConstants';

export const SearchTypeFilter: FC = () => {
  const { type, value, onTypeChange } = useSearchTypeFilter();

  return (
    <Box>
      <Menu variant="filter" closeOnSelect={false}>
        <SearchTypeFilterButton value={value} />
        <MenuList zIndex="dropdown">
          <MenuOptionGroup type="radio" defaultValue={type} onChange={onTypeChange}>
            <MenuItemOption value={SEARCH_IN_PARAM_VALUE.ALL} data-testid="searchInType-all">
              <T _str={DICTIONARY.ENTIRE_ITEM} />
            </MenuItemOption>
            <MenuItemOption value={SEARCH_IN_PARAM_VALUE.NAME} data-testid="searchInType-name">
              <T _str={DICTIONARY.ITEM_NAME_ONLY} />
            </MenuItemOption>
            <MenuItemOption value={SEARCH_IN_PARAM_VALUE.FIELDS} data-testid="searchInType-fields">
              <T _str={DICTIONARY.ITEM_FIELDS_ONLY} />
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
