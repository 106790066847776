import { FormLabel, Text } from '@chakra-ui/react';
import { FC } from 'react';

export interface FieldNameProps {
  value: string;
  isRequired?: boolean;
}

export const FieldName: FC<FieldNameProps> = ({ value, isRequired }) => {
  return (
    <FormLabel mb="none" title={value} noOfLines={1}>
      {value}{' '}
      {isRequired && (
        <Text as="span" color="danger">
          *
        </Text>
      )}
    </FormLabel>
  );
};
