import { useCallback } from 'react';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';

export const useViewOptionsActions = () => {
  const { setUrlContext } = useUrlSharing(UrlParams.None);
  return useCallback(
    (checked: boolean) => {
      if (!checked) {
        setUrlContext({ sc_itemid: undefined, sc_version: undefined });
      }
    },
    [setUrlContext],
  );
};
