import { Box, Tooltip } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { DICTIONARY, LOCK_NONE_SXA_ID } from 'src/constants';
import { useSiteCollections } from 'src/features/common/components/filters/hooks';
import { MenuWithSearch } from 'src/features/common/components/menuWithSearch/MenuWithSearch';

export const SiteCollectionsFilter: FC = () => {
  const { siteCollections, setCollection } = useSiteCollections();

  const isDisabled = useMemo(() => siteCollections.find((sc) => sc.value === LOCK_NONE_SXA_ID) !== undefined, [siteCollections]);

  if (siteCollections.length < 2) {
    return <></>;
  }

  return (
    <Tooltip label={isDisabled ? DICTIONARY.COLLECTIONS_TOOLTIP : ''}>
      <Box>
        <MenuWithSearch title={DICTIONARY.HEADER.COLLECTION} options={siteCollections} onChange={setCollection} disabled={isDisabled} />
      </Box>
    </Tooltip>
  );
};
