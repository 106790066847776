import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DICTIONARY } from 'src/constants';
import { SitecoreUser } from 'src/features/common/auth/SitecoreUser';
import { useGetUsers } from 'src/features/common/components/filters/hooks';
import { Actor, User } from 'src/features/common/components/filters/models';
import { MenuWithSearchOption } from 'src/features/common/components/menuWithSearch/models';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useCommonFilter } from 'src/features/search/hooks/useCommonFilter';

export const useUserFilter = (actor: Actor) => {
  const [searchParams] = useSearchParams();
  const { users: data } = useGetUsers();
  const { user } = useAuth0<SitecoreUser>();
  const { onFilterChange } = useCommonFilter(actor);
  const t = useTranslate();

  const currentUserEmail = user?.email;
  const users = useMemo(() => {
    if (!data.length) return [];

    const queryParams = searchParams.get(actor);
    const filterUsers = queryParams?.split(',') ?? [];

    return data
      .sort((s) => (currentUserEmail && currentUserEmail === s.localName ? -1 : 1))
      .map<MenuWithSearchOption>((user: User) => ({
        checked: filterUsers.includes(user.name),
        label: user.localName === currentUserEmail ? `${t(DICTIONARY.ME)} (${user.localName})` : user.localName,
        value: user.name,
      }));
  }, [actor, currentUserEmail, data, searchParams, t]);

  return { users, onFilterChange };
};
