import { FC } from 'react';
import { FileCard } from 'src/features/media/card/FileCard';
import { MediaCardProps } from 'src/features/shared/selectMediaModal/models';

export const FileCardWrapper: FC<MediaCardProps> = ({ item, isSelected, setSelectedId }) => {
  // This allow to avoid error comparison of FileCardType and MediaFile.type
  if (item.type === 'Image' || item.type === 'None' || item.type === 'MediaFolder') {
    return <></>;
  }

  return (
    <FileCard
      key={item.itemId}
      {...item}
      id={item.itemId}
      isSelected={isSelected}
      onSelect={setSelectedId}
      title={item.name}
      size={Number(item.size)}
      type={item.type}
    />
  );
};
