/* istanbul ignore file */

import { SELECTED_SHOW_OPTIONS_DEFAULT_VALUES, SELECTED_SHOW_OPTIONS_KEYS } from 'src/constants';
import { useLocalStorage } from 'usehooks-ts';

export const useShowOptions = () => {
  const [showHiddenItems] = useLocalStorage(
    SELECTED_SHOW_OPTIONS_KEYS.HIDDEN_ITEMS,
    SELECTED_SHOW_OPTIONS_DEFAULT_VALUES[SELECTED_SHOW_OPTIONS_KEYS.HIDDEN_ITEMS],
  );
  const [showAdvancedItems] = useLocalStorage(
    SELECTED_SHOW_OPTIONS_KEYS.ADVANCED_ITEMS,
    SELECTED_SHOW_OPTIONS_DEFAULT_VALUES[SELECTED_SHOW_OPTIONS_KEYS.ADVANCED_ITEMS],
  );

  const [showTemplateSites] = useLocalStorage(
    SELECTED_SHOW_OPTIONS_KEYS.TEMPLATE_SITES,
    SELECTED_SHOW_OPTIONS_DEFAULT_VALUES[SELECTED_SHOW_OPTIONS_KEYS.TEMPLATE_SITES],
  );

  return {
    showHiddenItems,
    showAdvancedItems,
    showTemplateSites,
  };
};
