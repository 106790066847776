import { Badge, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { BuilderField } from '../models';

type TemplateTagsProps = {
  field: BuilderField;
};

export const TemplateTags: FC<TemplateTagsProps> = ({ field }) => (
  <Flex gap="1">
    {field.containsStandardValue && <Badge colorScheme="neutral">{DICTIONARY.STANDARD_VALUE}</Badge>}
    {field.containsInheritedValue && <Badge colorScheme="neutral">{DICTIONARY.ORIGINAL_VALUE}</Badge>}
    <Badge colorScheme="neutral">{field.versioning}</Badge>
  </Flex>
);
