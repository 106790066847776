import { ButtonProps, IconButton, Tooltip } from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useSaveNavigate } from 'src/features/common/utils/navigationUtils';

export type BackButtonProps = {
  iconBoxSize?: string;
  path?: string;
} & ButtonProps;

export const BackButton: FC<BackButtonProps> = ({ path, iconBoxSize, ...rest }) => {
  const goBack = useSaveNavigate();
  const t = useTranslate();

  return (
    <Tooltip label={t(DICTIONARY.BACK)}>
      <IconButton
        size="md"
        variant="ghost"
        borderRadius="full"
        aria-label={t(DICTIONARY.BACK)}
        icon={<Icon path={mdiArrowLeft} boxSize={iconBoxSize || '6'} />}
        onClick={() => goBack(path || '/')}
        {...rest}
      />
    </Tooltip>
  );
};
