import { useCallback, useEffect, useMemo, useState } from 'react';
import { DICTIONARY } from 'src/constants';
import { fileSizeTransformer } from 'src/features/common/utils/fileSizeTransformer';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { useSource } from 'src/features/fieldBuilder/hooks/useSource';
import { BuilderField as field, MediaSelection, UseFileProps } from 'src/features/fieldBuilder/models';
import { useGetMediaData } from 'src/features/media/hooks';
import { useGenerateMediaLink } from 'src/features/media/hooks/useGenerateMediaLink';
import { FileValue } from 'src/features/media/models';

export const useFile = ({ field }: UseFileProps) => {
  const [value, setValue] = useState(new FileValue(field.value));

  const { mediaItem } = useGetMediaData(value.mediaid, { enabled: !!value.mediaid || !!field.source });
  const { builderField, updateField, resetField, updateErrors } = useFieldActions(field);
  const generateLink = useGenerateMediaLink();
  const { sourceIds, uploadPath } = useSource(field);

  const onReset = useCallback(async () => {
    const fieldUpdated = await resetField(field.fieldId);
    fieldUpdated && setValue(new FileValue(fieldUpdated.value));
  }, [field.fieldId, resetField, setValue]);

  const onChange = useCallback(
    (selection: Partial<MediaSelection>) => {
      if (selection.id) {
        value.mediaid = selection.id;
        value.src = generateLink(selection.id, false);
      }

      setValue(value);
      updateField(field.fieldId, value.toString());
    },
    [field.fieldId, value, setValue, updateField, generateLink],
  );

  const onRemove = useCallback(() => {
    setValue(new FileValue());
    updateField(field.fieldId, '');
  }, [field.fieldId, updateField]);

  const onLink = useCallback(() => window.open(generateLink(value.mediaid, true)), [value.mediaid, generateLink]);

  const fileSize = useMemo(() => fileSizeTransformer(mediaItem?.size, 1), [mediaItem]);
  const fileName = useMemo(() => {
    const name = mediaItem?.innerItem.displayName || '';
    const extention = mediaItem?.extension.startsWith('.') ? mediaItem?.extension : `.${mediaItem?.extension}`;

    return `${name}${extention}`;
  }, [mediaItem]);

  const verifiedFieldSource: { builderField: field; errorMsg?: string } = useMemo(() => {
    if (field.source && !uploadPath) {
      return {
        builderField: { ...builderField, canEdit: false },
        errorMsg: DICTIONARY.DATA_SOURCE_ERROR_MESSAGE,
      };
    }

    if (value.mediaid && mediaItem === null) {
      return {
        builderField,
        errorMsg: DICTIONARY.SELECT_LINK_OR_REBASE_LINKS_DB,
      };
    }

    return {
      builderField,
      errorMsg: undefined,
    };
  }, [builderField, field.source, mediaItem, uploadPath, value.mediaid]);

  useEffect(
    () => updateErrors(!!verifiedFieldSource.errorMsg || !!builderField.error),
    [verifiedFieldSource.errorMsg, builderField.error, updateErrors],
  );

  return {
    builderField: verifiedFieldSource.builderField,
    errorMsg: verifiedFieldSource.errorMsg,
    mediaItem,
    uploadPath,
    sourceIds,
    value,
    fileName,
    fileSize,
    onRemove,
    onChange,
    onReset,
    onLink,
  };
};
