import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { AxiosResponse } from 'axios';
import { memo, useCallback, useEffect, useState } from 'react';
import { DICTIONARY, MASTER_DB } from 'src/constants';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { AddItemVersionResponse, GraphQLResponse } from 'src/features/common/models';
import { useUpdateMillerColumns } from 'src/features/millerColumns/hooks/useUpdateMillerColumns';
import { useAddItemVersion } from 'src/features/versions/hooks/useAddItemVersion';

export interface CreateVersionModalProps {
  version: number;
  renderAction: any;
  initialStateName?: string;
}

export const CreateVersionModal = memo(({ version, renderAction, initialStateName }: CreateVersionModalProps) => {
  const { sc_itemid, sc_lang, setUrlContext } = useUrlStrictContext(UrlParams.sc_itemid | UrlParams.sc_site | UrlParams.sc_lang);
  const { mutate: addItemVersion } = useAddItemVersion();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const refreshColumns = useUpdateMillerColumns();

  const [versionName, setVersionName] = useState('');

  useEffect(() => {
    return () => {
      !isOpen && setVersionName('');
    };
  }, [isOpen]);

  const handleSave = useCallback(() => {
    addItemVersion(
      {
        itemId: sc_itemid,
        database: MASTER_DB,
        language: sc_lang,
        versionName,
      },
      {
        onSuccess(data: AxiosResponse<GraphQLResponse<AddItemVersionResponse>>) {
          const item = data.data.data.addItemVersion.item;
          setUrlContext({ sc_version: item.version });
          refreshColumns();
        },
      },
    );

    onClose();
  }, [addItemVersion, sc_itemid, sc_lang, versionName, refreshColumns, onClose, setUrlContext]);

  return (
    <>
      {renderAction(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose} autoFocus>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <ModalHeader>
              <T _str={DICTIONARY.CREATE_VERSION} />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <Text isTruncated mb="5">
                  <Text as="span" fontWeight="semibold" pr="1" lineHeight="1.3">
                    <T _str={DICTIONARY.VERSION} />:
                  </Text>
                  {version}
                </Text>
                <Text hidden={!initialStateName} isTruncated mb="5">
                  <Text as="span" fontWeight="semibold" pr="1" lineHeight="1.3">
                    <T _str={DICTIONARY.ASSIGNED_WORKFLOW_STATE} />:
                  </Text>
                  {initialStateName}
                </Text>
                <FormLabel>
                  <T _str={DICTIONARY.NAME_VERSION_MESSAGE} />
                  &nbsp;(
                  <T _str={DICTIONARY.OPTIONAL} />)
                </FormLabel>
                <Input
                  autoFocus
                  value={versionName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVersionName(e.target.value)}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button data-testid="cancel-btn" variant="ghost" onClick={onClose}>
                  <T _str={DICTIONARY.CANCEL} />
                </Button>
                <Button data-testid="submit-btn" variant="solid" onClick={handleSave} type="submit">
                  <T _str={DICTIONARY.SAVE} />
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
});

CreateVersionModal.displayName = CreateVersionModal.name;
