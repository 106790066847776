import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';

import { SelectItemContextType, SelectItemModalDictionaries, SelectItemOptions } from '../models';

export const useSelectItemContextProvider = (disclosure: Pick<ReturnType<typeof useDisclosure>, 'onOpen'>) => {
  const { onOpen } = disclosure;

  const { sc_lang, sc_site } = useUrlStrictContext(UrlParams.sc_lang | UrlParams.sc_site);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [datasource, setDatasource] = useState<string | undefined>(undefined);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [includeFilters, setIncludeFilters] = useState(false);
  const [texts, setTexts] = useState<SelectItemModalDictionaries | undefined>(undefined);
  const [selectState, setSelectState] = useState<SelectItemContextType | undefined>(undefined);

  const openModal = useCallback(
    ({ texts, includeFilters, context, datasource }: SelectItemOptions) => {
      setSelectedLanguage(context?.language || sc_lang);
      setSelectedSite(context?.sitename || sc_site);
      setSelectedIds(context ? [context.id] : []);
      setIncludeFilters(includeFilters);
      setDatasource(datasource);
      setSelectState(context);
      setTexts(texts);
      onOpen();
    },
    [onOpen, sc_lang, sc_site],
  );

  return {
    selectedIds,
    setSelectedIds,
    selectedSite,
    setSelectedSite,
    selectedLanguage,
    setSelectedLanguage,
    datasource,
    openModal,
    includeFilters,
    texts,
    selectState,
    setSelectState,
  };
};
