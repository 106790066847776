import {
  FormControl,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { NUMBER_NORMALIZATION_REGEX } from 'src/constants';
import { useFormikFieldActions } from 'src/features/fieldBuilder/hooks/useFormikFieldActions';
import { useValidationSchema } from 'src/features/fieldBuilder/hooks/useValidationSchema';
import { FormikFieldProps, FormikFieldState, GenericField } from 'src/features/fieldBuilder/models';
import { VALIDATIONS } from 'src/validation-constants';
import * as Yup from 'yup';
import { GenericFieldView } from './GenericFieldView';

const integerValidation = Yup.number()
  .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
  .max(VALIDATIONS.NUMBER_MAX_VALUE, VALIDATIONS.INTEGER_MESSAGE)
  .min(VALIDATIONS.NUMBER_MIN_VALUE, VALIDATIONS.INTEGER_MESSAGE)
  .integer()
  .nullable(true);

const numberValidation = Yup.number()
  .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
  .max(VALIDATIONS.NUMBER_MAX_VALUE, VALIDATIONS.NUMBER_MESSAGE)
  .min(VALIDATIONS.NUMBER_MIN_VALUE, VALIDATIONS.NUMBER_MESSAGE)
  .nullable(true);

export const Number: FC<GenericField> = ({ field }) => {
  const { builderField, onSubmit, onReset } = useFormikFieldActions(field);

  const validationSchema = useValidationSchema(field.name, field.typeKey === 'number' ? numberValidation : integerValidation);

  return (
    <Formik<FormikFieldState>
      initialValues={{ [field.name]: field.value || '0' }}
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {({ submitForm, resetForm, setFieldValue, setFieldTouched }) => (
        <Form>
          <Field name={field.name}>
            {({ field: formikField, form }: FormikFieldProps<string>) => (
              <FormControl isInvalid={(!!form.errors[field.name] || !!builderField.error) && form.touched[field.name]}>
                <GenericFieldView field={builderField} errorMsg={form.errors[field.name]} onReset={resetForm}>
                  <NumberInput
                    {...formikField}
                    onChange={(stringValue) => {
                      if (NUMBER_NORMALIZATION_REGEX.test(stringValue) || !stringValue) {
                        setFieldValue(field.name, stringValue);
                        setFieldTouched(field.name, true, false);
                      }
                    }}
                    onBlur={() => void submitForm()}
                    bgColor="white"
                    isDisabled={!field.canEdit}
                    max={VALIDATIONS.NUMBER_MAX_VALUE}
                    min={VALIDATIONS.NUMBER_MIN_VALUE}
                    isInvalid={!!form.errors[field.name] || !!builderField.error}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </GenericFieldView>
              </FormControl>
            )}
          </Field>
        </Form>
      )}
    </Formik>
  );
};
