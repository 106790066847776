import { ButtonGroup, Wrap } from '@chakra-ui/react';
import { FC } from 'react';
import { useViewSwitcherModes } from 'src/features/common/components/actionsBar/hooks';
import { ViewSwitcher } from './ViewSwitcher';

export const SwitchBar: FC<React.PropsWithChildren> = ({ children }) => {
  const { viewMode, setViewMode } = useViewSwitcherModes();

  return (
    <Wrap data-testid="switch-bar" align="center">
      <ButtonGroup as={Wrap}>
        <ViewSwitcher mode={viewMode} onModeChange={setViewMode} />
        {children}
      </ButtonGroup>
    </Wrap>
  );
};
