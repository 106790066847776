export class ImageValue {
  private document: Document = new Document();

  constructor(value?: string) {
    this.document = new DOMParser().parseFromString(value || '<Image />', 'text/xml');
  }

  private getValue(attributeName: string): string {
    return this.document.firstElementChild?.getAttribute(attributeName) ?? '';
  }

  private setValue(attributeName: string, value: string) {
    if (!this.document.firstElementChild) {
      const imageNode = this.document.createElement('image');
      this.document.appendChild(imageNode);
    }

    this.document.firstElementChild?.setAttribute(attributeName, value);
  }

  get mediaid(): string {
    return this.getValue('mediaid');
  }
  set mediaid(value: string) {
    this.setValue('mediaid', value);
  }

  get alt(): string {
    return this.getValue('alt');
  }
  set alt(value: string) {
    this.setValue('alt', value);
  }

  get height(): string {
    return this.getValue('height');
  }
  set height(value: string) {
    this.setValue('height', value);
  }

  get width(): string {
    return this.getValue('width');
  }
  set width(value: string) {
    this.setValue('width', value);
  }

  get hspace(): string {
    return this.getValue('hspace');
  }
  set hspace(value: string) {
    this.setValue('hspace', value);
  }

  get vspace(): string {
    return this.getValue('vspace');
  }
  set vspace(value: string) {
    this.setValue('vspace', value);
  }

  public toString: () => string = () => new XMLSerializer().serializeToString(this.document);
}
