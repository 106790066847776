import { THUMBNAIL } from 'src/constants';
import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { useViewSwitcherModes } from 'src/features/common/components/actionsBar/hooks';
import { viewModes } from 'src/features/common/components/actionsBar/models';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { DuplicateItemInput, GraphQLResponse, ItemDuplicateResponse } from 'src/features/common/models';
import { duplicateItemQueryString } from 'src/features/common/queries/items';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export const useDuplicateItem = () => {
  const { url } = useXMTenant();
  const { viewMode } = useViewSwitcherModes();

  return useAuthMutation(
    (axiosInstance) => (item: DuplicateItemInput) =>
      axiosInstance?.post<GraphQLResponse<ItemDuplicateResponse>>(getSitecoreGraphQLEndpoint(url), {
        query: duplicateItemQueryString,
        variables: {
          item,
          isResponseLimited: viewMode === viewModes.columns,
          thumbnailHeight: THUMBNAIL.URL.HEIGHT,
          thumbnailWidth: THUMBNAIL.URL.WIDTH,
        },
      }),
  );
};
