import { useCallback, useEffect, useMemo, useState } from 'react';
import { DICTIONARY } from 'src/constants';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { useGetFieldSource } from 'src/features/fieldBuilder/hooks/useGetFieldSource';
import { BuilderField } from 'src/features/fieldBuilder/models';
import { formatArrayIDsToString, formatStringIDsToArray } from 'src/features/fieldBuilder/utils/source';

export const useChecklist = (field: BuilderField) => {
  const [selections, setSelections] = useState(formatStringIDsToArray(field.value));
  const { builderField, updateField, resetField, updateErrors } = useFieldActions(field);
  const { source, isLoading } = useGetFieldSource(field.fieldId, field.source);

  const onReset = useCallback(async () => {
    const fieldUpdated = await resetField(field.fieldId);
    fieldUpdated && setSelections(formatStringIDsToArray(fieldUpdated.value));
  }, [field.fieldId, resetField, setSelections]);

  const onUpdate = useCallback(
    (ids: string[]) => {
      updateField(field.fieldId, formatArrayIDsToString(ids));
      setSelections(ids);
    },
    [field.fieldId, updateField, setSelections],
  );

  const error = useMemo(
    () => (isLoading || !!source.length ? '' : DICTIONARY.FIELD_DATASOURCE_OR_RESULT_EMPTY),
    [isLoading, source.length],
  );

  useEffect(() => updateErrors(!!error || !!builderField.error), [error, builderField.error, updateErrors]);

  return { builderField, selections, source, error, onReset, onUpdate };
};
