import { Badge, Box, Button, Divider, Flex, FlexProps, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { mdiClockOutline } from '@mdi/js';
import { T } from '@transifex/react';
import moment from 'moment';
import { memo, useMemo, useState } from 'react';
import { DATE_FORMAT, DICTIONARY, EMPTY_VALUE, VERSION_DATETIME_FORMAT } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useCurrentItem } from 'src/features/common/hooks/useCurrentItem';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { DetailedItem } from 'src/features/common/models/';
import { getGreatestVersion } from 'src/features/fieldEditor/components/FieldEditor';
import { WorkflowState } from 'src/features/workflow/components';
import { PublishRestrictionsModal } from './modals';

interface VersionCardProps {
  onVersionChange: (version: number, tabToSelect: number) => void;
  item: DetailedItem;
  children?: any;
}

const standardStyle: FlexProps = { borderWidth: '1px', borderStyle: 'solid', borderColor: 'chakra-border-color', borderRadius: 'base' };

export const VersionCard = memo(({ onVersionChange, item, children }: VersionCardProps) => {
  const { currentItem } = useCurrentItem();
  const { sc_version } = useUrlStrictContext(UrlParams.sc_version);
  const [openDetails, setOpenDetails] = useState(false);
  const greatestVersion = useMemo(() => getGreatestVersion(currentItem), [currentItem]);
  const cardStyle = useMemo(
    () => (item.version === (sc_version || greatestVersion) ? { ...standardStyle, borderColor: 'green' } : standardStyle),
    [item.version, sc_version, greatestVersion],
  );

  return (
    <Flex w="full" p="5" {...cardStyle} direction="column">
      {/* 1st row */}
      <Flex flexDirection="row" w="full" pb="5" justifyContent="space-between" wrap="nowrap">
        {/* col 1 with info */}
        <Flex wrap="wrap">
          <Flex data-testid="version-info" flexDirection="column" alignItems="flex-start" pr="2" pb="2">
            <Box display="flex" alignItems="center">
              <Heading size="md">
                <T
                  _str={DICTIONARY.VERSION_NUMBER_LATEST}
                  version={item.version}
                  latest={item.version === greatestVersion ? <T _str={DICTIONARY.LATEST} /> : ''}
                />
              </Heading>
              <Text as="span" pl="2" fontSize="md">
                {item.versionName || <T _str={DICTIONARY.UNNAMED} />}
              </Text>
            </Box>
            <HStack pt="2" direction="row">
              {(item.version === sc_version || item.version === greatestVersion) && (
                <Badge colorScheme="green">
                  <T _str={DICTIONARY.EDITING} />
                </Badge>
              )}
              {item.workflow?.workflow?.displayName && item.workflow?.workflowState?.displayName ? (
                <WorkflowState
                  workflowName={item.workflow?.workflow?.displayName}
                  name={item.workflow?.workflowState?.displayName}
                  isFinal={item?.workflow?.workflowState?.final || false}
                  maxWidth="lg"
                />
              ) : (
                <Text variant="small" isTruncated maxWidth="lg">
                  <T _str={DICTIONARY.NO_WORKFLOW} />
                </Text>
              )}
            </HStack>
          </Flex>
        </Flex>
        {/* col 2 with timeframe */}
        <Box data-testid="available-for-publishing">
          <HStack>
            <Icon fontSize="lg" path={mdiClockOutline} />
            <VStack alignItems="flex-start">
              <HStack>
                <Text as="span" fontSize="md" fontWeight="semibold">
                  {item.isHidden.value !== '1' ? (
                    <T _str={DICTIONARY.AVAILABLE_FOR_PUBLISHING} />
                  ) : (
                    <T _str={DICTIONARY.NOT_AVAILABLE_FOR_PUBLISHING} />
                  )}
                </Text>
                {!item.isHidden.value && moment().add(30, 'seconds').diff(moment(item.validFrom.value)) < 0 && (
                  <Text as="span" fontSize="md" fontWeight="semibold">
                    <T _str={DICTIONARY.IN} />
                    <Text as="span" fontWeight="normal">
                      {`
                        ${moment.duration(moment(item.validFrom.value).diff(moment().utc())).days()}d
                        ${moment.duration(moment(item.validFrom.value).diff(moment().utc())).hours()}h
                        ${moment.duration(moment(item.validFrom.value).diff(moment().utc())).minutes()}m
                        `}
                    </Text>
                  </Text>
                )}
              </HStack>
              <HStack data-testid="from-until" mt="0!important">
                {!!item.validFrom.value && (
                  <Text variant="small" fontWeight="semibold">
                    <T _str={DICTIONARY.FROM} />
                    <Text as="span" variant="small" fontWeight="medium" ml="1">
                      {moment(item.validFrom.value).local().format(VERSION_DATETIME_FORMAT)}
                    </Text>
                  </Text>
                )}
                {!!item.validTo.value && (
                  <Text variant="small" fontWeight="semibold">
                    <T _str={DICTIONARY.UNTIL} />
                    <Text as="span" variant="small" fontWeight="medium" ml="1">
                      {moment(item.validTo.value).local().format(VERSION_DATETIME_FORMAT)}
                    </Text>
                  </Text>
                )}
                {!item.validFrom.value && !item.validTo.value && (
                  <Text variant="small" fontWeight="semibold" align={'center'}>
                    <T _str={DICTIONARY.ALWAYS} />
                  </Text>
                )}
                <PublishRestrictionsModal
                  data-testid="schedule-publish"
                  key={`${item.version}-${item.validFrom.value}-${item.validTo.value}-${item.isHidden.value}`}
                  version={item.version}
                  validFrom={item.validFrom.value}
                  validTo={item.validTo.value}
                  isHidden={item.isHidden.value}
                />
              </HStack>
            </VStack>
          </HStack>
        </Box>
      </Flex>
      <Divider w="100%" />
      {/* 2nd row */}
      <Flex data-testid="version-editing" flexDirection="row" py="3" mb="-5" justifyContent="space-between">
        <Flex alignItems="center">
          <Button size="sm" variant="link" onClick={() => setOpenDetails(!openDetails)}>
            <T _str={DICTIONARY.MORE_DETAILS} />
          </Button>
        </Flex>
        <Flex>
          <Button
            data-testid="edit-version-button"
            size="sm"
            variant="outline"
            isDisabled={item.version === sc_version}
            onClick={() => onVersionChange(item.version, 0)}
          >
            <T _str={DICTIONARY.EDIT} />
          </Button>
          {children}
        </Flex>
      </Flex>
      {/* hidden details box */}
      <Box data-testid="version-details" hidden={!openDetails}>
        <Box py="4" pb="0">
          <Text fontWeight="semibold" fontSize="md">
            <T _str={DICTIONARY.LAST_MODIFIED} />
          </Text>
          <Text fontSize="md">
            {item.updatedAt?.value ? moment(item.updatedAt?.value, true).format(DATE_FORMAT) : EMPTY_VALUE}
            &nbsp;
            <T _str={DICTIONARY.BY} />
            &nbsp;
            {item.updatedBy?.value ? item.updatedBy?.value : EMPTY_VALUE}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
});

VersionCard.displayName = VersionCard.name;
