import { PublishItemInput } from 'src/features/common/models';

export const publish = {
  execute_publish_item: (input: PublishItemInput) => {
    return {
      query: `
        mutation PublishItem($input: PublishItemInput!) {
          publishItem(input: $input) {
            operationId
          }
        }
      `,
      variables: { input },
    };
  },
};
