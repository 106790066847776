export const DICTIONARY = {
  LOGOUT: 'Log out',
  MANAGE_ACCOUNT: 'Manage account',
  SITES: 'Sites',
  LOADING: 'Loading…',
  CONTENT: 'Content',
  DETAILS: 'Details',
  MORE: 'More',
  MORE_DETAILS: 'More details',
  VERSIONS: 'Versions',
  ARE_YOU_SURE: 'Are you sure?',
  CONFIRM: 'Confirm',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  OPTIONAL: 'Optional',
  REFRESH: 'Refresh',
  SEARCH: 'Search',
  CLEAR_SEARCH: 'Clear search',
  NO_SEARCH_RESULT: 'No search results',
  NO_FILES_IN_FOLDER: 'No files in this folder',
  SEARCH_RESULTS_COUNT: '{count} results',
  SEARCH_RESULT_COUNT: '{count} result',
  TRY_DIFFERENT_SEARCH: 'Try a different search query',
  TRY_DIFFERENT_SEARCH_OR_FILTER: 'Try a different search query or filter',
  DATA_SOURCE_ERROR_MESSAGE: 'Access to the Media library is broken. Open Content Editor template manager to fix it.',
  WORKFLOW_POPOVER_TEXTAREA: 'Add optional comment',
  COMMAND_BTN_TEXT: 'Something',
  REJECT: 'Reject',
  APPROVE: 'Approve',
  ACCESS_DENIED_TITLE: 'Access denied',
  ACCESS_DENIED_TEXT: "You don't have permission to access this page",
  ACCESS_DENIED_SUBTEXT: 'Error 403',
  ACCESS_DENIED_LINK_TEXT: 'Go to Homepage',
  NO_VALID_XM_VERSION_TEXT:
    'Your XM Cloud environment is outdated. Go to XM Cloud Deploy to build and deploy the environment to the latest version.',
  NO_VALID_XM_VERSION_LINK_TEXT: 'Go to XM Cloud Deploy',
  OPEN_IN_PAGES: 'Open in Pages',
  ITEM_ID: 'Item ID',
  ITEM_NAME: 'Item name',
  ITEM_PATH: 'Item path',
  TEMPLATE: 'Template',
  CREATED_OM: 'Created on',
  LAST_MODIFIED_ON: 'Last modified on',
  WORKFLOW: 'Workflow',
  NO_WORKFLOW: 'No workflow',
  CREATE: 'Create',
  RENAME: 'Rename',
  RENAME_ITEM: 'Rename Item',
  DUPLICATE: 'Duplicate',
  DELETE: 'Delete',
  DELETE_ITEM: 'Delete item',
  DELETE_INFO_SUBITEMS: 'You are about to delete {itemName} and all its sub-items. This action cannot be undone.',
  DELETE_INFO_NO_SUBITEMS: 'You are about to delete {itemName}. This action cannot be undone.',
  DELETE_SUCCESSFUL: '{itemName} has been deleted.',
  DELETE_UNSUCCESSFUL: '{itemName} has not been deleted.',
  NAME: 'Name',
  DISPLAY_NAME: 'Display name',
  WORKFLOW_STATE: 'Workflow state',
  STATE: 'State',
  TOOLTIP_WORKFLOW: 'Workflow: {name}',
  TOOLTIP_STATE: 'State: {name}',
  LAST_MODIFIED: 'Last modified',
  PUBLISHABLE_VERSION: 'Publishable version',
  PUBLISHABLE_VERSION_INFO:
    'This version is the one currently available for publishing. If publishing is not automatic, this version can be published manually by clicking Publish.',
  OTHER_VERSIONS: 'Other versions',
  OTHER_VERSIONS_INFO: 'To make these versions publishable, use the scheduling feature',
  NO_PUBLISHABLE_VERSION: 'No publishable version',
  UNNAMED: 'Unnamed',
  BY: 'by',
  SHOW: 'Show',
  SHOW_SETTING: {
    LANGUAGES: 'Languages',
    HIDDEN_ITEMS: 'Hidden items',
    ADVANCED_ITEMS: 'Advanced items',
    WORKFLOW_STATE: 'Workflow state',
    THUMBNAILS: 'Thumbnails',
    TEMPLATE_SITES: 'Template sites',
  },
  MISSING_VERSION_MESSAGE: 'The current item does not have a version in',
  ADD_VERSION: 'Add this version',
  VERSION: 'Version',
  VERSION_NUMBER: 'Version {version}',
  VERSION_NUMBER_WITH_DOTS: 'Version: {version}',
  VERSION_NUMBER_LATEST: 'Version {version} {latest}',
  CREATE_VERSION: 'Create version',
  ASSIGNED_WORKFLOW_STATE: 'Assigned workflow state',
  NAME_VERSION_MESSAGE: 'Give this version a name',
  RENAME_VERSION: 'Rename version {version}',
  DUPLICATE_VERSION: 'Duplicate version {version}',
  DUPLICATE_MESSAGE: 'This will create a',
  DELETE_MESSAGE: 'You are about to delete version {version}. This action cannot be undone.',
  DELETE_VERSION: 'Delete version',
  LEARN_MORE: 'Learn more',
  PUBLISH: 'Publish',
  EDIT: 'Edit',
  EDITING: 'Editing',
  LATEST: '(Latest)',
  IN: 'in',
  RETURN_TO_LATEST_VERSION: 'Return to Latest Version',
  YOU_ARE_EDITING_VERSION: 'You are editing version',
  NOT_DEFAULT_VERSION: 'This is not the default version.',
  SELECT_PUBLISH_SETTINGS: 'Select the relevant publish setting for the item',
  SMART_PUBLISH: 'Smart publish',
  SMART_PUBLISH_DESCRIPTION: 'Publish differences between source and target database.',
  REPUBLISH_EVERYTHING: 'Republish everything',
  REPUBLISH_EVERYTHING_DESCRIPTION:
    'Overwrite everything when publishing. Only use this option when you want to make sure that everything that is publishable is published. This option takes longer.',
  PUBLISH_SUB_ITEMS: 'Publish subitems',
  LANGUAGES_TO_PUBLISH: 'Languages to publish',
  LANGUAGE_FILTER: 'Language:',
  SCHEDULE_PUBLISH: 'Schedule publishing availability',
  AVAILABLE_FOR_PUBLISHING: 'Available for publishing',
  NOT_AVAILABLE_FOR_PUBLISHING: 'Not available for publishing',
  UNTIL: 'UNTIL',
  FROM: 'FROM',
  ALWAYS: 'Always',
  AVAILABLE: 'Available',
  NOT_AVAILABLE: 'Not available',
  TIMEZONE: 'Timezone: ',
  TIMEZONE_IS_ON_SERVER: 'Timezone is of hosting server',
  START_DATE: 'Start Date',
  START_DATE_INFO: 'Not setting a start date means that the version becomes publishable now.',
  END_DATE: 'End Date',
  END_DATE_INFO:
    'The end date is the time at which the version stops being publishable. This is not the time at which a version is unpublished. If the version is already published, it remains so until it is replaced by publishing another version.',
  ALL: 'All',
  CLEAR: 'Clear',
  CLEAR_SETTINGS: 'Clear settings',
  TYPE_NAME: 'Type name',
  PROJECT_NAME: 'Project name',
  ENVIRONMENT_NAME: 'Environment name',
  NO_ACCESS: 'No access',
  OPEN_ITEM_AND_ADD_LANGUAGE: 'Open the item and add a version in the appropriate language to edit the Display name.',
  GENERIC_ERROR: {
    TITLE: 'Something went wrong',
    DESCRIPTION: 'Reach out to your Sitecore Cloud admin or contact ',
    LINK_TEXT: 'Support',
    DOT: '.',
  },
  NO_WEBSITE: {
    TITLE: 'No sites created yet',
    MESSAGE: 'Time to populate this desert wasteland.',
    MESSAGE_NEXT_LINE: 'You can create sites from XM Cloud.',
    LINK_TEXT: 'Go to XM Cloud',
  },
  NO_RESULTS: {
    MESSAGE: 'The filter selection does not match with any results',
    BUTTON_TEXT: 'Reset filters',
  },
  ABOUT_VERSIONING: 'About Versioning',
  VERSION_HELP: [
    'Working with versions lets you store backup revisions of a content item as well as work on the next revision of this content item.',
    'To make a version publishable, it needs to be previously approved according to the approval workflow if one has been set up.',
    'Once a version has been approved, it becomes automatically the latest publishable version, unless publication was restricted.',
    'Preventing publication can be done by not approving a version, setting up a publication schedule, or marking the item as unavailable for publication.',
  ],
  NOTIFICATIONS: {
    SOMETHING_WENT_WRONG: 'Something went wrong!',
    CREATE_ITEM: 'Item {name} created',
    UPDATE_ITEM: 'Item updated',
    RENAME_ITEM: 'Item renamed',
    PUBLISH_RESTRICTIONS: 'Publishing availability scheduled',
    MEDIA_ITEM_REMOVED: 'Media item removed',
    PUBLISH: 'Item has been queued for publishing',
    URL_LANGUAGE_ERROR: 'There was an error. You landed on the default language.',
    URL_VERSION_ERROR: 'There was an error. You landed on the latest version.',
    NO_ITEM_FOUND_WITH_PATH: 'No item found with this path',
    MEDIA_UPLOADED: 'Media item uploaded successfully',
    MEDIA_TYPE_NOT_SUPPORTED: 'This media type is not supported',
    MEDIA_INVILID_SIZE: 'The media size is more than 2GB',
    CANNOT_FETCH_TENANT: 'Cannot fetch the URL of the selected environment',
  },
  WELCOME_INSTRUCTIONS_TEXT: ['Select an item from the list on the left', 'to see details and content'],
  COLLAPSE: 'Collapse',
  EXPAND: 'Expand',
  RESET: 'Reset',
  FIELD_ACCESS_RESTRICTED: 'You do not have access rights to modify the field',
  SELECT_FILE: 'Select file',
  ADD_FILE: 'Add file',
  SELECT_FILE_LINK: 'Select file to link',
  LINK_FILE: 'Link file',
  SELECT_IMAGE: 'Select image',
  ADD_IMAGE: 'Add image',
  RENAME_ITEM_NAME_DESCRIPTION: 'Enter the name to be used to build the URL of the item.',
  RENAME_DISPLAY_NAME_DESCRIPTION:
    'Enter an alternative name to be used to display the item. This name does not impact the URL of the item.',
  STANDARD_VALUE: 'Standard value',
  ORIGINAL_VALUE: 'Original value',
  URL: 'URL',
  LINK_TEXT: 'Link text',
  LINK_TITLE: 'Link title',
  LINK_TARGET: 'Open in a new tab',
  LINK_PATH: 'Insert path',
  LINK_QUERY: 'URL query string',
  LINK_ANCHOR: 'Anchor',
  BROWSE: 'Browse',
  EXTERNAL: 'External',
  INTERNAL: 'Internal',
  MEDIA: 'Media',
  LINK_TEXT_TOOLTIP: 'Text that appears as a hyperlink.',
  LINK_TITLE_TOOLTIP: 'Text displayed in a tooltip when a visitor hovers the cursor over the link.',
  LINK_QUERY_TOOLTIP: 'Parameters for the URL such as language and site.',
  LINK_ANCHOR_TOOLTIP: 'To link to an anchor on an internal page, enter the anchor ID. Learn more.',
  DUPLICATE_ITEM: 'Duplicate item',
  DUPLICATE_ITEM_INFO: 'The duplicated item will be on the same level as the original. All sub-items will be duplicated as well.',
  NEW_ITEM_NAME: 'New item name',
  COPY_OF_ITEM_NAME: 'Copy of {itemName}',
  DUPLICATE_SUCCESSFUL: '{itemName} has been duplicated.',
  DUPLICATE_UNSUCCESSFUL: '{itemName} has not been duplicated.',
  FIELD_DATASOURCE_OR_RESULT_EMPTY:
    'No data source property is associated with this field, or the selected source property has no children',
  FIELD_DATASOURCE_EMPTY: 'No data source property is associated with this field',
  INVALID_DATASOURCE:
    'No data source is associated with this field, or the selected source has no children. Open Content Editor to fix it.',
  SELECT_LINK_OR_REBASE_LINKS_DB: 'Select another link from the list or rebuild link database',
  SELECT_ITEM: 'Select item',
  SELECT_ITEM_TO_LINK: 'Select item to link',
  LINK_ITEM: 'Link item',
  SITE_WITH_COLON: 'Site: ',
  LANGUAGE_WITH_COLON: 'Language: ',
  NO_CONTENT_AVAILABLE_FOR_THIS_ITEM: 'No content available for this item',
  COLLECTION_FILTER: 'Collection: ',
  SITE_TYPE_FILTER: 'Site type',
  SXA_MANAGED: 'SXA managed',
  NON_SXA_MANAGED: 'Non-SXA managed',
  FIELD_CONTAINS_VALUE_NOT_IN_SELECTIONS: 'The field contains a value that is not in the selection list',
  REMOVE: 'Remove',
  NONE: 'None',
  ADD_ITEM: 'Add item',
  OR: 'or',
  REMOVE_MESSAGE: 'You are about to remove {name}. This action cannot be undone.',
  ALT_TEXT: 'Alt text',
  IMAGE_FIELD_TITLE: 'Upload a new image or select from the media library',
  FILE_FIELD_TITLE: 'Upload a new file or select from the media library',
  UPLOADING: 'Uploading...',
  MAX_UPLOAD_SIZE_MESSAGE: 'Maximum size 2GB',
  UPLOAD: 'Upload',
  DROP_IMAGE: 'drop image here',
  DROP_FILE: 'drop file here',
  BROWSE_MEDIA_LIBRARY: 'Browse media library',
  UPLOAD_FILE_TYPES: 'Documents, images and videos are allowed',
  NO_RESULTS_IMAGE_ALT_TEXT: 'Magnifier icon with an X indicating no results',
  SHORT_NOT_AVAILABLE: 'N/A',
  FILE_UPLOAD_TEXT: 'Documents, images and videos are allowed',
  TRY_AGAIN: 'Try again',
  LOG_IN_AGAIN: 'Log in again',
  SELECT_ANOTHER_ENV: 'Select another environment',
  EDIT_SCHEDULE: 'Edit schedule',
  COLLECTIONS_TOOLTIP: 'Only SXA managed sites have collections',
  PUBLISH_FIELDS_HAVE_ERRORS: 'Some fields in this item contain errors',
  SITE_TYPE_OPTION_DISABLED_MESSAGE: 'A collection is selected. Non-SXA managed sites do not support collections.',
  FAILED_TO_AUTHORIZE: 'Failed to authorize',
  lOGIN_AGAIN: 'Login again',
  COLUMNS_VIEW: 'Columns view',
  LIST_VIEW: 'List view',
  HEADER: {
    NAME: 'Name',
    TYPE: 'Type',
    CREATED: 'Created by',
    UPDATED: 'Last modified by',
    LANGUAGE: 'Language',
    SITE: 'Site',
    WORKFLOW: 'Workflow',
    COLLECTION: 'Collection',
  },
  BACK: 'Back',
  CREATE_ITEM: 'Create item',
  OPTIONS: 'Options',
  CREATE_CHILD: 'Create child {insertOptionsName}',
  CREATE_SIBLING: 'Create sibling {insertOptionsName}',
  NEW_CHILD: 'New child {insertOptionsName}',
  NEW_SIBLING: 'New sibling {insertOptionsName}',
  FIELDS_CONTAIN_ERRORS: 'Some fields contain errors',
  FIELDS_IN_SECTION_CONTAIN_ERRORS: 'Some fields in this section contain errors',
  SEARCH_IN_COLON: 'Search in:',
  ENTIRE_ITEM: 'Entire item',
  ITEM_NAME_ONLY: 'Item name only',
  ITEM_FIELDS_ONLY: 'Item fields only',
  SHOW_FILTERS: 'Show filters',
  HIDE_FILTERS: 'Hide filters',
  VIEW_ITEM: 'View item',
  ME: 'Me',
  CLEAR_ALL: 'Clear all',
  SITE: 'Site',
  TYPE: 'Type',
  CREATE_SUPPORT_TICKET: 'Create support ticket',
  UPLOAD_JS_SVG_MESSAGE:
    'You can\'t upload "{filename}" because it might contain malicious JavaScript code. A system administrator can enable uploading SVGs with JavaScript code, if necessary. Please make sure you always upload content only from trusted sources.',
  WE_COULD_NOT_FIND_ITEM: "Something went wrong, we couldn't find this item.",
};
