import { Field } from 'formik';
import { FC } from 'react';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { FieldName } from 'src/features/fieldBuilder/components/FieldName';
import { FormikFieldProps } from 'src/features/fieldBuilder/models';
import { GENERAL_LINK, GenericInput } from 'src/features/generalLink/models';
import { PathInput } from './PathInput';

export const FieldBuilderInputWithBrowse: FC<React.PropsWithChildren<GenericInput>> = ({
  label,
  inputName,
  setFieldValue,
  setFieldTouched,
  submitForm,
  isRequired,
  updateErrors,
  children,
  field: builderField,
}) => {
  const t = useTranslate();
  return (
    <>
      <FieldName value={t(label)} isRequired={isRequired}></FieldName>
      <Field name={GENERAL_LINK.ID}>
        {(props: FormikFieldProps<string>) => (
          <PathInput
            {...props}
            builderField={builderField}
            input={inputName}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            submitForm={submitForm}
            updateErrors={updateErrors}
          >
            {children}
          </PathInput>
        )}
      </Field>
    </>
  );
};
