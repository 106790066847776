import { FC } from 'react';
import { NUM_OF_COLS_TABLE } from 'src/constants';
import { LoadingRow } from 'src/features/shared/loadingRow/LoadingRow';
import { ShowOptions } from 'src/features/shared/treeTable/hooks/useOptions';

type LoadingRowProps = { subRowLvl: number; itemsLength: number } & ShowOptions;

export const TreeTableLoadingRow: FC<LoadingRowProps> = ({ subRowLvl, itemsLength, showLanguages, showWorkflow }) => {
  const numberOfCols = NUM_OF_COLS_TABLE - +!showLanguages - +!showWorkflow;
  const nameCellPadding = `${(subRowLvl + 1) * 1.5 + 1}rem`;

  return <LoadingRow firstCellPadding={nameCellPadding} numberOfCols={numberOfCols} numberOfRows={itemsLength > 0 ? 1 : 3} />;
};
