import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useFeature } from 'src/features/common/flags/features';
import { SearchInput, SearchTypeFilter } from 'src/features/search/components';

export const SearchContainer: FC = () => {
  const xm_searchTableView = useFeature('xm_searchTableView');

  if (!xm_searchTableView) {
    return <></>;
  }

  return (
    <Flex gap="2">
      <SearchInput />
      <SearchTypeFilter />
    </Flex>
  );
};
