import { useEffect } from 'react';
import { TokenCustomClaimKeysEnum } from 'src/features/common/auth/SitecoreUser';
import { useAuthWithClaims } from 'src/features/common/auth/useAuthWithClaims';

export const useGainsighSetIdentifyFields = () => {
  const global = window as any;
  const { user } = useAuthWithClaims();
  const script = document.getElementById('gainsight');

  useEffect(() => {
    if (!user || !script) return;

    const accountFields = { id: user[TokenCustomClaimKeysEnum.ORG_ACC_ID] };
    const userFields = {
      id: user.email,
      email: user.email,
      firstName: user.given_name,
      lastName: user.family_name,
      lastLogin: new Date(user.lastLogin).valueOf(),
      signUpDate: new Date(user.created).valueOf(),
    };

    global.aptrinsic?.('identify', userFields, accountFields);
    global.identifyInitialized = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, script]);
};
