import { useCallback, useState } from 'react';
import { SECTIONS_ERRORS_STORAGE_KEY } from 'src/constants';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { FieldValueInput } from 'src/features/common/models';
import { BuilderField, FieldActions, ValidationErrors } from '../models';
import { useUpdateItemField } from './useUpdateItemField';

export const useFieldActions = (field: BuilderField): FieldActions => {
  const [builderField, setBuilderField] = useState<BuilderField>(field);

  const { sc_itemid, sc_lang, sc_version } = useUrlStrictContext(UrlParams.sc_itemid | UrlParams.sc_lang | UrlParams.sc_version);
  const { mutateAsync } = useUpdateItemField();

  const updateErrors = useCallback(
    (shouldAddError: boolean) => {
      const data = sessionStorage.getItem(SECTIONS_ERRORS_STORAGE_KEY);
      const errors = (data ? JSON.parse(data) : []) as ValidationErrors[];

      const index = errors.findIndex((r) => r.sectionName === builderField.section.name);

      if (index >= 0 && shouldAddError) {
        if (!errors[index].fields.includes(builderField.fieldId)) {
          errors[index].fields.push(builderField.fieldId);
        }
      } else if (index < 0 && shouldAddError) {
        errors.push({ sectionName: builderField.section.name, fields: [builderField.fieldId] });
      } else if (index >= 0 && !shouldAddError) {
        const fieldIndex = errors[index].fields.findIndex((r) => r === builderField.fieldId);

        if (fieldIndex >= 0) {
          errors[index].fields.splice(fieldIndex, 1);
        }

        if (errors[index].fields.length === 0) {
          errors.splice(index, 1);
        }
      }

      sessionStorage.setItem(SECTIONS_ERRORS_STORAGE_KEY, JSON.stringify(errors));
      window.dispatchEvent(new Event('session-storage'));
    },
    [builderField],
  );

  const sendRequest = useCallback(
    async (field: FieldValueInput) => {
      const { data } = await mutateAsync({
        itemId: sc_itemid,
        language: sc_lang,
        version: sc_version,
        fields: [field],
      });

      const result = data.data.updateItem?.item.field;
      const error = result?.validation?.[0]?.results?.nodes?.[0]?.message;

      setBuilderField((state) => ({ ...state, ...result, error }));
      updateErrors(!!error);

      return result;
    },
    [sc_itemid, sc_lang, sc_version, mutateAsync, setBuilderField, updateErrors],
  );

  const resetField = useCallback(async (fieldId: string) => sendRequest({ name: fieldId, value: '', reset: true }), [sendRequest]);
  const updateField = useCallback(
    async (fieldId: string, value: string) => sendRequest({ name: fieldId, value, reset: false }),
    [sendRequest],
  );

  return { builderField, updateField, resetField, updateErrors };
};
