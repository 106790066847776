import { Box, Link } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { useCreateItemToastDescription } from 'src/features/shared/createItemToastDescription/hooks/useCreateItemToastDescription';

type CreateItemToastDescriptionProps = {
  itemId: string;
  name: string;
};

export const CreateItemToastDescription: FC<CreateItemToastDescriptionProps> = ({ itemId, name }) => {
  const { href } = useCreateItemToastDescription(itemId);

  return (
    <>
      <Box>
        <T _str={DICTIONARY.NOTIFICATIONS.CREATE_ITEM} name={name} />
      </Box>
      <Link data-testid="open-created-item" href={href} target="_blank">
        <T _str={DICTIONARY.VIEW_ITEM} />
      </Link>
    </>
  );
};
