import { EditorConfig } from '@ckeditor/ckeditor5-core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { GenericFieldView } from 'src/features/fieldBuilder/components/fields/GenericFieldView';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { GenericField } from 'src/features/fieldBuilder/models';
import 'src/features/fieldBuilder/styles/ckeditor.scss';

const errorClassName = 'sc-field-error';

export const RichTextEditor: FC<GenericField> = ({ field }) => {
  const [value, setValue] = useState(field.value);
  const { builderField, updateField, resetField } = useFieldActions(field);
  const [ckEditor, setCkEditor] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ckEditor) {
      return;
    }

    if (builderField.error) {
      ckEditor.classList.add(errorClassName);
    } else {
      ckEditor.classList.remove(errorClassName);
    }
  }, [builderField.error, ckEditor]);

  const onReset = useCallback(async () => {
    const fieldUpdated = await resetField(field.fieldId);
    fieldUpdated && setValue(fieldUpdated?.value);
  }, [field.fieldId, resetField]);

  const onUpdate = useCallback(
    (data: string) => {
      updateField(field.fieldId, data);
      setValue(data);
    },
    [field.fieldId, updateField],
  );

  const editorConfiguration: EditorConfig = useMemo(
    () => ({
      toolbar: {
        items: [
          'undo',
          'redo',
          '|',
          'link',
          'insertImage',
          'insertTable',
          '|',
          // 'wproofreader',
          'findAndReplace',
          '|',
          'sourceEditing',
          '-',
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          {
            label: 'Formatting',
            icon: 'text',
            items: [
              'bulletedList',
              'numberedList',
              '|',
              'outdent',
              'indent',
              '|',
              'strikethrough',
              'superscript',
              'subscript',
              '|',
              'removeFormat',
            ],
          },
          '|',
          'alignment',
          'horizontalLine',
        ],
        shouldNotGroupWhenFull: true,
      },
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
          { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
          { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
        ],
      },
    }),
    [],
  );

  return (
    <GenericFieldView field={builderField} onReset={onReset}>
      <CKEditor
        editor={Editor}
        config={editorConfiguration}
        data={value}
        disabled={!field.canEdit}
        onBlur={(event, editor) => onUpdate(editor.data.get())}
        onReady={(editor) => setCkEditor((editor as any).sourceElement.nextSibling)}
      />
    </GenericFieldView>
  );
};
