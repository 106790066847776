import { FC, useCallback, useState } from 'react';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { GenericField } from 'src/features/fieldBuilder/models';
import { convertToIsoFormat, convertToLocale } from 'src/features/fieldBuilder/utils/date';
import { DatePicker } from 'src/features/temporary/DatePicker/DatePicker';
import './Datepicker.scss';
import { GenericFieldView } from './GenericFieldView';

export const Datepicker: FC<GenericField> = ({ field }) => {
  const [date, setDate] = useState(convertToLocale(field.value));
  const { builderField, updateField, resetField } = useFieldActions(field);

  const onReset = useCallback(async () => {
    const value = await resetField(field.fieldId);

    value && setDate(convertToLocale(value.value));
  }, [field.fieldId, resetField, setDate]);

  const onChange = useCallback(
    (date: Date) => {
      updateField(field.fieldId, convertToIsoFormat(date));
      setDate(date);
    },
    [field.fieldId, updateField],
  );

  return (
    <GenericFieldView field={builderField} onReset={onReset}>
      <DatePicker
        ariaInvalid={builderField.error}
        clearButtonClassName="clear-button"
        popperClassName="date-popover"
        disabled={!field.canEdit}
        onChange={onChange}
        isClearable={field.canEdit}
        selected={date}
        showPopperArrow={false}
      />
    </GenericFieldView>
  );
};
