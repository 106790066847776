import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { CreateItemModal } from 'src/features/shared/createItem/components';
import { CreateItemButtonProps } from 'src/features/shared/createItem/models';

export const CreateItemButton: FC<CreateItemButtonProps> = (props) => {
  const disclosure = useDisclosure();

  const handleOpenModal = (e: React.MouseEvent) => {
    disclosure.onOpen();
    e.stopPropagation();
  };

  return (
    <>
      <MenuItem onClick={(e) => handleOpenModal(e)} isDisabled={props.isDisabled}>
        <T
          _str={props.type === 'child' ? DICTIONARY.CREATE_CHILD : DICTIONARY.CREATE_SIBLING}
          insertOptionsName={props.option.name.toLowerCase()}
        />
      </MenuItem>
      {disclosure.isOpen && <CreateItemModal {...disclosure} {...props} />}
    </>
  );
};
