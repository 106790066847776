import { useMemo } from 'react';
import { SearchCriteriaInput, SearchCriteriaType, SearchOperator } from 'src/features/search/models';
import { FILTER_FIELDS, QUERIES_NAMES } from 'src/features/search/searchConstants';

export const useSiteFilterCriteria = (params: URLSearchParams): SearchCriteriaInput[] | undefined => {
  const sites = useMemo(() => params.get(QUERIES_NAMES.SITES)?.split(',') || [], [params]);

  return useMemo<SearchCriteriaInput[] | undefined>(() => {
    if (!sites.length) return undefined;

    return sites.map<SearchCriteriaInput>((site) => ({
      field: FILTER_FIELDS.SITE,
      value: site.toLowerCase(),
      criteriaType: SearchCriteriaType.EXACT,
      operator: SearchOperator.SHOULD,
    }));
  }, [sites]);
};
