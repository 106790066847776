import * as React from 'react';

/**
 * React Datepicker
 */
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/**
 * Interfaces
 */
import { mdiCalendarOutline } from '@mdi/js';
import { Icon } from 'src/features/common/components/icon/Icon';
import type { DatePickerProps } from './model';

/**
 * Sitecore UI DatePicker components
 */
export const DatePicker: React.FC<DatePickerProps> = (props) => {
  return <ReactDatePicker {...props} shouldCloseOnSelect={props.shouldCloseOnSelect || false} customInput={<DatePickerInput />} />;
};

const DatePickerInput = React.forwardRef((props: any, ref: React.LegacyRef<HTMLDivElement> | undefined) => (
  <InputGroup ref={ref} onClick={props.onClick}>
    <Input value={props.value} isInvalid={!!props['aria-invalid']} onChange={props.onChange} />
    <InputRightElement>
      <Icon path={mdiCalendarOutline} />
    </InputRightElement>
  </InputGroup>
));

/**
 * Sitecore UI DatePicker component default props
 */
DatePicker.defaultProps = {};
/**
 * Sitecore UI DatePicker components display names
 */
DatePicker.displayName = 'DatePicker';

DatePickerInput.displayName = 'DatePickerInput';
