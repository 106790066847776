import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { AxiosResponse } from 'axios';
import { FC, useCallback, useRef } from 'react';
import { DICTIONARY, MASTER_DB } from 'src/constants';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { DeleteItemVersionResponse, GraphQLResponse } from 'src/features/common/models';
import { useRefetchFieldEditorData } from 'src/features/fieldEditor/hooks/useRefetchFieldEditorData';
import { useUpdateMillerColumns } from 'src/features/millerColumns/hooks/useUpdateMillerColumns';
import { useDeleteItemVersion } from 'src/features/versions/hooks/useDeleteItemVersion';

export interface DeleteVersionAlertDialogProps {
  version: number;
  isOpen: any;
  onClose: any;
}

export const DeleteVersionAlertDialog: FC<DeleteVersionAlertDialogProps> = ({ version, isOpen, onClose }) => {
  const { sc_itemid, sc_lang, sc_version, setUrlContext } = useUrlStrictContext();
  const { mutate: deleteItemVersion } = useDeleteItemVersion();
  const updateEditor = useRefetchFieldEditorData();
  const refreshColumns = useUpdateMillerColumns();
  const current = useRef<HTMLButtonElement>(null);

  const handleDelete = useCallback(() => {
    deleteItemVersion(
      {
        itemId: sc_itemid,
        database: MASTER_DB,
        language: sc_lang,
        version: version,
      },
      {
        onSuccess(data: AxiosResponse<GraphQLResponse<DeleteItemVersionResponse>>) {
          const item = data.data.data.deleteItemVersion.item;

          if (item === null && !sc_version) {
            updateEditor([sc_itemid, sc_lang, '0']);
          } else if (item && item.version === sc_version) {
            updateEditor([sc_itemid, sc_lang, item.version.toString()]);
          } else {
            setUrlContext({ sc_version: item ? item.version : undefined });
          }

          refreshColumns();
        },
      },
    );

    onClose();
  }, [deleteItemVersion, updateEditor, sc_itemid, sc_lang, version, onClose, setUrlContext, sc_version, refreshColumns]);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={current} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <AlertDialogHeader>
              <T _str={DICTIONARY.DELETE_VERSION} />
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                <T _str={DICTIONARY.DELETE_MESSAGE} version={version} />
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button data-testid="cancel-btn" variant="ghost" ref={current} onClick={onClose}>
                  <T _str={DICTIONARY.CANCEL} />
                </Button>
                <Button data-testid="submit-btn" colorScheme="danger" onClick={handleDelete} type="submit">
                  <T _str={DICTIONARY.DELETE} />
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
