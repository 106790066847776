import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptions } from 'react-query';
import { CLIENT_LANGUAGE } from 'src/constants';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { DetailedItem, GraphQLResponse, ItemResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { getLinkedItemQueryString } from '../queries';

type LinkedResponseGraphQLResponse = GraphQLResponse<ItemResponse | undefined>;
type LinkedItemResponseResponse = AxiosResponse<LinkedResponseGraphQLResponse>;

type GetLinkedItemData = {
  item: Pick<DetailedItem, 'displayName'> | undefined;
  isFetching: boolean;
};

const useGetLinkedItem = (
  input: { id: string; language: string },
  useQueryOptions?:
    | Omit<UseQueryOptions<LinkedItemResponseResponse, unknown, LinkedItemResponseResponse, string[]>, 'queryKey' | 'queryFn'>
    | undefined,
): GetLinkedItemData => {
  const { url } = useXMTenant();
  const toast = useToast();

  const { data, isFetching } = useAuthQuery(
    ['get-linked-item', input.id, input.language],
    async (axiosInstance) => {
      return !input.id
        ? new Promise((r) => r({ data: { data: undefined } } as AxiosResponse<LinkedResponseGraphQLResponse>))
        : axiosInstance?.post<LinkedResponseGraphQLResponse>(getSitecoreGraphQLEndpoint(url), {
            query: getLinkedItemQueryString,
            variables: {
              itemId: input.id,
              language: input.language,
            },
          });
    },

    { ...useQueryOptions },
    toast,
  );

  const item = useMemo(() => {
    const sitecoreItem = data?.data?.data?.item;

    return sitecoreItem ? { ...sitecoreItem } : undefined;
  }, [data]);

  return { item, isFetching };
};

export const useGetLinkedItemWrapper = (itemId: string, enabled: boolean, language?: string | null) =>
  useGetLinkedItem({ id: itemId, language: language ?? CLIENT_LANGUAGE }, { enabled, keepPreviousData: true });
