import { MenuItem } from '@chakra-ui/react';
import { iconPages } from '@sitecore/blok-theme';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { SelectContextItem } from 'src/features/common/models';
import { useOpenInPagesButton } from 'src/features/fieldEditor/hooks/useOpenInPagesButton';

export const OpenInPagesMenuButton: FC<SelectContextItem> = (contextItem) => {
  const openPages = useOpenInPagesButton(contextItem);

  return (
    <MenuItem onClick={openPages} icon={<Icon path={iconPages} />} data-testid="pages-link">
      <T _str={DICTIONARY.OPEN_IN_PAGES} />
    </MenuItem>
  );
};
