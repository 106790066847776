import { FlexProps, useToast } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { DICTIONARY, MEDIA_LIBRARY_PATH, UPLOAD_MAX_SIZE } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useUploadMedia } from 'src/features/media/hooks/useUploadMedia';
import { UseUploadAreaProps } from 'src/features/media/models';
import { useSelectMediaContext } from 'src/features/shared/selectMediaModal/components/SelectMediaContext';

export const DROP_AREA_CLASS_NAME = 'sc-image-drop-area-active';

export const useUploadArea = ({ type, mediaId, uploadPath, allowedExtensions, onSelect, onUpload, sourceIds }: UseUploadAreaProps) => {
  const { mutateAsync: uploadMedia, isLoading } = useUploadMedia();
  const { selectMedia } = useSelectMediaContext();
  const toast = useToast();
  const t = useTranslate();
  const uploadRelatedPath = useMemo(
    () => (uploadPath && uploadPath.startsWith(MEDIA_LIBRARY_PATH) ? uploadPath.substring(MEDIA_LIBRARY_PATH.length + 1) : ''),
    [uploadPath],
  );

  const isValidMedia = useCallback(
    (file: File) => {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);

      const isValidExtension = allowedExtensions.length === 0 || allowedExtensions.includes(fileExtension.toLocaleLowerCase());
      const isValidSize = file.size <= UPLOAD_MAX_SIZE;

      if (!isValidExtension) {
        toast({ status: 'error', description: t(DICTIONARY.NOTIFICATIONS.MEDIA_TYPE_NOT_SUPPORTED) });
        return isValidExtension;
      }

      if (!isValidSize) {
        toast({ status: 'error', description: t(DICTIONARY.NOTIFICATIONS.MEDIA_INVILID_SIZE) });
        return isValidSize;
      }

      return true;
    },
    [allowedExtensions, toast, t],
  );

  const uploadMediaData = useCallback(
    async (file: File) => {
      if (!file || !isValidMedia(file)) return;

      const alt = file.name.substring(0, file.name.lastIndexOf('.'));
      const result = await uploadMedia({ alt, file, uploadPath: uploadRelatedPath });

      if (result.Message) {
        toast({ status: 'error', description: result.Message });
        return;
      }

      toast({ status: 'success', description: t(DICTIONARY.NOTIFICATIONS.MEDIA_UPLOADED) });
      onUpload(result);
    },
    [uploadRelatedPath, onUpload, uploadMedia, isValidMedia, toast, t],
  );

  const onMediaSelect = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || !event.target.files.length) {
        return;
      }

      uploadMediaData(event.target.files[0]);
    },
    [uploadMediaData],
  );

  const onMediaDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.currentTarget.classList.remove(DROP_AREA_CLASS_NAME);
      event.preventDefault();

      if (!event.dataTransfer || !event.dataTransfer.files.length) {
        return;
      }

      uploadMediaData(event.dataTransfer.files[0]);
    },
    [uploadMediaData],
  );

  const onMediaModalOpen = useCallback(() => {
    selectMedia({
      selectType: type,
      onSelected: onSelect,
      rootItemIdOrPath: uploadPath,
      sourceIds,
      preSelectedMediaId: mediaId,
    });
  }, [selectMedia, type, onSelect, uploadPath, sourceIds, mediaId]);

  const dropEvents = useMemo<FlexProps>(
    () => ({
      onDrop: (event) => onMediaDrop(event),
      onDragOver: (event) => event.preventDefault(),
      onDragEnter: (event) => event.currentTarget.classList.add(DROP_AREA_CLASS_NAME),
      onDragLeave: (event) => event.currentTarget.classList.remove(DROP_AREA_CLASS_NAME),
    }),
    [onMediaDrop],
  );

  return { isLoading, dropEvents, onMediaSelect, onMediaModalOpen };
};
