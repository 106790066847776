/* istanbul ignore file */

import { useMemo } from 'react';
import { XA_ADVANCED_ITEMS_ID, XA_DICTIONARY_ID } from 'src/constants';
import { useDisplayOptions } from 'src/features/millerColumns/hooks/useDisplayOptions';
import { useShowOptions } from 'src/features/millerColumns/hooks/useShowOptions';

export const useOptions = () => {
  const { showLanguages, showThumbnail, showWorkflow } = useDisplayOptions();
  const { showAdvancedItems, showHiddenItems, showTemplateSites } = useShowOptions();
  const excludeIDs = useMemo(() => (showAdvancedItems ? [] : [XA_ADVANCED_ITEMS_ID, XA_DICTIONARY_ID]), [showAdvancedItems]);

  return {
    showLanguages,
    showThumbnail,
    showWorkflow,
    showAdvancedItems,
    showHiddenItems,
    showTemplateSites,
    advancedItemsExcludeIds: excludeIDs,
  };
};

export type ShowOptions = ReturnType<typeof useOptions>;
