export class FileValue {
  private document: Document = new Document();

  constructor(value?: string) {
    this.document = new DOMParser().parseFromString(value || '<file />', 'text/xml');
  }

  private getValue(attributeName: string): string {
    return this.document.firstElementChild?.getAttribute(attributeName) ?? '';
  }

  private setValue(attributeName: string, value: string) {
    if (!this.document.firstElementChild) {
      const imageNode = this.document.createElement('file');
      this.document.appendChild(imageNode);
    }

    this.document.firstElementChild?.setAttribute(attributeName, value);
  }

  get mediaid(): string {
    return this.getValue('mediaid');
  }
  set mediaid(value: string) {
    this.setValue('mediaid', value);
  }

  get src(): string {
    return this.getValue('src');
  }
  set src(value: string) {
    this.setValue('src', value);
  }

  public toString: () => string = () => new XMLSerializer().serializeToString(this.document);
}
