import { useCallback } from 'react';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { NestedItems } from 'src/features/common/models';
import { ActionsMenuCallbacks } from 'src/features/fieldBuilder/models';
import { useClearMillerColumnsCache } from 'src/features/millerColumns/hooks/useClearMillerColumnsCache';
import { useUpdateMillerColumns } from 'src/features/millerColumns/hooks/useUpdateMillerColumns';

export const useActionsMenuFieldEditorWrapper = (): ActionsMenuCallbacks => {
  const { setUrlContext } = useUrlSharing(UrlParams.None);
  const refreshColumns = useUpdateMillerColumns();
  const clearCache = useClearMillerColumnsCache();

  const onDuplicateItemSuccess = useCallback(
    async (item: NestedItems) => {
      await refreshColumns();
      setUrlContext({ sc_itemid: item.itemId, sc_version: undefined });
    },
    [refreshColumns, setUrlContext],
  );

  const onDeleteItemSuccess = useCallback(
    (parentItemId: string) => {
      clearCache();
      setUrlContext({ sc_itemid: parentItemId, sc_version: undefined });
    },
    [clearCache, setUrlContext],
  );

  const onDeleteItemError = useCallback(
    (parentItemId: string) => {
      clearCache();
      setUrlContext({ sc_itemid: parentItemId });
    },
    [clearCache, setUrlContext],
  );

  return { onDuplicateItemSuccess, onDeleteItemSuccess, onDeleteItemError };
};
