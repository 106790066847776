import { Box, useBreakpointValue } from '@chakra-ui/react';
import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { AdaptiveView } from 'src/features/common/models/adaptiveView.model';
import { MillerColumnsProvider } from './MillerColumnsProvider';
import { MillerColumnsProps } from './models';
import { MillerColumnData, MillerColumnSelection, SelectionButtonColorsProps } from './models/MillerColumn.model';
import { MilllerColumnsView } from './models/MillerColumns.model';

const defaultViewConfig = { columnWidth: 300, containerWidth: 900 } as MilllerColumnsView;
const defaultSelectionProps = { primaryColor: 'primary.100', secondaryColor: 'gray.100' } as SelectionButtonColorsProps;
const defaultViewConfigSet = { base: defaultViewConfig } as Partial<AdaptiveView<MilllerColumnsView>>;

export const MillerColumns = <T,>({
  onColumnItemClick,
  selectionsMap,
  children,
  columnsProps = {},
  selectionButtonStylesProps = {},
  columnsView = defaultViewConfigSet,
  selectionButtonColorProps = defaultSelectionProps,
  doClick,
}: MillerColumnsProps<T>) => {
  const columnsRef = useRef<HTMLDivElement>();
  const adaptiveView = useBreakpointValue(columnsView, 'base') || defaultViewConfig;
  const columnsScrollLength = useMemo(() => Math.floor(adaptiveView.containerWidth / adaptiveView.columnWidth) - 2, [adaptiveView]);

  const scrollLeft = useCallback(
    (columnLength: number) => {
      if (columnsRef.current) {
        columnsRef.current.scrollLeft = columnLength * adaptiveView.columnWidth;
      }
    },
    [adaptiveView.columnWidth],
  );

  const scrollWithAction = useCallback(
    (columnLength: number, action: () => void) => {
      const scroll = columnLength * adaptiveView.columnWidth;
      const finalScrollPos = scroll < adaptiveView.columnWidth ? 0 : scroll;
      scrollLeft(columnLength);

      const timer = setInterval(() => {
        if (columnsRef.current && (columnsRef.current.scrollLeft === finalScrollPos || columnsRef.current.scrollLeft < 0)) {
          clearInterval(timer);
          action();
        }
      });
    },
    [columnsRef, adaptiveView.columnWidth, scrollLeft],
  );

  useEffect(() => scrollLeft(children.length - 1), [children.length, scrollLeft]);

  const onClick = useCallback(
    (data: MillerColumnData<unknown>, selection: MillerColumnSelection, map: MillerColumnSelection[], isAdded: boolean) => {
      if (isAdded || columnsScrollLength < 1) {
        onColumnItemClick && onColumnItemClick(data as MillerColumnData<T>, selection, map, isAdded);
      } else {
        scrollWithAction(selection.columnIndex - columnsScrollLength, () => {
          onColumnItemClick && onColumnItemClick(data as MillerColumnData<T>, selection, map, isAdded);
        });
      }
    },
    [columnsScrollLength, onColumnItemClick, scrollWithAction],
  );

  return (
    <Box
      scrollBehavior="smooth"
      display="flex"
      w="auto"
      h="full"
      maxW={adaptiveView.containerWidth}
      overflow="auto"
      ref={columnsRef as RefObject<HTMLDivElement>}
    >
      <MillerColumnsProvider
        onColumnItemClick={onClick}
        selectionsMap={selectionsMap}
        viewConfig={adaptiveView}
        columnsProps={columnsProps}
        selectionButtonColorProps={selectionButtonColorProps}
        selectionButtonStylesProps={selectionButtonStylesProps}
        doClick={doClick}
      >
        {children}
      </MillerColumnsProvider>
    </Box>
  );
};
