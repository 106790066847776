import { Box, Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { GenericFieldView } from 'src/features/fieldBuilder/components/fields/GenericFieldView';
import { GenericField } from 'src/features/fieldBuilder/models';
import { CleanFieldButton } from 'src/features/shared/cleanFieldButton/CleanFieldButton';
import { SelectedItemCard } from 'src/features/shared/selectedItemCard/SelectedItemCard';
import { SelectedItemView } from 'src/features/shared/selectedItemCard/SelectedItemView';
import { useDroptree } from './hooks';

export const Droptree: FC<GenericField> = ({ field }) => {
  const { error, onReset, onSelectItemButtonClick, onClean, selectedItemViewText, builderField } = useDroptree(field);

  return (
    <GenericFieldView field={builderField} errorMsg={error} onReset={onReset}>
      <SelectedItemCard
        isDisabled={!field.canEdit}
        actions={!error && builderField.canEdit && builderField.value && <CleanFieldButton onClean={onClean} />}
      >
        <SelectedItemView text={selectedItemViewText} />
      </SelectedItemCard>
      <Box>
        {!error && field.canEdit && (
          <Button onClick={onSelectItemButtonClick} size="sm" variant="solid" marginTop="1" data-testid="select-item">
            <T _str={DICTIONARY.SELECT_ITEM} />
          </Button>
        )}
      </Box>
    </GenericFieldView>
  );
};
