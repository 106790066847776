import { Box, Flex, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';

export const NoFilesInFolder: FC = () => {
  return (
    <Flex gap="3" direction="column" alignItems="center">
      <Box height="20" />
      <object
        data="https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-cactus-neutral"
        type="image/svg+xml"
        aria-label={DICTIONARY.NO_FILES_IN_FOLDER}
        width="80px"
        height="80px"
      />
      <Text fontWeight="semibold" fontSize="xl">
        <T _str={DICTIONARY.NO_FILES_IN_FOLDER} />
      </Text>
    </Flex>
  );
};
