import { Checkbox, CheckboxGroup, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { GenericFieldView } from 'src/features/fieldBuilder/components/fields/GenericFieldView';
import { useChecklist } from 'src/features/fieldBuilder/components/fields/hooks';
import { GenericField } from 'src/features/fieldBuilder/models';
import { formatIdWithBraces } from 'src/features/fieldBuilder/utils/source';

export const Checklist: FC<GenericField> = ({ field }) => {
  const { builderField, selections, source, error, onUpdate, onReset } = useChecklist(field);

  return (
    <GenericFieldView field={builderField} errorMsg={error ? DICTIONARY.FIELD_DATASOURCE_OR_RESULT_EMPTY : ''} onReset={onReset}>
      <CheckboxGroup value={selections} onChange={onUpdate}>
        <Flex gap={3} direction="column" maxH="40" overflowY="auto" ml="-1" pl="1">
          {source.map(({ itemId, displayName, path }) => (
            <Checkbox key={`${field.fieldId}-${itemId}`} value={formatIdWithBraces(itemId)} title={path} isDisabled={!field.canEdit}>
              {displayName}
            </Checkbox>
          ))}
        </Flex>
      </CheckboxGroup>
    </GenericFieldView>
  );
};
