import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MASTER_DB } from 'src/constants';
import { MenuWithSearchOption } from 'src/features/common/components/menuWithSearch/models/menuWithSearch.model';
import { useGetLanguages } from 'src/features/common/hooks/useGetLanguages';
import { LanguageItem } from 'src/features/common/models';
import { useCommonFilter } from 'src/features/search/hooks/useCommonFilter';
import { QUERIES_NAMES } from 'src/features/search/searchConstants';

export const useLanguageFilter = () => {
  const { data } = useGetLanguages(MASTER_DB);
  const [params] = useSearchParams();
  const { onFilterChange } = useCommonFilter(QUERIES_NAMES.LANGUAGES);

  const languages = useMemo(() => {
    const languagesData = data?.data?.data?.languages?.nodes;
    if (!languagesData) return [];

    const langs = params.get(QUERIES_NAMES.LANGUAGES);
    const filterLanguages = langs?.split(',') ?? [];

    return languagesData.map<MenuWithSearchOption>((language: LanguageItem) => ({
      checked: filterLanguages.includes(language.name),
      label: language.englishName,
      value: language.name,
    }));
  }, [data?.data?.data?.languages?.nodes, params]);

  return { languages, onFilterChange };
};
