import { useMemo } from 'react';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { useGetSites } from 'src/features/shared/treeTable/hooks/useGetSites';
import { TreeListItem } from 'src/features/shared/treeTable/models';
import { formatSites } from 'src/features/shared/treeTable/utils/formatters';
import { SortingOrder, treeItemFilter } from 'src/features/shared/treeTable/utils/treeItemFilter';

export const useGetSitesViewModel = (showTemplateSites: boolean, orderField?: keyof TreeListItem, sortingOrder?: SortingOrder) => {
  const { sc_collection, sc_type } = useUrlStrictContext(UrlParams.sc_collection | UrlParams.sc_type);
  const { data, isLoading } = useGetSites({ keepPreviousData: true });

  const sites = data?.data?.data?.solutionSites;

  const tableListItems: TreeListItem[] = useMemo((): TreeListItem[] => {
    if (!sites || !sites.length) return [];

    const formattedSites = formatSites(sites, { showTemplateSites, sc_collection, sc_type });
    return treeItemFilter(formattedSites, orderField, sortingOrder);
  }, [orderField, sc_collection, sc_type, showTemplateSites, sites, sortingOrder]);

  return {
    sites: tableListItems,
    isLoading,
  };
};
