import { T } from '@transifex/react';
import moment from 'moment';
import { useMemo } from 'react';
import { DATETIME_FORMAT, DICTIONARY, EMPTY_VALUE, TEMPLATE_FOLDER } from 'src/constants';
import { useGetXMCLink } from 'src/features/common/config';
import { useGetSitesWrapper } from 'src/features/common/hooks/useGetSitesWrapper';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { DetailedItem } from 'src/features/common/models';
import { ItemType } from 'src/features/millerColumns/models';
import { DetailProperty } from './DetailProperty';

export interface ItemDetailsProps {
  item: DetailedItem | undefined;
}

export const ItemDetails = ({ item }: ItemDetailsProps) => {
  const { sites } = useGetSitesWrapper();
  const dateOfCreation = item !== null ? moment(item?.createdAt?.value, true).format(DATETIME_FORMAT) : EMPTY_VALUE;
  const dateOfUpdate = item !== null ? moment(item?.createdAt?.value, true).format(DATETIME_FORMAT) : EMPTY_VALUE;
  const siteName = useMemo(() => sites.find((site) => item?.path.startsWith(site.rootItem.path))?.name, [sites, item?.path]);
  const itemType = useMemo<ItemType>(() => {
    if (item?.template.isFolder || item?.template.name === TEMPLATE_FOLDER) {
      return 'folder';
    } else if (item?.template.isSite) {
      return 'site';
    } else {
      return 'content';
    }
  }, [item?.template.isSite, item?.template.isFolder, item?.template.name]);

  const { urlContext } = useUrlSharing(UrlParams.sc_lang);
  const lang = urlContext.sc_lang || 'en';

  const templateUrl = useGetXMCLink({ params: { sc_bw: 1, fo: item?.template.templateId, lang } });

  return (
    <>
      <DetailProperty heading={DICTIONARY.ITEM_ID} value={item?.itemId || <T _str={EMPTY_VALUE} />} pt="0" />
      <DetailProperty heading={DICTIONARY.ITEM_NAME} value={item?.name || <T _str={EMPTY_VALUE} />} />
      <DetailProperty heading={DICTIONARY.SITE} value={siteName || <T _str={EMPTY_VALUE} />} />
      <DetailProperty heading={DICTIONARY.ITEM_PATH} value={item?.path || <T _str={EMPTY_VALUE} />} />
      <DetailProperty heading={DICTIONARY.TYPE} value={itemType || <T _str={EMPTY_VALUE} />} valueStyle={{ textTransform: 'capitalize' }} />
      <DetailProperty
        heading={DICTIONARY.TEMPLATE}
        value={item ? `${item?.template.fullName} - ${item?.template.templateId}` : <T _str={EMPTY_VALUE} />}
        href={templateUrl}
        linkStyle={{ isExternal: true }}
      />
      <DetailProperty heading={DICTIONARY.HEADER.CREATED} value={item !== null ? item?.createdBy?.value : <T _str={EMPTY_VALUE} />} />
      <DetailProperty heading={DICTIONARY.CREATED_OM} value={dateOfCreation} />
      <DetailProperty heading={DICTIONARY.HEADER.UPDATED} value={item !== null ? item?.updatedBy?.value : <T _str={EMPTY_VALUE} />} />
      <DetailProperty heading={DICTIONARY.LAST_MODIFIED_ON} value={dateOfUpdate} />
      <DetailProperty
        heading={DICTIONARY.WORKFLOW}
        value={item?.workflow?.workflowState?.displayName || <T _str={DICTIONARY.NO_WORKFLOW} />}
      />
    </>
  );
};
