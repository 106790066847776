import { IconButton, Menu, MenuButton, MenuDivider, MenuList, Tooltip } from '@chakra-ui/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { FC, useMemo } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { SelectContextItem } from 'src/features/common/models';
import { ActionsMenuProps } from 'src/features/fieldBuilder/models';
import { DeleteButton } from './delete/DeleteButton';
import { DuplicateButton } from './duplicate/DuplicateButton';
import { OpenInPagesMenuButton } from './pagesMenuButton/OpenInPagesMenuButton';
import { RenameMenuItem } from './rename/RenameMenuItem';

export const ActionsMenu: FC<ActionsMenuProps> = ({
  hasPresentation,
  canCreate,
  canDelete,
  canRename,
  canWrite,
  canWriteDisplayName,
  itemId,
  name,
  version,
  displayName,
  hasChildren,
  parentItemId,
  shouldRenderRenameButton,
  onDuplicateItemSuccess,
  onDeleteItemSuccess,
  onDeleteItemError,
  onRenameSuccess,
  ...props
}) => {
  const { sc_isSite, sc_lang, sc_site } = useUrlStrictContext(UrlParams.sc_itemid | UrlParams.sc_site | UrlParams.sc_lang);
  const shouldRenderDeleteButton = useMemo(() => canDelete && !sc_isSite, [sc_isSite, canDelete]);
  const shouldRenderDuplicateButton = useMemo(() => canCreate && !sc_isSite, [sc_isSite, canCreate]);
  const t = useTranslate();

  const shellContext = useMemo<SelectContextItem>(
    () => ({
      itemId,
      language: sc_lang,
      siteName: sc_site,
    }),
    [itemId, sc_lang, sc_site],
  );

  return (
    <Menu isLazy lazyBehavior="keepMounted">
      <Tooltip label={t(DICTIONARY.OPTIONS)}>
        <MenuButton data-testid="item-options-menu" as={IconButton} size="sm" icon={<Icon path={mdiDotsHorizontal} />} {...props} />
      </Tooltip>
      <MenuList>
        {shouldRenderRenameButton && (
          <RenameMenuItem
            itemId={itemId}
            name={name}
            canRename={canRename || false}
            canWrite={canWrite || false}
            displayName={displayName || ''}
            canWriteDisplayName={canWriteDisplayName || false}
            onRenameSuccess={onRenameSuccess}
            version={version || 0}
          />
        )}
        {shouldRenderDuplicateButton && itemId && (
          <DuplicateButton name={name} itemId={itemId} onDuplicateItemSuccess={onDuplicateItemSuccess} />
        )}
        {shouldRenderDeleteButton && itemId && (
          <DeleteButton
            name={name}
            itemId={itemId}
            hasChildren={hasChildren}
            parentItemId={parentItemId || ''}
            onDeleteItemSuccess={onDeleteItemSuccess}
            onDeleteItemError={onDeleteItemError}
          />
        )}
        {hasPresentation && (
          <>
            {(shouldRenderDuplicateButton || shouldRenderDeleteButton) && <MenuDivider />}
            <OpenInPagesMenuButton {...shellContext} />
          </>
        )}
      </MenuList>
    </Menu>
  );
};
