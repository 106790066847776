import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useRef } from 'react';
import { DICTIONARY } from 'src/constants';
import { Disclosure } from 'src/features/common/models';
import { DeleteActionCallbacks } from 'src/features/fieldBuilder/models';
import { useDeleteItemModal } from '../../hooks/useDeleteItemModal';
import { DeleteButtonProps } from './DeleteButton';

type DeleteModalProps = Disclosure & DeleteButtonProps & DeleteActionCallbacks;

export const DeleteModal: FC<DeleteModalProps> = ({
  onClose,
  hasChildren,
  name,
  itemId,
  parentItemId,
  onDeleteItemSuccess,
  onDeleteItemError,
}) => {
  const deleteItem = useDeleteItemModal(itemId, name, parentItemId, onClose, onDeleteItemSuccess, onDeleteItemError);

  const current = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isOpen leastDestructiveRef={current} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent onKeyDown={(e) => e.stopPropagation()}>
          <AlertDialogHeader>
            <T _str={DICTIONARY.DELETE_ITEM} />
          </AlertDialogHeader>
          <AlertDialogBody>
            {hasChildren ? (
              <T _str={DICTIONARY.DELETE_INFO_SUBITEMS} itemName={name} />
            ) : (
              <T _str={DICTIONARY.DELETE_INFO_NO_SUBITEMS} itemName={name} />
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button data-testid="cancelBtn" variant="ghost" ref={current} onClick={onClose}>
                <T _str={DICTIONARY.CANCEL} />
              </Button>
              <Button data-testid="deleteForeverBtn" colorScheme="danger" onClick={deleteItem}>
                <T _str={DICTIONARY.DELETE} />
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
