import { createContext, useContext } from 'react';

export type XMTenantModel = {
  id: string;
  name: string;
  url: string;
  projectName: string;
  environmentName: string;
};

export type XMTenantModelWrapper =
  | {
      resolved: true;
      model: XMTenantModel;
    }
  | { resolved: false; model: undefined };

export const XMTenantContext = createContext<XMTenantModelWrapper | undefined>(undefined);

export function useXMTenant(): XMTenantModel;
export function useXMTenant(optional: false): XMTenantModel;
export function useXMTenant(optional: true): XMTenantModel | undefined;
export function useXMTenant(optional = false): XMTenantModel | undefined {
  const xmtenantContext = useContext(XMTenantContext);

  if (!xmtenantContext) {
    throw new Error('useXMTenant must be used within a XMTenantProvider');
  }
  if (optional) {
    return xmtenantContext.model;
  }

  if (!xmtenantContext.resolved) {
    return {} as XMTenantModel;
  }

  return xmtenantContext.model;
}
