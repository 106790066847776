import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { useSiteTypes } from 'src/features/common/components/filters/hooks';
import { MenuWithRadio } from 'src/features/common/components/menuWithRadio/MenuWithRadio';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlSiteType } from 'src/features/common/models';

export const SiteTypesFilter: FC = () => {
  const { shouldHideTypes, onTypeChange, options } = useSiteTypes();
  const t = useTranslate();

  if (shouldHideTypes) {
    return <></>;
  }

  return (
    <MenuWithRadio title={t(DICTIONARY.SITE_TYPE_FILTER)} options={options} onChange={(value) => onTypeChange(value as UrlSiteType)} />
  );
};
