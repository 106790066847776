export interface SessionLocalStorage {
  value: string | undefined;
  storages: {
    session: string | undefined;
    local: string | undefined;
  };
}

export const setValueToStorages = (key: string, value?: string) => {
  if (value) {
    sessionStorage.setItem(key, value);
    localStorage.setItem(key, value);
  } else {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }
};

export const getValueFromStorages = (key: string): SessionLocalStorage => {
  const session = sessionStorage.getItem(key) || undefined;
  const local = localStorage.getItem(key) || undefined;

  return {
    value: session || local,
    storages: {
      session,
      local,
    },
  };
};
