import { Flex } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FC } from 'react';
import { DICTIONARY, LINK_TYPES } from 'src/constants';
import { AccessRestrictedWrapper, FieldError, ResetButton } from 'src/features/fieldBuilder/components/fields/Common';
import { FormikFieldState } from 'src/features/fieldBuilder/models';
import { GENERAL_LINK, GenericLink } from '../models';
import { internalLinkValidation } from '../utils/validationSchemas';
import { FieldBuilderCheckbox, FieldBuilderInput, InternalLinkButton } from './';
import { FieldBuilderInputWithBrowse } from './FieldBuilderInputWithBrowse';

export const InternalLink: FC<GenericLink> = ({ field, value, onSubmit, onReset, updateErrors }) => {
  return (
    <Formik<FormikFieldState>
      initialValues={{
        id: value.linktype === LINK_TYPES.INTERNAL ? value.id : '',
        text: value.text,
        title: value.title,
        querystring: value.querystring,
        anchor: value.anchor,
        target: value.target,
        linktype: LINK_TYPES.INTERNAL,
      }}
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={internalLinkValidation}
      validateOnChange={true}
    >
      {({ submitForm, resetForm, setFieldValue, setFieldTouched }) => (
        <Form>
          <AccessRestrictedWrapper canEdit={field.canEdit}>
            <Flex direction="column" gap={3}>
              <FieldBuilderInputWithBrowse
                inputName={GENERAL_LINK.ID}
                label={DICTIONARY.LINK_PATH}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
                isRequired={true}
                updateErrors={updateErrors}
              >
                <InternalLinkButton
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  submitForm={submitForm}
                  value={value}
                  field={field}
                />
              </FieldBuilderInputWithBrowse>
              <FieldBuilderInput
                inputName={GENERAL_LINK.TEXT}
                label={DICTIONARY.LINK_TEXT}
                subtext={DICTIONARY.LINK_TEXT_TOOLTIP}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
                isRequired={true}
                updateErrors={updateErrors}
              />
              <FieldBuilderInput
                inputName={GENERAL_LINK.TITLE}
                label={DICTIONARY.LINK_TITLE}
                subtext={DICTIONARY.LINK_TITLE_TOOLTIP}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
              />
              <FieldBuilderInput
                inputName={GENERAL_LINK.QUERY}
                label={DICTIONARY.LINK_QUERY}
                subtext={DICTIONARY.LINK_QUERY_TOOLTIP}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
              />
              <FieldBuilderInput
                inputName={GENERAL_LINK.ANCHOR}
                label={DICTIONARY.LINK_ANCHOR}
                subtext={DICTIONARY.LINK_ANCHOR_TOOLTIP}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
              />
              <FieldBuilderCheckbox
                inputName={GENERAL_LINK.TARGET}
                label={DICTIONARY.LINK_TARGET}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                submitForm={submitForm}
                field={field}
              />
              {field.error && <FieldError message={field.error} />}
              <ResetButton onClick={resetForm} isDisabled={!field.canEdit || field.containsStandardValue || field.containsInheritedValue} />
            </Flex>
          </AccessRestrictedWrapper>
        </Form>
      )}
    </Formik>
  );
};
