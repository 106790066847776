import { Text } from '@chakra-ui/react';
import { FC } from 'react';

export const UserCell: FC<{ user: string | undefined }> = ({ user }) => {
  return (
    <Text isTruncated minWidth="28" maxWidth="xs" noOfLines={2} whiteSpace="unset" title={user}>
      {user}
    </Text>
  );
};
