import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useTransition } from 'react';
import { GetListItemsVariables, useGetTreeListItems } from 'src/features/shared/treeTable/hooks/useGetTreeListItems';
import { TreeListItem } from 'src/features/shared/treeTable/models/TableList.model';
import { formatItem } from 'src/features/shared/treeTable/utils/formatters';
import { SortingOrder, treeItemFilter } from 'src/features/shared/treeTable/utils/treeItemFilter';

export type TreeListItemsArrayProps = {
  itemCache: TreeListItem[];
  setItemsCache: (items: TreeListItem[]) => void;
  isExpanded: boolean;
  orderField?: keyof TreeListItem;
  sortingOrder?: SortingOrder;
  toast: ReturnType<typeof useToast>;
} & GetListItemsVariables;

export const useTreeListItemsArray = (props: TreeListItemsArrayProps) => {
  const { itemCache, setItemsCache, isExpanded, orderField, sortingOrder, toast } = props;

  const [, startTransition] = useTransition();

  const onError = useCallback(
    (errors: any[] | undefined) => {
      toast({
        description: errors?.[0]?.message,
        status: 'error',
      });
    },
    [toast],
  );

  const { item, isLoading, refetch } = useGetTreeListItems({ ...props }, isExpanded, onError);

  useEffect(() => {
    if (!item) {
      return;
    }

    const newState = item.children.nodes.map<TreeListItem>((node) => formatItem(node));
    startTransition(() => setItemsCache(newState));
  }, [item, setItemsCache]);

  const sortedItems = useMemo(() => {
    return treeItemFilter(itemCache, orderField, sortingOrder);
  }, [itemCache, orderField, sortingOrder]);

  const treeListItem = useMemo(() => (item ? formatItem(item) : undefined), [item]);

  return { items: sortedItems, item: treeListItem, isLoading, refetch };
};
