import { ITEM_CREATION_REGEX } from 'src/constants';
import { VALIDATIONS } from 'src/validation-constants';
import * as Yup from 'yup';

export const itemNameValidationSchema = Yup.object({
  name: Yup.string()
    .required(VALIDATIONS.NAME_REQUIRED)
    .trim(VALIDATIONS.NAME_VALIDATION_TRIM)
    .strict()
    .matches(ITEM_CREATION_REGEX, VALIDATIONS.NAME_VALIDATION_MATCHES)
    .max(VALIDATIONS.NAME_VALIDATION_NUMBER, VALIDATIONS.NAME_VALIDATION_MAX),
});
