import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'src/constants';

export const NoMediaItemsFound: FC = () => {
  return (
    <Flex gap="3" direction="column" alignItems="center">
      <Box height="20" />
      <Image src={SC_ICONS_SRC.MAGNIFYING_GLASS_X_GRAY} height="24" />
      <Text variant="strong" fontSize="xl">
        <T _str={DICTIONARY.NO_SEARCH_RESULT} />
      </Text>
      <Text>
        <T _str={DICTIONARY.TRY_DIFFERENT_SEARCH} />
      </Text>
    </Flex>
  );
};
