import { withAuthenticationRequired } from '@auth0/auth0-react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DICTIONARY } from 'src/constants';
import { LoadingScreen } from 'src/features/temporary/LoadingScreen';
import { ExplorerLayout } from './ExplorerLayout';

export const AuthenticatedExplorerLayout: FC<React.PropsWithChildren> = ({ children }) => {
  const AuthorizedExplorerLayout = withAuthenticationRequired(ExplorerLayout, {
    onRedirecting: () => <LoadingScreen data-testid="loading-component" message={<T _str={DICTIONARY.LOADING} />} />,
  });

  return (
    <Routes>
      <Route path="*" element={<AuthorizedExplorerLayout>{children}</AuthorizedExplorerLayout>} />
    </Routes>
  );
};
