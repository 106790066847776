import { AxiosInstance, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseInfiniteQueryOptions } from 'react-query/types/react';
import { CLIENT_LANGUAGE, MASTER_DB, isGuid } from 'src/constants';
import { useAuthInfiniteQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { MediaDataResponse } from 'src/features/shared/selectMediaModal/models';
import { getMediaDataQueryString } from 'src/features/shared/selectMediaModal/queries';

type MediaDataType = GraphQLResponse<MediaDataResponse>;
type AxiosMediaDataType = AxiosResponse<MediaDataType>;

export const useGetMediaData = (
  itemId: string,
  includeIds: string[],
  useQueryOptions?: Omit<UseInfiniteQueryOptions<AxiosMediaDataType, unknown, AxiosMediaDataType>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const { urlContext } = useUrlSharing(UrlParams.sc_lang);
  const { url } = useXMTenant();

  const item = useMemo(() => {
    const inputSource = isGuid(itemId) ? { itemId } : { path: itemId };
    return { ...inputSource, language: urlContext.sc_lang ?? CLIENT_LANGUAGE, database: MASTER_DB };
  }, [itemId, urlContext.sc_lang]);

  const { data, isLoading, fetchNextPage, refetch } = useAuthInfiniteQuery<AxiosMediaDataType, unknown, AxiosMediaDataType>(
    ['get-media-library-data', itemId, urlContext.sc_lang ?? CLIENT_LANGUAGE],
    async (axiosInstance: AxiosInstance, cursor) => {
      return axiosInstance?.post<MediaDataType>(getSitecoreGraphQLEndpoint(url), {
        query: getMediaDataQueryString,
        variables: { item, includeIds, cursor },
      });
    },
    {
      ...useQueryOptions,
      onSuccess: (data) => {
        const lastPage = data.pages[data.pages.length ? data.pages.length - 1 : 0];
        const pI = lastPage.data?.data?.item?.children?.pageInfo || { endCursor: '', hasNextPage: false };

        if (pI.hasNextPage) {
          fetchNextPage({ pageParam: pI.endCursor });
        }
      },
    },
  );

  return {
    data,
    isLoading,
    refetch,
    url,
  };
};
