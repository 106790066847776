import { useCallback, useMemo } from 'react';
import { DICTIONARY, LINK_TYPES } from 'src/constants';
import { useSelectItemContext } from 'src/features/shared/selectItemModal/components/SelectItemContext';
import { SelectItemContextType } from 'src/features/shared/selectItemModal/models';
import { GENERAL_LINK, LinkButtonProps } from '../models';

const queryStringInput = 'querystring';
const insertLinkModalTexts = {
  header: DICTIONARY.SELECT_ITEM_TO_LINK,
  actionButton: DICTIONARY.LINK_ITEM,
};

export const useInsertLinkModal = ({ value, setFieldValue, submitForm, setFieldTouched }: LinkButtonProps) => {
  const searchParamsFromInput = useMemo(() => new URLSearchParams(value && value[queryStringInput]), [value]);

  const onSelectChange = useCallback(
    (data: SelectItemContextType) => {
      searchParamsFromInput.set('sc_lang', data.language ?? '');
      searchParamsFromInput.set('sc_site', data.sitename ?? '');

      setFieldTouched(GENERAL_LINK.ID, true);
      setFieldValue(GENERAL_LINK.ID, data.id, false);
      setFieldTouched(GENERAL_LINK.QUERY, true);
      setFieldValue(GENERAL_LINK.QUERY, searchParamsFromInput.toString(), false);

      setTimeout(() => submitForm()); // give a delay to update state
    },
    [searchParamsFromInput, setFieldTouched, setFieldValue, submitForm],
  );

  const { openModal } = useSelectItemContext();

  const open = useCallback(() => {
    openModal({
      texts: insertLinkModalTexts,
      includeFilters: true,
      context: {
        id: value?.linktype === LINK_TYPES.INTERNAL ? value?.id : '',
        language: searchParamsFromInput.get('sc_lang') ?? '',
        sitename: searchParamsFromInput.get('sc_site') ?? '',
        onSelectChange,
      },
    });
  }, [openModal, value?.linktype, value?.id, searchParamsFromInput, onSelectChange]);

  return { open };
};
