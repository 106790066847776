import { ENV } from 'src/constants';

const element = document.getElementById('site-manager-auth-settings');
const settings = element ? JSON.parse(element.textContent || '') : null;

const inventoryXmCloudSystemId = settings?.xmCloud?.systemId;
const inventoryBaseUrl = settings?.inventory?.apiBaseUrl;
const envName = settings?.env.toLowerCase();

export const environment = envName === ENV.DEV || envName === ENV.QA ? ENV.STAGING : envName;

const inventoryUrl = `${inventoryBaseUrl}/api/inventory/v1`;

export const inventory = {
  xmSystemId: inventoryXmCloudSystemId,
  get_tenants: {
    scope: 'inventory.tenants:read',
    url: (organizationId: string, systemId: string, name: string | undefined, pagenumber = 1, pagesize = 20) => {
      return `${inventoryUrl}/tenants?pagenumber=${pagenumber}&pagesize=${pagesize}&state=Active&organizationId=${organizationId}&systemId=${systemId}&name=${name}`;
    },
    xmCloudSystemId: inventoryXmCloudSystemId,
  },
};
