import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { useResetSearch } from 'src/features/search/hooks';

export const ClearAll: FC = () => {
  const { resetAll } = useResetSearch();

  return (
    <Button variant="ghost" colorScheme="primary" onClick={resetAll} data-testid="clear-all-btn">
      <T _str={DICTIONARY.CLEAR_ALL} />
    </Button>
  );
};
