import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SearchCriteriaInput, SearchCriteriaType } from 'src/features/search/models';
import { QUERIES_NAMES, SEARCH_FIELDS, SEARCH_IN_PARAM_VALUE } from 'src/features/search/searchConstants';

export const useSearchCriteria = () => {
  const [searchQuery] = useSearchParams();

  const searchIn = useMemo(() => searchQuery.get(QUERIES_NAMES.SEARCH_IN) || '', [searchQuery]);
  const searchData = useMemo(() => {
    const search = searchQuery.get(QUERIES_NAMES.SEARCH) || '';
    const isExactSearch = search.startsWith('"') && search.endsWith('"');
    const searchInput = isExactSearch ? search.substring(1, search.length - 1) : search;

    return { search, searchInput, isExactSearch };
  }, [searchQuery]);

  const searchCriteria = useMemo<SearchCriteriaInput[]>(() => {
    const criteriaType = searchData.isExactSearch ? SearchCriteriaType.EXACT : SearchCriteriaType.CONTAINS;
    const searchInput = searchData.searchInput;

    const name = [
      { field: SEARCH_FIELDS.DISPLAY_NAME, value: searchInput, criteriaType },
      { field: SEARCH_FIELDS.NAME, value: searchInput, criteriaType },
    ];
    const fields = [{ field: SEARCH_FIELDS.CONTENT, value: searchInput, criteriaType }];

    switch (searchIn) {
      case SEARCH_IN_PARAM_VALUE.NAME:
        return name;
      case SEARCH_IN_PARAM_VALUE.FIELDS:
        return fields;
      default:
        return [...name, ...fields];
    }
  }, [searchData, searchIn]);

  return { ...searchData, searchIn, searchCriteria };
};
