import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { mdiFormTextbox } from '@mdi/js';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { RenameItemModal, RenameItemModalProps } from 'src/features/fieldEditor/modals/RenameItemModal';

export const RenameMenuItem: FC<RenameItemModalProps> = (props) => {
  const disclosure = useDisclosure();

  return (
    <>
      <MenuItem onClick={disclosure.onOpen} icon={<Icon path={mdiFormTextbox} />} data-testid="rename-item">
        <T _str={DICTIONARY.RENAME} />
      </MenuItem>
      {disclosure.isOpen && <RenameItemModal {...disclosure} {...props} />}
    </>
  );
};
