import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { DeleteItemInput, GraphQLResponse, ItemDeleteResponse } from 'src/features/common/models';
import { items } from 'src/features/common/queries/items';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export const useDeleteItem = () => {
	const { url } = useXMTenant();

	return useAuthMutation(
    (axiosInstance) => (item: DeleteItemInput) => axiosInstance?.post<GraphQLResponse<ItemDeleteResponse>>(getSitecoreGraphQLEndpoint(url), items.delete_item(item)),
  );
};