import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { ItemTreeProps } from 'src/features/common/treeComponent/components/ItemTree';
import { ItemViewProps } from 'src/features/common/treeComponent/components/ItemView';
import { TreeLoadingSkeleton } from 'src/features/common/treeComponent/components/TreeLoadingSkeleton';
import { useTreeView } from 'src/features/common/treeComponent/hooks/useTreeView';
import { GetTreeItemData, ItemViewModel } from 'src/features/common/treeComponent/models';

export type TreeViewProps = {
  itemId: string;
  itemViewModel?: ItemViewModel;
  useGetItem: (itemId: string, enabled: boolean, language?: string | null) => GetTreeItemData;
  isOpened?: boolean;
  getItemView: (props: ItemViewProps & ItemTreeProps) => JSX.Element;
  ancestors?: string[];
  stopLoadAncestors: () => void;
  level?: number;
  isMultiple?: boolean;
};

export const TreeView: FC<TreeViewProps & ItemTreeProps> = (props) => {
  const { itemViewModel, getItemView, level = 0, isMultiple } = props;
  const { opened, item, isLoading, checkAncestor, onBranchOpen, openBranchOnClick } = useTreeView(props);

  return (
    <Flex direction="column" data-testid="tree-node" flex={isMultiple ? 0 : 1}>
      {getItemView({ ...props, item: item || itemViewModel, opened, setOpened: onBranchOpen, setSelectedIds: openBranchOnClick })}
      {isLoading && <TreeLoadingSkeleton />}
      <Flex direction="column" minWidth="2xs">
        {opened &&
          item?.children &&
          item.children.map((it) => (
            <Flex key={it.itemId}>
              <TreeView {...props} itemId={it.itemId} itemViewModel={it} isOpened={checkAncestor(it.itemId)} level={level + 1} />
            </Flex>
          ))}
      </Flex>
    </Flex>
  );
};
