import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { mdiMagnify } from '@mdi/js';
import { T } from '@transifex/react';
import { FC, useRef } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useMenuWithSearch } from 'src/features/common/components/menuWithSearch/hooks';
import { MenuWithSearchProps } from 'src/features/common/components/menuWithSearch/models';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { SEARCH_MENU_MIN_LENGTH, VISIBLE_SELECTED_ITEMS_COUNT } from 'src/features/search/searchConstants';

export const MenuWithSearch: FC<MenuWithSearchProps> = ({
  title,
  options,
  onChange,
  searchShowCount = SEARCH_MENU_MIN_LENGTH,
  visibleSelectedItemsCount = VISIBLE_SELECTED_ITEMS_COUNT,
  disabled,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const t = useTranslate();
  const {
    isIndeterminate,
    filteredOptions,
    selectedString,
    icon,
    search,
    setSearch,
    onCloseClick,
    handleOnChangeParent,
    isSearchVisible,
    handleOnChangeChild,
    isEachChildChecked,
    isEachChildHidden,
  } = useMenuWithSearch(options, visibleSelectedItemsCount, onChange, searchShowCount);

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        rightIcon={<Icon layerStyle="menuButtonIcon" path={icon} onClick={onCloseClick} />}
        data-testid="menu-button-filter"
        isDisabled={disabled}
      >
        <Text as="span">
          <T _str={title} />
          {selectedString ? ':' : ''}
        </Text>
        {selectedString && (
          <Text as="span" fontWeight="normal" marginLeft="1">
            {selectedString}
          </Text>
        )}
      </MenuButton>
      <MenuList onFocus={() => ref.current?.focus()} data-testid="menu-with-search-dropdown">
        {isSearchVisible && (
          <Flex paddingX="2" paddingBottom="2">
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon pointerEvents="none" path={mdiMagnify} boxSize="5" />
              </InputLeftElement>
              <Input ref={ref} placeholder={t(DICTIONARY.SEARCH)} value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
              <InputRightElement hidden={!search}>
                <Tooltip label={<T _str={DICTIONARY.CLEAR_SEARCH} />}>
                  <CloseButton data-testid="clear-search" size="sm" onClick={() => setSearch('')} />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </Flex>
        )}
        <Box maxHeight="xs" overflowY="auto" position="relative">
          {isEachChildHidden(filteredOptions) && (
            <Box paddingX="2">
              <T _str={DICTIONARY.NO_SEARCH_RESULT} />
            </Box>
          )}
          {filteredOptions.map((option, parentIndex) => (
            <Box key={option.value}>
              {!option.isHidden && (
                <MenuItem
                  data-testid={option.label}
                  as={Checkbox}
                  value={option.value}
                  isIndeterminate={isIndeterminate(option)}
                  isChecked={option.checked ?? (option.children && isEachChildChecked(option.children))}
                  onChange={(event: any) => handleOnChangeParent({ ...option, checked: event.target.checked }, parentIndex)}
                  noOfLines={1}
                  title={option.label}
                >
                  {option.label}
                </MenuItem>
              )}
              {option.children && !isEachChildHidden(option.children) && (
                <Box marginTop="1" gap="1">
                  {option.children.map((child, index) => (
                    <Box key={child.value}>
                      {!child.isHidden && (
                        <MenuItem
                          data-testid={option.label + ' - ' + child.label}
                          as={Checkbox}
                          paddingLeft="10"
                          value={child.value}
                          isChecked={child.checked}
                          onChange={(event: any) => handleOnChangeChild({ ...child, checked: event.target.checked }, index, parentIndex)}
                          noOfLines={1}
                          title={child.label}
                        >
                          {child.label}
                        </MenuItem>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </MenuList>
    </Menu>
  );
};
