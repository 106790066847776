import { AdaptiveView } from 'src/features/common/models/adaptiveView.model';
import { MilllerColumnsView } from 'src/features/shared/millerColumns/models';

export const MILLER_COLUMNS_ADAPTIVE_VIEWS = {
  '2xl': { columnWidth: 300, containerWidth: 900 },
  xl: { columnWidth: 300, containerWidth: 900 },
  lg: { columnWidth: 250, containerWidth: 350 },
  md: { columnWidth: 250, containerWidth: 350 },
  sm: { columnWidth: 250, containerWidth: 350 },
  base: { columnWidth: 300, containerWidth: 300 },
} as AdaptiveView<MilllerColumnsView>;
