import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { mdiAlertCircleOutline, mdiCheck, mdiClose, mdiPlus } from '@mdi/js';
import { T } from '@transifex/react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { DICTIONARY, MASTER_DB } from 'src/constants';
import { itemNameValidationSchema } from 'src/features/common/components/createItem/validation/ItemNameValidationSchema';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useCreateItem } from 'src/features/common/hooks/useCreateItem';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { CreateItemInput, InsertOption } from 'src/features/common/models';
import { useClearMillerColumnsCache } from 'src/features/millerColumns/hooks/useClearMillerColumnsCache';
import { useEventListener } from 'usehooks-ts';

export interface CreateItemProps {
  parent: string;
  insertOptions?: InsertOption[];
  canCreate?: boolean | undefined;
  menuConfig?: ButtonProps;
  color?: string;
}

interface Values {
  name: string;
}

export const CreateItem: FC<CreateItemProps> = ({ parent, insertOptions, canCreate = false, menuConfig = {}, color }) => {
  const { sc_lang, setUrlContext } = useUrlStrictContext(UrlParams.sc_lang | UrlParams.sc_itemid);
  const [templateId, setTemplateId] = useState('');
  const { mutate: createItem, isLoading } = useCreateItem();
  const clearCache = useClearMillerColumnsCache();
  const toast = useToast();
  const t = useTranslate();
  const ref = useRef<HTMLInputElement>(null);

  const input: CreateItemInput = useMemo(
    () => ({ language: sc_lang, name: '', parent, templateId: templateId, database: MASTER_DB }),
    [parent, sc_lang, templateId],
  );

  const isMenuDisabled = useMemo(() => !canCreate || !insertOptions?.length, [canCreate, insertOptions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const escFunction = useCallback((event: { key: string | undefined }) => event.key === 'Escape' && closeInputBox(), []);

  const closeInputBox = useCallback(() => {
    setTemplateId('');
  }, [setTemplateId]);

  const createItemHandle = useCallback(() => {
    !isLoading &&
      createItem(input, {
        onSuccess(data) {
          if (data && !data.data.errors) {
            const item = data.data.data.createItem.item;

            clearCache();
            setUrlContext({ sc_itemid: item.itemId, sc_version: item.version });
            closeInputBox();
            toast({ status: 'success', description: t(DICTIONARY.NOTIFICATIONS.CREATE_ITEM, { name: input.name }) });
          } else {
            toast({ status: 'error', description: data.data.errors[0].message });
          }
        },
      });
  }, [createItem, input, isLoading, toast, setUrlContext, closeInputBox, clearCache, t]);

  const onFormSubmit = useCallback(
    (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      input.name = values.name;
      createItemHandle();
      setSubmitting(false);
    },
    [input, createItemHandle],
  );

  const handleClickOutside: (event: MouseEvent) => void = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLInputElement)) {
        closeInputBox();
      }
    },
    [closeInputBox],
  );

  useEventListener('keydown', escFunction);
  useEventListener('mousedown', handleClickOutside);

  if (templateId) {
    return (
      <Box ref={ref}>
        <Formik initialValues={{ name: '' }} validationSchema={itemNameValidationSchema} onSubmit={onFormSubmit}>
          <Form data-testid="new-item-dialog">
            <Stack direction="row" spacing="2" m="2">
              <Field name="name">
                {({ field, form }: any) => (
                  <FormControl isInvalid={form.errors.name && form.touched.name}>
                    <Input {...field} placeholder={t(DICTIONARY.TYPE_NAME)} autoFocus data-testid="new-item-input" />
                    <FormErrorMessage data-testid="error-container">
                      <Icon path={mdiAlertCircleOutline} boxSize="5" mr="2" />
                      <T _str={form.errors.name ?? ''} />
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <IconButton
                variant="ghost"
                colorScheme="primary"
                aria-label={t(DICTIONARY.SAVE)}
                icon={<Icon path={mdiCheck} />}
                type="submit"
                data-testid="name-submit-button"
                isDisabled={isLoading}
              />
              <IconButton
                variant="ghost"
                aria-label={t(DICTIONARY.CANCEL)}
                icon={<Icon path={mdiClose} />}
                onClick={closeInputBox}
                type="reset"
              />
            </Stack>
          </Form>
        </Formik>
      </Box>
    );
  }

  return (
    <Menu>
      <MenuButton
        data-testid="plus-new-button"
        as={Button}
        isDisabled={isMenuDisabled}
        leftIcon={<Icon layerStyle="menuButtonIcon" path={mdiPlus} />}
        variant="outline"
        {...menuConfig}
      >
        <T _str={DICTIONARY.CREATE} />
      </MenuButton>
      <MenuList>
        {insertOptions?.map(({ name, templateId }: InsertOption) => (
          <MenuItem data-testid="new-item-type" key={templateId} onClick={() => setTemplateId(templateId)}>
            {name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
