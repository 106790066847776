import { ButtonProps, IconButton } from '@chakra-ui/react';
import { mdiArrowUp } from '@mdi/js';
import { FC, RefObject, useCallback } from 'react';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useScroll } from 'src/features/common/hooks/useScroll';

type ScrollToTopButtonProps = { boxRef: RefObject<HTMLDivElement> } & ButtonProps;

export const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({ boxRef, ...rest }) => {
  const { hasScrolled } = useScroll(boxRef);

  const scrollToTop = useCallback(() => {
    boxRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [boxRef]);

  return (
    <IconButton
      visibility={hasScrolled ? 'visible' : 'hidden'}
      position="fixed"
      bottom="6"
      right="6"
      onClick={scrollToTop}
      aria-label="scroll-top"
      variant="ghost"
      shadow="md"
      backgroundColor="chakra-body-bg"
      borderRadius="full"
      icon={<Icon path={mdiArrowUp} boxSize="6" />}
      data-testid="scroll-to-top-button"
      {...rest}
    />
  );
};
