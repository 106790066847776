import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useMemo } from 'react';
import { DICTIONARY, SECTIONS_ERRORS_STORAGE_KEY } from 'src/constants';
import { ValidationErrors } from 'src/features/fieldBuilder/models';
import { useSessionStorage } from 'usehooks-ts';

export const FieldsErrorsAlert: FC = () => {
  const [errors] = useSessionStorage<ValidationErrors[]>(SECTIONS_ERRORS_STORAGE_KEY, []);
  const hasErrors = useMemo(() => errors.length > 0, [errors]);

  if (hasErrors) {
    return (
      <Alert status="warning" data-testid="sc-publishErrorsAlert">
        <AlertIcon />
        <AlertDescription>
          <T _str={DICTIONARY.PUBLISH_FIELDS_HAVE_ERRORS} />
        </AlertDescription>
      </Alert>
    );
  }

  return <></>;
};
