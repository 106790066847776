import { Box, Button, Flex, FlexProps, Heading, Image, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useCallback } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'src/constants';
import { getAppLink } from 'src/features/common/config';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';

export const NoWebsitePage: FC<FlexProps> = (props) => {
  const { urlContext } = useUrlSharing(UrlParams.tenantName | UrlParams.organization);
  const organization = urlContext.organization || '';
  const tenantName = urlContext.tenantName || '';
  const onOpenXmAppsClick = useCallback(() => {
    const link = getAppLink({ app: 'xmapps', params: { organization, tenantName }, isNew: true });
    window.open(link, '_blank');
  }, [organization, tenantName]);

  return (
    <Flex data-testid="no-websites" align="center" boxSize="full" overflowY="auto" direction="column" {...props}>
      <Box boxSize="20">
        <Image src={SC_ICONS_SRC.CACTUS} />
      </Box>
      <Heading size="sm" marginBottom="2" marginTop="6">
        <T _str={DICTIONARY.NO_WEBSITE.TITLE} />
      </Heading>
      <Box paddingX="2" textAlign="center">
        <Text>
          <T _str={DICTIONARY.NO_WEBSITE.MESSAGE} />
        </Text>
        <Text>
          <T _str={DICTIONARY.NO_WEBSITE.MESSAGE_NEXT_LINE} />
        </Text>
      </Box>
      <Button marginTop="4" onClick={onOpenXmAppsClick}>
        <T _str={DICTIONARY.NO_WEBSITE.LINK_TEXT} />
      </Button>
    </Flex>
  );
};
