import { mdiFileOutline, mdiFolderOutline } from '@mdi/js';
import { useMemo } from 'react';
import { TEMPLATE_FOLDER } from 'src/constants';
import { useFeature } from 'src/features/common/flags/features';
import { SearchResponse, useFilterCriteria, useGetSearchData, useSearchCriteria } from 'src/features/search/hooks';
import { useQueryKeys } from 'src/features/search/hooks/useQueryKeys';
import { SearchOperator, SearchQueryInput, SearchResultItem, SearchTableRow } from 'src/features/search/models';
import {
  SEARCH_CACHE_TIME,
  SEARCH_EXCLUDE_FILTERS,
  SEARCH_MIN_LENGTH,
  SEARCH_PAGE_SIZE,
  SEARCH_PATH_CRITERIA,
} from 'src/features/search/searchConstants';

const getNextPageParam = (lastPage: SearchResponse, allPages: SearchResponse[]) => {
  const totalCount = lastPage?.data?.data?.search?.totalCount || 0;

  if (totalCount > allPages.length * SEARCH_PAGE_SIZE) {
    return { pageSize: SEARCH_PAGE_SIZE, skip: allPages.length * SEARCH_PAGE_SIZE };
  }

  return undefined;
};

export const useSearchData = () => {
  const { search, searchInput, searchCriteria } = useSearchCriteria();
  const filterCriteria = useFilterCriteria();
  const xm_searchTableView = useFeature('xm_searchTableView');

  const hasEnabledSearch = xm_searchTableView && (searchInput.length >= SEARCH_MIN_LENGTH || filterCriteria.length > 0);

  const queries: SearchQueryInput = useMemo(() => {
    const query: SearchQueryInput = {};

    if (search) {
      query.searchStatement = {
        criteria: [SEARCH_PATH_CRITERIA],
        operator: SearchOperator.MUST,
        subStatements: [
          { criteria: searchCriteria, operator: SearchOperator.MUST },
          { criteria: SEARCH_EXCLUDE_FILTERS, operator: SearchOperator.MUST },
        ],
      };
    }

    if (filterCriteria.length) {
      query.filterStatement = {
        criteria: [SEARCH_PATH_CRITERIA],
        subStatements: filterCriteria.map((criteria) => ({ criteria, operator: SearchOperator.MUST })),
      };
    }

    return query;
  }, [search, filterCriteria, searchCriteria]);

  const keys = useQueryKeys();

  const queryProps = useGetSearchData(queries, keys, {
    enabled: hasEnabledSearch,
    keepPreviousData: true,
    staleTime: SEARCH_CACHE_TIME,
    getNextPageParam,
  });

  const searchTableRows = useMemo<SearchTableRow[]>(() => {
    if (!queryProps.data) {
      return [];
    }

    const searchResults = queryProps.data.pages.flatMap<SearchResultItem>((page) => page?.data?.data?.search?.results || []);
    const searchRows = searchResults.map<SearchTableRow>((result) => {
      return {
        itemId: result.itemId,
        language: result.language,
        name: result.name,
        displayName: result.innerItem?.displayName.value,
        canWriteDisplayName: result.innerItem?.displayName.access.canWrite,
        parentId: result.parentId,
        version: result.innerItem?.version || 0,
        createdBy: result.innerItem?.createdBy?.value || '',
        updatedBy: result.innerItem?.updatedBy?.value || '',
        thumbnailUrl: result.innerItem?.thumbnailUrl || '',
        type: result.templateName === TEMPLATE_FOLDER ? 'folder' : 'content',
        workflow: result.innerItem?.workflow?.workflow,
        workflowState: result.innerItem?.workflow?.workflowState,
        hasChildren: result.innerItem?.hasChildren || false,
        hasPresentation: result.innerItem?.hasPresentation || false,
        icon: result.templateName === TEMPLATE_FOLDER ? mdiFolderOutline : mdiFileOutline,
        access: result.innerItem?.access || { canCreate: false, canDelete: false, canRename: false, canWrite: false },
        insertOptions: result.innerItem?.insertOptions || [],
        parent: {
          access: { canCreate: result.innerItem?.parent.access?.canCreate || false },
          insertOptions: result.innerItem?.parent.insertOptions || [],
        },
      };
    });

    return searchRows;
  }, [queryProps.data]);

  return { ...queryProps, data: searchTableRows, hasEnabledSearch };
};
