import { debounce } from 'lodash';
import { useMemo, useTransition } from 'react';
import { DEBOUNCE_SCROLL_EVENT_TIME } from 'src/features/search/searchConstants';
import { useEventListener } from 'usehooks-ts';

export const useFetchPageOnScrollEnd = (boxRef: React.RefObject<HTMLDivElement>, hasNextPage: boolean | undefined, action: () => any) => {
  const [isPending, startTransition] = useTransition();

  const onScroll = useMemo(
    () =>
      debounce((event: Event) => {
        const target = event.target as HTMLDivElement;
        const { scrollHeight, scrollTop, clientHeight } = target;

        if (Math.abs(scrollHeight - scrollTop - clientHeight) < 1 && hasNextPage) {
          startTransition(() => {
            action();
          });
        }
      }, DEBOUNCE_SCROLL_EVENT_TIME),
    [hasNextPage, action, startTransition],
  );

  useEventListener('scroll', onScroll, boxRef);

  return isPending;
};
