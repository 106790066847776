import { Button, useDisclosure } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useMemo } from 'react';
import { DICTIONARY } from 'src/constants';
import { useCurrentItem } from 'src/features/common/hooks/useCurrentItem';
import { PublishModal } from './modals/PublishModal';

export const PublishButton = () => {
  const { currentItem } = useCurrentItem();
  const disclosure = useDisclosure();

  const isDisabled = useMemo(() => {
    const isFinal = currentItem?.workflow?.workflowState?.final;
    return isFinal !== null && isFinal !== undefined ? !isFinal : false;
  }, [currentItem?.workflow?.workflowState?.final]);

  return (
    <>
      <Button isDisabled={isDisabled} data-testid="publish-button" variant="outline" size="sm" onClick={disclosure.onOpen}>
        <T _str={DICTIONARY.PUBLISH} />
      </Button>
      {disclosure.isOpen && <PublishModal {...disclosure} />}
    </>
  );
};
