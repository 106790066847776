import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { viewModes } from 'src/features/common/components/actionsBar/models';
import { useCurrentItem } from 'src/features/common/hooks/useCurrentItem';
import { BackButton } from 'src/features/fieldEditor/components/backButton/BackButton';

const stickyProps: FlexProps = {
  position: 'sticky',
  top: 0,
  width: 'full',
  zIndex: 'sticky',
};

export const ItemViewHeader: FC<{ hasScrolled: boolean }> = ({ hasScrolled }) => {
  const { currentItem } = useCurrentItem();

  return (
    <Flex gap="3" paddingX="6" paddingY="3" backgroundColor="white" {...stickyProps} boxShadow={hasScrolled ? 'base' : 'none'}>
      <BackButton path={'/' + viewModes.list} />
      <Text fontSize="3xl" fontWeight="semibold" width="100%">
        {hasScrolled && currentItem?.displayName}
      </Text>
    </Flex>
  );
};
