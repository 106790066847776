import { useCallback, useState } from 'react';
import { LINK_TYPES } from 'src/constants';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { FormikFieldState, GenericField } from 'src/features/fieldBuilder/models';
import { GENERAL_LINK } from 'src/features/generalLink/models';
import { LinkValue } from 'src/features/generalLink/models/linkValue.model';

export const useLink = ({ field }: GenericField) => {
  const [value, setValue] = useState<LinkValue>(new LinkValue(field.value));
  const { builderField, updateField, resetField, updateErrors } = useFieldActions(field);

  const onSubmit = useCallback(
    async (values: FormikFieldState) => {
      const link = new LinkValue();
      Object.keys(values).forEach((key) => Object.assign(link, { [key]: values[key] || '' }));

      updateField(field.fieldId, link.toString());
      setValue(link);
    },
    [field.fieldId, updateField],
  );

  const onReset = useCallback(
    async (values: FormikFieldState) => {
      const fieldUpdated = await resetField(field.fieldId);
      const newValues = new LinkValue(fieldUpdated?.value);
      setValue(newValues);

      Object.values(GENERAL_LINK).forEach((key) => Object.assign(values, { [key]: Object(newValues)[key] || '' }));

      return values;
    },
    [field.fieldId, resetField],
  );

  const covertLinkTypeToTabIndex = useCallback((type: string) => {
    switch (type) {
      case LINK_TYPES.EXTERNAL:
        return 1;
      case LINK_TYPES.MEDIA:
        return 2;
      default:
        return 0;
    }
  }, []);

  return { builderField, value, setValue, onReset, onSubmit, covertLinkTypeToTabIndex, updateErrors };
};
