import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { DICTIONARY, MASTER_DB } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { DuplicateItemInput, NestedItems } from 'src/features/common/models';
import { useDuplicateItem } from './useDuplicateItem';

export const useDuplicateItemModal = (
  itemId = '',
  itemName = '',
  onClose = () => {},
  onDuplicateItemSuccess: (item: NestedItems, itemId: string) => void,
) => {
  const { mutateAsync: duplicateMutation, isLoading } = useDuplicateItem();
  const t = useTranslate();
  const toast = useToast();

  const duplicateItem = useCallback(
    async (name: string) => {
      const input: DuplicateItemInput = { itemId, database: MASTER_DB, deepCopy: true, copyItemName: name };
      const result = await duplicateMutation(input);

      if (!result.data.errors) {
        const newItemId = result?.data?.data?.copyItem?.item?.itemId;

        if (newItemId) {
          onDuplicateItemSuccess(result?.data?.data?.copyItem?.item, itemId);
        }

        toast(
          newItemId
            ? { description: t(DICTIONARY.DUPLICATE_SUCCESSFUL, { itemName }), status: 'success' }
            : { description: t(DICTIONARY.DUPLICATE_UNSUCCESSFUL, { itemName }), status: 'error' },
        );
      } else {
        toast({ description: t(DICTIONARY.DUPLICATE_UNSUCCESSFUL, { itemName }), status: 'error' });
      }

      onClose();
    },
    [itemId, duplicateMutation, onClose, toast, t, itemName, onDuplicateItemSuccess],
  );

  return { duplicateItem, isLoading };
};
