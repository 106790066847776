import { SIZE_UNITS } from 'src/constants';

export const fileSizeTransformer = (bytes = 0, precision = 2) => {
  if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
    return '';
  }

  let unit = 0;

  while (bytes >= 1024) {
    bytes /= 1024;
    unit++;
  }

  return `${bytes.toFixed(precision)} ${SIZE_UNITS[unit]}`;
};
