import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'src/constants';
import { useResetSearch } from 'src/features/search/hooks';

export const NoSearchResults: FC = () => {
  const { resetAll } = useResetSearch();

  return (
    <Flex direction="column" alignItems="center" data-testid="no-search-result">
      <Image src={SC_ICONS_SRC.MAGNIFYING_GLASS_X_GRAY} height="20" marginTop="20" marginBottom="6" />
      <Flex direction="column" gap="4" alignSelf="stretch" alignItems="center">
        <Flex direction="column" gap="2" alignItems="center">
          <Heading size="sm">
            <T _str={DICTIONARY.NO_SEARCH_RESULT} />
          </Heading>
          <Text>
            <T _str={DICTIONARY.TRY_DIFFERENT_SEARCH_OR_FILTER} />
          </Text>
        </Flex>
        <Button variant="link" onClick={resetAll}>
          <T _str={DICTIONARY.RESET} />
        </Button>
      </Flex>
    </Flex>
  );
};
