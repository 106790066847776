import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useViewSwitcherModes } from 'src/features/common/components/actionsBar/hooks';
import { viewModes } from 'src/features/common/components/actionsBar/models';
import { SwitchBarColumnView } from 'src/features/common/components/actionsBar/SwitchBarColumnView';
import { SwitchBarListView } from 'src/features/common/components/actionsBar/SwitchBarListView';

export const ActionsBar: FC = () => {
  const { viewMode } = useViewSwitcherModes();

  return (
    <Flex paddingX="4" paddingY="6" gap="4" flexDirection="column" data-testid="actions-bar">
      {viewMode === viewModes.columns && <SwitchBarColumnView />}
      {viewMode === viewModes.list && <SwitchBarListView />}
    </Flex>
  );
};
