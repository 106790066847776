import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { ItemTree } from 'src/features/common/treeComponent/components/ItemTree';
import { ItemView } from 'src/features/common/treeComponent/components/ItemView';
import { useGetSelectedItemAncestorsWrapper, useGetSelectedTreeItemWrapper, useSelectItemModal } from '../hooks';
import { SelectItemModalProps } from '../models';
import { SelectItemModalFilters } from './SelectItemModalFilters';

export const SelectItemModal: FC<SelectItemModalProps> = (props) => {
  const { isOpen, onClose, texts, selectedIds, setSelectedIds, selectedLanguage, selectedSite, includeFilters, selectState } = props;

  const { onSiteChange, onLanguageChange, siteRootItemId, onSubmit, rootItemId } = useSelectItemModal(props);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <T _str={texts?.header ?? ''} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {includeFilters && (
            <SelectItemModalFilters
              selectedSite={selectedSite}
              selectedLanguage={selectedLanguage}
              onSiteChange={onSiteChange}
              onLanguageChange={onLanguageChange}
            />
          )}
          <Box height="375" overflowY="auto" position="relative" marginTop="3">
            <ItemTree
              itemId={rootItemId}
              useGetItem={useGetSelectedTreeItemWrapper}
              selectedIDs={selectedIds}
              setSelectedIds={setSelectedIds}
              selectionVariant="single"
              language={selectedLanguage}
              expandOnSelect
              getItemView={(props) => <ItemView {...props} />}
              initialId={selectState?.id ?? siteRootItemId}
              useGetInitialAncestors={useGetSelectedItemAncestorsWrapper}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose} variant="ghost">
              <T _str={DICTIONARY.CANCEL} />
            </Button>
            <Button variant="solid" type="submit" data-testid="submit-btn" isDisabled={!selectedIds[0]} onClick={onSubmit}>
              <T _str={texts?.actionButton ?? ''} />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
