import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { WorkflowState as WorkflowStateModel } from 'src/features/common/models';
import { WorkflowState } from 'src/features/workflow/components';

type WorkflowStateCellProps = { workflowName: string } & Pick<WorkflowStateModel, 'displayName' | 'final'>;

export const WorkflowStateCell: FC<WorkflowStateCellProps> = ({ workflowName, displayName, final }) => {
  return (
    <Box minWidth="28" maxWidth="xs">
      <WorkflowState workflowName={workflowName} name={displayName} isFinal={final} maxWidth="xs" />
    </Box>
  );
};
