/**
 * Represents language within database Sitecore
 */
export class LanguageItem {
  constructor(public name = '', public englishName = '', public displayName = '', public iso = '') {}
}

/**
 * A connection to a list of languages
 */
export interface LanguageConnection {
  nodes: LanguageItem[];
}

/**
 * Represents language response
 */
export interface LanguageResponse {
  languages: LanguageConnection;
}
