import { throttle } from 'lodash';
import { useMemo } from 'react';
import { CACHE_LIFETIME, THROTTLE_MILLER_DELAY, XA_ADVANCED_ITEMS_ID, XA_DICTIONARY_ID } from 'src/constants';
import { useGetSitesWrapper } from 'src/features/common/hooks/useGetSitesWrapper';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { fullUrlContext, useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { isSameGuid } from 'src/features/common/utils/guidUpdater';
import { useGetItemAncestors } from 'src/features/millerColumns/hooks/useGetItemAncestors';
import { useShowOptions } from 'src/features/millerColumns/hooks/useShowOptions';
import { MillerColumnClick, MillerColumns } from 'src/features/millerColumns/models';
import { formatAncestorsItems, formatSites, resolvedSelectionMap } from 'src/features/millerColumns/utils/formatters';

const compareString = (first: string, second: string): boolean => first.toLowerCase() === second.toLowerCase();
const fullUrlContex = fullUrlContext | UrlParams.sc_collection | UrlParams.sc_type;

export const useMillerColumnsContainer = () => {
  const { sc_itemid, sc_site, sc_lang, sc_collection, sc_type, setUrlContext } = useUrlStrictContext(fullUrlContex);

  const { showAdvancedItems, showHiddenItems, showTemplateSites } = useShowOptions();
  const { sites } = useGetSitesWrapper();

  const sitesColumns = useMemo(
    () => formatSites(sites, { showTemplateSites, sc_collection, sc_type }),
    [sites, showTemplateSites, sc_collection, sc_type],
  );
  const excludeIDs = useMemo(() => (showAdvancedItems ? [] : [XA_ADVANCED_ITEMS_ID, XA_DICTIONARY_ID]), [showAdvancedItems]);

  const ancestorsData = useGetItemAncestors(sc_itemid || '', sc_lang, showHiddenItems, excludeIDs, {
    enabled: !!sitesColumns.length && !!sc_itemid,
    keepPreviousData: true,
    staleTime: CACHE_LIFETIME,
  });

  const columnsData = useMemo<MillerColumns>(() => {
    if (!ancestorsData || !sitesColumns.length || !sc_site) {
      return { data: sitesColumns, selections: [] };
    }

    const site = sitesColumns.flatMap(({ items }) => items).find(({ data }) => compareString(data.name, sc_site));

    if (!site) {
      return { data: sitesColumns, selections: [] };
    }

    const ancestors = formatAncestorsItems(ancestorsData);

    if ((!ancestors || !ancestors.length) && isSameGuid(site.data.id, sc_itemid)) {
      const itemIndex = sitesColumns[0].items.findIndex(({ data }) => compareString(data.name, sc_site));
      return { data: sitesColumns, selections: [{ columnIndex: 0, itemIndex, isPrimary: true }] };
    }

    const startDataIndex = ancestors.findIndex(({ items }) => !!items.find((r) => isSameGuid(r.data.id, site?.data.id)));

    if (startDataIndex < 0) {
      return { data: sitesColumns, selections: [] };
    }

    ancestors.splice(0, startDataIndex + 1, sitesColumns[0]);
    const selections = resolvedSelectionMap(ancestors, sc_itemid);

    return { data: ancestors, selections };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ancestorsData, sitesColumns]);

  const onClick = useMemo(
    () =>
      throttle(
        async ({ itemData }: MillerColumnClick) => {
          const { id: itemId, name: siteName, itemType } = itemData.data;
          const isSite = itemType === 'site';

          if (isSite) {
            setUrlContext({ sc_site: siteName, sc_itemid: undefined, sc_version: undefined });
          } else {
            setUrlContext({ sc_itemid: itemId, sc_version: undefined });
          }
        },
        THROTTLE_MILLER_DELAY,
        { leading: true, trailing: true },
      ),
    [setUrlContext],
  );

  return { columnsData, onClick };
};
