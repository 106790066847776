import { useCallback } from 'react';
import { DetailedItem } from 'src/features/common/models';

export const useRenameItemButton = (onRenameSuccess: (item: DetailedItem) => void, refetch: () => Promise<any>) => {
  return useCallback(
    async (item: DetailedItem) => {
      await refetch();
      onRenameSuccess(item);
    },
    [onRenameSuccess, refetch],
  );
};
