import { Button } from '@chakra-ui/react';
import { mdiRefresh } from '@mdi/js';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useTranslate } from 'src/features/common/hooks/useTranslate';

export type RefreshButtonProps = {
  refresh: () => void;
  isDisabled: boolean;
};

export const RefreshButton: FC<RefreshButtonProps> = ({ refresh, isDisabled }) => {
  const t = useTranslate();

  return (
    <Button
      aria-label={t(DICTIONARY.REFRESH)}
      gap="2"
      variant="ghost"
      onClick={refresh}
      data-testid="modal-refresh-button"
      isDisabled={isDisabled}
    >
      <Icon path={mdiRefresh} fontSize="lg" />
      <T _str={DICTIONARY.REFRESH} />
    </Button>
  );
};
