import { useToast } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { DICTIONARY } from 'src/dictionary';
import { useGetItem } from 'src/features/common/hooks/useGetItem';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';

export const useCurrentItem = (toast?: ReturnType<typeof useToast>, t?: ReturnType<typeof useTranslate>) => {
  const { sc_lang, sc_itemid, sc_version, sc_site, setUrlContext } = useUrlStrictContext();

  const { data, refetch, isFetching } = useGetItem(
    { id: sc_itemid, lang: sc_lang, ver: sc_version },
    { enabled: !!sc_itemid, keepPreviousData: true },
  );

  const currentItem = useMemo(() => data?.data?.data?.item, [data]);

  useEffect(() => {
    if (!currentItem && !isFetching) {
      setUrlContext({ sc_site, sc_itemid: undefined, sc_version: undefined });

      if (toast && t) {
        toast({ description: t(DICTIONARY.WE_COULD_NOT_FIND_ITEM), status: 'error' });
      }
    }
  }, [currentItem, setUrlContext, sc_site, isFetching, toast, t]);

  return { currentItem, refetch, isFetching };
};
