import { useCallback, useMemo } from 'react';
import { DICTIONARY } from 'src/constants';
import { SiteTypeValue, UseSiteTypes } from 'src/features/common/components/filters/models';
import { useGetSitesWrapper } from 'src/features/common/hooks/useGetSitesWrapper';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { UrlSiteType } from 'src/features/common/models';

export const useSiteTypes: UseSiteTypes = () => {
  const { urlContext, setUrlContext } = useUrlSharing(UrlParams.sc_collection | UrlParams.sc_type);
  const { sites } = useGetSitesWrapper();
  const t = useTranslate();

  const shouldHideTypes = useMemo(() => {
    const hasNoneSxa = !!sites.find((r) => !r.siteCollection);
    const hasSxa = !!sites.find((r) => r.siteCollection);
    return !(hasNoneSxa && hasSxa);
  }, [sites]);

  const { type } = useMemo(() => {
    let type: SiteTypeValue = 'all';

    switch (urlContext.sc_type) {
      case 'nonsxa':
        type = 'nonsxa';
        break;
      case 'sxa':
        type = 'sxa';
        break;
    }

    return { type };
  }, [urlContext.sc_type]);

  const onTypeChange = useCallback(
    (type: UrlSiteType) => {
      switch (type) {
        case 'sxa':
          setUrlContext({ sc_type: type, sc_site: undefined, sc_itemid: undefined, sc_version: undefined });
          break;
        case 'nonsxa':
          setUrlContext({ sc_type: type, sc_collection: undefined, sc_site: undefined, sc_itemid: undefined, sc_version: undefined });
          break;
        default:
          setUrlContext({ sc_type: undefined });
      }
    },
    [setUrlContext],
  );

  const options = useMemo(() => {
    const isNonSXADisabled = !!urlContext.sc_collection;

    return [
      { value: 'sxa', label: t(DICTIONARY.SXA_MANAGED), isChecked: type === 'sxa' },
      {
        value: 'nonsxa',
        label: t(DICTIONARY.NON_SXA_MANAGED),
        isDisabled: isNonSXADisabled,
        isChecked: type === 'nonsxa',
        title: isNonSXADisabled ? t(DICTIONARY.SITE_TYPE_OPTION_DISABLED_MESSAGE) : undefined,
      },
    ];
  }, [t, type, urlContext.sc_collection]);

  return { shouldHideTypes, onTypeChange, options };
};
