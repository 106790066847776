import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { SwitchBar } from 'src/features/common/components/actionsBar/SwitchBar';
import { VisualOptionsMenu } from 'src/features/common/components/actionsBar/VisualOptionsMenu';
import { useShowFiltersMode } from 'src/features/common/components/actionsBar/hooks';
import { FiltersBar } from 'src/features/common/components/filters/FiltersBar';
import { SearchContainer } from 'src/features/search/components';
import { useFeature } from '../../flags/features';

export const SwitchBarListView: FC = () => {
  const { isFilterVisible, toggleFilters } = useShowFiltersMode();
  const xm_searchTableView = useFeature('xm_searchTableView');

  return (
    <>
      <SwitchBar>
        <SearchContainer />
        <VisualOptionsMenu />
        {xm_searchTableView ? (
          <Button variant="ghost" onClick={toggleFilters} data-testid="showFiltersButton">
            <T _str={isFilterVisible ? DICTIONARY.HIDE_FILTERS : DICTIONARY.SHOW_FILTERS} />
          </Button>
        ) : (
          <></>
        )}
      </SwitchBar>
      {xm_searchTableView ? <FiltersBar /> : <></>}
    </>
  );
};
