import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { useMediaLinkModal } from '../hooks/useMediaLinkModal';
import { LinkButtonProps } from '../models';

export const MediaLinkButton: FC<LinkButtonProps> = (props) => {
  const { open } = useMediaLinkModal(props);

  return (
    <Button size="md" variant="outline" onClick={open} isDisabled={!props.field.canEdit}>
      <T _str={DICTIONARY.BROWSE} />
    </Button>
  );
};
