import { Hide, Thead, Tr } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY } from 'src/constants';
import { useDisplayOptions } from 'src/features/millerColumns/hooks/useDisplayOptions';
import { SearchTh } from 'src/features/search/components/SearchTh';

export const SearchTableHeader = () => {
  const { showLanguages, showWorkflow } = useDisplayOptions();

  return (
    <Thead position="sticky" zIndex="1" top="0" backgroundColor="white">
      <Tr boxShadow="xs">
        <SearchTh>
          <T _str={DICTIONARY.HEADER.NAME} />
        </SearchTh>
        <SearchTh>
          <T _str={DICTIONARY.HEADER.TYPE} />
        </SearchTh>
        <Hide below="md">
          <SearchTh>
            <T _str={DICTIONARY.HEADER.CREATED} />
          </SearchTh>
          <SearchTh>
            <T _str={DICTIONARY.HEADER.UPDATED} />
          </SearchTh>
        </Hide>
        {showLanguages && (
          <SearchTh>
            <T _str={DICTIONARY.HEADER.LANGUAGE} />
          </SearchTh>
        )}
        {showWorkflow && (
          <SearchTh>
            <T _str={DICTIONARY.HEADER.WORKFLOW} />
          </SearchTh>
        )}
      </Tr>
    </Thead>
  );
};
