import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { DEBOUNCE_SEARCH_EVENT_TIME, QUERIES_NAMES, SEARCH_MIN_LENGTH } from 'src/features/search/searchConstants';

export const useSearchInput = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const t = useTranslate();
  const ref = useRef<HTMLInputElement>(null);

  const searchValue = useMemo<string>(() => searchParams.get(QUERIES_NAMES.SEARCH) ?? '', [searchParams]);

  const searchHandler = useMemo(() => {
    return debounce((e: ChangeEvent<HTMLInputElement>) => {
      setSearchParams((params) => {
        if (e.target.value.length === 0) {
          params.delete(QUERIES_NAMES.SEARCH);
        } else if (e.target.value.length >= SEARCH_MIN_LENGTH) {
          params.set(QUERIES_NAMES.SEARCH, e.target.value);
        }

        return params;
      });
    }, DEBOUNCE_SEARCH_EVENT_TIME);
  }, [setSearchParams]);

  const clearSearch = useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
    }

    setSearchParams((params) => {
      params.delete(QUERIES_NAMES.SEARCH);
      return params;
    });
  }, [setSearchParams]);

  useEffect(() => {
    if (!searchValue && !!ref.current?.value) {
      ref.current.value = searchValue;
    }
  }, [searchValue]);

  return { searchValue, searchHandler, ref, clearSearch, t };
};
