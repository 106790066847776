import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { CREATE_ITEM_TOAST_DURATION } from 'src/constants';
import { CreateItemToastDescription } from 'src/features/shared/createItemToastDescription/CreateItemToastDescription';
import { RefetchFunction } from 'src/features/shared/treeTable/components/TreeTableRefetchContext';
import { useRefetchAndBlink } from 'src/features/shared/treeTable/hooks/useRefetchAndBlink';
import { NestedItems } from 'src/features/shared/treeTable/models/item.model';

export const useTreeTableCreateItemMenuWrapper = (refetchParent: RefetchFunction, refetchCurrent: RefetchFunction) => {
  const refetchParentAndBlink = useRefetchAndBlink(refetchParent);
  const refetchCurrentAndBlink = useRefetchAndBlink(refetchCurrent);
  const toast = useToast();

  return useCallback(
    async (newItem: NestedItems, type: string) => {
      type === 'sibling' ? refetchParentAndBlink(newItem.itemId) : refetchCurrentAndBlink(newItem.itemId);
      toast({
        description: <CreateItemToastDescription name={newItem.name} itemId={newItem.itemId} />,
        status: 'success',
        isClosable: true,
        duration: CREATE_ITEM_TOAST_DURATION,
      });
    },
    [refetchCurrentAndBlink, refetchParentAndBlink, toast],
  );
};
