import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptions } from 'react-query';
import { CLIENT_LANGUAGE, MASTER_DB } from 'src/constants';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { MediaItemResponse } from 'src/features/media/models';
import { media } from 'src/features/media/query/media';

type MediaType = GraphQLResponse<MediaItemResponse>;

export const useGetMediaData = (
  mediaItemId: string,
  useQueryOptions?:
    | Omit<UseQueryOptions<AxiosResponse<MediaType>, unknown, AxiosResponse<MediaType>, string[]>, 'queryKey' | 'queryFn'>
    | undefined,
) => {
  const { urlContext } = useUrlSharing(UrlParams.sc_lang);
  const { url } = useXMTenant();
  const toast = useToast();

  const { data } = useAuthQuery(
    ['get-media-data', mediaItemId, urlContext.sc_lang || CLIENT_LANGUAGE],
    async (axiosInstance) => {
      return axiosInstance?.post<MediaType>(
        getSitecoreGraphQLEndpoint(url),
        media.get_media_data({ mediaItemId, database: MASTER_DB, language: urlContext.sc_lang || CLIENT_LANGUAGE }),
      );
    },
    { ...useQueryOptions },
    toast,
  );

  const mediaItem = useMemo(() => data?.data?.data?.mediaItem, [data]);

  return { mediaItem };
};
