import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { mdiPencilOutline } from '@mdi/js';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useCurrentItem } from 'src/features/common/hooks/useCurrentItem';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { DetailedItem } from 'src/features/common/models';
import { RenameItemModal } from 'src/features/fieldEditor/modals/RenameItemModal';
import { useRenameItemButton } from './hooks';

export type RenameItemButtonProps = {
  onRenameSuccess: (item: DetailedItem) => void;
};

export const RenameItemButton: FC<RenameItemButtonProps> = ({ onRenameSuccess }) => {
  const disclosure = useDisclosure();
  const t = useTranslate();
  const { currentItem, refetch } = useCurrentItem();
  const onRenameItemButtonSuccess = useRenameItemButton(onRenameSuccess, refetch);

  return (
    <>
      <Tooltip label={t(DICTIONARY.RENAME)}>
        <IconButton
          onClick={disclosure.onOpen}
          variant="ghost"
          size="xs"
          ml="1"
          aria-label={t(DICTIONARY.RENAME_ITEM)}
          icon={<Icon boxSize="5" path={mdiPencilOutline} />}
          data-testid="rename-item"
        />
      </Tooltip>
      {disclosure.isOpen && (
        <RenameItemModal
          {...disclosure}
          canRename={currentItem?.access.canRename || false}
          canWriteDisplayName={currentItem?.displayNameField?.access.canWrite || false}
          canWrite={currentItem?.access.canWrite || false}
          displayName={currentItem?.displayName || ''}
          itemId={currentItem?.itemId || ''}
          name={currentItem?.name || ''}
          version={currentItem?.version || 0}
          onRenameSuccess={onRenameItemButtonSuccess}
        />
      )}
    </>
  );
};
