import { Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { ItemType } from 'src/features/millerColumns/models';

export const ItemTypeCell: FC<{ type: ItemType }> = ({ type }) => {
  return (
    <Text _firstLetter={{ textTransform: 'uppercase' }} minWidth="28" maxWidth="xs">
      <T _str={type} />
    </Text>
  );
};
