import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { MenuWithSearch } from 'src/features/common/components/menuWithSearch/MenuWithSearch';
import { useWorkflowFilter } from 'src/features/search/hooks/useWorkflowFilter';

export const WorkflowFilter: FC = () => {
  const { workflows, onFilterChange } = useWorkflowFilter();

  return <MenuWithSearch title={DICTIONARY.HEADER.WORKFLOW} options={workflows} onChange={onFilterChange} visibleSelectedItemsCount={1} />;
};
