import _ from 'lodash';
import { TreeListItem } from 'src/features/shared/treeTable/models';

export type SortingOrder = 'asc' | 'desc' | undefined;

export const treeItemFilter = (items: TreeListItem[], orderField?: keyof TreeListItem, sortingOrder?: SortingOrder) => {
  if (!items || !items.length || !sortingOrder) {
    return items;
  }

  if (orderField === 'languages') {
    return _.orderBy(items, (item) => item.languages?.join(','), [sortingOrder]);
  }

  if (orderField === 'workflow') {
    return _.orderBy(items, (item) => item.workflow?.workflowState?.displayName, [sortingOrder]);
  }

  const orderedItems = _.orderBy(items, [orderField], [sortingOrder]);
  return orderedItems as TreeListItem[];
};
