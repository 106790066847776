import { useCallback, useMemo } from 'react';
import { DEFAULT_MEDIA_LIBRARY_ID } from 'src/constants';
import { SelectMediaModalProps } from 'src/features/shared/selectMediaModal/components/SelectMediaModal';
import { useGetMediaDataWrapper, useGetPreSelectedItemAncestors, useMediaItemSearch } from 'src/features/shared/selectMediaModal/hooks';

export const useSelectMediaModal = (props: SelectMediaModalProps) => {
  const { preSelectedMediaId, selectedFolderIds, rootItemIdOrPath, onClose, selectType, onSelectedClick, sourceIds } = props;
  const { firstAncestorIds, isLoading: isFirsAncestorLoading } = useGetPreSelectedItemAncestors(preSelectedMediaId, sourceIds);

  const selectedFolderId = useMemo(() => {
    if (selectedFolderIds.length) {
      return selectedFolderIds[0];
    }

    if (firstAncestorIds.length) {
      return firstAncestorIds[0];
    }

    return rootItemIdOrPath ? rootItemIdOrPath : DEFAULT_MEDIA_LIBRARY_ID;
  }, [firstAncestorIds, rootItemIdOrPath, selectedFolderIds]);

  const { mediaDataItems, isMediaDataLoading, refetch, isWrongSource } = useGetMediaDataWrapper(selectedFolderId, selectType);

  const { searchInput, searchResult, setSearchInput } = useMediaItemSearch(mediaDataItems);

  const resetStateAndClose = useCallback(() => {
    setSearchInput('');
    onClose();
  }, [onClose, setSearchInput]);

  const resetStateAndSelect = useCallback(() => {
    setSearchInput('');
    onSelectedClick();
  }, [onSelectedClick, setSearchInput]);

  const selectedFolderIdsOrDefault = selectedFolderIds.length ? selectedFolderIds : firstAncestorIds;

  return {
    isFirsAncestorLoading,
    isMediaDataLoading,
    refetchMedia: refetch,
    isWrongSource,
    searchInput,
    searchResult,
    setSearchInput,
    resetStateAndClose,
    resetStateAndSelect,
    selectedFolderIdsOrDefault,
    selectedFolderId,
    mediaDataItems,
    firstAncestorIds,
  };
};
