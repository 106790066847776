import { useCallback } from 'react';
import { LINK_TYPES } from 'src/constants';
import { useSource } from 'src/features/fieldBuilder/hooks/useSource';
import { useSelectMediaContext } from 'src/features/shared/selectMediaModal/components/SelectMediaContext';
import { GENERAL_LINK, LinkButtonProps } from '../models';

export const useMediaLinkModal = ({ value, setFieldValue, submitForm, setFieldTouched, field }: LinkButtonProps) => {
  const { selectMedia } = useSelectMediaContext();
  const { sourceIds } = useSource(field);

  const onSelected = useCallback(
    (id: string) => {
      setFieldTouched(GENERAL_LINK.ID, false);
      setFieldValue(GENERAL_LINK.ID, id, false);
      setTimeout(() => submitForm()); // give a delay to update state
    },
    [setFieldTouched, setFieldValue, submitForm],
  );

  const open = useCallback(() => {
    selectMedia({
      onSelected,
      sourceIds,
      selectType: 'link',
      preSelectedMediaId: value?.linktype === LINK_TYPES.MEDIA ? value?.id : '',
    });
  }, [onSelected, selectMedia, sourceIds, value?.id, value?.linktype]);

  return { open };
};
