import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FC, useCallback } from 'react';
import { DICTIONARY } from 'src/constants';
import { itemNameValidationSchema } from 'src/features/common/components/createItem/validation/ItemNameValidationSchema';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { Disclosure } from 'src/features/common/models';
import { useDuplicateItemModal } from 'src/features/fieldEditor/hooks/useDuplicateItemModal';
import { DuplicateButtonProps } from './DuplicateButton';

interface Values {
  name: string;
}

export const DuplicateModal: FC<Disclosure & DuplicateButtonProps> = ({ onClose, itemId, name, onDuplicateItemSuccess }) => {
  const t = useTranslate();
  const { duplicateItem, isLoading } = useDuplicateItemModal(itemId, name, onClose, onDuplicateItemSuccess);

  const onFormSubmit = useCallback(
    async (value: Values, { setSubmitting }: FormikHelpers<Values>) => {
      setSubmitting(true);
      await duplicateItem(value.name);
    },
    [duplicateItem],
  );

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent onKeyDown={(e) => e.stopPropagation()}>
        <ModalHeader>
          <T _str={DICTIONARY.DUPLICATE_ITEM} />
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ name: t(DICTIONARY.COPY_OF_ITEM_NAME, { itemName: name || '' }) }}
          validationSchema={itemNameValidationSchema}
          onSubmit={onFormSubmit}
        >
          <Form>
            <ModalBody>
              <Box pb="8">
                <T _str={DICTIONARY.DUPLICATE_ITEM_INFO} />
              </Box>
              <Field name="name">
                {({ field, form }: any) => (
                  <FormControl isInvalid={form.errors.name && form.touched.name}>
                    <FormLabel>
                      <T _str={DICTIONARY.NEW_ITEM_NAME} />
                    </FormLabel>
                    <Input
                      {...field}
                      autoFocus
                      onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => event.target.select()}
                      disabled={form.isSubmitting}
                      data-testid="duplicate-item-name-input"
                    />
                    {form.errors.name && (
                      <Flex data-testid="duplicate-item-error-message">
                        <FormErrorMessage>
                          <Icon path={mdiAlertCircleOutline} boxSize="5" mr="2" />
                          <T _str={form.errors.name} />
                        </FormErrorMessage>
                      </Flex>
                    )}
                  </FormControl>
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button variant="ghost" data-testid="cancelBtn" onClick={onClose}>
                  <T _str={DICTIONARY.CANCEL} />
                </Button>
                <Button type="submit" data-testid="submit-btn" isDisabled={isLoading}>
                  <T _str={DICTIONARY.DUPLICATE} />
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};
