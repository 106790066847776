/* istanbul ignore file */

import { useState } from 'react';
import { TreeListItem } from 'src/features/shared/treeTable/models';
import { SortingOrder } from 'src/features/shared/treeTable/utils/treeItemFilter';

export type SortingParameters = {
  orderField?: keyof TreeListItem;
  sortingOrder?: SortingOrder;
};

export type useSortingParametersReturnType = {
  setOrderField: (value: keyof TreeListItem | undefined) => void;
  setSortingOrder: (order: SortingOrder) => void;
} & SortingParameters;

export const useSortingParameters = (): useSortingParametersReturnType => {
  const [orderField, setOrderField] = useState<keyof TreeListItem | undefined>();
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>();

  return { orderField, sortingOrder, setOrderField, setSortingOrder };
};
