import { FC } from 'react';
import { GenericField } from 'src/features/fieldBuilder/models';
import {
  Checkbox,
  Checklist,
  Datepicker,
  DateTime,
  Droplink,
  Droplist,
  Droptree,
  File,
  GeneralLink,
  Image,
  Multiline,
  Number,
  RichTextEditor,
  SingleLineText,
} from './fields';

export type AssignEditorType = { [key: string]: FC<GenericField> };

export const AssignEditor: AssignEditorType = {
  'multi-line text': Multiline,
  checkbox: Checkbox,
  date: Datepicker,
  datetime: DateTime,
  image: Image,
  file: File,
  default: Multiline,
  'single-line text': SingleLineText,
  integer: Number,
  number: Number,
  'rich text': RichTextEditor,
  'general link': GeneralLink,
  checklist: Checklist,
  droplink: Droplink,
  droplist: Droplist,
  droptree: Droptree,
};
