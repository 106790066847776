import { Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { mdiChevronDown, mdiContentCopy, mdiFormTextbox, mdiTrashCanOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { memo } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { DetailedItem } from 'src/features/common/models';
import { DeleteVersionAlertDialog, DuplicateVersionModal, RenameVersionModal } from '../modals';

export interface ActionListProps {
  item: DetailedItem;
  autoIncrVersion?: number;
  hasRename?: boolean;
  hasDuplicate?: boolean;
  hasDelete?: boolean;
}
export const ActionsList = memo(({ item, autoIncrVersion = 0, hasRename, hasDuplicate, hasDelete }: ActionListProps) => {
  const { isOpen: isOpenRename, onOpen: onOpenRename, onClose: onCloseRename } = useDisclosure();
  const { isOpen: isOpenDuplicate, onOpen: onOpenDuplicate, onClose: onCloseDuplicate } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          rightIcon={<Icon layerStyle="menuButtonIcon" path={mdiChevronDown} />}
          size="sm"
          ml="2"
          data-testid="versions-menu-button"
        >
          <T _str={DICTIONARY.MORE} />
        </MenuButton>
        <MenuList data-testid="versions-menu-list">
          {hasRename && (
            <MenuItem icon={<Icon path={mdiFormTextbox} />} onClick={onOpenRename}>
              <T _str={DICTIONARY.RENAME} />
            </MenuItem>
          )}
          {hasDuplicate && (
            <MenuItem icon={<Icon path={mdiContentCopy} />} onClick={onOpenDuplicate}>
              <T _str={DICTIONARY.DUPLICATE} />
            </MenuItem>
          )}
          {hasDelete && (
            <MenuItem color="danger" icon={<Icon color="danger" path={mdiTrashCanOutline} />} onClick={onOpenDelete}>
              <T _str={DICTIONARY.DELETE} />
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <>
        {hasRename && <RenameVersionModal item={item} isOpen={isOpenRename} onClose={onCloseRename} />}
        {hasDuplicate && (
          <DuplicateVersionModal
            version={item.version}
            autoIncrVersion={autoIncrVersion}
            isOpen={isOpenDuplicate}
            onClose={onCloseDuplicate}
          />
        )}
        {hasDelete && <DeleteVersionAlertDialog version={item.version} isOpen={isOpenDelete} onClose={onCloseDelete} />}
      </>
    </>
  );
});

ActionsList.displayName = ActionsList.name;
