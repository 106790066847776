import { Extension, ExtensionLifeCycleParams, windowEventExtensionRegistry } from '@sitecore/page-composer-sdk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

class SiteManagerExtension implements Extension {
  async mount(context: ExtensionLifeCycleParams): Promise<void> {
    const root = createRoot(context.rootElement);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  }

  async unmount(): Promise<void> {}
}

windowEventExtensionRegistry.define({
  name: 'sc-site-manager:app',
  extension: SiteManagerExtension,
});
