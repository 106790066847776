import { mdiEarth, mdiFileOutline, mdiFolderOutline } from '@mdi/js';
import { TEMPLATE_FOLDER } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { Workflow, WorkflowState } from 'src/features/common/models';
import { collectLanguages } from 'src/features/common/utils/collectLanguages';
import { ItemType } from 'src/features/millerColumns/models';
import { NestedItems, SitesOption, SolutionSite, TreeListItem } from 'src/features/shared/treeTable/models';

export const filterSites = (sites: SolutionSite[], options: Partial<SitesOption>): SolutionSite[] => {
  return sites
    ?.filter((f) => (options.showTemplateSites ? true : f.isTemplateSite !== 'true'))
    .filter((f) => (!options.sc_type ? true : options.sc_type === 'sxa' ? !!f.siteCollection : !f.siteCollection))
    .filter((f) => (!options.sc_collection ? true : f.siteCollection?.displayName === options.sc_collection));
};

export const formatSites = (sites: SolutionSite[], options: Partial<SitesOption>): TreeListItem[] => {
  const filteredSites = filterSites(sites, options);
  const siteColumns: TreeListItem[] = filteredSites.map((site) => {
    return {
      itemId: site.rootItem.itemId,
      name: site.name,
      displayName: site.displayName,
      displayNameWriteAccess: false,
      hasChildren: true,
      icon: <Icon boxSize="5" path={mdiEarth} />,
      thumbnailUrl: site.startItem.thumbnailUrl,
      type: 'site',
      workflow: {
        workflowState: {} as WorkflowState,
      },
      languages: [site.language.iso],
      createdBy: site.settings?.createdBy.value,
      updatedBy: site.settings?.updatedBy.value,
      hasPresentation: false,
    };
  });

  return siteColumns.length ? siteColumns : [];
};

export const formatItem = (item: NestedItems): TreeListItem => {
  let itemType: ItemType;
  let icon: JSX.Element;

  switch (item.template.name) {
    case TEMPLATE_FOLDER:
      itemType = 'folder';
      icon = <Icon boxSize="5" path={mdiFolderOutline} />;
      break;
    default:
      itemType = 'content';
      icon = <Icon boxSize="5" path={mdiFileOutline} />;
      break;
  }

  return {
    itemId: item.itemId,
    name: item.name,
    displayName: item.displayName?.value,
    displayNameWriteAccess: item.displayName?.access.canWrite || false,
    languages: collectLanguages(item.languages),
    icon,
    thumbnailUrl: item.thumbnailUrl,
    workflow: {
      workflow: item.workflow?.workflow || ({} as Workflow),
      workflowState: item.workflow?.workflowState || ({} as WorkflowState),
    },
    type: itemType,
    hasChildren: item.hasChildren,
    hasPresentation: item.hasPresentation,
    createdBy: item.createdBy?.value,
    updatedBy: item.updatedBy?.value,
    access: item.access,
    insertOptions: item.insertOptions,
    version: item.version,
  };
};
