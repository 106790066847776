import { Button, Flex, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, Text } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import { FC } from 'react';
import { MENU_HEIGHT } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useSelectItemFilter } from '../hooks';
import { SelectItemFilterProps } from '../models';

export const SelectItemFilter: FC<SelectItemFilterProps> = (props) => {
  const { collection, labelPrefix } = props;

  const { onFilterChange, selectedFilter } = useSelectItemFilter(props);

  return (
    <Menu>
      <MenuButton
        data-testid="selectItemFilter"
        as={Button}
        variant="outline"
        borderRadius="md"
        title={selectedFilter?.label}
        rightIcon={<Icon layerStyle="menuButtonIcon" path={mdiChevronDown} />}
      >
        <Flex direction="row">
          <Text marginRight={1}>{labelPrefix}</Text>
          <Text isTruncated>{selectedFilter?.label}</Text>
        </Flex>
      </MenuButton>
      <MenuList maxHeight={MENU_HEIGHT} overflowY="auto">
        <MenuOptionGroup type="radio" defaultValue={selectedFilter?.label}>
          {collection.map((item) => (
            <MenuItemOption value={item.label} key={item.id} onClick={() => onFilterChange(item.id as string)}>
              {item.label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
