import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useWorkflowListWrapper } from 'src/features/common/components/filters/hooks';
import { StateItem, WorkflowItem } from 'src/features/common/components/filters/models';
import { useCommonFilter } from 'src/features/search/hooks';
import { QUERIES_NAMES } from 'src/features/search/searchConstants';

export const useWorkflowFilter = () => {
  const data = useWorkflowListWrapper();
  const [params] = useSearchParams();
  const { onFilterChange } = useCommonFilter(QUERIES_NAMES.WORKFLOWS);

  const workflows = useMemo(() => {
    if (!data || !data.workflowList) {
      return [];
    }

    const workflows = params.get(QUERIES_NAMES.WORKFLOWS);
    const filterWorkflows = workflows?.split(',') ?? [];

    return data.workflowList.map((workflow: WorkflowItem) => ({
      label: workflow.displayName,
      value: workflow.workflowId,
      children: workflow.states?.nodes?.map((state: StateItem) => ({
        checked: filterWorkflows.includes(state.stateId),
        label: state.displayName,
        value: state.stateId,
      })),
    }));
  }, [data, params]);

  return { workflows, onFilterChange };
};
