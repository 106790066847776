import { useToast } from '@chakra-ui/react';
import { FC, Fragment, memo, useState } from 'react';
import { TreeTableLoadingRow } from 'src/features/shared/treeTable/components/TreeTableLoadingRow';
import { RefetchFunction } from 'src/features/shared/treeTable/components/TreeTableRefetchContext';
import { TreeTr } from 'src/features/shared/treeTable/components/TreeTr';
import { OpenFieldEditorAndSetContext } from 'src/features/shared/treeTable/hooks/useOpenEditorAndSetContext';
import { ShowOptions } from 'src/features/shared/treeTable/hooks/useOptions';
import { useRefetchCurrentRow } from 'src/features/shared/treeTable/hooks/useRefetchCurrentRow';
import { SortingParameters } from 'src/features/shared/treeTable/hooks/useSortingParameters';
import { useTreeListItemsArray } from 'src/features/shared/treeTable/hooks/useTreeListItemsArray';
import { useUpdateRowProps } from 'src/features/shared/treeTable/hooks/useUpdateRowProps';
import { ParentData, TreeListItem } from 'src/features/shared/treeTable/models';

export type TreeTableRowProps = {
  currentLanguage: string;
  subRowLvl: number;
  refetchParent?: RefetchFunction;
  openFieldEditor: OpenFieldEditorAndSetContext;
  initiallyExpanded?: boolean;
} & TreeListItem &
  SortingParameters &
  ParentData &
  ShowOptions;

const TreeTableRowComponent: FC<TreeTableRowProps> = (props) => {
  const {
    advancedItemsExcludeIds,
    showHiddenItems,
    currentLanguage,
    subRowLvl,
    orderField,
    sortingOrder,
    refetchParent,
    openFieldEditor,
    initiallyExpanded,

    itemId,
    access,
    insertOptions,
  } = props;

  const toast = useToast();
  const [itemCache, setItemsCache] = useState<TreeListItem[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(initiallyExpanded || false);

  const { items, item, isLoading, refetch } = useTreeListItemsArray({
    itemCache,
    setItemsCache,
    id: itemId,
    excludeTemplateIDs: advancedItemsExcludeIds,
    includeHiddenItems: showHiddenItems,
    language: currentLanguage,
    isExpanded,
    orderField,
    sortingOrder,
    toast,
  });

  const nameCellPadding = `${subRowLvl * 1.5 + 1}rem`;

  const updatedProps = useUpdateRowProps(props, item);

  const refetchCurrent = useRefetchCurrentRow(item, isExpanded, setIsExpanded, refetch);

  return (
    <Fragment>
      <TreeTr
        {...updatedProps}
        setIsExpanded={setIsExpanded}
        onOpenEditor={() => openFieldEditor(itemId, refetchParent)}
        isExpanded={isExpanded}
        refetch={refetchCurrent}
        nameCellPadding={nameCellPadding}
      />
      {isExpanded &&
        items?.map((subItem) => (
          <TreeTableRow
            key={subItem.itemId}
            {...props}
            {...subItem}
            subRowLvl={subRowLvl + 1}
            refetchParent={refetch}
            parentId={itemId}
            parentAccess={access}
            parentInsertOptions={insertOptions}
            initiallyExpanded={false}
          />
        ))}
      {isLoading && <TreeTableLoadingRow itemsLength={items.length} {...props} />}
    </Fragment>
  );
};

export const TreeTableRow = memo(TreeTableRowComponent);
