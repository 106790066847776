import { useMemo } from 'react';
import { CLIENT_LANGUAGE } from 'src/constants';
import { AncestorsResponseData } from 'src/features/common/treeComponent/models';
import { useGetAncestors } from 'src/features/shared/selectMediaModal/hooks/useGetAncestors';

export const useGetAncestorsWrapper = (itemId: string, enabled: boolean): AncestorsResponseData => {
  const { data, isLoading } = useGetAncestors({ id: itemId, lang: CLIENT_LANGUAGE }, { enabled });

  const ancestors = useMemo(() => {
    if (isLoading) return;

    const list = data?.data.data.item.ancestors;
    return list?.map<string>((anc) => anc.itemId);
  }, [data, isLoading]);

  return { ancestors, isLoading };
};
