import { FC } from 'react';
import { Card, CardFileInfo, CardImage, CardInfoPanel, CardMediaDimensions } from 'src/features/media/card/Card';
import { ImageCardProps } from 'src/features/media/card/model/card.model';

export const ImageCard: FC<ImageCardProps> = ({ id, isSelected, src, extension, size, title, dimensions, onSelect }) => (
  <Card id={id} isSelected={isSelected} onSelect={onSelect}>
    <CardImage src={src} />
    <CardInfoPanel title={title}>
      <CardFileInfo extension={extension} size={size} />
      <CardMediaDimensions dimensions={dimensions} />
    </CardInfoPanel>
  </Card>
);
