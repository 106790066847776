export const VALIDATIONS = {
  INTEGER_MESSAGE: 'The integer is out of range',
  NUMBER_MESSAGE: 'The number is out of range',
  NUMBER_MAX_VALUE: Number.MAX_SAFE_INTEGER,
  NUMBER_MIN_VALUE: Number.MIN_SAFE_INTEGER,
  NAME_REQUIRED: 'Name is required',
  NAME_VALIDATION_TRIM: 'The item name cannot include leading or trailing spaces',
  NAME_VALIDATION_MATCHES: 'Invalid characters. Allowed are A–Z, a–z, 0–9, hyphen (-), underscore (_) and spaces',
  NAME_VALIDATION_NUMBER: 100,
  NAME_VALIDATION_MAX: 'Max 100 characters allowed',
  PATH_VALIDATION_REQUIRED: 'Path is required',
  URL_VALIDATION_REQUIRED: 'URL is required',
  TEXT_VALIDATION_REQUIRED: 'Link text is required',
};
