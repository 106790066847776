import { mdiChevronDown, mdiClose } from '@mdi/js';
import { useCallback, useMemo } from 'react';
import { UseMenuWithRadio } from 'src/features/common/components/menuWithRadio/models';

export const useMenuWithRadio: UseMenuWithRadio = (options, onChange) => {
  const checkedIndex = useMemo(() => options.findIndex((option) => option.isChecked), [options]);

  const icon = checkedIndex >= 0 ? mdiClose : mdiChevronDown;

  const onRadioChange = useCallback((value: string) => onChange(value), [onChange]);

  const onCloseClick = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      if (checkedIndex >= 0) {
        event.stopPropagation();
        onChange('');
      }
    },
    [checkedIndex, onChange],
  );

  return { icon, onCloseClick, onRadioChange, checkedIndex };
};
