import { Flex, Link } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { getAppLink } from 'src/features/common/config';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { GenericErrorTemplate } from 'src/features/temporary/GenericErrorTemplate';

export const NoAccessPage: FC = () => {
  const t = useTranslate();

  return (
    <Flex pb="4" overflowY="auto">
      <GenericErrorTemplate
        title={t(DICTIONARY.ACCESS_DENIED_TITLE)}
        subtext={t(DICTIONARY.ACCESS_DENIED_SUBTEXT)}
        text={t(DICTIONARY.ACCESS_DENIED_TEXT)}
        variant="access-denied"
        shortcuts={
          <Link pb={4} href={getAppLink({ app: 'portal' })}>
            <T _str={DICTIONARY.ACCESS_DENIED_LINK_TEXT} />
          </Link>
        }
      />
    </Flex>
  );
};
