import { Flex, FlexProps, FormLabel, Image, Input } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { MediaPreviewProps } from 'src/features/media/models';
import { MediaLightbox } from './MediaLightbox';

const overlayStyles = {
  position: 'absolute',
  borderRadius: 'base',
  boxSize: 'full',
} as FlexProps;

export const MediaPreview: FC<React.PropsWithChildren<MediaPreviewProps>> = ({ mediaItem, altText, canEdit, children, onAltChange }) => {
  return (
    <Flex p="3" gap="2" backgroundColor="neutral.50" borderRadius="base">
      <MediaLightbox
        imageUrl={mediaItem.fullImageUrl}
        alt={altText}
        title={`${mediaItem.innerItem.displayName}.${mediaItem.extension}`}
        overlayStyles={overlayStyles}
      >
        <Image boxSize="32" src={mediaItem.url} borderRadius="base" objectFit="contain" />
      </MediaLightbox>
      <Flex direction="column" width="full">
        <Flex direction="column" height="full" gap="2">
          <FormLabel mb="none">
            <T _str={DICTIONARY.ALT_TEXT} />
          </FormLabel>
          <Input
            data-testid="sc-image-media-preview-alt"
            style={{ backgroundColor: 'white' }}
            isDisabled={!canEdit}
            defaultValue={altText}
            onBlur={(event: React.FocusEvent<HTMLInputElement, Element>) => onAltChange(event.target.value)}
          />
        </Flex>
        <Flex gap="2">{children}</Flex>
      </Flex>
    </Flex>
  );
};
