import { ButtonProps } from '@chakra-ui/react';

export const defaultButtonStyleProps: ButtonProps = {
  variant: 'ghost',
  borderRadius: 'lg',
  flex: 0,
  justifyContent: 'flex-start',
  height: 'auto',
  w: '100%',
  minHeight: '60px',
  textAlign: 'left',
  p: 2,
  _focusWithin: { zIndex: 1 },
  _hover: { backgroundColor: 'blackAlpha.50' },
  _active: { backgroundColor: 'blackAlpha.100' },
};
