import { MenuItemOption } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useCallback } from 'react';
import { SELECTED_SHOW_OPTIONS_DEFAULT_VALUES } from 'src/constants';
import { useLocalStorage } from 'usehooks-ts';

export type ActionCheckboxProps = {
  storageKey: string;
  text: string;
  onClick?: (checked: boolean) => void;
};

export const ActionCheckbox: FC<ActionCheckboxProps> = ({ storageKey, text, onClick }) => {
  const [checked, onChecked] = useLocalStorage(storageKey, SELECTED_SHOW_OPTIONS_DEFAULT_VALUES[storageKey]);
  const onClickAction = useCallback(() => {
    onChecked(!checked);
    if (onClick) {
      onClick(!checked);
    }
  }, [checked, onChecked, onClick]);

  return (
    <MenuItemOption value={storageKey} isChecked={checked} onClick={onClickAction} data-testid={storageKey} fontSize="md">
      <T _str={text} />
    </MenuItemOption>
  );
};
