import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DICTIONARY } from 'src/constants';
import { QUERIES_NAMES, SEARCH_IN_PARAM_VALUE } from 'src/features/search/searchConstants';

export const useSearchTypeFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { type, value } = useMemo(() => {
    const searchIn = searchParams.get(QUERIES_NAMES.SEARCH_IN) ?? SEARCH_IN_PARAM_VALUE.ALL;

    switch (searchIn) {
      case SEARCH_IN_PARAM_VALUE.NAME:
        return { value: DICTIONARY.ITEM_NAME_ONLY, type: SEARCH_IN_PARAM_VALUE.NAME };
      case SEARCH_IN_PARAM_VALUE.FIELDS:
        return { value: DICTIONARY.ITEM_FIELDS_ONLY, type: SEARCH_IN_PARAM_VALUE.FIELDS };
      default:
        return { value: DICTIONARY.ENTIRE_ITEM, type: SEARCH_IN_PARAM_VALUE.ALL };
    }
  }, [searchParams]);

  const onTypeChange = useCallback(
    (value: string | string[]) => {
      setSearchParams((params) => {
        switch (value) {
          case SEARCH_IN_PARAM_VALUE.NAME:
            params.set(QUERIES_NAMES.SEARCH_IN, SEARCH_IN_PARAM_VALUE.NAME);
            break;
          case SEARCH_IN_PARAM_VALUE.FIELDS:
            params.set(QUERIES_NAMES.SEARCH_IN, SEARCH_IN_PARAM_VALUE.FIELDS);
            break;
          default:
            params.delete(QUERIES_NAMES.SEARCH_IN);
        }

        return params;
      });
    },
    [setSearchParams],
  );

  return { type, value, onTypeChange };
};
