import { useCallback, useTransition } from 'react';
import { RefetchFunction, useTreeTableSetRefetchContext } from 'src/features/shared/treeTable/components/TreeTableRefetchContext';

export type OpenFieldEditorAndSetContext = (itemId: string, refetchParent: RefetchFunction) => void;

export const useOpenEditorAndSetContext = (openFieldEditor: (itemId: string) => void): OpenFieldEditorAndSetContext => {
  const [, startTransition] = useTransition();
  const setRefetchParent = useTreeTableSetRefetchContext();

  return useCallback(
    (itemId: string, refetchParent: RefetchFunction) => {
      startTransition(() => openFieldEditor(itemId));
      setRefetchParent(refetchParent);
    },
    [openFieldEditor, setRefetchParent],
  );
};
