import { AxiosInstance, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseInfiniteQueryOptions } from 'react-query/types/react';
import { useAuthInfiniteQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { FieldSourceResponse } from 'src/features/fieldBuilder/models';
import { useGetFieldSourceQueryString } from 'src/features/fieldBuilder/queries';

type ItemGraphQLResponse = GraphQLResponse<FieldSourceResponse>;
type ItemResponse = AxiosResponse<ItemGraphQLResponse>;

export const useGetFieldSource = (
  fieldId: string,
  fieldSource: string,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<ItemResponse, unknown, ItemResponse>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const { sc_itemid, sc_lang, sc_version } = useUrlStrictContext();
  const { url } = useXMTenant();

  const { data, isLoading, fetchNextPage } = useAuthInfiniteQuery<ItemResponse, unknown, ItemResponse>(
    ['get-item-field-source', sc_itemid, sc_lang, sc_version?.toString(), fieldId],
    async (axiosInstance: AxiosInstance, cursor) => {
      return axiosInstance?.post<ItemGraphQLResponse>(getSitecoreGraphQLEndpoint(url), {
        query: useGetFieldSourceQueryString,
        variables: { id: sc_itemid, lang: sc_lang, ver: sc_version, fieldId, cursor },
      });
    },
    {
      ...useQueryOptions,
      enabled: !!fieldSource,
      keepPreviousData: true,
      onSuccess: (data) => {
        const lastPage = data.pages[data.pages.length ? data.pages.length - 1 : 0];
        const pI = lastPage.data?.data?.item?.field?.templateField?.dataSource?.items?.pageInfo || { endCursor: '', hasNextPage: false };

        if (pI.hasNextPage) {
          fetchNextPage({ pageParam: pI.endCursor });
        }
      },
    },
  );

  const source = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return data?.pages.flatMap((page) => page?.data?.data?.item?.field?.templateField?.dataSource?.items?.nodes || []) || [];
  }, [data, isLoading]);

  return { source, isLoading };
};
