import { ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { CreateItem } from 'src/features/common/components/createItem/CreateItem';
import { MillerColumnsData } from 'src/features/millerColumns/models';

interface MillerColumnCreateProps extends MillerColumnsData {}
const createStyle: ButtonProps = { size: 'sm', variant: 'ghost', width: 'fit-content', mt: 2 };

export const MillerColumnCreate: FC<MillerColumnCreateProps> = ({ insertOptions, parent, access }) => (
  <CreateItem insertOptions={insertOptions} parent={parent} canCreate={access.canCreate} menuConfig={createStyle} />
);
