import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { mdiViewColumnOutline, mdiViewListOutline } from '@mdi/js';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { viewModes, ViewSwitcherModes } from 'src/features/common/components/actionsBar/models';
import 'src/features/common/components/actionsBar/styles/switch.scss';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useFeature } from 'src/features/common/flags/features';
import { useTranslate } from 'src/features/common/hooks/useTranslate';

export type ViewSwitcherProps = {
  mode: ViewSwitcherModes;
  onModeChange: (newMode: ViewSwitcherModes) => void;
};

const buttonProps = {
  active: { isActive: true, variant: 'ghost', colorScheme: 'primary' },
  inactive: { isActive: false, variant: 'ghost', colorScheme: 'neutral', color: 'neutral.600' },
};

const getButtonProps = (currentMode: ViewSwitcherModes, expectedMode: ViewSwitcherModes) =>
  currentMode === expectedMode ? buttonProps.active : buttonProps.inactive;

export const ViewSwitcher: FC<ViewSwitcherProps> = ({ mode, onModeChange }: ViewSwitcherProps) => {
  const t = useTranslate();
  const isEnabled = useFeature('xm_additionalListView');

  if (!isEnabled) return <></>;

  return (
    <ButtonGroup
      variant="ghost"
      borderRadius="full"
      border="1px"
      borderColor="chakra-border-color"
      background="chakra-body-bg"
      padding="1"
      spacing="0"
    >
      <IconButton
        className={`switch-button ${mode === 'columns' ? 'active' : ''}`}
        {...getButtonProps(mode, viewModes.columns)}
        onClick={() => onModeChange(viewModes.columns)}
        aria-label={t(DICTIONARY.COLUMNS_VIEW)}
        size="sm"
        data-testid="columns-button"
        icon={<Icon boxSize="5" path={mdiViewColumnOutline} />}
      />
      <IconButton
        className={`switch-button ${mode === 'list' ? 'active' : ''}`}
        {...getButtonProps(mode, viewModes.list)}
        onClick={() => onModeChange(viewModes.list)}
        aria-label={t(DICTIONARY.LIST_VIEW)}
        size="sm"
        data-testid="list-button"
        icon={<Icon boxSize="5" path={mdiViewListOutline} />}
      />
    </ButtonGroup>
  );
};

ViewSwitcher.defaultProps = {
  mode: 'columns',
  onModeChange: () => {},
};
