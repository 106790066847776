import { Button, Flex, IconButton, MenuButton, Text, useMediaQuery } from '@chakra-ui/react';
import { mdiChevronDown, mdiTuneVariant } from '@mdi/js';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';

type Props = { value: string };

export const SearchTypeFilterButton: FC<Props> = ({ value }) => {
  const [isLargerThanMd] = useMediaQuery('(min-width: 769px)');

  if (isLargerThanMd)
    return (
      <MenuButton
        data-testid="searchTypes"
        as={Button}
        variant="outline"
        borderRadius="md"
        rightIcon={<Icon layerStyle="menuButtonIcon" path={mdiChevronDown} />}
      >
        <Flex direction="row" minWidth="fit-content" data-testid="siteTypes-text">
          <Text>
            <T _str={DICTIONARY.SEARCH_IN_COLON} />
          </Text>
          &nbsp;
          <T _str={value} />
        </Flex>
      </MenuButton>
    );

  return <MenuButton data-testid="searchTypes" as={IconButton} variant="filter" icon={<Icon path={mdiTuneVariant} />} />;
};
