import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QUERIES_NAMES } from 'src/features/search/searchConstants';

export const useQueryKeys = () => {
  const [params] = useSearchParams();

  return useMemo(() => {
    const keys: string[] = [];
    Object.values(QUERIES_NAMES).forEach((key) => {
      keys.push(params.get(key) || '');
    });

    return keys;
  }, [params]);
};
