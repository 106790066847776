import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { Button, Text, useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useMemo } from 'react';
import { DICTIONARY } from 'src/constants';
import { SitecoreUser, TokenCustomClaimKeysEnum } from './SitecoreUser';

export interface SitecoreAuthClaimOptions {
  tenant_id?: string;
  organization_id?: string;
}

/**
 * Ensures that getAccessTokenSilently always includes organization_id and tenant_id parameters (if user contains one or both of those claims).
 */
export function useAuthWithClaims() {
  const auth0 = useAuth0<SitecoreUser>();
  const toast = useToast();

  const authWithClaims = useMemo(
    () => ({
      ...auth0,
      getAccessTokenSilently: (options?: GetTokenSilentlyOptions & SitecoreAuthClaimOptions): Promise<string> =>
        auth0
          .getAccessTokenSilently({
            tenant_id: auth0.user?.[TokenCustomClaimKeysEnum.TENANT_ID],
            organization_id: auth0.user?.[TokenCustomClaimKeysEnum.ORG_ID],
            ...options,
          })
          .catch((error) => {
            // Likely something wrong with org or tenant id.
            // Either organization/tenant doesn't exist, or tenant doesn't belong to specified org.
            // Can also be that user doesn't have access to org/tenant.
            // There is also a few other reasons this can trigger like audience and scope.
            return new Promise(() => {
              toast({
                status: 'error',
                title: <T _str={DICTIONARY.FAILED_TO_AUTHORIZE} />,
                description: (
                  <>
                    <Text my="1" wordBreak="break-word">
                      {error?.message ?? error?.toString()}
                    </Text>
                    <Button variant="outline" marginTop="2.5" size="sm" onClick={async () => await auth0.loginWithRedirect()}>
                      <T _str={DICTIONARY.lOGIN_AGAIN} />
                    </Button>
                  </>
                ),
              });
            });
          }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth0],
  );

  return authWithClaims;
}
