import { AxiosInstance, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseInfiniteQueryOptions } from 'react-query/types/react';
import { CACHE_LIFETIME } from 'src/constants';
import { useAuthInfiniteQuery } from 'src/features/common/apiUtils/queryHelper';
import { UserNodes } from 'src/features/common/components/filters/models';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { getUsersString } from '../queries';

type UsersGraphQLResponse = GraphQLResponse<UserNodes>;
type UsersResponse = AxiosResponse<UsersGraphQLResponse>;

export const useGetUsers = (
  useQueryOptions?: Omit<UseInfiniteQueryOptions<UsersResponse, unknown, UsersResponse>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const { url } = useXMTenant();

  const { data, isLoading, fetchNextPage } = useAuthInfiniteQuery<UsersResponse, unknown, UsersResponse>(
    ['get-users'],
    async (axiosInstance: AxiosInstance, cursor) => {
      return axiosInstance?.post<UsersGraphQLResponse>(getSitecoreGraphQLEndpoint(url), {
        query: getUsersString,
        variables: { cursor },
      });
    },
    {
      ...useQueryOptions,
      staleTime: CACHE_LIFETIME,
      keepPreviousData: true,
      onSuccess: (data) => {
        const lastPage = data.pages[data.pages.length ? data.pages.length - 1 : 0];
        const pI = lastPage.data?.data?.users?.pageInfo || { endCursor: '', hasNextPage: false };

        if (pI.hasNextPage) {
          fetchNextPage({ pageParam: pI.endCursor });
        }
      },
    },
  );

  const users = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return data?.pages.flatMap((page) => page?.data?.data?.users?.nodes || []) || [];
  }, [data, isLoading]);

  return { users, isLoading };
};
