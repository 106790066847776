import { Button, Flex, ModalHeader, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { SelectMediaModalProps } from 'src/features/shared/selectMediaModal/components/SelectMediaModal';

export type MediaModalHeaderProps = {
  resetStateAndClose: () => void;
  onSelect: () => void;
  isSelectDisabled: boolean;
} & Pick<SelectMediaModalProps, 'selectType'>;

export const MediaModalHeader: FC<MediaModalHeaderProps> = ({ selectType, resetStateAndClose, onSelect, isSelectDisabled }) => {
  return (
    <ModalHeader fontSize="2xl">
      <Flex direction="row" justifyContent="space-between" gap={3}>
        <Text>
          {selectType === 'image' && <T _str={DICTIONARY.SELECT_IMAGE} />}
          {selectType === 'file' && <T _str={DICTIONARY.SELECT_FILE} />}
          {selectType === 'link' && <T _str={DICTIONARY.SELECT_FILE_LINK} />}
        </Text>
        <Flex direction="row" gap={2}>
          <Button onClick={resetStateAndClose} variant="outline">
            <T _str={DICTIONARY.CANCEL} />
          </Button>
          <Button variant="solid" onClick={onSelect} isDisabled={isSelectDisabled}>
            {selectType === 'image' && <T _str={DICTIONARY.ADD_IMAGE} />}
            {selectType === 'file' && <T _str={DICTIONARY.ADD_FILE} />}
            {selectType === 'link' && <T _str={DICTIONARY.LINK_FILE} />}
          </Button>
        </Flex>
      </Flex>
    </ModalHeader>
  );
};
