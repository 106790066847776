import { useToast } from '@chakra-ui/toast';
import { AxiosInstance, AxiosResponse } from 'axios';
import {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from 'react-query';
import { DICTIONARY } from 'src/constants';
import { useAuthenticatedAxios } from './AxiosProvider';

type Toast = ReturnType<typeof useToast>;

const customGQLErrorHandler = (response: unknown, toast?: Toast) => {
  const errorResponse: AxiosResponse = response as AxiosResponse;
  if (errorResponse.status === 200 && errorResponse.data.errors && toast) {
    errorResponse.data.errors.forEach((err: { message: string }) => {
      toast({
        status: 'error',
        description: err ? err.message : DICTIONARY.NOTIFICATIONS.SOMETHING_WENT_WRONG,
        duration: 5000,
      });
    });
  }
};

export interface UseAuthQueryOptions extends Omit<UseQueryOptions, 'queryKey' | 'queryFn'> {}
// Needs to be used inside Auth0Context and AxiosProvider
export const useAuthQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  key: TQueryKey,
  fetcher: (
    axiosInstanceWithAuth: AxiosInstance,
    queryFnContext?: QueryFunctionContext<QueryKey, TQueryKey>,
  ) => TQueryFnData | Promise<TQueryFnData>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
  toast?: Toast,
): UseQueryResult<TData, TError> => {
  const axiosContext = useAuthenticatedAxios();
  if (!axiosContext) throw new Error('useAuthQuery can not be used outside of auth and axios context');

  const enhancedFetcher: QueryFunction<TQueryFnData, TQueryKey> = (params) => fetcher(axiosContext, params);
  options = options || {};

  const onSuccessCallback = options.onSuccess;

  options.onSuccess = (res) => {
    if (onSuccessCallback) {
      customGQLErrorHandler(res, toast);
      onSuccessCallback(res);
    }
  };

  return useQuery<TQueryFnData, TError, TData, TQueryKey>(key, enhancedFetcher, options);
};

export const useAuthInfiniteQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  key: TQueryKey,
  fetcher: (
    axiosInstanceWithAuth: AxiosInstance,
    cursor: string,
    queryFnContext?: UseInfiniteQueryOptions<QueryKey, TQueryKey>,
  ) => TQueryFnData | Promise<TQueryFnData>,
  options?: Omit<UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>, 'queryKey' | 'queryFn'>,
  toast?: Toast,
): UseInfiniteQueryResult<TData, TError> => {
  const axiosContext = useAuthenticatedAxios();
  if (!axiosContext) throw new Error('useInfiniteQuery can not be used outside of auth and axios context');

  options = options || {};

  const enhancedFetcher: QueryFunction<TQueryFnData, TQueryKey> = (params) => {
    return fetcher(axiosContext, params.pageParam, params);
  };

  const onSuccessCallback = options.onSuccess;
  options.onSuccess = (data: InfiniteData<TData>) => {
    if (onSuccessCallback) {
      customGQLErrorHandler(data, toast);
      onSuccessCallback(data);
    }
  };

  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(key, enhancedFetcher, options);
};

// Needs to be used inside Auth0Context and AxiosProvider
export const useAuthMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: (axiosInstanceWithAuth: AxiosInstance) => MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const axiosContext = useAuthenticatedAxios();

  if (!axiosContext) throw new Error('useAuthMutation can not be used outside of auth and axios context');

  options = options || {};

  const enhancedMutationFn: MutationFunction<TData, TVariables> = mutationFn(axiosContext);

  return useMutation<TData, TError, TVariables, TContext>(enhancedMutationFn, options);
};
