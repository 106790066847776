import { ExecuteWorkflowCommandInput } from 'src/features/common/models';

export const workflow = {
  get_workflow_commands: (itemId: string, language: string, workflowId: string, stateId: string) => {
    return {
      query: `
      query GetWorkflowCommands($workflow: WorkflowIdOrItemQueryInput!, $workflowCommands: WorkflowStateOrItemQueryInput!) {
        workflow(where: $workflow) {
          commands(query: $workflowCommands) {
            nodes {
              commandId
              displayName
              suppressComments
            }
          }
        }
      }
      `,
      variables: {
        workflow: {
          item: {
            itemId,
            language,
          },
          workflowId,
        },
        workflowCommands: {
          item: {
            itemId,
            language,
          },
          stateId,
        },
      },
    };
  },

  execute_workflow_command: (input: ExecuteWorkflowCommandInput) => {
    return {
      query: `
      mutation ExecuteWorkflowCommand($input: ExecuteWorkflowCommandInput!){
        executeWorkflowCommand(input: $input) {
          completed
          error
          message
          nextStateId
          successful
        }
      }
      `,
      variables: { input },
    };
  },
};
