import { Flex, Link, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'src/constants';
import { GenericErrorTemplate } from 'src/features/temporary/GenericErrorTemplate';

export const GenericErrorPage: FC = () => (
  <Flex pb="4" overflowY="auto">
    <GenericErrorTemplate
      title={<T _str={DICTIONARY.GENERIC_ERROR.TITLE} />}
      text={
        <>
          <Text as="span">
            <T _str={DICTIONARY.GENERIC_ERROR.DESCRIPTION} />
          </Text>
          <Link pb={4} href={SC_ICONS_SRC.SUPPORT_PAGE_LINK}>
            <T _str={DICTIONARY.GENERIC_ERROR.LINK_TEXT} />
          </Link>
          <Text as="span">
            <T _str={DICTIONARY.GENERIC_ERROR.DOT} />
          </Text>
        </>
      }
      variant="error"
    />
  </Flex>
);
