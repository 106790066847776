import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useCommonFilter = (queryName: string) => {
  const [params, setSearchParams] = useSearchParams();

  const onFilterChange = useCallback(
    (value: string[]) => {
      value.length ? params.set(queryName, value.join(',')) : params.delete(queryName);
      setSearchParams(params);
    },
    [params, queryName, setSearchParams],
  );

  return { onFilterChange };
};
