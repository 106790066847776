import { Box, Flex, IconButton, Image, ImageProps, Text } from '@chakra-ui/react';
import { mdiMenuDown, mdiMenuRight } from '@mdi/js';
import { FC, useCallback } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useTranslate } from 'src/features/common/hooks/useTranslate';

const thumbnailUrlProps: ImageProps = { borderRadius: 'base', w: '9', h: '9', margin: '1px', borderWidth: '1px', borderStyle: 'solid' };

export const NameCell: FC<{
  name: string;
  canExpand: boolean;
  isExpanded?: boolean;
  toggleExpanded?: () => void;
  thumbnailUrl?: string;
  showThumbnail?: boolean;
  icon: JSX.Element;
  noChildrenSpace: string | number;
}> = ({ name, canExpand, isExpanded, toggleExpanded, thumbnailUrl, showThumbnail, icon, noChildrenSpace }) => {
  const t = useTranslate();
  const expandIcon = isExpanded ? mdiMenuDown : mdiMenuRight;

  const onExpand = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleExpanded?.();
      event.stopPropagation();
    },
    [toggleExpanded],
  );

  return (
    <Flex gap="2" minWidth={{ md: '2xs', lg: 'xs', xl: 'sm' }} boxSizing="unset" alignItems="center" marginLeft={noChildrenSpace}>
      {canExpand && (
        <IconButton
          icon={<Icon path={expandIcon} />}
          variant="ghost"
          size="sm"
          aria-label={isExpanded ? t(DICTIONARY.COLLAPSE) : t(DICTIONARY.EXPAND)}
          onClick={onExpand}
        />
      )}
      <Box flexShrink="0">
        {showThumbnail && thumbnailUrl ? (
          <Image {...thumbnailUrlProps} src={thumbnailUrl} />
        ) : (
          <Box borderRadius="base" background="chakra-subtle-bg" borderWidth="1" padding="2">
            {icon}
          </Box>
        )}
      </Box>
      <Text isTruncated maxWidth="16.75rem" noOfLines={2} whiteSpace="unset" title={name} fontWeight="semibold" data-testid="tableItemName">
        {name}
      </Text>
    </Flex>
  );
};
