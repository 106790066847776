import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { createAuthAxios } from './customAxios';

export const AxiosContext = React.createContext(axios.create());

// Needs to be used inside Auth0Context
export const AuthenticatedAxiosProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  const authAxios = createAuthAxios(axios.create());
  authAxios.interceptors.request.use(async (request: AxiosRequestConfig<unknown>) => {
    const token = await getAccessTokenSilently();

    request && request.headers && (request.headers.Authorization = `Bearer ${token}`);

    return request;
  });
  return <AxiosContext.Provider value={authAxios}>{props.children}</AxiosContext.Provider>;
};

export const useAuthenticatedAxios = () => {
  return React.useContext(AxiosContext);
};
