import { Flex, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { ReactComponent as FileDocumentIcon } from 'src/features/common/images/file-document-outline.svg';
import { GenericFieldView } from 'src/features/fieldBuilder/components/fields/GenericFieldView';
import { useFile } from 'src/features/fieldBuilder/components/fields/hooks/useFile';
import { GenericField } from 'src/features/fieldBuilder/models';
import { SelectedItemFileView, UploadArea } from 'src/features/media/components';
import { CleanFieldButton } from 'src/features/shared/cleanFieldButton/CleanFieldButton';
import { SelectedItemCard } from 'src/features/shared/selectedItemCard/SelectedItemCard';

const tooltips = { dropMessage: DICTIONARY.DROP_FILE, supportTypes: DICTIONARY.FILE_UPLOAD_TEXT };

export const File: FC<GenericField> = ({ field }) => {
  const { builderField, value, mediaItem, uploadPath, sourceIds, errorMsg, fileName, fileSize, onChange, onReset, onRemove, onLink } =
    useFile({
      field,
    });

  return (
    <GenericFieldView field={builderField} errorMsg={errorMsg} onReset={onReset}>
      <Text>
        <T _str={DICTIONARY.FILE_FIELD_TITLE} />
      </Text>
      <Flex gap="3" pt="2" direction="column">
        <UploadArea
          type="file"
          tooltips={tooltips}
          onUpload={(result) => onChange({ id: result.Id })}
          onSelect={(id) => onChange({ id })}
          canEdit={builderField.canEdit}
          allowedExtensions={[]}
          uploadPath={uploadPath}
          sourceIds={sourceIds}
          mediaId={value.mediaid}
        />
        {value.mediaid && mediaItem && (
          <SelectedItemCard
            isDisabled={!field.canEdit}
            icon={<FileDocumentIcon />}
            actions={field.canEdit && <CleanFieldButton onClean={onRemove} />}
          >
            <SelectedItemFileView name={fileName} size={fileSize} onLink={onLink} />
          </SelectedItemCard>
        )}
      </Flex>
    </GenericFieldView>
  );
};
