/* istanbul ignore file */

import { FC } from 'react';
import { CreateItemMenu } from 'src/features/shared/createItem/components/CreateItemMenu';
import { useTreeTableCreateItemMenuWrapper } from 'src/features/shared/createItem/hooks';
import { ActionsCellProps } from 'src/features/shared/treeTable/components/ActionsCell';

export const TreeTableCreateItemMenuWrapper: FC<ActionsCellProps> = ({
  insertOptions,
  access,
  parentAccess,
  parentInsertOptions,
  parentId,
  itemId,
  refetchParent,
  refetchCurrent,
}) => {
  const onCreateSuccess = useTreeTableCreateItemMenuWrapper(refetchParent, refetchCurrent);

  return (
    <CreateItemMenu
      insertOptions={insertOptions || []}
      canCreate={access?.canCreate || false}
      parentCanCreate={parentAccess?.canCreate || false}
      parentInsertOptions={parentInsertOptions || []}
      parent={parentId || ''}
      itemId={itemId}
      onCreateSuccess={onCreateSuccess}
    />
  );
};
