/* istanbul ignore file */

import { useMemo } from 'react';
import * as Yup from 'yup';

export const useValidationSchema = (fieldName: string, validation: any) =>
  useMemo(
    () =>
      Yup.object({
        [fieldName]: validation,
      }),
    [fieldName, validation],
  );
