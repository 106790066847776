import { TableCellProps, Td } from '@chakra-ui/react';
import { FC } from 'react';
import { rowProps } from 'src/features/shared/treeTable/styles/tableListProps';

export const TableCell: FC<React.PropsWithChildren<TableCellProps>> = (props) => {
  const { children } = props;
  return (
    <Td {...props} {...rowProps}>
      {children}
    </Td>
  );
};
