import { BoxProps, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { mdiCommentAlertOutline } from '@mdi/js';
import { useEffect } from 'react';
import { CREATE_SUPPORT_CASE_BUTTON_SELECTOR, CREATE_SUPPORT_CASE_COMPONENT_NAME, DICTIONARY } from 'src/constants';
import { TokenCustomClaimKeysEnum } from 'src/features/common/auth/SitecoreUser';
import { useAuthWithClaims } from 'src/features/common/auth/useAuthWithClaims';
import { useUserInOrganization } from 'src/features/common/auth/useUserInOrganization';
import { Icon } from 'src/features/common/components/icon/Icon';
import { environment } from 'src/features/common/config';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useXmCloudVersion } from 'src/features/common/hooks/useXmCloudVersion';
import { environmentUpdater } from 'src/features/common/utils/environmentUpdater';
import { CreateSupportInterface } from '../models';
import { compatibleGetAccessTokenSilently } from './AppSwitcherWrapper';

export const CreateSupportCase = (boxProps: BoxProps) => {
  const { loginWithRedirect, getAccessTokenSilently, user } = useAuthWithClaims();
  const userInOrganization = useUserInOrganization();
  const { xMVersion } = useXmCloudVersion();
  const t = useTranslate();

  useEffect(() => {
    const initialize = async () => {
      const createSupportCase = document.querySelector<Element & CreateSupportInterface>(CREATE_SUPPORT_CASE_COMPONENT_NAME);

      if (!userInOrganization || createSupportCase === null) {
        return;
      }

      createSupportCase.componentName = CREATE_SUPPORT_CASE_COMPONENT_NAME;
      createSupportCase.buttonSelector = CREATE_SUPPORT_CASE_BUTTON_SELECTOR;

      // @ts-ignore
      createSupportCase.clientContext = {
        getAccessToken: compatibleGetAccessTokenSilently(getAccessTokenSilently),
        organizationId: user?.[TokenCustomClaimKeysEnum.ORG_ID] ?? '',
        tenantId: user?.[TokenCustomClaimKeysEnum.TENANT_ID],
        email: user?.email ?? '',
        userAgent: { 'X-Sitecore-User-Agent': xMVersion ?? '' },
        environment: environmentUpdater(environment),
      };
    };

    initialize();
  }, [getAccessTokenSilently, loginWithRedirect, userInOrganization, user, xMVersion]);

  return (
    <Flex {...boxProps}>
      <Tooltip label={t(DICTIONARY.CREATE_SUPPORT_TICKET)}>
        <IconButton
          data-support-case={CREATE_SUPPORT_CASE_COMPONENT_NAME}
          variant="ghost"
          size="sm"
          aria-label={t(DICTIONARY.CREATE_SUPPORT_TICKET)}
          icon={<Icon path={mdiCommentAlertOutline} boxSize="5" />}
        />
      </Tooltip>
      <create-support-case style={{ width: 0 }} data-testid={CREATE_SUPPORT_CASE_COMPONENT_NAME} />
    </Flex>
  );
};
