import { HStack, IconButton, Image, Tooltip } from '@chakra-ui/react';
import { mdiChevronRight, mdiHomeVariantOutline } from '@mdi/js';
import { SC_ICONS_SRC } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { getAppLink } from 'src/features/common/config';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { AppSwitcherWrapper } from 'src/features/main/components/AppSwitcherWrapper';

export const ApplicationLinks = () => {
  const { urlContext } = useUrlSharing(UrlParams.tenantName | UrlParams.organization);
  const onHomeIconClick = () => {
    const organization = urlContext.organization || '';
    const tenantName = urlContext.tenantName || '';

    window.location.href = encodeURI(getAppLink({ app: 'xmapps', params: { organization, tenantName }, isNew: true }));
  };

  return (
    <HStack gap="4">
      <AppSwitcherWrapper />
      <HStack gap="2">
        <Tooltip label="Go to XM Cloud">
          <IconButton
            icon={<Icon boxSize="6" path={mdiHomeVariantOutline} />}
            variant="ghost"
            size="sm"
            aria-label=""
            data-testid="home-icon"
            onClick={onHomeIconClick}
          />
        </Tooltip>
        <Icon boxSize="5" path={mdiChevronRight} color="neutral-fg" />
        <Image data-testid="explorer-logo" h="6" w="auto" src={SC_ICONS_SRC.LOGO} />
      </HStack>
    </HStack>
  );
};
