import { Alert, AlertDescription, AlertIcon, Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';

interface CurrentVersionAlertProps {
  selectedVersion: number;
  onClick: () => void;
}
export const CurrentVersionAlert: FC<CurrentVersionAlertProps> = ({ selectedVersion, onClick }) => (
  <Box width="full" paddingX="6" paddingBottom="2">
    <Alert colorScheme="purple" width="full" orientation="horizontal" alignItems="center" status="info">
      <AlertIcon />
      <AlertDescription>
        <T _str={DICTIONARY.YOU_ARE_EDITING_VERSION} />
        <Text as="span">{` ${selectedVersion}. `}</Text>
        <T _str={DICTIONARY.NOT_DEFAULT_VERSION} />
      </AlertDescription>
      <Spacer />
      <Flex>
        <Button variant="outline" size="sm" marginX="2.5" onClick={onClick}>
          <T _str={DICTIONARY.RETURN_TO_LATEST_VERSION} />
        </Button>
      </Flex>
    </Alert>
  </Box>
);
