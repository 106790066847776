import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { DICTIONARY, DISPLAY_NAME_ID, MASTER_DB } from 'src/constants';
import { itemNameValidationSchema } from 'src/features/common/components/createItem/validation/ItemNameValidationSchema';
import { Icon } from 'src/features/common/components/icon/Icon';
import { RenameItemInputProps, useRenameItem } from 'src/features/common/hooks/useRenameItem';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { DetailedItem, Disclosure, ItemRenameResponse } from 'src/features/common/models';

interface Values {
  displayName: string;
  name: string;
}

export type RenameItemModalProps = {
  itemId: string;
  name: string;
  displayName: string;
  canWrite: boolean;
  canRename: boolean;
  canWriteDisplayName: boolean;
  version: number;
  onRenameSuccess?: (item: DetailedItem) => void;
};

export const RenameItemModal: FC<Disclosure & RenameItemModalProps> = ({
  isOpen,
  onClose,
  itemId,
  name,
  displayName,
  canRename,
  canWriteDisplayName,
  version,
  onRenameSuccess,
}) => {
  const { sc_lang: language, sc_version } = useUrlStrictContext();
  const { mutateAsync: renameItem } = useRenameItem();
  const toast = useToast();
  const t = useTranslate();

  const input: RenameItemInputProps = useMemo(
    () => ({
      renameInput: { itemId, newName: '', database: MASTER_DB },
      updateInput: { itemId, language, version: sc_version, fields: [{ name: DISPLAY_NAME_ID, value: '' }] },
    }),
    [itemId, language, sc_version],
  );

  const handleRename = useCallback(async () => {
    if (input.renameInput.newName === name) input.renameInput.newName = '';
    if (
      input.updateInput.fields[0].value &&
      input.updateInput.fields[0].value === displayName &&
      input.updateInput.fields[0].value === input.renameInput.newName
    )
      input.updateInput.fields = [];

    const data = await renameItem(input);

    const result: ItemRenameResponse = data?.data?.data;
    const prevState = { name, displayName, itemId } as DetailedItem;

    prevState.name = result.renameItem ? result.renameItem.item.name : prevState.name;
    prevState.displayName = result.updateItem ? result.updateItem?.item.displayName : prevState.displayName;
    prevState.itemId = itemId;

    if (result.renameItem) {
      prevState.path = result.renameItem.item.path;
    }

    if (!data.data.errors) {
      result.renameItem || result.updateItem
        ? toast({ status: 'success', description: t(DICTIONARY.NOTIFICATIONS.RENAME_ITEM) })
        : toast({ status: 'error', description: t(DICTIONARY.NOTIFICATIONS.SOMETHING_WENT_WRONG) });
    }

    onRenameSuccess && onRenameSuccess(prevState);
    data.data.errors?.forEach((error: { message: string }) => toast({ status: 'error', description: error.message }));

    onClose();
  }, [input, name, displayName, renameItem, itemId, onRenameSuccess, onClose, toast, t]);

  const onFormSubmit = useCallback(
    (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      input.updateInput && input.updateInput.fields[0] && (input.updateInput.fields[0].value = values.displayName);
      input.renameInput && (input.renameInput.newName = values.name);
      handleRename();
      setSubmitting(false);
    },
    [input, handleRename],
  );

  const canChangeDisplayName = canWriteDisplayName && version !== 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus>
      <ModalOverlay />
      <ModalContent data-testid="rename-dialog">
        <ModalHeader>
          <T _str={`${DICTIONARY.RENAME} ${name}`} />
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            name: name || '',
            displayName: name !== displayName ? displayName || '' : '',
          }}
          validationSchema={itemNameValidationSchema}
          onSubmit={onFormSubmit}
        >
          <Form>
            <ModalBody display="flex" gap="8" flexDir="column">
              <Field name="name">
                {({ field, form }: any) => (
                  <FormControl isInvalid={(form.errors.name && form.touched.name) || !canRename}>
                    <FormLabel data-testid="item-name-label">
                      <T _str={DICTIONARY.ITEM_NAME} />
                    </FormLabel>
                    <Input {...field} autoFocus disabled={form.isSubmitting || !canRename} data-testid="name-input" />
                    <Text variant="small" mt="2">
                      <T _str={DICTIONARY.RENAME_ITEM_NAME_DESCRIPTION} />
                    </Text>
                    <FormErrorMessage data-testid="error-container">
                      <Icon path={mdiAlertCircleOutline} boxSize="5" mr="2" />
                      <T _str={!canRename ? DICTIONARY.NO_ACCESS : form.errors.name ?? ''} />
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="displayName">
                {({ field, form }: any) => (
                  <FormControl isInvalid={!canChangeDisplayName}>
                    <FormLabel data-testid="display-name-label">
                      <T _str={DICTIONARY.DISPLAY_NAME} />
                    </FormLabel>
                    <Input {...field} disabled={form.isSubmitting || !canChangeDisplayName} data-testid="display-name-input" />
                    <Text variant="small" mt="2">
                      <T _str={DICTIONARY.RENAME_DISPLAY_NAME_DESCRIPTION} />
                    </Text>
                    {!canChangeDisplayName && (
                      <FormErrorMessage>
                        <Icon path={mdiAlertCircleOutline} boxSize="5" mr="2" />
                        <T _str={version === 0 ? DICTIONARY.OPEN_ITEM_AND_ADD_LANGUAGE : DICTIONARY.NO_ACCESS} />
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button variant="ghost" data-testid="cancel-btn" onClick={onClose}>
                  <T _str={DICTIONARY.CANCEL} />
                </Button>
                <Button data-testid="submit-btn" type="submit">
                  <T _str={DICTIONARY.RENAME} />
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};
