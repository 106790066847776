import React, { useCallback, useEffect, useState } from 'react';
import { MillerColumnSelection, MillerColumnsProviderProps, MillerColumnsProviderType } from './models';

export const MillerColumnsContext = React.createContext({} as MillerColumnsProviderType);
export const useMillerColumnsContext = () => React.useContext(MillerColumnsContext);

export const MillerColumnsProvider = <T,>({
  onColumnItemClick,
  children,
  selectionsMap = [],
  selectionButtonStylesProps = {},
  columnsProps = {},
  selectionButtonColorProps,
  viewConfig,
  doClick,
}: MillerColumnsProviderProps<T>) => {
  const [selections, setSelections] = useState<MillerColumnSelection[]>(selectionsMap);

  useEffect(() => setSelections(selectionsMap), [selectionsMap]);

  const setSelection = useCallback(
    (millerColumnSelection: MillerColumnSelection) => {
      const { columnIndex } = millerColumnSelection;
      const newSelections = columnIndex + 1 < selections.length ? [...selections].slice(0, columnIndex + 1) : [...selections];

      newSelections.forEach((selection) => (selection.isPrimary = false));
      newSelections.length > columnIndex ? (newSelections[columnIndex] = millerColumnSelection) : newSelections.push(millerColumnSelection);

      setSelections([...newSelections]);

      return newSelections;
    },
    [selections, setSelections],
  );

  const getSelection = useCallback(
    (column: number): MillerColumnSelection => (selections.length > column ? selections[column] : ({} as MillerColumnSelection)),
    [selections],
  );

  const getSelectionsMap = useCallback(() => selections, [selections]);

  const millerColumnsProviderValues = {
    getSelection,
    setSelection,
    getSelectionsMap,
    onColumnItemClick,
    selectionButtonColorProps,
    selectionButtonStylesProps,
    viewConfig,
    columnsProps,
    doClick,
  } as MillerColumnsProviderType;

  return <MillerColumnsContext.Provider value={millerColumnsProviderValues}>{children}</MillerColumnsContext.Provider>;
};
