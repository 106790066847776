import { Input } from '@chakra-ui/react';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { GenericField } from 'src/features/fieldBuilder/models';
import { GenericFieldView } from './GenericFieldView';

export const SingleLineText: FC<GenericField> = ({ field }) => {
  const { builderField, resetField, updateField } = useFieldActions(field);
  const [text, setText] = useState(field.value);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => setText(target.value);

  const onReset = useCallback(async () => {
    const value = await resetField(field.fieldId);
    value && setText(value.value);
  }, [field.fieldId, resetField, setText]);

  const onUpdate = useCallback(() => {
    updateField(field.fieldId, text);
  }, [field.fieldId, text, updateField]);

  return (
    <GenericFieldView field={builderField} onReset={onReset}>
      <Input
        value={text}
        data-testid="ef-SingleLine"
        type="text"
        onChange={handleChange}
        onBlur={onUpdate}
        bgColor="white"
        disabled={!field.canEdit}
        isInvalid={!!builderField.error}
      />
    </GenericFieldView>
  );
};
