import { useMemo, useState } from 'react';
import { MediaItemData } from 'src/features/shared/selectMediaModal/models';

export const useMediaItemSearch = (mediaItemsList: MediaItemData[]) => {
  const [searchInput, setSearchInput] = useState<string>('');

  const searchResult = useMemo(() => {
    return mediaItemsList.filter(
      (media) =>
        media.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()) ||
        media.displayName.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()),
    );
  }, [mediaItemsList, searchInput]);

  return {
    searchInput,
    setSearchInput,
    searchResult,
  };
};
