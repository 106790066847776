import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';

export const useCreateItemToastDescription = (itemId: string) => {
  const location = useLocation();

  const href = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(UrlParams[UrlParams.sc_itemid], itemId);

    return `${location.pathname}/${ROUTES.EDITOR_VIEW}?${searchParams.toString()}`;
  }, [itemId, location.pathname, location.search]);

  return { href };
};
