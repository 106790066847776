import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { DetailedItem } from 'src/features/common/models';
import { DeleteActionCallbacks } from 'src/features/fieldBuilder/models';
import { DeleteModal } from './DeleteModal';

export type DeleteButtonProps = Pick<DetailedItem, 'itemId' | 'name' | 'hasChildren'> & {
  parentItemId: string;
} & DeleteActionCallbacks;

export const DeleteButton: FC<DeleteButtonProps> = (props) => {
  const disclosure = useDisclosure();

  return (
    <>
      <MenuItem
        onClick={disclosure.onOpen}
        color="danger"
        icon={<Icon path={mdiTrashCanOutline} color="danger" />}
        data-testid="delete-item"
      >
        <T _str={DICTIONARY.DELETE} />
      </MenuItem>
      {disclosure.isOpen && <DeleteModal {...disclosure} {...props} />}
    </>
  );
};
