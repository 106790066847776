import { useT } from '@transifex/react';
import { useCallback } from 'react';

export const useTranslate = () => {
	const t = useT();

	return useCallback(
		(_str: string, props?: Record<string, string>) => {
			return t(_str, props) as string;
		}, [t]
	)
	
};