import { Item } from 'src/features/common/models';

/**
 * Collects languages from items and removes duplicates
 * @param items array of items
 * @returns  array of languages
 */
export const collectLanguages = (items: Array<Pick<Item, 'language'>>): string[] => {
  if (!items || items.length === 0) {
    return [];
  }

  const languages = items.map((item) => item.language.iso);
  return languages.filter((element, index) => languages.indexOf(element) === index) as string[];
};
