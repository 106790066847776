import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { memo, useCallback, useEffect, useState } from 'react';
import { DICTIONARY, VERSION_NAME_ID } from 'src/constants';
import { useCurrentItem } from 'src/features/common/hooks/useCurrentItem';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useUpdateItemField } from 'src/features/common/hooks/useUpdateItemField';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { DetailedItem, UpdateItemInput } from 'src/features/common/models';

export interface RenameVersionModalProps {
  item: DetailedItem;
  renderAction?: any;
  isOpen: any;
  onClose: any;
}

export const RenameVersionModal = memo(({ item, isOpen, onClose }: RenameVersionModalProps) => {
  const t = useTranslate();
  const [versionName, setVersionName] = useState(item.versionName || t(DICTIONARY.UNNAMED));
  const { currentItem, refetch } = useCurrentItem();
  const { sc_lang } = useUrlStrictContext(UrlParams.sc_lang);
  const { mutateAsync: updateItemField } = useUpdateItemField();

  useEffect(() => {
    return () => {
      !isOpen && setVersionName(item.versionName || t(DICTIONARY.UNNAMED));
    };
  }, [isOpen, item, t]);

  const handleSave = useCallback(async () => {
    const input: UpdateItemInput = {
      itemId: currentItem?.itemId || '',
      language: sc_lang,
      version: item.version,
      fields: [
        {
          name: VERSION_NAME_ID,
          value: versionName,
        },
      ],
    };
    const data = await updateItemField(input);

    const result = data?.data?.data?.updateItem?.item;

    if (result && currentItem && result.itemId === currentItem?.itemId) {
      await refetch();
    }

    onClose();
  }, [currentItem, sc_lang, item.version, versionName, updateItemField, onClose, refetch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <ModalHeader>
            <T _str={DICTIONARY.RENAME_VERSION} version={item.version} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>
                <T _str={DICTIONARY.NAME} />
              </FormLabel>
              <Input autoFocus value={versionName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVersionName(e.target.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button data-testid="cancel-btn" variant="ghost" onClick={onClose}>
                <T _str={DICTIONARY.CANCEL} />
              </Button>
              <Button data-testid="submit-btn" variant="solid" onClick={handleSave} type="submit">
                <T _str={DICTIONARY.SAVE} />
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
});

RenameVersionModal.displayName = RenameVersionModal.name;
