import { Box, BoxProps, Button, ButtonProps } from '@chakra-ui/react';
import { mdiChevronRight } from '@mdi/js';
import { useCallback, useEffect } from 'react';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useMillerColumnsContext } from './MillerColumnsProvider';
import { MillerColumnData, MillerColumnProps, MillerColumnSelection } from './models';
import { defaultButtonStyleProps } from './styles';

export const MillerColumn = <T,>({
  ContentTemplate,
  templateData,
  columnIndex,
  beforeColumnComponent,
  beforeColumnComponentStyle = {},
  afterColumnComponent,
  afterColumnComponentStyle = {},
  templateContainer = {},
}: MillerColumnProps<T>) => {
  const {
    getSelection,
    setSelection,
    onColumnItemClick,
    getSelectionsMap,
    selectionButtonColorProps,
    selectionButtonStylesProps,
    columnsProps,
    viewConfig,
    doClick,
  } = useMillerColumnsContext();
  const beforeColumnComponentStyles = { w: 'full', ...beforeColumnComponentStyle } as BoxProps;
  const afterColumnComponentStyles = { w: 'full', ...afterColumnComponentStyle } as BoxProps;
  const { itemIndex, isPrimary } = getSelection(columnIndex);
  const { primaryColor, secondaryColor } = selectionButtonColorProps;

  useEffect(() => {
    if (doClick && doClick.columnIndex === columnIndex) {
      onClickHandler(templateData[doClick.itemIndex], doClick.itemIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doClick]);

  const selectionState = useCallback(
    (index: number) =>
      itemIndex === index
        ? ({ backgroundColor: isPrimary ? primaryColor : secondaryColor, className: isPrimary ? 'active' : '' } as ButtonProps)
        : {},
    [itemIndex, primaryColor, secondaryColor, isPrimary],
  );

  const onClickHandler = useCallback(
    (data: MillerColumnData<T>, index: number) => {
      const selection = { columnIndex, itemIndex: index, isPrimary: true } as MillerColumnSelection;
      const map = setSelection(selection);
      onColumnItemClick && onColumnItemClick(data, selection, map, map.length > getSelectionsMap().length);
    },
    [columnIndex, setSelection, onColumnItemClick, getSelectionsMap],
  );

  return (
    <Box data-testid="items-box" p="2" overflowY="auto" {...columnsProps} minW={viewConfig.columnWidth} maxW={viewConfig.columnWidth}>
      {beforeColumnComponent && <Box {...beforeColumnComponentStyles}>{beforeColumnComponent}</Box>}
      {templateData.map((item, index) => (
        <Button
          data-testid="item"
          key={`mc-${columnIndex}-${index}`}
          {...defaultButtonStyleProps}
          {...selectionState(index)}
          {...selectionButtonStylesProps}
          onClick={() => onClickHandler(item, index)}
          variant="ghost"
        >
          <Box {...templateContainer} boxSize="full">
            <ContentTemplate data={item.data} />
            {item.hasChildren && <Icon boxSize="6" ml="3" path={mdiChevronRight} color="neutral" />}
          </Box>
        </Button>
      ))}
      {afterColumnComponent && <Box {...afterColumnComponentStyles}>{afterColumnComponent}</Box>}
    </Box>
  );
};
