import { useCallback, useMemo } from 'react';
import { SelectItemModalProps } from '../models';
import { useSelectItemModalFilters } from './useSelectItemModalFilters';

export const useSelectItemModal = (props: SelectItemModalProps) => {
  const {
    setSelectedSite,
    setSelectedIds,
    setSelectedLanguage,
    onClose,
    selectedSite,
    selectedLanguage,
    selectedIds,
    selectState,
    includeFilters,
    datasource,
  } = props;

  const { siteCollection } = useSelectItemModalFilters();

  const siteRootItemId = useMemo(
    () => siteCollection.find((el) => el.label === selectedSite)?.rootItemId ?? '',
    [siteCollection, selectedSite],
  );

  const onSiteChange = useCallback(
    (filter: string) => {
      setSelectedSite(filter);
      setSelectedIds([]);
    },
    [setSelectedSite, setSelectedIds],
  );

  const onLanguageChange = useCallback(
    (filter: string) => {
      setSelectedLanguage(filter);
      setSelectedIds([]);
    },
    [setSelectedLanguage, setSelectedIds],
  );

  const onSubmit = useCallback(() => {
    onClose();

    if (selectState && selectState.onSelectChange) {
      selectState.onSelectChange({ id: selectedIds[0], language: selectedLanguage, sitename: selectedSite });
    }
  }, [selectedIds, onClose, selectState, selectedLanguage, selectedSite]);

  const rootItemId = useMemo(() => {
    return !includeFilters && datasource ? datasource : siteRootItemId;
  }, [datasource, includeFilters, siteRootItemId]);

  return {
    siteRootItemId,
    onSiteChange,
    onLanguageChange,
    onSubmit,
    rootItemId,
  };
};
