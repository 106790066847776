import { AxiosError } from 'axios';
import { CLIENT_LANGUAGE, DICTIONARY, MASTER_DB, UPLOAD_SVG_RESPONSE_ERROR } from 'src/constants';
import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { GraphQLResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { MediaInput, UploadMediaInput, UploadMediaItemResponse, UploadMediaResponse } from 'src/features/media/models';
import { media } from 'src/features/media/query/media';

type UploadMedia = GraphQLResponse<UploadMediaResponse>;

export const useUploadMedia = () => {
  const { urlContext } = useUrlSharing(UrlParams.sc_lang);
  const { url } = useXMTenant();
  const t = useTranslate();

  return useAuthMutation((axiosInstance) => async (input: MediaInput) => {
    let uploadLink = '';
    const uploadPath = input.uploadPath.length > 0 ? `${input.uploadPath}/` : '';
    const uploadMediaInput: UploadMediaInput = {
      alt: input.alt,
      database: MASTER_DB,
      itemPath: `${uploadPath}${input.alt}`,
      language: urlContext.sc_lang || CLIENT_LANGUAGE,
      overwriteExisting: false,
      versioned: false,
    };

    try {
      const result = await axiosInstance?.post<UploadMedia>(getSitecoreGraphQLEndpoint(url), media.upload_media(uploadMediaInput));
      uploadLink = result.data.data.uploadMedia?.presignedUploadUrl;
    } catch (error: any) {
      const axiosError = error as AxiosError<UploadMedia>;
      const errorMessage = axiosError?.response?.data.errors?.[0] ?? axiosError.message ?? t(DICTIONARY.NOTIFICATIONS.SOMETHING_WENT_WRONG);

      return { Message: errorMessage } as UploadMediaItemResponse;
    }

    const data = new FormData();
    data.append(input.file.name, input.file);

    try {
      return (await axiosInstance.post<UploadMediaItemResponse>(uploadLink, data)).data;
    } catch (error: any) {
      const axiosError = error as AxiosError<UploadMediaItemResponse>;
      const errorMessage =
        axiosError?.response?.data.Message ??
        (axiosError.message === UPLOAD_SVG_RESPONSE_ERROR && input.file.type === 'image/svg+xml'
          ? t(DICTIONARY.UPLOAD_JS_SVG_MESSAGE, { filename: uploadMediaInput.itemPath })
          : axiosError.message) ??
        t(DICTIONARY.NOTIFICATIONS.SOMETHING_WENT_WRONG);

      return { Message: errorMessage } as UploadMediaItemResponse;
    }
  });
};
