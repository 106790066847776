import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { mdiContentCopy } from '@mdi/js';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { DetailedItem, NestedItems } from 'src/features/common/models';
import { DuplicateModal } from './DuplicateModal';

export type DuplicateButtonProps = Pick<DetailedItem, 'name' | 'itemId'> & {
  onDuplicateItemSuccess: (item: NestedItems, itemId: string) => void;
};

export const DuplicateButton: FC<DuplicateButtonProps> = ({ name, itemId, onDuplicateItemSuccess }) => {
  const disclosure = useDisclosure();

  return (
    <>
      <MenuItem onClick={disclosure.onOpen} icon={<Icon path={mdiContentCopy} />} data-testid="duplicate-item">
        <T _str={DICTIONARY.DUPLICATE} />
      </MenuItem>
      {disclosure.isOpen && <DuplicateModal {...disclosure} name={name} itemId={itemId} onDuplicateItemSuccess={onDuplicateItemSuccess} />}
    </>
  );
};
