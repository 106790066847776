import { Flex, useToast } from '@chakra-ui/react';
import { FC, useCallback, useMemo } from 'react';
import { FIELD_EDITOR_SELECTED_TAB_KEY } from 'src/constants';
import { useCurrentItem } from 'src/features/common/hooks/useCurrentItem';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { DetailedItem } from 'src/features/common/models';
import { ActionButtons } from 'src/features/fieldEditor/components/ActionButtons';
import { EditorTabs } from 'src/features/fieldEditor/components/EditorTabs';
import { Header } from 'src/features/fieldEditor/components/Header';
import { ItemBadges } from 'src/features/fieldEditor/components/ItemBadges';
import { useUpdateMillerColumns } from 'src/features/millerColumns/hooks/useUpdateMillerColumns';
import { CurrentVersionAlert } from 'src/features/versions/components/alerts/CurrentVersionAlert';
import { MissingVersionAlert } from 'src/features/versions/components/alerts/MissingVersionAlert';
import { useLocalStorage } from 'usehooks-ts';

export const getGreatestVersion = (item: DetailedItem | undefined): number =>
  item && item?.versions.length > 0 ? Math.max(...item.versions.map((x) => x.version)) : 0;

export const FieldEditor: FC = () => {
  const [, setFieldEditorTabIndex] = useLocalStorage<number>(FIELD_EDITOR_SELECTED_TAB_KEY, 0);
  const { sc_version, setUrlContext } = useUrlStrictContext();
  const refreshColumns = useUpdateMillerColumns();
  const toast = useToast();
  const t = useTranslate();

  const { currentItem, isFetching, refetch } = useCurrentItem(toast, t);

  const isOnCurrentVersion = useMemo(() => {
    if (isFetching) {
      return false;
    }

    const greatestVersion = getGreatestVersion(currentItem);
    return !!sc_version && sc_version !== greatestVersion;
  }, [isFetching, currentItem, sc_version]);

  const handleVersionChange = useCallback(
    (version: number | undefined, tabToSelect: number) => {
      setUrlContext({ sc_version: version });
      setFieldEditorTabIndex(tabToSelect);
    },
    [setUrlContext, setFieldEditorTabIndex],
  );

  const onWorkflowUpdate = useCallback(() => {
    refetch();
    refreshColumns();
  }, [refetch, refreshColumns]);

  const hideFieldEditor = useMemo(() => currentItem && currentItem?.version < 1, [currentItem]);

  return (
    <>
      {currentItem && <MissingVersionAlert currentItem={currentItem} hidden={!hideFieldEditor} />}
      <Flex data-testid="field-editor" flexDirection="column" w="full" hidden={hideFieldEditor}>
        {isOnCurrentVersion && <CurrentVersionAlert selectedVersion={sc_version || 0} onClick={() => handleVersionChange(undefined, 2)} />}
        <Flex flexDirection="column" w="full" px="8" paddingTop="3">
          <Flex alignItems="center" gap="2">
            <Header item={currentItem} />
          </Flex>
          <Flex data-testid="item-badges" flexDirection="row" mt="2" minH="4" alignItems="baseline" gap={2}>
            <ItemBadges item={currentItem} />
          </Flex>
          <Flex data-testid="commandButton" marginTop="4">
            <ActionButtons item={currentItem} refetch={onWorkflowUpdate} />
          </Flex>
        </Flex>
        <Flex pt="6" h="full">
          <EditorTabs item={currentItem} onVersionChange={handleVersionChange} />
        </Flex>
      </Flex>
    </>
  );
};
