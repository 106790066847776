import { Link, LinkProps, Text, TextProps } from '@chakra-ui/react';
import { T } from '@transifex/react';

export interface DetailProps {
  heading: string;
  value: string | JSX.Element;
  pt: string | number;
  valueStyle?: TextProps;
  href?: string;
  linkStyle?: LinkProps;
}

export const DetailProperty = ({ heading = '', value = '', pt = 5, valueStyle = {}, href, linkStyle = {} }: Partial<DetailProps>) => {
  return (
    <>
      <Text variant="strong" pt={pt} data-testid="detail-property-heading">
        <T _str={heading} />:
      </Text>
      {href ? (
        <Link href={href} {...linkStyle} data-testid="detail-property-value">
          {value}
        </Link>
      ) : (
        <Text {...valueStyle} data-testid="detail-property-value">
          {value}
        </Text>
      )}
    </>
  );
};
