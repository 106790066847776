import { CloseButton, Input, InputGroup, InputLeftElement, InputRightElement, Tooltip } from '@chakra-ui/react';
import { mdiMagnify } from '@mdi/js';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useSearchInput } from 'src/features/search/hooks';

export const SearchInput: FC = () => {
  const { searchValue, ref, searchHandler, t, clearSearch } = useSearchInput();

  return (
    <InputGroup maxW="md">
      <InputLeftElement pointerEvents="none">
        <Icon pointerEvents="none" path={mdiMagnify} boxSize="5" />
      </InputLeftElement>
      <Input
        data-testid="searchInput"
        width="xs"
        defaultValue={searchValue}
        ref={ref}
        type="text"
        placeholder={t(DICTIONARY.SEARCH)}
        onChange={searchHandler}
      />
      {!!searchValue && (
        <InputRightElement>
          <Tooltip label={<T _str={DICTIONARY.CLEAR_SEARCH} />}>
            <CloseButton data-testid="clearSearch" size="sm" onClick={clearSearch} />
          </Tooltip>
        </InputRightElement>
      )}
    </InputGroup>
  );
};
