import { createContext, FC, PropsWithChildren, RefObject, useContext, useMemo, useRef } from 'react';

export interface ActionsBarContextType {
  viewOptionsRef: RefObject<HTMLDivElement>;
  languageSwitcherRef: RefObject<HTMLDivElement>;
}

export const ActionsBarContext = createContext({} as ActionsBarContextType);

export const ActionsBarProvider: FC<PropsWithChildren> = ({ children }) => {
  const viewOptionsRef = useRef<HTMLDivElement>(null);
  const languageSwitcherRef = useRef<HTMLDivElement>(null);

  const value = useMemo(
    () => ({
      viewOptionsRef,
      languageSwitcherRef,
    }),
    [],
  );

  return <ActionsBarContext.Provider value={value}>{children}</ActionsBarContext.Provider>;
};

export const useActionsBarContext = () => useContext(ActionsBarContext);
