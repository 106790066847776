import { useMemo } from 'react';
import { CACHE_LIFETIME, MASTER_DB } from 'src/constants';
import { useGetLanguages } from 'src/features/common/hooks/useGetLanguages';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { LanguageItem } from 'src/features/common/models';

export const useGetLanguageItem = () => {
  const { sc_lang } = useUrlStrictContext(UrlParams.sc_lang);
  const { data, isLoading } = useGetLanguages(MASTER_DB, { staleTime: CACHE_LIFETIME, keepPreviousData: true });

  const selectedLanguage: LanguageItem | undefined = useMemo(
    () => data?.data.data.languages.nodes.find(({ name }) => name === sc_lang),
    [data, sc_lang],
  );

  return { selectedLanguage, isLoading };
};
