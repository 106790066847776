import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { useSelectItemModalFilters } from '../hooks';
import { SelectItemModalFiltersProps } from '../models';
import { SelectItemFilter } from './SelectItemFilter';

export const SelectItemModalFilters: FC<SelectItemModalFiltersProps> = ({
  onSiteChange,
  onLanguageChange,
  selectedSite,
  selectedLanguage,
}) => {
  const { siteCollection, languageCollection, filterBoxStyles } = useSelectItemModalFilters();

  return (
    <Flex className="sc-select-item-modal-filters">
      <Flex {...filterBoxStyles}>
        <SelectItemFilter
          onChange={onSiteChange}
          defaultFilter={selectedSite}
          collection={siteCollection}
          labelPrefix={DICTIONARY.SITE_WITH_COLON}
        />
      </Flex>
      <Flex {...filterBoxStyles}>
        <SelectItemFilter
          onChange={onLanguageChange}
          defaultFilter={selectedLanguage}
          collection={languageCollection}
          labelPrefix={DICTIONARY.LANGUAGE_WITH_COLON}
        />
      </Flex>
    </Flex>
  );
};
