import { Textarea } from '@chakra-ui/react';
import { FC, useCallback, useState } from 'react';
import { GenericFieldView } from 'src/features/fieldBuilder/components/fields/GenericFieldView';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { GenericField } from 'src/features/fieldBuilder/models';

export const Multiline: FC<GenericField> = ({ field }) => {
  const [text, setText] = useState(field.value);
  const { builderField, updateField, resetField } = useFieldActions(field);

  const onReset = useCallback(async () => {
    const value = await resetField(field.fieldId);
    value && setText(value.value);
  }, [field.fieldId, resetField, setText]);

  const onUpdate = useCallback(() => {
    updateField(field.fieldId, text);
  }, [field.fieldId, text, updateField]);

  return (
    <GenericFieldView field={builderField} onReset={onReset}>
      <Textarea
        data-testid="ef-multiline"
        value={text}
        style={{ backgroundColor: 'white', minWidth: '100%' }}
        isInvalid={!!builderField.error}
        isDisabled={!field.canEdit}
        onChange={({ target }) => setText(target.value)}
        onBlur={onUpdate}
      />
    </GenericFieldView>
  );
};
