import { Badge, Stack, Text, Tooltip } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useMemo } from 'react';
import { DICTIONARY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';

export interface WorkflowStateProps {
  workflowName: string;
  name: string;
  isFinal: boolean;
  minWidth?: string;
  maxWidth?: string;
}

const workflowColors = {
  final: 'success',
  common: 'blue',
};

export const WorkflowState = ({ workflowName, name, isFinal, ...rest }: WorkflowStateProps) => {
  const t = useTranslate();

  const label = useMemo(
    () => (
      <Stack>
        <Text>
          <T _str={DICTIONARY.TOOLTIP_WORKFLOW} name={workflowName} />
        </Text>
        <Text>
          <T _str={DICTIONARY.TOOLTIP_STATE} name={name} />
        </Text>
      </Stack>
    ),
    [name, workflowName],
  );

  return (
    <Tooltip label={!name ? t(DICTIONARY.NO_WORKFLOW) : label}>
      <Badge
        lineHeight="base"
        isTruncated
        colorScheme={!name ? undefined : isFinal ? `${workflowColors.final}` : `${workflowColors.common}`}
        {...rest}
      >
        <T _str={!name ? DICTIONARY.NO_WORKFLOW : name} />
      </Badge>
    </Tooltip>
  );
};
