import { Flex } from '@chakra-ui/react';
import { FC } from 'react';

export const LeftSidePanel: FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      maxWidth={72}
      paddingY={6}
      scrollBehavior="smooth"
      overflow="auto"
      borderRightStyle="solid"
      borderRightWidth="1px"
      borderRightColor="chakra-border-color"
      width={72}
      flexGrow={0}
      flexShrink={0}
      direction="column"
    >
      {children}
    </Flex>
  );
};
