import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse, WorkflowResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { workflow } from 'src/features/workflow/graphql/workflow';

export const useGetWorkflowCommands = (
  itemId: string,
  language: string,
  workflowId: string,
  stateId: string,
  useQueryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<GraphQLResponse<WorkflowResponse>>,
          unknown,
          AxiosResponse<GraphQLResponse<WorkflowResponse>>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { url } = useXMTenant();
  const toast = useToast();

  return useAuthQuery(
    ['get-workflow-commands', itemId, language, workflowId, stateId],
    async (axiosInstance) => {
      return axiosInstance?.post<GraphQLResponse<WorkflowResponse>>(
        getSitecoreGraphQLEndpoint(url),
        workflow.get_workflow_commands(itemId, language, workflowId, stateId),
      );
    },
    { ...useQueryOptions },
    toast,
  );
};
