import { mdiEarth, mdiFileOutline, mdiFolderOutline } from '@mdi/js';
import { TEMPLATE_FOLDER } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import {
  Item,
  NestedItems,
  NestedItemsWithAncestors,
  SitesOption,
  SolutionSite,
  Workflow,
  WorkflowState,
} from 'src/features/common/models';
import { collectLanguages } from 'src/features/common/utils/collectLanguages';
import { filterSites } from 'src/features/common/utils/filterSites';
import { isSameGuid } from 'src/features/common/utils/guidUpdater';
import { ChildMillerItem, ItemType, MillerColumnsData, MillerItemTemplateData } from 'src/features/millerColumns/models';
import { MillerColumnData, MillerColumnSelection } from 'src/features/shared/millerColumns/models';

export const filterChildMillerItems = (items: Item[]): ChildMillerItem[] => {
  return items
    .filter((f) => f !== null)
    .map((item) => ({
      hasChildren: item.hasChildren,
      data: formatItem(item),
    }));
};

export const formatItem = (item: Item): MillerItemTemplateData => {
  let itemType: ItemType;
  let icon: JSX.Element;

  switch (item.template.name) {
    case TEMPLATE_FOLDER:
      itemType = 'folder';
      icon = <Icon boxSize="5" color="blackAlpha.900" path={mdiFolderOutline} />;
      break;
    default:
      itemType = 'content';
      icon = <Icon boxSize="5" color="blackAlpha.900" path={mdiFileOutline} />;
      break;
  }
  return {
    id: item.itemId,
    name: item.name,
    displayName: item.displayName || item.name,
    languages: collectLanguages(item.versions),
    icon,
    thumbnailUrl: item.thumbnailUrl,
    workflow: item.workflow?.workflow || ({} as Pick<Workflow, 'displayName'>),
    workflowState: item.workflow?.workflowState || ({} as WorkflowState),
    itemType,
  };
};

export const formatSites = (sites: SolutionSite[], options: Partial<SitesOption>): MillerColumnsData[] => {
  const filteredSites = filterSites(sites, options);
  const siteColumns: MillerColumnsData = {
    items: filteredSites?.map<MillerColumnData<MillerItemTemplateData>>((site: SolutionSite) => ({
      data: {
        id: site.rootItem.itemId,
        name: site.name,
        thumbnailUrl: site.startItem.thumbnailUrl,
        itemType: 'site',
        displayName: site.name,
        icon: <Icon boxSize="5" color="blackAlpha.900" path={mdiEarth} />,
        languages: [],
        workflow: {} as Pick<Workflow, 'displayName'>,
        workflowState: {} as WorkflowState,
      },
      hasChildren: true,
    })),
    parent: '',
    insertOptions: [],
    access: { canCreate: false },
  };

  return filteredSites.length ? [siteColumns] : [];
};

export const formatItems = (item: NestedItems): MillerColumnsData => ({
  items: filterChildMillerItems(item.children.nodes),
  parent: item.itemId,
  insertOptions: item.insertOptions,
  access: { canCreate: item.access?.canCreate || false }, //TODO: temp fix, must discuss [is bug from gql or we have to account empty access if there is no version in other language?]
});

export const formatAncestorsItems = (item: NestedItemsWithAncestors): MillerColumnsData[] => {
  const columns = item?.ancestors.filter((f) => f !== null).map((elem) => formatItems(elem));

  return columns?.concat(formatItems(item)) ?? [];
};

export const resolvedSelectionMap = (data: MillerColumnsData[], expandItemId: string, selections: Array<MillerColumnSelection> = []) => {
  let parentId = expandItemId;
  let isPrimary = true;

  for (let index = data.length - 1; index >= 0; index--) {
    // eslint-disable-next-line no-loop-func
    const itemIndex = data[index].items.findIndex((record) => isSameGuid(record.data.id, parentId));

    if (itemIndex >= 0) {
      selections.unshift({ columnIndex: index, itemIndex, isPrimary });
      parentId = data[index].parent;
      isPrimary = false;
    }
  }

  return selections;
};
