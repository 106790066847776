import { ENV } from 'src/constants';
import { environment } from 'src/features/common/config';

export const GAINSIGHT_SCRIPT_ID = 'gainsight';

const GainsightTagPerEnv = {
  staging: 'AP-BR1SACAOWQ2G-2-2',
  prod: 'AP-BR1SACAOWQ2G-2',
};

const getGainsightTag = () => {
  switch (environment) {
    case ENV.STAGING:
      return GainsightTagPerEnv.staging;
    case ENV.PRODUCTION:
      return GainsightTagPerEnv.prod;
    default:
      return '';
  }
};

export const gainsightScript = getGainsightTag()
  ? `
      (function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
          (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
        var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
        var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
      })(window,document,"https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js","${getGainsightTag()}");
`
  : '';
