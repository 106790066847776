import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Disclosure } from 'src/features/common/models';
import { FieldsErrorsAlert } from 'src/features/publish/components/FieldsErrorsAlert';
import { usePublishModal } from 'src/features/publish/hooks/usePublishModal';

export const PublishModal: FC<Disclosure> = (props) => {
  const { input, languages, isAllLangs, onClick, setInputProps, setAllOrZeroLangs } = usePublishModal(props);

  return (
    <Modal isOpen onClose={props.onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <T _str={DICTIONARY.PUBLISH} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDir="column" gap="4">
          <FieldsErrorsAlert />
          <FormControl>
            <Stack spacing="4">
              <Text>
                <T _str={DICTIONARY.SELECT_PUBLISH_SETTINGS} />
              </Text>
              <RadioGroup name="publish_mode" defaultValue="SMART" onChange={(e) => setInputProps({ publishItemMode: e })}>
                <Stack spacing="2">
                  <Radio value="SMART">
                    <Text>
                      <T _str={DICTIONARY.SMART_PUBLISH} />
                    </Text>
                    <Text variant="small">
                      <T _str={DICTIONARY.SMART_PUBLISH_DESCRIPTION} />
                    </Text>
                  </Radio>
                  <Radio value="FULL">
                    <Text>
                      <T _str={DICTIONARY.REPUBLISH_EVERYTHING} />
                    </Text>
                    <Text variant="small">
                      <T _str={DICTIONARY.REPUBLISH_EVERYTHING_DESCRIPTION} />
                    </Text>
                  </Radio>
                </Stack>
              </RadioGroup>
              <CheckboxGroup>
                <Stack data-testid="publish-items" spacing="2">
                  <Checkbox isChecked={input.publishSubItems} onChange={(e) => setInputProps({ publishSubItems: e.target.checked })}>
                    <T _str={DICTIONARY.PUBLISH_SUB_ITEMS} />
                  </Checkbox>
                </Stack>
              </CheckboxGroup>
              <Text>
                <T _str={DICTIONARY.LANGUAGES_TO_PUBLISH} />
              </Text>
              <CheckboxGroup value={input.languages} onChange={(languages) => setInputProps({ languages })}>
                <Stack data-testid="publish-languages" spacing="2" maxH="36" overflowY="auto" ml="-1" pl="1">
                  <Checkbox isChecked={isAllLangs} key="publish_lang_all" onChange={(e) => setAllOrZeroLangs(e.target.checked)}>
                    <T _str={DICTIONARY.ALL} />
                  </Checkbox>
                  {languages.map(({ name, englishName }) => (
                    <Checkbox key={`publish_lang_${name}`} value={name}>
                      {englishName}
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </Stack>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" spacing="2">
            <Button data-testid="cancel-btn" variant="ghost" onClick={props.onClose}>
              <T _str={DICTIONARY.CANCEL} />
            </Button>
            <Button data-testid="submit-btn" variant="solid" onClick={onClick} type="submit" isDisabled={!input.languages.length}>
              <T _str={DICTIONARY.PUBLISH} />
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
