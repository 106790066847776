import { Box, Tooltip } from '@chakra-ui/react';
import { mdiAlertCircle } from '@mdi/js';
import { T } from '@transifex/react';
import { FC, useMemo } from 'react';
import { DICTIONARY, SECTIONS_ERRORS_STORAGE_KEY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { ValidationErrors } from 'src/features/fieldBuilder/models';
import { useSessionStorage } from 'usehooks-ts';

interface EditorTabHeaderProps {
  name: string;
}

export const EditorTabHeader: FC<EditorTabHeaderProps> = ({ name }) => {
  const [errors] = useSessionStorage<ValidationErrors[]>(SECTIONS_ERRORS_STORAGE_KEY, []);
  const t = useTranslate();
  const hasError = useMemo(() => !!errors.length, [errors]);

  return (
    <Tooltip label={t(DICTIONARY.FIELDS_CONTAIN_ERRORS)} isDisabled={!hasError}>
      <Box isTruncated height={5}>
        {hasError && <Icon data-testid="sc-errorIcon" path={mdiAlertCircle} boxSize="4" color="danger" mr="1" verticalAlign="middle" />}
        <Box as="span" verticalAlign="middle">
          <T _str={name} />
        </Box>
      </Box>
    </Tooltip>
  );
};
