import { Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { ItemView } from 'src/features/common/treeComponent/components/ItemView';
import {
  FilesView,
  ItemTreeWrapper,
  MediaModalHeader,
  NoFilesInFolder,
  NoMediaItemsFound,
  RefreshButton,
  SearchInput,
  UploadFileButton,
} from 'src/features/shared/selectMediaModal/components/mediaModal';
import { WrongDataSourceError } from 'src/features/shared/selectMediaModal/components/mediaModal/WrongDataSourceError';
import { SelectMediaCallbackType } from 'src/features/shared/selectMediaModal/components/SelectMediaContext';
import { useGetAncestorsWrapper, useGetMediaItemWrapper, useSelectMediaModal } from 'src/features/shared/selectMediaModal/hooks';
import { DataSourcesList } from 'src/features/shared/selectMediaModal/models';

export type SelectMediaModalProps = {
  selectedMediaId: string | undefined;
  setSelectedMediaId: (itemId: string) => void;
  selectedFolderIds: string[];
  setSelectedFolderIds: (value: string[]) => void;
  preSelectedMediaId?: string;
  onSelectedClick: () => void;
} & Pick<ReturnType<typeof useDisclosure>, 'isOpen' | 'onClose'> &
  Required<Omit<SelectMediaCallbackType, 'preSelectedMediaId' | 'onSelected'>> &
  DataSourcesList;

export const SelectMediaModal: FC<SelectMediaModalProps> = (props) => {
  const { isOpen, selectType, rootItemIdOrPath, selectedMediaId, setSelectedMediaId, setSelectedFolderIds, sourceIds } = props;

  const uploadType = selectType === 'link' ? 'file' : selectType;

  const {
    isFirsAncestorLoading,
    isMediaDataLoading,
    isWrongSource,
    refetchMedia,
    resetStateAndClose,
    resetStateAndSelect,
    searchInput,
    searchResult,
    selectedFolderIdsOrDefault,
    setSearchInput,
    mediaDataItems,
    firstAncestorIds,
  } = useSelectMediaModal(props);

  return (
    <Modal isOpen={isOpen} onClose={resetStateAndClose} size="full" blockScrollOnMount={false}>
      <ModalOverlay>
        <ModalContent height="full" borderRadius="none">
          <MediaModalHeader
            resetStateAndClose={resetStateAndClose}
            selectType={selectType}
            onSelect={resetStateAndSelect}
            isSelectDisabled={!selectedMediaId}
          />
          {isWrongSource && !isMediaDataLoading && <WrongDataSourceError />}
          <ModalBody display="flex" flexDirection="row" alignItems="stretch" height="90vh" paddingLeft="0">
            <ItemTreeWrapper
              itemId={rootItemIdOrPath}
              sourceIds={sourceIds}
              useGetItem={useGetMediaItemWrapper}
              selectedIDs={selectedFolderIdsOrDefault}
              setSelectedIds={setSelectedFolderIds}
              selectionVariant="single"
              expandOnSelect
              getItemView={(props) => <ItemView {...props} />}
              initialId={firstAncestorIds[0]}
              useGetInitialAncestors={useGetAncestorsWrapper}
              isFirsAncestorLoading={isFirsAncestorLoading}
              isWrongSource={isWrongSource}
              isSourceLoading={isMediaDataLoading}
            />
            <Flex padding={6} paddingBottom={8} direction="column" width="full" flexGrow={0} flexShrink={1}>
              <Flex direction="row" justifyContent="space-between" gap={3}>
                <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} isDisabled={!selectedFolderIdsOrDefault.length} />
                <Flex gap={2}>
                  <RefreshButton refresh={refetchMedia} isDisabled={isMediaDataLoading || isWrongSource} />
                  <UploadFileButton
                    folderItemId={selectedFolderIdsOrDefault[0] ?? rootItemIdOrPath}
                    refetchList={refetchMedia}
                    type={uploadType}
                    isDisabled={isWrongSource || isMediaDataLoading}
                  />
                </Flex>
              </Flex>

              <Text variant="small" marginTop={3}>
                <T
                  _str={searchResult.length === 1 ? DICTIONARY.SEARCH_RESULT_COUNT : DICTIONARY.SEARCH_RESULTS_COUNT}
                  count={searchResult.length}
                />
              </Text>

              {!!searchResult.length && (
                <FilesView
                  mediaDataItems={searchResult}
                  isLoading={isMediaDataLoading}
                  selectedMediaId={selectedMediaId}
                  setSelectedMediaId={setSelectedMediaId}
                />
              )}
              {!mediaDataItems.length && !isMediaDataLoading && <NoFilesInFolder />}
              {!searchResult.length && !!mediaDataItems.length && <NoMediaItemsFound />}
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
