import { FC } from 'react';
import { ActionsMenu } from 'src/features/fieldEditor/components/ActionsMenu';
import { ActionsCellProps } from 'src/features/shared/treeTable/components/ActionsCell';
import { useRefetchAndBlink } from 'src/features/shared/treeTable/hooks/useRefetchAndBlink';

export const ActionsMenuTreeTableWrapper: FC<ActionsCellProps> = ({
  refetchParent,
  access,
  hasPresentation,
  hasChildren,
  itemId,
  name,
  displayName,
  version,
  displayNameWriteAccess,
}) => {
  const refetchParentAndBlink = useRefetchAndBlink(refetchParent);

  if (!refetchParent) return <></>;

  return (
    <ActionsMenu
      variant="ghost"
      canCreate={access?.canCreate || false}
      canWrite={access?.canWrite || false}
      canDelete={access?.canDelete || false}
      hasChildren={hasChildren || false}
      hasPresentation={hasPresentation || false}
      itemId={itemId}
      name={name}
      displayName={displayName}
      version={version || 0}
      shouldRenderRenameButton={access?.canRename || false}
      canRename={access?.canRename || false}
      canWriteDisplayName={displayNameWriteAccess || false}
      onDuplicateItemSuccess={(item) => refetchParentAndBlink(item.itemId)}
      onDeleteItemSuccess={refetchParent}
      onDeleteItemError={refetchParent}
      onRenameSuccess={refetchParent}
    />
  );
};
