import { SearchCriteriaInput, SearchCriteriaType, SearchOperator } from 'src/features/search/models';

export const QUERIES_NAMES = {
  SEARCH: 'search',
  SEARCH_IN: 'searchin',
  LANGUAGES: 'filter_lang',
  WORKFLOWS: 'workflow',
  CREATE_BY: 'createdby',
  MODIFIED_BY: 'modifiedby',
  COLLECTIONS: 'sc_collection',
  SITES: 'sites',
  SITE_TYPE: 'sc_type',
};

export const SEARCH_IN_PARAM_VALUE = {
  NAME: 'name',
  FIELDS: 'fields',
  ALL: 'all',
};

export const DEBOUNCE_SEARCH_EVENT_TIME = 350;

export const DEBOUNCE_SCROLL_EVENT_TIME = 100;

export const SEARCH_MIN_LENGTH = 2;

export const SEARCH_PAGE_SIZE = 50;

export const SEARCH_CACHE_TIME = 30000;

export const CONTENT_ITEM_ID = '0de95ae441ab4d019eb067441b7c2450';

export const IGNORED_COLLECTION_TEMPLATE_NAME = 'Headless Tenant';

export const WEBSITE_NAME = 'website';

export const SEARCH_FIELDS = {
  DISPLAY_NAME: '_displayname',
  NAME: '_name',
  CONTENT: '_content',
  PATH: '_path',
};

export const FILTER_FIELDS = {
  COLLECTION: 'site_collection',
  LANGUAGE: 'culture',
  TYPE: '',
  CREATED_BY: 'parsedcreatedby',
  MODIFIED_BY: 'parsedupdatedby',
  WORKFLOW_STATE: '__workflow_state',
  TEMPLATE_NAME: '_templatename',
  SITE: 'site',
  SITE_TYPE: 'site_type',
};

export const SEARCH_PATH_CRITERIA: SearchCriteriaInput = {
  field: SEARCH_FIELDS.PATH,
  value: CONTENT_ITEM_ID,
  operator: SearchOperator.MUST,
  criteriaType: SearchCriteriaType.CONTAINS,
};

export const SEARCH_DEFAULT_PAGE = { pageSize: SEARCH_PAGE_SIZE, skip: 0 };

export const SEARCH_MENU_MIN_LENGTH = 10;

export const VISIBLE_SELECTED_ITEMS_COUNT = 2;

export const IGNORE_COLLECTIONS_FILTER: SearchCriteriaInput = {
  field: FILTER_FIELDS.TEMPLATE_NAME,
  value: IGNORED_COLLECTION_TEMPLATE_NAME,
  criteriaType: SearchCriteriaType.CONTAINS,
  operator: SearchOperator.NOT,
};

export const EXCLUDE_WEBSITE_FILTER: SearchCriteriaInput = {
  field: FILTER_FIELDS.SITE,
  value: WEBSITE_NAME,
  criteriaType: SearchCriteriaType.EXACT,
  operator: SearchOperator.NOT,
};

export const SEARCH_EXCLUDE_FILTERS: SearchCriteriaInput[] = [EXCLUDE_WEBSITE_FILTER, IGNORE_COLLECTIONS_FILTER];

export const SITE_TYPE_VALUE = {
  HEADLESS: 'headless',
  NON_SXA: 'nonsxa',
  SXA: 'sxa',
};
