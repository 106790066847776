import { useMemo } from 'react';
import { SearchCriteriaInput, SearchCriteriaType, SearchOperator } from 'src/features/search/models';
import { FILTER_FIELDS, QUERIES_NAMES } from 'src/features/search/searchConstants';

export const useWorkflowFilterCriteria = (params: URLSearchParams): SearchCriteriaInput[] | undefined => {
  const workflows = useMemo(() => params.get(QUERIES_NAMES.WORKFLOWS)?.split(',') || [], [params]);

  return useMemo<SearchCriteriaInput[] | undefined>(() => {
    if (!workflows.length) {
      return undefined;
    }

    return workflows.map<SearchCriteriaInput>((value) => ({
      field: FILTER_FIELDS.WORKFLOW_STATE,
      value,
      criteriaType: SearchCriteriaType.EXACT,
      operator: SearchOperator.SHOULD,
    }));
  }, [workflows]);
};
