import { useContext } from 'react';
import { ENV } from 'src/constants';
import { environment } from 'src/features/common/config/tenant-config';
import { XMTenantContext } from 'src/features/common/tenants/xmTenantContext';

interface appLinkOptions {
  app: string;
  path?: string;
  params?: Record<string, string | undefined>;
  isNew?: boolean;
}

export const getAppLink = ({ app, path = '', params = {}, isNew = false }: appLinkOptions) => {
  let urlParams = '';

  Object.entries(params).map(([key, value], index) => !!value && (urlParams += `${index > 0 ? '&' : '/?'}${key}=${value}`));

  switch (environment) {
    case ENV.DEV:
    case ENV.QA:
    case ENV.STAGING:
      return `https://${app}-staging.sitecore-staging.cloud${path}${urlParams}`;
    case ENV.PRE_PRODUCTION:
      return `https://${app}-${isNew ? 'preprod' : 'beta'}.sitecorecloud.io${path}${urlParams}`;
    case ENV.PRODUCTION:
      return `https://${app}.sitecorecloud.io${path}${urlParams}`;
    default:
      return '';
  }
};

interface XMCLinkOptions {
  params?: Record<string, string | number | undefined>;
}

export const useGetXMCLink = ({ params = {} }: XMCLinkOptions) => {
  let urlParams = '';

  const xmTenantContext = useContext(XMTenantContext);

  if (!xmTenantContext?.resolved) {
    return undefined;
  }

  const tenantUrl = xmTenantContext.model.url;

  Object.entries(params).map(([key, value], index) => !!value && (urlParams += `${index > 0 ? '&' : '/?'}${key}=${value}`));

  return `${tenantUrl}/sitecore/shell/Applications/Content%20Editor.aspx${urlParams}`;
};
