import { Skeleton, Td, Tr } from '@chakra-ui/react';
import { FC } from 'react';

type LoadingRowProps = { numberOfCols: number; firstCellPadding?: string; numberOfRows?: number };

export const LoadingRow: FC<LoadingRowProps> = ({ firstCellPadding = 0, numberOfRows = 3, numberOfCols }) => {
  return (
    <>
      {Array.from({ length: numberOfRows }, (x, i) => (
        <Tr key={i}>
          {Array.from({ length: numberOfCols }, (n, y) => (
            <Td paddingLeft={y === 0 ? firstCellPadding : undefined} key={`${i}-${y}`}>
              <Skeleton height="6" />
            </Td>
          ))}
        </Tr>
      ))}
    </>
  );
};
