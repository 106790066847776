import { Box, Button, Menu, MenuButton, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY, SELECTED_SHOW_OPTIONS_KEYS } from 'src/constants';
import { ActionCheckbox } from 'src/features/common/components/actionsBar/ActionCheckbox';
import { useActionsBarContext } from 'src/features/common/components/actionsBar/ActionsBarContext';
import { useViewOptionsActions } from 'src/features/common/components/actionsBar/hooks';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useFeature } from 'src/features/common/flags/features';

export const VisualOptionsMenu: FC = () => {
  const isTemplateSitesEnabled = useFeature('xm_technicalTemplateSites'); //* xm_technicalTemplateSites
  const resetItemIdAndVersion = useViewOptionsActions();
  const { viewOptionsRef } = useActionsBarContext();

  return (
    <Box>
      <Menu closeOnSelect={false}>
        <MenuButton
          data-testid="showOptions"
          as={Button}
          variant="outline"
          borderRadius="md"
          rightIcon={<Icon layerStyle="menuButtonIcon" path={mdiChevronDown} />}
        >
          <T _str={DICTIONARY.SHOW} />
        </MenuButton>
        <MenuList zIndex="dropdown" ref={viewOptionsRef}>
          <MenuOptionGroup type="checkbox">
            <ActionCheckbox storageKey={SELECTED_SHOW_OPTIONS_KEYS.LANGUAGES} text={DICTIONARY.SHOW_SETTING.LANGUAGES} />
            <ActionCheckbox
              storageKey={SELECTED_SHOW_OPTIONS_KEYS.HIDDEN_ITEMS}
              text={DICTIONARY.SHOW_SETTING.HIDDEN_ITEMS}
              onClick={resetItemIdAndVersion}
            />
            <ActionCheckbox
              storageKey={SELECTED_SHOW_OPTIONS_KEYS.ADVANCED_ITEMS}
              text={DICTIONARY.SHOW_SETTING.ADVANCED_ITEMS}
              onClick={resetItemIdAndVersion}
            />
            <ActionCheckbox storageKey={SELECTED_SHOW_OPTIONS_KEYS.WORKFLOW_STATE} text={DICTIONARY.SHOW_SETTING.WORKFLOW_STATE} />
            <ActionCheckbox storageKey={SELECTED_SHOW_OPTIONS_KEYS.THUMBNAILS} text={DICTIONARY.SHOW_SETTING.THUMBNAILS} />
            {isTemplateSitesEnabled && (
              <ActionCheckbox storageKey={SELECTED_SHOW_OPTIONS_KEYS.TEMPLATE_SITES} text={DICTIONARY.SHOW_SETTING.TEMPLATE_SITES} />
            )}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
