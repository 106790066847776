import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { PublishItemInput } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { publish } from 'src/features/publish/graphql/publish';

export const usePublishItem = () => {
  const { url } = useXMTenant();

  return useAuthMutation(
    (axiosInstance) => (input: PublishItemInput) =>
      axiosInstance?.post(getSitecoreGraphQLEndpoint(url), publish.execute_publish_item(input)),
  );
};
