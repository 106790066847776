import { Flex, FormControl, Input, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { AxiosResponse } from 'axios';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { CLIENT_LANGUAGE, DICTIONARY, isGuid } from 'src/constants';
import { useGetItemByPath } from 'src/features/common/hooks/useGetItemByPath';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { GraphQLResponse, ItemResponse } from 'src/features/common/models';
import { isSameGuid } from 'src/features/common/utils/guidUpdater';
import { FieldErrorMessage } from 'src/features/fieldBuilder/components/fields/Common';
import { PathInputProps } from '../models';

export const PathInput: FC<React.PropsWithChildren<PathInputProps>> = ({
  input,
  field,
  builderField,
  form,
  setFieldValue,
  setFieldTouched,
  submitForm,
  updateErrors,
  children,
}) => {
  const { urlContext } = useUrlSharing(UrlParams.sc_lang);
  const [path, setPath] = useState(field.value);
  const [prevValue, setPrevValue] = useState(field.value);

  const onSuccess = useCallback(
    async (data: AxiosResponse<GraphQLResponse<ItemResponse>, any>) => {
      if (data?.data?.data?.item) {
        if (!isSameGuid(field.value, data?.data?.data?.item.itemId)) {
          setFieldTouched(input, true);
          setFieldValue(input, data?.data?.data?.item.itemId, true);
          setTimeout(() => submitForm()); // give a delay to update state
        }
      } else {
        updateErrors && updateErrors(true);
        setFieldTouched(input, true);
        setFieldValue(input, '', true);
      }
    },
    [field.value, setFieldTouched, input, setFieldValue, submitForm, updateErrors],
  );

  const { data } = useGetItemByPath(
    { path, lang: urlContext.sc_lang || CLIENT_LANGUAGE },
    { enabled: !!path, keepPreviousData: true, onSuccess },
  );

  if (!!field.value && field.value !== prevValue) {
    setPath(field.value);
    setPrevValue(field.value);
  }

  const defaultValue = useMemo(() => {
    if (!field.value) {
      return '';
    }

    return isGuid(field.value) ? data?.data?.data?.item?.path : path;
  }, [data?.data?.data?.item?.path, field.value, path]);

  return (
    <FormControl isInvalid={!!form.errors.id && form.touched.id} display="flex" flexDirection="column" gap="2">
      <Flex gap="3" justify="center" align="center">
        <Input
          aria-label={input}
          bgColor="white"
          defaultValue={defaultValue}
          key={defaultValue}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => {
            setPath(e.target.value);
            if (!e.target.value) {
              updateErrors && updateErrors(!!form.errors.id && form.touched.id);
              setFieldTouched(input, true);
              setFieldValue(input, '', true);
            }
          }}
          isDisabled={!builderField.canEdit}
        />
        <Text fontSize="md">
          <T _str={DICTIONARY.OR} />
        </Text>
        {children}
      </Flex>
      <FieldErrorMessage message={form.errors.id} />
    </FormControl>
  );
};
