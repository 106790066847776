import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, IconButton, Tooltip } from '@chakra-ui/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import 'src/features/fieldBuilder/components/Section.scss';
import { SectionHeader } from 'src/features/fieldBuilder/components/SectionHeader';
import { GroupedBuilderFields } from '../models';

interface SectionProps {
  section: GroupedBuilderFields;
  index: number;
}

export const Section: FC<React.PropsWithChildren<SectionProps>> = ({ section, index, children }) => {
  const t = useTranslate();

  return (
    <Box background="gray.50" borderRadius="8" key={section.sectionName} mt={index ? '6' : '1'}>
      <AccordionItem style={{ border: 'none' }}>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              style={{
                borderRadius: isExpanded ? '0.5rem 0.5rem 0 0' : '0.5rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
              _hover={{ backgroundColor: 'initial' }}
              justifyContent="space-between"
            >
              <SectionHeader name={section.sectionName} />
              <Tooltip label={t(DICTIONARY[isExpanded ? 'COLLAPSE' : 'EXPAND'])}>
                <IconButton
                  variant="ghost"
                  size="xs"
                  as={Box}
                  _hover={{ background: 'initial' }}
                  aria-label={DICTIONARY[isExpanded ? 'COLLAPSE' : 'EXPAND']}
                  icon={<AccordionIcon fontSize="24" />}
                />
              </Tooltip>
            </AccordionButton>
            <AccordionPanel>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Box>
  );
};
