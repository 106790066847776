import { useCallback, useMemo, useState } from 'react';
import { ItemTreeProps } from 'src/features/common/treeComponent/components/ItemTree';
import { AncestorsResponseData } from 'src/features/common/treeComponent/models';

export const useGetAncestorsDummy = (): AncestorsResponseData => ({ ancestors: [], isLoading: false });

export const useItemTree = (props: ItemTreeProps) => {
  const { useGetInitialAncestors, initialId } = props;
  const [initialIdState] = useState(initialId);
  const [isLoadAncestors, setLoadAncestors] = useState(!!initialIdState);

  const { ancestors, isLoading } = (useGetInitialAncestors || useGetAncestorsDummy)(initialIdState || '', isLoadAncestors, props.language);
  const realAncestors = useMemo(() => (isLoadAncestors ? ancestors : []), [ancestors, isLoadAncestors]);

  const stopLoadAncestors = useCallback(() => setLoadAncestors(false), []);

  return {
    ancestors: realAncestors,
    stopLoadAncestors,
    isLoading,
  };
};
