import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { CreateItemInput, GraphQLResponse, ItemCreateResponse } from 'src/features/common/models';
import { items } from 'src/features/common/queries/items';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export const useCreateItem = () => {
  const { url } = useXMTenant();

  return useAuthMutation(
    (axiosInstance) => (input: CreateItemInput) =>
      axiosInstance?.post<GraphQLResponse<ItemCreateResponse>>(getSitecoreGraphQLEndpoint(url), items.create_item(input)),
  );
};
