/* istanbul ignore file */

import { FC } from 'react';
import { InsertOption } from 'src/features/common/models';
import { useCreateItemMenuSearchTableWrapper } from 'src/features/search/hooks';
import { CreateItemMenu } from 'src/features/shared/createItem/components';

type CreateItemMenuSearchTableWrapperProps = {
  insertOptions: InsertOption[];
  canCreate: boolean;
  parentInsertOptions: InsertOption[];
  parentCanCreate: boolean;
  parentId: string;
  itemId: string;
};

export const CreateItemMenuSearchTableWrapper: FC<CreateItemMenuSearchTableWrapperProps> = (props) => {
  const { insertOptions, canCreate, parentInsertOptions, parentCanCreate, parentId, itemId } = props;
  const onCreateSuccess = useCreateItemMenuSearchTableWrapper();

  return (
    <CreateItemMenu
      insertOptions={insertOptions}
      canCreate={canCreate}
      parentInsertOptions={parentInsertOptions}
      parentCanCreate={parentCanCreate}
      parent={parentId}
      itemId={itemId}
      onCreateSuccess={onCreateSuccess}
    />
  );
};
