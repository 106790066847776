import { useMemo } from 'react';
import { useGetSites } from 'src/features/common/hooks/useGetSites';

export const useGetSitesWrapper = () => {
  const { data, refetch, isLoading } = useGetSites({ keepPreviousData: true });

  const sites = useMemo(() => data?.data?.data?.solutionSites ?? [], [data]);
  const collections = useMemo(() => data?.data?.data?.siteCollections ?? [], [data]);

  return { sites, collections, isLoading, refetch };
};
