/* istanbul ignore file */

import { useDisclosure } from '@chakra-ui/react';
import { createContext, FC, useContext, useMemo } from 'react';
import { SelectMediaModal } from 'src/features/shared/selectMediaModal/components/SelectMediaModal';
import { useSelectMediaContextProvider } from 'src/features/shared/selectMediaModal/hooks';
import { DataSourcesList } from 'src/features/shared/selectMediaModal/models';

export type SelectMediaCallbackType = {
  rootItemIdOrPath?: string;
  selectType: 'image' | 'file' | 'link';
  onSelected: (itemId: string) => void;
  preSelectedMediaId?: string;
} & DataSourcesList;

export type SelectMediaContextData = {
  selectMedia: (options: SelectMediaCallbackType) => void;
};

export const SelectMediaContext = createContext<SelectMediaContextData>({
  selectMedia: () => {},
});

export const SelectMediaContextProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const disclosure = useDisclosure();

  const {
    selectedFolderIds,
    setSelectedFolderIds,
    selectState,
    openModal,
    rootIdOrPath,
    selectedMediaId,
    setSelectedMediaId,
    onSelectButtonClick,
  } = useSelectMediaContextProvider({ ...disclosure });

  const providerValue: SelectMediaContextData = useMemo(
    () => ({
      selectMedia: openModal,
    }),
    [openModal],
  );

  return (
    <>
      <SelectMediaContext.Provider value={providerValue}>{children}</SelectMediaContext.Provider>
      {selectState && (
        <SelectMediaModal
          {...disclosure}
          {...selectState}
          selectedMediaId={selectedMediaId}
          setSelectedMediaId={setSelectedMediaId}
          selectedFolderIds={selectedFolderIds}
          setSelectedFolderIds={setSelectedFolderIds}
          rootItemIdOrPath={rootIdOrPath}
          selectType={selectState.selectType || 'image'}
          onSelectedClick={onSelectButtonClick}
        />
      )}
    </>
  );
};

export const useSelectMediaContext = () => {
  return useContext(SelectMediaContext);
};
