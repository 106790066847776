import { IconButton, Menu, MenuButton, MenuDivider, MenuList, Tooltip } from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { CreateItemButton } from 'src/features/shared/createItem/components';
import { CreateItemMenuProps } from 'src/features/shared/createItem/models';

export const CreateItemMenu: FC<CreateItemMenuProps> = ({
  insertOptions,
  canCreate,
  parentInsertOptions,
  parentCanCreate,
  parent,
  itemId,
  onCreateSuccess,
}) => {
  const t = useTranslate();

  return (
    <Menu isLazy lazyBehavior="keepMounted">
      <Tooltip label={t(DICTIONARY.CREATE_ITEM)}>
        <MenuButton as={IconButton} variant="ghost" size="sm" alignSelf="end" data-testid="create-action-button">
          <Icon path={mdiPlus} boxSize="5" />
        </MenuButton>
      </Tooltip>
      <MenuList>
        {insertOptions.map((option) => (
          <CreateItemButton
            key={option.name}
            option={option}
            isDisabled={!canCreate}
            type="child"
            parent={itemId}
            onCreateSuccess={onCreateSuccess}
          />
        ))}
        {!!parentInsertOptions?.length && (
          <>
            {!!insertOptions?.length && <MenuDivider />}
            {parentInsertOptions.map((option) => (
              <CreateItemButton
                key={option.templateId}
                option={option}
                isDisabled={!parentCanCreate}
                type="sibling"
                parent={parent}
                onCreateSuccess={onCreateSuccess}
              />
            ))}
          </>
        )}
      </MenuList>
    </Menu>
  );
};
