import { HIDE_VERSION_ID, VALID_FROM_ID, VALID_TO_ID } from 'src/constants';

export const ITEM_DETAILS = `
  fragment ItemDetails on Item {
    itemId(format: D)
    name
    displayName
    path
  }
`;

export const CREATE_DETAILS = `
  fragment CreateDetails on Item {
    createdBy: field(name: "__Created by") {
      value
    }
    createdAt: field(name: "__Created") {
      value
    }
  }
`;

export const UPDATE_DETAILS = `
  fragment UpdateDetails on Item {
    updatedBy: field(name: "__Updated by") {
      value
    }
    updatedAt: field(name: "__Updated") {
      value
    }
  }
`;

export const PUBLISH_RESTRICTIONS_DETAILS = `
  fragment PublishRestrictionsDetails on Item {
    validFrom: field(name: "${VALID_FROM_ID}") {
      value
    }
    validTo: field(name: "${VALID_TO_ID}") {
      value
    }
    isHidden: field(name: "${HIDE_VERSION_ID}") {
      value
    }
  }
`;

export const VERSION_DETAILS = `
  fragment VersionDetails on Item {
    version
    versionName
  }
`;

export const TEMPLATE_DETAILS = `
  fragment TemplateDetails on Item {
    template {
      fullName
      name
      templateId(format: D)
    }  
  }
`;
