import { Flex, FlexProps, Image, ImageProps, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { fileSizeTransformer } from 'src/features/common/utils/fileSizeTransformer';
import {
  CardFileInfoProps,
  CardIconProps,
  CardInfoPanelProps,
  CardMediaDimensionsProps,
  CardProps,
} from 'src/features/media/card/model/card.model';

const border: FlexProps = { outline: '1px solid', outlineColor: 'gray.100', borderRadius: 'md' };
const commonStyles: FlexProps = { p: '3', userSelect: 'none', direction: 'column', bgColor: 'white', w: 'min-content' };

const CardImageWrapper: FC<React.PropsWithChildren> = ({ children }) => (
  <Flex align="center" justify="center" boxSize="184" borderRadius="md">
    {children}
  </Flex>
);

export const CardImage: FC<ImageProps> = (props) => (
  <CardImageWrapper>
    <Image {...props} maxHeight="184" />
  </CardImageWrapper>
);

export const CardIcon: FC<CardIconProps> = ({ icon }) => <CardImageWrapper>{icon}</CardImageWrapper>;

export const CardInfoPanel: FC<React.PropsWithChildren<CardInfoPanelProps>> = ({ title, children }) => (
  <Flex pt="3" gap="1" direction="column">
    <Text fontWeight="semibold" title={title} noOfLines={1} maxWidth="184">
      {title}
    </Text>
    {children}
  </Flex>
);

export const CardMediaDimensions: FC<CardMediaDimensionsProps> = ({ dimensions }) => (
  <Text variant="small">{dimensions && `${dimensions} px`}&nbsp;</Text>
);

export const CardFileInfo: FC<CardFileInfoProps> = ({ extension, size }) => {
  const fileExtension = useMemo(() => (extension.startsWith('.') ? extension.substring(1) : extension), [extension]);
  const fileSize = useMemo(() => fileSizeTransformer(size, 1), [size]);

  return (
    <Text variant="small">
      <Text as="span" textTransform="uppercase">
        {fileExtension}
      </Text>
      <Text as="span">{` - ${fileSize}`}</Text>
    </Text>
  );
};

export const Card: FC<React.PropsWithChildren<CardProps>> = ({ id, isSelected, onSelect, children }) => {
  const activeCard = useMemo<FlexProps>(
    () => (isSelected ? ({ outlineColor: 'primary.500', outline: '2px solid', _hover: { borderColor: 'transparent' } } as FlexProps) : {}),
    [isSelected],
  );

  return (
    <Flex
      _hover={{ outlineColor: 'gray.200' }}
      {...commonStyles}
      {...border}
      {...activeCard}
      onClick={() => onSelect(id)}
      height="fit-content"
      data-testid="media-card"
    >
      {children}
    </Flex>
  );
};
