/* istanbul ignore file */

import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { useRefetchAndBlink } from 'src/features/shared/treeTable/hooks/useRefetchAndBlink';

export type RefetchFunction = (() => Promise<any>) | undefined;

export type TreeTableRefetchContextType = ((itemId?: string) => void) | undefined;
export type TreeTableSetRefetchContextType = (value: RefetchFunction) => void;

export const TreeTableRefetchContext = createContext<TreeTableRefetchContextType>(undefined);
export const TreeTableSetRefetchContext = createContext<TreeTableSetRefetchContextType>(() => {});

export const TreeTableDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const [refetch, setRefetch] = useState<RefetchFunction>(undefined);

  const refetchAndBlink = useRefetchAndBlink(refetch);

  return (
    <TreeTableSetRefetchContext.Provider value={setRefetch}>
      <TreeTableRefetchContext.Provider value={refetchAndBlink}>{children}</TreeTableRefetchContext.Provider>
    </TreeTableSetRefetchContext.Provider>
  );
};

export const useTreeTableRefetchContext = () => {
  const refetch = useContext(TreeTableRefetchContext);

  if (!refetch) {
    throw new Error('useTreeTableRefetchContext must be used within TreeTableDataProvider');
  }

  return refetch;
};
export const useTreeTableSetRefetchContext = () => {
  const setRefetch = useContext(TreeTableSetRefetchContext);

  if (!setRefetch) {
    throw new Error('useTreeTableSetRefetchContext must be used within TreeTableDataProvider');
  }

  return setRefetch;
};
