import { useAuthMutation } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { UpdateItemInput } from 'src/features/common/models';
import { items } from 'src/features/common/queries/items';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export const useUpdateItemField = () => {
  const { url } = useXMTenant();

  return useAuthMutation(
    (axiosInstance) => (item: UpdateItemInput) => axiosInstance?.post(getSitecoreGraphQLEndpoint(url), items.update_item(item)),
  );
};
