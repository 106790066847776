import { Flex, Hide, Table, Tbody, Text, Tr } from '@chakra-ui/react';
import { FC } from 'react';
import { NUM_OF_COLS_TABLE } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { stopPropagation } from 'src/features/common/utils/stopPropagation';
import { useDisplayOptions } from 'src/features/millerColumns/hooks/useDisplayOptions';
import {
  ActionsMenuSearchTableWrapper,
  CreateItemMenuSearchTableWrapper,
  NoSearchResults,
  SearchTableHeader,
  SearchTd,
} from 'src/features/search/components';
import { useFetchPageOnScrollEnd, useSearchData } from 'src/features/search/hooks';
import { LoadingRow } from 'src/features/shared/loadingRow/LoadingRow';
import { ItemTypeCell } from 'src/features/shared/treeTable/components/ItemTypeCell';
import { NameCell } from 'src/features/shared/treeTable/components/NameCell';
import { WorkflowStateCell } from 'src/features/shared/treeTable/components/WorkFlowStateCell';
import { useOpenFieldEditor } from 'src/features/shared/treeTable/hooks/useOpenFieldEditor';

interface SearchTableViewProps {
  boxRef: React.RefObject<HTMLDivElement>;
  fallback?: React.ReactNode;
}

export const SearchTableView: FC<SearchTableViewProps> = ({ boxRef, fallback }) => {
  const { data, hasEnabledSearch, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } = useSearchData();
  const handleOpenEditor = useOpenFieldEditor();
  const isPending = useFetchPageOnScrollEnd(boxRef, hasNextPage, fetchNextPage);
  const { showThumbnail, showLanguages, showWorkflow } = useDisplayOptions();
  const numberOfCols = NUM_OF_COLS_TABLE - +!showLanguages - +!showWorkflow;

  if (!hasEnabledSearch && fallback) {
    return <>{fallback}</>;
  }

  if (!isLoading && !data.length) {
    return <NoSearchResults />;
  }

  return (
    <Table marginBottom="10">
      <SearchTableHeader />
      <Tbody>
        {data.map((row) => (
          <Tr
            key={`${row.itemId}-${row.language.iso}-${row.version}`}
            _hover={{ backgroundColor: 'chakra-subtle-bg' }}
            onClick={() => handleOpenEditor(row.itemId, row.version, row.language.name)}
            cursor="pointer"
            data-testid={`searchRow-${row.itemId}`}
          >
            <SearchTd fontWeight="semibold">
              <NameCell
                canExpand={false}
                showThumbnail={showThumbnail}
                thumbnailUrl={row.thumbnailUrl}
                name={row.displayName || row.name}
                icon={<Icon path={row.icon} boxSize="5" />}
                noChildrenSpace="0"
              />
            </SearchTd>
            <SearchTd>
              <ItemTypeCell type={row.type} />
            </SearchTd>
            <Hide below="md">
              <SearchTd>
                <Text isTruncated minWidth="28" maxWidth="xs" noOfLines={2} whiteSpace="unset" title={row.createdBy}>
                  {row.createdBy}
                </Text>
              </SearchTd>
              <SearchTd>
                <Text isTruncated minWidth="28" maxWidth="xs" noOfLines={2} whiteSpace="unset" title={row.updatedBy}>
                  {row.updatedBy}
                </Text>
              </SearchTd>
            </Hide>
            {showLanguages && <SearchTd textTransform="uppercase">{row.language.iso}</SearchTd>}
            {showWorkflow && (
              <SearchTd>
                <WorkflowStateCell
                  workflowName={row.workflow?.displayName || ''}
                  displayName={row.workflowState?.displayName || ''}
                  final={row.workflowState?.final || false}
                />
              </SearchTd>
            )}
            <SearchTd onClick={stopPropagation}>
              <Flex direction="row" gap="1">
                {(row.access?.canCreate ||
                  row.parent.access.canCreate ||
                  !!row.insertOptions?.length ||
                  !!row.parent.insertOptions.length) && (
                  <CreateItemMenuSearchTableWrapper
                    insertOptions={row.insertOptions}
                    canCreate={row.access.canCreate}
                    parentInsertOptions={row.parent.insertOptions}
                    parentCanCreate={row.parent.access.canCreate}
                    parentId={row.parentId}
                    itemId={row.itemId}
                  />
                )}
                {(row?.access?.canCreate || row?.access?.canDelete || row?.access?.canRename || row?.hasPresentation) && (
                  <ActionsMenuSearchTableWrapper
                    canCreate={row.access.canCreate}
                    canDelete={row.access.canDelete}
                    canRename={row.access.canRename}
                    hasChildren={row.hasChildren}
                    hasPresentation={row.hasPresentation}
                    itemId={row.itemId}
                    name={row.name}
                    displayName={row.displayName}
                    version={row.version}
                    canWriteDisplayName={row.canWriteDisplayName}
                  />
                )}
              </Flex>
            </SearchTd>
          </Tr>
        ))}
        {(isFetchingNextPage || isPending) && <LoadingRow numberOfRows={3} numberOfCols={numberOfCols} />}
      </Tbody>
    </Table>
  );
};
