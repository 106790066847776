import { DICTIONARY } from 'src/dictionary';
import { generate_uuidv4 } from 'src/features/common/utils/generate_uuidv4';

export const ITEM_CREATION_REGEX = /^[\w*$][\w\s-$]*(\(\d{1,}\)){0,1}$/;

export const GUID_REGEX =
  /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/;

export const SC_ICONS_SRC = {
  SUPPORT_PAGE_LINK: 'https://www.sitecore.com/company/contact-us',
  LOGO: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/logo-explorer',
  NO_RESULTS: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-magnify-close',
  CACTUS: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-cactus',
  CACTUS_GRAY: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-cactus-neutral',
  ROCKET: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-rocket-launch',
  MAGNIFYING_GLASS_X_GRAY: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-magnify-close-neutral',
};

export const NORMALIZE_ID_WITHOUT_DASH_REGEX = /[{}-]/g;

export const SMOOTH_SCROLL_TIMEOUT = 350;

export const BLOCK_OTHER_INPUTS = '-1';

export const DEFAULT_CONTEXT = { itemId: '110d559f-dea5-42ea-9c1c-8a5df7e70ef9', language: 'en', siteName: 'website' };

export const CLIENT_LANGUAGE = 'en';

export const DISPLAY_LANGUAGE = 'English : English';

export const DATETIME_FORMAT = 'MMM D YYYY, h:mm:ss A';

export const DATETIME_ISO_REGEX = /-|:|\.\d{3}/g;

export const DATETIMEPICKER_FORMAT = 'MM/dd/yyyy h:mm aa';

export const SITECORE_ISO_DATE_FORMAT = 'YYYYMMDDTHHmmSS';

export const VERSION_DATETIME_FORMAT = 'MMM D, YYYY, hh:mm A';

export const DATE_FORMAT = 'MMM D, YYYY';

export const TENANT_COOKIE_NAME = 'explorer.tenantName';

export const FIELD_EDITOR_SELECTED_TAB_KEY = 'selectedTabs:fieldEditorTab';

const optionsMainPart = 'explorer:showOptions:';
export const SELECTED_SHOW_OPTIONS_KEYS = {
  LANGUAGES: `${optionsMainPart}:${DICTIONARY.SHOW_SETTING.LANGUAGES}`,
  HIDDEN_ITEMS: `${optionsMainPart}:${DICTIONARY.SHOW_SETTING.HIDDEN_ITEMS}`,
  ADVANCED_ITEMS: `${optionsMainPart}:${DICTIONARY.SHOW_SETTING.ADVANCED_ITEMS}`,
  WORKFLOW_STATE: `${optionsMainPart}:${DICTIONARY.SHOW_SETTING.WORKFLOW_STATE}`,
  THUMBNAILS: `${optionsMainPart}:${DICTIONARY.SHOW_SETTING.THUMBNAILS}`,
  TEMPLATE_SITES: `${optionsMainPart}:${DICTIONARY.SHOW_SETTING.TEMPLATE_SITES}`,
};

export const SELECTED_SHOW_OPTIONS_DEFAULT_VALUES: Record<string, boolean> = {
  [SELECTED_SHOW_OPTIONS_KEYS.LANGUAGES]: true,
  [SELECTED_SHOW_OPTIONS_KEYS.HIDDEN_ITEMS]: false,
  [SELECTED_SHOW_OPTIONS_KEYS.ADVANCED_ITEMS]: false,
  [SELECTED_SHOW_OPTIONS_KEYS.WORKFLOW_STATE]: true,
  [SELECTED_SHOW_OPTIONS_KEYS.THUMBNAILS]: true,
  [SELECTED_SHOW_OPTIONS_KEYS.TEMPLATE_SITES]: false,
};

export const ORG_ID_STORAGE_KEY = 'explorer:org_id';

export const TENANT_NAME_STORAGE_KEY = 'explorer:tenant_Name';

export const LANGUAGE_STORAGE_KEY = 'explorer:language';

export const TABLE_LIST_EXPANDED_STORAGE_KEY = 'explorer:table_list_expanded';

export const SECTIONS_ERRORS_STORAGE_KEY = `explorer:section_errors:${generate_uuidv4()}`;

export const EMPTY_VALUE = '--';

export const XA_ADVANCED_ITEMS_ID = '423773c2-795c-4e52-b996-9e6a465b7c1f';

export const XA_DICTIONARY_ID = '0a2847e6-9885-450b-b61e-f9e6528480ef';

export const VERSION_NAME_ID = '9857f526-390f-48df-b6d1-1a97cc328e8f';

export const VALID_FROM_ID = 'C8F93AFE-BFD4-4E8F-9C61-152559854661';

export const VALID_TO_ID = '4C346442-E859-4EFD-89B2-44AEDF467D21';

export const HIDE_VERSION_ID = 'B8F42732-9CB8-478D-AE95-07E25345FB0F';

export const DISPLAY_NAME_ID = 'B5E02AD9-D56F-4C41-A065-A133DB87BDEB';

export const MEDIA_LIBRARY_ID = '3D6658D8-A0BF-4E75-B3E2-D050FABCF4E1';

export const THROTTLE_MILLER_DELAY = 230;

export const THROTTLE_LANGUAGE_DELAY = 3000;

export const THROTTLE_DEFAULT = 100;

export const MASTER_DB = 'master';

export const EDGE_DB = 'experienceedge';

export const APP_NAME = 'Explorer';

export const SXA_HEADLESS_SITE = 'Headless Site';

export const SXA_HEADLESS_TENANT = 'Headless Tenant';

export const TEMPLATE_FOLDER = 'Folder';

export const SIZE_UNITS = ['BYTES', 'KB', 'MB', 'GB', 'TB', 'PB'];

export const DEFAULT_MEDIA_LIBRARY_ID = '{3d6658d8-a0bf-4e75-b3e2-d050fabcf4e1}';

export const MEDIA_LIBRARY_PATH = '/sitecore/media library';

export const ONE_MB = 1048576;

export const IMAGE_TYPES = ['jpeg', 'jpg', 'gif', 'png', 'svg', 'bmp', 'ico'];

export const UPLOAD_MAX_SIZE = ONE_MB * 1024 * 2; // 2 Gb

export const stringOverload = (value: string, ...params: string[]): string => {
  params.forEach((el, index) => (value = value.replace(`{${index}}`, el)));
  return value;
};

export const isGuid = (string: string) => !!string.match(GUID_REGEX);

export const NORMALIZE_GUID_REGEX = /{?(.{8})-?(.{4})-?(.{4})-?(.{4})-?(.{12})}?$/;

export const NUMBER_NORMALIZATION_REGEX = /^[+-]?(\d+(\.\d*)?|\.\d+)?$/;

export const CACHE_LIFETIME = 600000;

export const LARGE_ICON_SIZE = '5rem';

export const MENU_HEIGHT = 180;

export const THUMBNAIL = { URL: { WIDTH: 54, HEIGHT: 54 }, SIZE: { WIDTH: 12, HEIGHT: 12 } };

export const LOCK_NONE_SXA_ID = '_NoneSxaIdentifier';

export const ENV = {
  DEV: 'dev',
  QA: 'qa',
  STAGING: 'staging',
  PRE_PRODUCTION: 'preprod',
  PRODUCTION: 'prod',
};

export const LINK_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
  MEDIA: 'media',
};

export const ROUTES = {
  EDITOR_VIEW: 'editor',
};

export const TOAST_DEFAULTS = { variant: 'subtle', position: 'bottom-left' };

export const DEBOUNCE_EVENT_TIME = 50;

export const LIST_VIEW_SORTING = 'listView:sorting';

export const OPEN_IN_PAGES_ROUTE = '/editor';

export const NUM_OF_COLS_TABLE = 7;

export const FILTERS_BAR_VISIBLE_STORAGE_KEY = 'explorer:filters_bar_visible';

export const SITE_TEMPLATE_ID = '{A2B9FDC3-F641-4966-94A5-B63944DC39DE}';

export const FOLDER_TEMPLATE_ID = '{A87A00B1-E6DB-45AB-8B54-636FEC3B5523}';

export const CREATE_ITEM_TOAST_DURATION = 10_000;

export const CREATE_SUPPORT_CASE_COMPONENT_NAME = 'create-support-case';

export const CREATE_SUPPORT_CASE_BUTTON_SELECTOR = `[data-support-case="${CREATE_SUPPORT_CASE_COMPONENT_NAME}"]`;

export const UPLOAD_SVG_RESPONSE_ERROR = 'Network Error';
