import { MASTER_DB, THUMBNAIL } from 'src/constants';
import { FeatureFlags } from 'src/features/common/flags/features';

export const sites = {
  get_sites: (features: Partial<FeatureFlags>) => {
    return {
      query: `
        query GetSites {
          solutionSites(input: { database: "${MASTER_DB}", includeNonSxaSites: true }) {
            id(format: D)
            name
            displayName
            language {
              iso
            }
            rootItem {
              itemId (format: D)
            }
            startItem {
              thumbnailUrl(options: { alwaysIncludeServerUrl: true, thumbnail: true, width: ${THUMBNAIL.URL.WIDTH} height: ${
        THUMBNAIL.URL.HEIGHT
      } })
            }
            siteCollection {
              id(format: D)
              displayName
            }
            ${features.xm_technicalTemplateSites ? 'isTemplateSite: property (key: "isTemplateSite")' : ''}
            settings {
              createdBy: field(name: "__Created by") {
               value
              }
              updatedBy: field(name: "__Updated by") {
               value
              }
            }
          }          
        }
        `,
    };
  },
};
