import { Box } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { FC } from 'react';
import { MENU_HEIGHT } from 'src/constants';
import { GenericFieldView } from 'src/features/fieldBuilder/components/fields/GenericFieldView';
import { DroplinkSelection, useDroplink } from 'src/features/fieldBuilder/components/fields/hooks';
import { GenericField } from 'src/features/fieldBuilder/models';

const DroplinkOption: FC<DroplinkSelection> = ({ label, path }) => (
  <Box boxSize="full" title={path} data-testid="sc-droplink">
    {label}
  </Box>
);

export const Droplink: FC<GenericField> = ({ field }) => {
  const { builderField, value, selections, error, onReset, onUpdate } = useDroplink(field);

  return (
    <GenericFieldView field={builderField} errorMsg={error} onReset={onReset}>
      <Select
        value={value}
        options={selections}
        isDisabled={!field.canEdit || !builderField.source}
        onChange={onUpdate}
        maxMenuHeight={MENU_HEIGHT}
        formatOptionLabel={DroplinkOption}
        menuPosition="fixed"
        isInvalid={!!error || !!builderField.error}
        className="ef-droplink"
      />
    </GenericFieldView>
  );
};
