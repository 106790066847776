/* istanbul ignore file */

import { print } from 'graphql';
import { loader } from 'graphql.macro';

export const getItemQuery = loader('./getItem.graphql');
export const getItemQueryString = print(getItemQuery);

export const getAncestorsQuery = loader('./getAncestors.graphql');
export const getAncestorsQueryString = print(getAncestorsQuery);
