/* istanbul ignore file */

import { FC } from 'react';
import { ActionsMenu } from 'src/features/fieldEditor/components/ActionsMenu';

type ActionsMenuSearchTableWrapperProps = {
  canCreate: boolean;
  canDelete: boolean;
  canRename: boolean;
  hasChildren: boolean;
  hasPresentation: boolean;
  itemId: string;
  name: string;
  version: number;
  displayName?: string;
  canWriteDisplayName?: boolean;
};

export const ActionsMenuSearchTableWrapper: FC<ActionsMenuSearchTableWrapperProps> = (props) => {
  const { canCreate, canDelete, canRename, hasChildren, hasPresentation, itemId, name, displayName, canWriteDisplayName, version } = props;

  return (
    <ActionsMenu
      variant="ghost"
      canCreate={canCreate}
      canDelete={canDelete}
      canRename={canRename}
      shouldRenderRenameButton={canRename}
      hasChildren={hasChildren}
      hasPresentation={hasPresentation}
      itemId={itemId}
      name={name}
      displayName={displayName}
      canWriteDisplayName={canWriteDisplayName}
      version={version}
      onDuplicateItemSuccess={() => Promise.resolve()}
      onDeleteItemSuccess={() => {}}
      onDeleteItemError={() => {}}
    />
  );
};
