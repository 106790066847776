import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { AxiosResponse } from 'axios';
import { memo, useCallback, useEffect, useState } from 'react';
import { DICTIONARY, MASTER_DB } from 'src/constants';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import { AddItemVersionResponse, GraphQLResponse } from 'src/features/common/models';
import { useAddItemVersion } from 'src/features/versions/hooks/useAddItemVersion';

export interface DuplicateVersionModalProps {
  version: number;
  autoIncrVersion: number;
  isOpen: any;
  onClose: any;
}

export const DuplicateVersionModal = memo(({ version, autoIncrVersion, isOpen, onClose }: DuplicateVersionModalProps) => {
  const [versionName, setVersionName] = useState('');

  const { sc_itemid, sc_lang, setUrlContext } = useUrlStrictContext(UrlParams.sc_itemid | UrlParams.sc_site | UrlParams.sc_lang);
  const { mutate: addItemVersion } = useAddItemVersion();

  useEffect(() => {
    return () => {
      !isOpen && setVersionName('');
    };
  }, [isOpen]);

  const handleSave = useCallback(() => {
    addItemVersion(
      {
        itemId: sc_itemid,
        database: MASTER_DB,
        language: sc_lang,
        versionName,
        version,
      },
      {
        onSuccess(data: AxiosResponse<GraphQLResponse<AddItemVersionResponse>>) {
          const item = data.data.data.addItemVersion.item;
          setUrlContext({ sc_version: item.version });
        },
      },
    );

    onClose();
  }, [addItemVersion, onClose, sc_itemid, sc_lang, setUrlContext, version, versionName]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <ModalHeader>
            <T _str={DICTIONARY.DUPLICATE_VERSION} version={version} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Text mb="5">
                <T _str={DICTIONARY.DUPLICATE_MESSAGE} />
                &nbsp;
                <Text as="span" fontWeight="semibold">
                  <T _str={DICTIONARY.VERSION_NUMBER} version={autoIncrVersion} />
                </Text>
              </Text>
              <FormLabel>
                <T _str={DICTIONARY.NAME_VERSION_MESSAGE} />
                &nbsp;(
                <T _str={DICTIONARY.OPTIONAL} />)
              </FormLabel>
              <Input autoFocus value={versionName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVersionName(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button data-testid="cancel-btn" variant="ghost" onClick={onClose}>
                <T _str={DICTIONARY.CANCEL} />
              </Button>
              <Button data-testid="submit-btn" variant="solid" onClick={handleSave} type="submit">
                <T _str={DICTIONARY.SAVE} />
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
});

DuplicateVersionModal.displayName = DuplicateVersionModal.name;
