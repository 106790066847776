import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { Query, UseQueryOptions } from 'react-query';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { inventory } from 'src/features/common/config/tenant-config';
import { IGetTenantsRequestPayload, IGetTenantsResponse } from './tenantsModel';

type TenantType = AxiosResponse<IGetTenantsResponse>;
const MAX_ATTEMPS = 3;
const DELAY = 2000;

export const useGetTenant = (
  { organizationId, tenantName = '', systemId = inventory.xmSystemId, pageNumber = 1, pageSize = 100 }: IGetTenantsRequestPayload,
  useQueryOptions?: Omit<UseQueryOptions<TenantType, unknown, TenantType, any[]>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const toast = useToast();
  const [attempt, setAttempt] = useState(0);

  const refetchInterval = useCallback(
    (data: TenantType | undefined, { state: { dataUpdateCount, errorUpdateCount } }: Query<TenantType, unknown, TenantType, any[]>) => {
      setAttempt(dataUpdateCount || errorUpdateCount);
      return (dataUpdateCount || errorUpdateCount) >= MAX_ATTEMPS || !!data?.data?.data?.length ? false : DELAY;
    },
    [setAttempt],
  );

  const { data, isLoading: tenantLoading } = useAuthQuery(
    ['get-tenant', pageNumber, pageSize, organizationId, systemId, tenantName],
    async (axiosInstance) =>
      axiosInstance.get<IGetTenantsResponse>(inventory.get_tenants.url(organizationId, systemId, tenantName, pageNumber, pageSize)),
    { ...useQueryOptions, refetchInterval },
    toast,
  );

  const isLoading = tenantLoading || (!!attempt && attempt < MAX_ATTEMPS && !data?.data?.data?.length);
  const isFailed = attempt >= MAX_ATTEMPS;

  return { data: data?.data?.data?.[0], isLoading, isFailed };
};
