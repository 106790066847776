import { MediaItemQueryInput, UploadMediaInput } from 'src/features/media/models';

export const media = {
  get_media_data: (input: MediaItemQueryInput) => {
    return {
      query: `
        query GetMediaData($input: MediaItemQueryInput!) {
          mediaItem(where: $input) @include(if: ${!!input.mediaItemId}) {
            url(options: { alwaysIncludeServerUrl: true, thumbnail: true })
            fullImageUrl: url(options: { alwaysIncludeServerUrl: true })
            alt
            extension
            size
            title
            innerItem {
              displayName
            }
          }          
        }
        `,
      variables: {
        input,
      },
    };
  },
  upload_media: (input: UploadMediaInput) => {
    return {
      query: `
        mutation DeleteMediaItem($input: UploadMediaInput!) {
          uploadMedia(input: $input) {
            presignedUploadUrl
          }
        }
      `,
      variables: { input },
    };
  },
};
