import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ORG_ID_STORAGE_KEY, TENANT_NAME_STORAGE_KEY } from 'src/constants';
import { AuthorizationFailedPage } from 'src/features/common/components/customErrorPages/AuthorizationFailedPage';
import { getAuthSettings } from 'src/features/common/config';
import { getValueFromStorages } from 'src/features/common/utils/storages';

interface Auth0ProviderParams {
  organization: string | undefined;
  tenantName: string | undefined;
}

export const Auth0ProviderWithHistory: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { domain, clientId, audience, systemId } = getAuthSettings();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { error, errorMsg, organization, tenantName } = useMemo(() => {
    const error = searchParams.get('error') || '';
    const errorMsg = searchParams.get('error_description') || '';
    const organization = searchParams.get('organization');
    const tenantName = searchParams.get('tenantName');

    return { organization, tenantName, error, errorMsg };
  }, [searchParams]);

  const onRedirectCallback = useCallback(
    (appState?: AppState | undefined) => navigate(appState?.returnTo || window.location.pathname, { replace: true }),
    [navigate],
  );

  if (!domain || !clientId || !audience) {
    throw new Error('Missing auth provider properties');
  }

  const getAuth0ProviderParams = useMemo((): Auth0ProviderParams => {
    const { storages: orgStorages } = getValueFromStorages(ORG_ID_STORAGE_KEY);
    const { value: storedTenantName, storages: tenantStorages } = getValueFromStorages(TENANT_NAME_STORAGE_KEY);

    if (organization && tenantName) {
      return { organization, tenantName: tenantName };
    } else if (!organization && tenantName) {
      return { organization: undefined, tenantName: tenantName };
    } else if (organization && !tenantName) {
      return organization === orgStorages.session
        ? { organization, tenantName: storedTenantName }
        : { organization, tenantName: undefined };
    } else if (orgStorages.session && tenantStorages.session) {
      return { organization: orgStorages.session, tenantName: tenantStorages.session };
    } else if (orgStorages.local && tenantStorages.local) {
      return { organization: orgStorages.local, tenantName: tenantStorages.local };
    }

    return { organization: undefined, tenantName: undefined };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error || errorMsg) {
    return <AuthorizationFailedPage title={error} message={errorMsg} />;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
        organization_id: getAuth0ProviderParams.organization,
        tenant_name: getAuth0ProviderParams.tenantName,
        system_id: systemId,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
