import { useMemo } from 'react';
import { TreeTableRowProps } from 'src/features/shared/treeTable/components/TreeTableRow';
import { TreeListItem } from 'src/features/shared/treeTable/models';

export const useUpdateRowProps = (props: TreeTableRowProps, item: TreeListItem | undefined) => {
  const { type } = props;

  return useMemo(() => {
    if (type === 'site' || !item) return props;

    return { ...props, ...item };
  }, [type, item, props]);
};
