import { FC } from 'react';
import { Layout, LayoutHeader, LayoutMain } from 'src/features/temporary/Layout';
import { Header } from './Header';

export const ExplorerLayout: FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Layout>
      <LayoutHeader>
        <Header />
      </LayoutHeader>
      <LayoutMain display="flex" flexDirection="column" p="0" overflow="hidden">
        {children}
      </LayoutMain>
    </Layout>
  );
};
