import { BoxProps, Flex } from '@chakra-ui/react';
import { MillerColumnCreate } from 'src/features/millerColumns/components/MillerColumnCreate';
import { useMillerColumnsContainer } from 'src/features/millerColumns/hooks/useMillerColumnsContainer';
import { MillerItemTemplateData } from 'src/features/millerColumns/models';
import { MILLER_COLUMNS_ADAPTIVE_VIEWS } from 'src/features/millerColumns/utils/adaptiveView';
import { MillerColumn, MillerColumns } from 'src/features/shared/millerColumns';
import { MillerColumnItemTemplate } from './MillerColumnItemTemplate';

const stickyStyles: BoxProps = { position: 'sticky', bottom: '-2.5', background: 'white', paddingBottom: '2' };

export const MillerColumnsContainer = () => {
  const { columnsData, onClick } = useMillerColumnsContainer();

  return (
    <Flex direction="column">
      <MillerColumns<MillerItemTemplateData>
        onColumnItemClick={(itemData, selection, selectionsMap) => onClick({ itemData, selection, selectionsMap })}
        columnsView={MILLER_COLUMNS_ADAPTIVE_VIEWS}
        selectionsMap={columnsData.selections}
      >
        {columnsData.data.map((elem, index) => (
          <MillerColumn<MillerItemTemplateData>
            afterColumnComponent={index ? <MillerColumnCreate {...elem} /> : <></>}
            key={`mcs-${index}`}
            ContentTemplate={MillerColumnItemTemplate}
            templateContainer={{ display: 'flex', alignItems: 'center' }}
            templateData={elem.items}
            columnIndex={index}
            afterColumnComponentStyle={stickyStyles}
          />
        ))}
      </MillerColumns>
    </Flex>
  );
};
