import { IconButton, Tooltip } from '@chakra-ui/react';
import { mdiMinusCircleOutline } from '@mdi/js';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { Icon } from 'src/features/common/components/icon/Icon';
import { useTranslate } from 'src/features/common/hooks/useTranslate';

export type CleanFieldButtonProps = {
  onClean: () => void;
};

export const CleanFieldButton: FC<CleanFieldButtonProps> = ({ onClean }) => {
  const t = useTranslate();

  return (
    <Tooltip label={t(DICTIONARY.REMOVE)}>
      <IconButton
        size="sm"
        aria-label={t(DICTIONARY.REMOVE)}
        onClick={onClean}
        icon={<Icon path={mdiMinusCircleOutline} boxSize="5" />}
        data-testid="sc-clean-field"
        variant="ghost"
      />
    </Tooltip>
  );
};
