import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UrlContext } from '../models';

export enum UrlParams {
  None = 0,
  organization = 1,
  tenantName = 2,
  sc_site = 4,
  sc_itemid = 8,
  sc_version = 16,
  sc_lang = 32,
  sc_collection = 64,
  sc_type = 128,
}

export const useUrlSharing = (urlParams: UrlParams) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const urlContext = useMemo(() => {
    const urlContext = new UrlContext();

    if (urlParams === UrlParams.None) {
      return urlContext;
    }

    const keys = Object.keys(UrlParams);
    for (const key of keys) {
      if (!isNaN(Number(key))) {
        continue;
      }

      const index = Object.keys(UrlParams).indexOf(key);
      const flag = Object.values(UrlParams)[index];
      if (flag === UrlParams.None) {
        continue;
      }

      if (typeof flag === 'number' && (urlParams & flag) === flag) {
        Object.assign(urlContext, { [key]: searchParams.get(key) || undefined });
      }
    }

    return urlContext;
  }, [searchParams, urlParams]);

  const setUrlContext = useCallback(
    (context: Partial<UrlContext>, shouldOverride = false) => {
      let isUpdated = false;

      Object.entries(context).forEach((record: [string, string | null]) => {
        if (searchParams.get(record[0])?.toString() !== record[1]?.toString()) {
          record[1] ? searchParams.set(record[0], record[1]) : searchParams.delete(record[0]);
          isUpdated = true;
        }
      });

      isUpdated && setSearchParams(searchParams, shouldOverride ? { replace: true } : {});
    },
    [searchParams, setSearchParams],
  );

  return { urlContext, setUrlContext };
};
