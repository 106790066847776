import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { CACHE_LIFETIME } from 'src/constants';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { useFeatures } from 'src/features/common/flags/features';
import { GraphQLResponse, SolutionSites } from 'src/features/common/models';
import { sites } from 'src/features/common/queries/sites';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

type SitesType = AxiosResponse<GraphQLResponse<SolutionSites>>;

export const useGetSites = (
  useQueryOptions?: Omit<UseQueryOptions<SitesType, unknown, SitesType, string[]>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const toast = useToast();
  const { url } = useXMTenant();
  const { xm_technicalTemplateSites } = useFeatures();

  return useAuthQuery(
    ['get-sites'],
    async (axiosInstance) => {
      return axiosInstance?.post<GraphQLResponse<SolutionSites>>(
        getSitecoreGraphQLEndpoint(url),
        sites.get_sites({ xm_technicalTemplateSites }),
      );
    },
    { ...useQueryOptions, staleTime: CACHE_LIFETIME },
    toast,
  );
};
