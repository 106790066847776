import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getSitecoreGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse, ItemResponse } from 'src/features/common/models';
import { items } from 'src/features/common/queries/items';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export const useGetItemByPath = (
  input: { path: string; lang: string },
  useQueryOptions?:
    | Omit<
        UseQueryOptions<AxiosResponse<GraphQLResponse<ItemResponse>>, unknown, AxiosResponse<GraphQLResponse<ItemResponse>>, string[]>,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const toast = useToast();
  const { url } = useXMTenant();

  return useAuthQuery(
    ['get-item-path', input.path, input.lang],
    async (axiosInstance) => {
      return axiosInstance?.post<GraphQLResponse<ItemResponse>>(
        getSitecoreGraphQLEndpoint(url),
        items.get_item_by_path(input.path, input.lang),
      );
    },
    { ...useQueryOptions },
    toast,
  );
};
