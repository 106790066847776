import { Flex, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY, IMAGE_TYPES } from 'src/constants';
import { GenericFieldView } from 'src/features/fieldBuilder/components/fields/GenericFieldView';
import { useImage } from 'src/features/fieldBuilder/components/fields/hooks/useImage';
import { GenericField } from 'src/features/fieldBuilder/models';
import { MediaPreview, UploadArea } from 'src/features/media/components';
import { CleanFieldButton } from 'src/features/shared/cleanFieldButton/CleanFieldButton';

const tooltips = { dropMessage: DICTIONARY.DROP_IMAGE, supportTypes: IMAGE_TYPES.join(', ') };

export const Image: FC<GenericField> = ({ field }) => {
  const { builderField, value, mediaItem, uploadPath, errorMsg, onChange, onReset, onRemove, sourceIds } = useImage({ field });

  return (
    <GenericFieldView field={builderField} errorMsg={errorMsg} onReset={onReset}>
      <Text>
        <T _str={DICTIONARY.IMAGE_FIELD_TITLE} />
      </Text>
      <Flex gap="3" pt="2" direction="column">
        <UploadArea
          type="image"
          tooltips={tooltips}
          onUpload={(result) => onChange({ id: result.Id, alt: result.Name })}
          onSelect={(id) => onChange({ id })}
          canEdit={builderField.canEdit}
          allowedExtensions={IMAGE_TYPES}
          uploadPath={uploadPath}
          sourceIds={sourceIds}
          mediaId={value.mediaid}
        />
        {value.mediaid && mediaItem && (
          <MediaPreview mediaItem={mediaItem} canEdit={field.canEdit} altText={value.alt} onAltChange={(alt) => onChange({ alt })}>
            {field.canEdit && <CleanFieldButton onClean={onRemove} />}
          </MediaPreview>
        )}
      </Flex>
    </GenericFieldView>
  );
};
