import { ButtonGroup, Wrap } from '@chakra-ui/react';
import { FC } from 'react';
import { SwitchBar } from 'src/features/common/components/actionsBar/SwitchBar';
import { VisualOptionsMenu } from 'src/features/common/components/actionsBar/VisualOptionsMenu';
import { SiteCollectionsFilter } from 'src/features/common/components/filters/SiteCollectionsFilter';
import { SiteTypesFilter } from 'src/features/common/components/filters/SiteTypesFilter';

export const SwitchBarColumnView: FC = () => {
  return (
    <SwitchBar>
      <ButtonGroup as={Wrap} variant="filter">
        <VisualOptionsMenu />
        <SiteCollectionsFilter />
        <SiteTypesFilter />
      </ButtonGroup>
    </SwitchBar>
  );
};
