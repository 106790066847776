import { Box, Button, Flex, FormErrorMessage, Icon, Text } from '@chakra-ui/react';
import { mdiAlertCircle } from '@mdi/js';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { FieldName } from 'src/features/fieldBuilder/components/FieldName';
import { TemplateTags } from 'src/features/fieldBuilder/components/TemplateTags';
import { GenericField } from 'src/features/fieldBuilder/models';

export const ToolTip: FC<{ text: string }> = ({ text }) => <Text variant="small">{text}</Text>;

export const LengthCounter: FC<{ count: number; threshold?: number }> = ({ count, threshold }) => {
  return (
    <Text minWidth="fit-content">
      {count} {!!threshold && `/ ${threshold}`}
    </Text>
  );
};

export const FieldErrorMessage: FC<{ message?: string }> = ({ message }) => (
  <FormErrorMessage css={{ margin: 0 }}>
    <FieldError message={message} />
  </FormErrorMessage>
);

export const FieldError: FC<{ message?: string }> = ({ message }) => (
  <Flex gap="2" alignItems="center" color="danger">
    <Icon height={4} width={4} fontSize="24">
      <path d={mdiAlertCircle} fill="currentColor" />
    </Icon>
    {message && (
      <Text variant="small" color="danger">
        <T _str={message} />
      </Text>
    )}
  </Flex>
);

export const ResetButton: FC<{ onClick: () => void; isDisabled: boolean }> = ({ onClick, isDisabled = false }) => {
  return (
    <Button
      variant="ghost"
      colorScheme="primary"
      size="sm"
      isDisabled={isDisabled}
      onClick={onClick}
      alignSelf="start"
      data-testid="field-reset"
    >
      <T _str={DICTIONARY.RESET} />
    </Button>
  );
};

export const FieldTopping: FC<GenericField> = ({ field }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" gap="4">
      <FieldName value={field.name} />
      <TemplateTags field={field} />
    </Flex>
  );
};

export const FieldFooterWithCounter: FC<{ toolTip: string; count: number; threshold?: number }> = ({ toolTip, count, threshold }) => {
  return (
    <Flex gap={1} justify="space-between">
      <ToolTip text={toolTip} />
      <LengthCounter count={count} threshold={threshold} />
    </Flex>
  );
};

export const AccessRestrictedWrapper: FC<React.PropsWithChildren<{ canEdit: boolean }>> = ({ canEdit, children }) => {
  const t = useTranslate();
  const title = t(DICTIONARY.FIELD_ACCESS_RESTRICTED);

  if (canEdit) return <>{children}</>;
  return <Box title={title}>{children}</Box>;
};
