import { SingleValue } from 'chakra-react-select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DICTIONARY } from 'src/constants';
import { useTranslate } from 'src/features/common/hooks/useTranslate';
import { isSameGuid } from 'src/features/common/utils/guidUpdater';
import { useFieldActions } from 'src/features/fieldBuilder/hooks/useFieldActions';
import { useGetFieldSource } from 'src/features/fieldBuilder/hooks/useGetFieldSource';
import { BuilderField } from 'src/features/fieldBuilder/models';
import { formatIdWithBraces } from 'src/features/fieldBuilder/utils/source';

const emptyValue = { value: '', label: '', path: '' } as DroplinkSelection;
export type DroplinkSelection = { value: string; label: string; path: string };

export interface useDroplinkProps {
  builderField: BuilderField;
  value: DroplinkSelection | null;
  error: string;
  selections: DroplinkSelection[];
  onUpdate: (selection: SingleValue<DroplinkSelection>) => void;
  onReset: () => Promise<void>;
}

export const useDroplink = (field: BuilderField): useDroplinkProps => {
  const [value, setValue] = useState<DroplinkSelection | null | undefined>(undefined);

  const { builderField, resetField, updateField, updateErrors } = useFieldActions(field);
  const { source, isLoading } = useGetFieldSource(field.fieldId, field.source);
  const t = useTranslate();

  const selections = useMemo<DroplinkSelection[]>(
    () => source.map<DroplinkSelection>((r) => ({ value: formatIdWithBraces(r.itemId), label: r.displayName, path: r.path })),
    [source],
  );

  const defaultValue = useMemo(
    () => (field.value ? selections.find((r) => isSameGuid(r.value, field.value)) ?? emptyValue : null),
    [selections, field.value],
  );

  const verifiedValue = useMemo(() => (value === undefined ? defaultValue : value), [value, defaultValue]);

  const error = useMemo(() => {
    if (isLoading) {
      return '';
    } else if (!field.source) {
      return t(DICTIONARY.FIELD_DATASOURCE_EMPTY);
    } else if (verifiedValue && !verifiedValue.value) {
      return t(DICTIONARY.SELECT_LINK_OR_REBASE_LINKS_DB);
    }

    return '';
  }, [field.source, isLoading, verifiedValue, t]);

  useEffect(() => updateErrors(!!error || !!builderField.error), [error, builderField.error, updateErrors]);

  const onReset = useCallback(async () => {
    const fieldUpdated = await resetField(field.fieldId);

    if (fieldUpdated && fieldUpdated.value) {
      const selection = selections.find((r) => isSameGuid(r.value, fieldUpdated.value));
      setValue(selection ?? emptyValue);
    } else if (fieldUpdated && !fieldUpdated.value) {
      setValue(null);
    }
  }, [field.fieldId, selections, resetField, setValue]);

  const onUpdate = useCallback(
    (selection: SingleValue<DroplinkSelection>) => {
      if (selection) {
        updateField(field.fieldId, selection.value);
        setValue(selection);
      }
    },
    [field.fieldId, updateField, setValue],
  );

  return { value: verifiedValue, builderField, error, selections, onReset, onUpdate };
};
