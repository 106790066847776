import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useState } from 'react';
import { DICTIONARY } from 'src/constants';
import { GenericField } from 'src/features/fieldBuilder/models';
import { ExternalLink, InternalLink, MediaLink } from 'src/features/generalLink/components';
import { useLink } from 'src/features/generalLink/hooks';
import { FieldTopping } from './Common';

export const GeneralLink: FC<GenericField> = ({ field }) => {
  const { builderField, value, onSubmit, onReset, covertLinkTypeToTabIndex, updateErrors } = useLink({ field });
  const [tabIndex, setTabIndex] = useState(covertLinkTypeToTabIndex(value.linktype));

  return (
    <Flex direction="column" gap={3}>
      <FieldTopping field={builderField} />
      <Tabs size="sm" onChange={(index) => setTabIndex(index)} defaultIndex={tabIndex} variant="soft-rounded" w="100%" isLazy>
        <TabList data-testid="linkTypes" borderRadius="full" border="1px" borderColor="chakra-border-color" p="1" mb="3" w="fit-content">
          <Tab>
            <T _str={DICTIONARY.INTERNAL} />
          </Tab>
          <Tab>
            <T _str={DICTIONARY.EXTERNAL} />
          </Tab>
          <Tab>
            <T _str={DICTIONARY.MEDIA} />
          </Tab>
        </TabList>
        <TabPanels data-testid="linkContainer">
          <TabPanel p="0">
            <InternalLink field={builderField} value={value} onSubmit={onSubmit} onReset={onReset} updateErrors={updateErrors} />
          </TabPanel>
          <TabPanel p="0">
            <ExternalLink field={builderField} value={value} onSubmit={onSubmit} onReset={onReset} updateErrors={updateErrors} />
          </TabPanel>
          <TabPanel p="0">
            <MediaLink field={builderField} value={value} onSubmit={onSubmit} onReset={onReset} updateErrors={updateErrors} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
